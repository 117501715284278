import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class EditGroupDialogReducer extends BaseReducer {
    public static handle(state: StM.IEditGroupDialogStoreState = new StM.EditGroupDialogStoreState(), action: any): StM.IEditGroupDialogStoreState {
        switch (action.type) {
            case 'Dialogs/Admin/EditGroup/Open': {
                return <StM.IEditGroupDialogStoreState>_.assign({}, state, {
                    isOpened: true,
                    userId: action.userId,
                    sectionType: action.sectionType
                });
            }
            case 'Dialogs/Admin/EditGroup/Close': {
                return <StM.IEditGroupDialogStoreState>_.assign({}, state, { isOpened: false });
            }
            default: {
                return state;
            }
        }
    }
}
