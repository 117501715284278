import BaseReducer from '../baseReducer';
import { StM } from '../../modules';

export default class AdminGroupsReducer extends BaseReducer {
    static handle(state: Array<StM.IGroupStoreState> = new Array<StM.IGroupStoreState>(), action: any): Array<StM.IGroupStoreState> {
        switch (action.type) {
            case 'Admin/Groups/Init': {
                const groups = <Array<StM.IGroupStoreState>>action.payload;
                return [...groups];
            }
            case 'Admin/Groups/Update': {
                const groups = state.map(g => g.id === action.payload.id ? new StM.GroupStoreState(action.payload) : g);
                return [...groups];
            }
            default: {
                return state;
            }
        }
    }
}
