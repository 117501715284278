import './dialogs.scss';
import './cancelConfirmation.scss';

import ClassNames from 'classnames';
import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import * as StM from '../../models/store';
import { SrvM } from '../../modules';
import * as ActM from '../../actions';
import {Modal} from '../../components';

let utils = new SrvM.Utils();

interface ICancelConfirmationProps {
    isShown: boolean;
    messageType: string;
    title: string;
    note: string;
    isOpenByService?: boolean;
    session: StM.ISessionStoreState;
    match: any;
    agreeWithCancelationFee: boolean;
    acceptButtonText: string;
    rejectButtonText: string;

    closeDialog: (isOpenByService?: boolean) => void;
    showSpinner: () => any;
    hideSpinner: () => any;
    yesCallback: () => void;
}

interface ICancelConfirmationState {}

class CancelConfirmation extends React.Component<ICancelConfirmationProps, ICancelConfirmationState> {
    constructor(props: ICancelConfirmationProps) {
        super(props);
    }

    render() {
        if (this.props.isShown) {
            const messageType = this.props.messageType || StM.MessageTypes.Success || StM.MessageTypes.Warning || StM.MessageTypes.Error;
            const rootClasses = ClassNames('alert-dialog', messageType)
            return (
                <Modal 
                    customZIndex={999999} classes={rootClasses} 
                    ref={(elem) => this.setPositionAlertDialog(elem)} 
                    closeHandler={() => this.props.closeDialog(this.props.isOpenByService)} 
                    dialogName={StM.DialogNames.CancelConfirmation}
                >
                    <div className="modal-dialog dialog">
                        <div className="modal-dialog-header">
                            <div className="title success"><span className="ico-alert-dialog" /><span>{this.props.title}</span></div>
                        </div>
                        <div className="modal-dialog-body">
                            {this.renderNote(this.props.note)}
                            <div className="cancel-btns-wrapper">
                                <button className='btn close-btn' onClick={(e) => this.closeClick(e)}>{this.props.rejectButtonText}</button>
                                <button className='btn btn-red' onClick={(e) => {this.props.yesCallback(); this.closeClick(e)}}>{this.props.acceptButtonText}</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            );
        } else {
            return null;
        }
    }

    setPositionAlertDialog = (elem: any) => {
        var windowHeight: any = document.body.clientHeight;
        var dialog: any = document.getElementsByClassName('alert-dialog')[0];
        var dialogHeight: any = dialog.offsetHeight;
        var topPosition = (windowHeight - dialogHeight)/2;
        dialog.style.top = topPosition + "px";
    }

    renderNote(string: string) {
        if(string) {
            return <div className="note" dangerouslySetInnerHTML={{__html: string}}></div>
        } 
        return null;
    }

    closeClick(e: any) {
        if(e) {e.stopPropagation();}
        this.props.closeDialog(this.props.isOpenByService);
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState) => {
    let confirmationDialog = state.dialogs.cancelConfirmation;
    let sessionInfoDialog = state.dialogs.sessionInfo;
    let title = StM.Messages[confirmationDialog.msgKey] || 'Success!';
    let note = confirmationDialog.message ? `<p>${confirmationDialog.message}</p>` : StM.Notes.getNote(confirmationDialog.msgKey, state.club);
    let goods = state.basket.goods;
    let session: StM.ISessionStoreState = null;
    let basketCopy = null;
    let originCopy = null;
    let agreeWithCancelationFee = true;

    if (sessionInfoDialog.isOpened) {
        let sessions = utils.getPageSessions(state);
        originCopy = state.dialogs.sessionInfo.session;
        const checkIsCourse = (basketItem: StM.ISessionStoreState, session: StM.ISessionStoreState): boolean => {
            const isCourse = !!session && !!session.series && session.series.isCourse && !!basketItem.series && basketItem.series.isCourse;
            return isCourse && basketItem.series.id === session.series.id;
        };

        if (sessionInfoDialog.from == StM.SessionInfoFromTypes.basket) {
            session = _.find(sessions, { basketId: + sessionInfoDialog.id }) || _.find(sessions, { id: + sessionInfoDialog.id });
            basketCopy = originCopy = session;
        }
        else {
            originCopy = state.dialogs.sessionInfo.session;
            basketCopy = goods.find(item => checkIsCourse(item, originCopy) || item.id === +sessionInfoDialog.id);
            if (basketCopy) originCopy = basketCopy;
        }

        if (session && session.basketId) {
            let fromBasket = _.find(goods, (item) => { return checkIsCourse(item, session) || item.basketId === session.basketId; });
            if (!fromBasket) session.basketId = null;
        }
        if (session && session.id) {
            let fromBasket = _.find(goods, (item) => { return checkIsCourse(item, session) || item.id === session.id; });
            if (fromBasket) session.basketId = fromBasket.basketId;
        }
    }

    session = basketCopy || originCopy; 
    
    return {
        isShown: confirmationDialog.isOpened,
        messageType: confirmationDialog.messageType,
        title: title,
        note: note,
        isOpenByService: confirmationDialog.isOpenByService,
        session,
        match: state.app.match,
        agreeWithCancelationFee: agreeWithCancelationFee,
        acceptButtonText: confirmationDialog.acceptButtonText,
        rejectButtonText: confirmationDialog.rejectButtonText,
        yesCallback: confirmationDialog.yesCallback
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        showSpinner: () => dispatch(ActM.AppActions.showSpinner()),
        hideSpinner: () => dispatch(ActM.AppActions.hideSpinner()),
        closeDialog: (isOpenByService?: boolean) => dispatch(ActM.DialogActions.close(StM.DialogNames.CancelConfirmation, null, isOpenByService)),
        showAlert: (msgKey: string, messageType: string = StM.MessageTypes.Error, message?: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, { msgKey, messageType, message }))
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(CancelConfirmation);
