export class CoachFeeTierDto implements ICoachFeeTierDto{
    id: number;
    title: string;
    subtitle: string;
    orderNumber: number;
    isNoCoach: boolean;
    isShowForUser: boolean;
    isDefaultForUser: boolean;
    courts: ICourtDto[];

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : '';
        this.title = dto && dto.title ? dto.title : '';
        this.subtitle = dto && dto.subtitle ? dto.subtitle : '';
        this.orderNumber = dto && dto.orderNumber ? dto.orderNumber : 0;
        this.isNoCoach = dto && dto.isNoCoach;
        this.isShowForUser = dto && dto.isShowForUser;
        this.isDefaultForUser = dto && dto.isDefaultForUser;
        this.courts = dto && dto.courts ? dto.courts : [];
    }
}
