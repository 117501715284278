/// <reference path="../../../typings/index.d.ts" />
import * as axios from 'axios';

import {BaseApiService} from '../baseApiService';
import Constants from '../../constants';
import * as DtoModule from '../../models/dto';
import * as StoreModule from '../../models/store';

export class CountriesApiService extends BaseApiService {
    private static _instance: CountriesApiService;

    constructor(config?: any) {
        if (typeof CountriesApiService._instance == "undefined") {
            super(config);
            CountriesApiService._instance = this;
        }
        return CountriesApiService._instance;
    }

    public getList() {
        return StoreModule.Countries.List.sort((a, b)=>{
                if(a.name < b.name) return -1;
                if(a.name > b.name) return 1;
                return 0;
        });
    }
}
