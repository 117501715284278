import * as Redux from 'redux';
import * as _ from 'lodash';

import { ThunkService } from '../services/thunkService';
import * as StM from '../models/store';
import * as ActM from './';

export class NotificationActions {

    static init(notifications: Array<StM.INotificationStoreState>): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Notification/Init',
                payload: notifications
            });
        };
    }

    static clear(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Notification/Clear',
            });
            dispatch({
                type: 'Notification/Close',
            });
        };
    }

    static toggle(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            let state = getState();
            if (state.basket.isShown) {
                dispatch({
                    type: 'Basket/Close',
                });
            }
            dispatch(ActM.BaseActions.setContentHeight());
            dispatch({
                type: 'Notification/Toggle',
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Notification/Close',
            });
        };
    }

    static getForCurrentUser(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.notification.getForCurrentUser(club, pricingTiers)
            .then((notifications: Array<StM.INotificationStoreState>) => {
                return dispatch(NotificationActions.init(notifications));
            }).catch(() => {

            });
        };
    }

    static create(notification: StM.INotificationStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            thunkService.api.notification.create(notification, club, pricingTiers)
            .then((notification: StM.INotificationStoreState) => {
                return notification;
            }).catch(() => {

            });
        };
    }

    static dismiss(notification: StM.INotificationStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            dispatch({
                type: 'Notification/Dismiss',
                payload: notification.id
            });
                
            thunkService.api.notification.dismiss(notification, club, pricingTiers)
            .then((notification: StM.INotificationStoreState) => {
                return notification;
            }).catch(() => {

            });
        };
    }

    static ignore(sessionId: number, userId: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            const noty = _.find(getState().notificationBoard.notifications, { targetId : sessionId});
            if(noty){
                dispatch({
                    type: 'Notification/Dismiss',
                    payload: noty.id
                });
            }
            thunkService.api.notification.ignore(noty, club, pricingTiers)
            .then((notification: StM.INotificationStoreState) => {
                return notification;
            }).catch(() => {

            });
        };
    }

    static markAsRead(notification: StM.INotificationStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
             dispatch({
                    type: 'Notification/MarkAsRead',
                    payload: notification.id
                });
                
            thunkService.api.notification.markAsRead(notification, club, pricingTiers)
            .then((notification: StM.INotificationStoreState) => {
                return notification;
            }).catch(() => {

            });
        };
    }

    static removeBySessionId(sessionId: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const notifications = getState().notificationBoard.notifications;
            const removingNots = _.filter(notifications, {targetId: sessionId, targetType: StM.NotificationTargetType.Session});
            _.each(removingNots, (removingNot) => {
                dispatch({
                    type: 'Notification/Remove',
                    payload: removingNot.id
                });
            });
        };
    }
}
