import Axios, * as axios from 'axios';
import * as _ from 'lodash';

import { store } from '../store/store';
import { MapperService } from './mapperService';
import { ActM, StM } from '../modules';
import { AuthenticationService } from './authenticationService';

enum RequestType {
    GET,
    POST,
    PUT,
    DELETE
}

const ClientExceptionMarkers = [
    'EBT.SOF.BusinessLayer.Exceptions.BusinessRuleViolationException:', 
    'card_declined/generic_decline'
];

export class BaseApiService {
    protected config: axios.AxiosRequestConfig;
    protected mapper: MapperService;
    protected service: axios.AxiosInstance;
    protected authSrv: AuthenticationService;
    protected exclusionsForUnauthorizedRequests: Array<string>;
    protected static pendingRequests = new Set<string>();

    constructor(config?: axios.AxiosRequestConfig) {
        this.config = config || {};
        this.service = Axios.create({
            baseURL: config.baseURL || '',
            withCredentials: false,
            headers: {}
        });
        this.initNoCacheInterceptor();
        this.mapper = new MapperService();
        this.authSrv = new AuthenticationService();
        this.exclusionsForUnauthorizedRequests = ['/api/v2/notifications', '/api/v2/users/current'];
    }

    public get(url: string, config?: axios.AxiosRequestConfig) {
        return this.processRequest(RequestType.GET, url, null, config);
    }

    public post(url: string, data?: any, config?: axios.AxiosRequestConfig) {
        return this.processRequest(RequestType.POST, url, data || {}, config);
    }

    public put(url: string, data?: any, config?: axios.AxiosRequestConfig) {
        return this.processRequest(RequestType.PUT, url, data || {}, config);
    }

    public delete(url: string, config?: axios.AxiosRequestConfig) {
        return this.processRequest(RequestType.DELETE, url, null, config);
    }

    public postForm(url: string, data?: any, config?: axios.AxiosRequestConfig) {
        const formData = this.encodeFormData(data) || '';
        const formConfig = <axios.AxiosRequestConfig>_.assign({}, config, this.config);
        formConfig.headers = formConfig.headers || {};
        formConfig.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        return this.processRequest(RequestType.POST, url, formData, formConfig);
    }

    private processRequest(type: RequestType, url: string, data?: any, config?: axios.AxiosRequestConfig) {
        const processedConfig = this.getProcessedConfig(config);
        const completeUrl = processedConfig.baseURL + url;

        const currentWindow : any = window;

        if(!currentWindow.kiosk){
            if (BaseApiService.pendingRequests.has(completeUrl)) return Promise.reject();
            BaseApiService.pendingRequests.add(completeUrl);
        }
            
        let request: () => Promise<any>;
        switch (type) {
            case RequestType.GET:
                request = () => this.service.get(url, processedConfig);
                break;
            case RequestType.POST:
                request = () => this.service.post(url, data, processedConfig);
                break;
            case RequestType.PUT:
                request = () => this.service.put(url, data, processedConfig);
                break;
            case RequestType.DELETE:
                request = () => this.service.delete(url, processedConfig);
                break;
            default:
                request = () => Promise.resolve();
                break;
        }

        return Promise.resolve(request())
            .then(response => this.handleResponse(response, completeUrl))
            .catch(error => this.handleError(error, completeUrl));
    }

    private handleResponse(data: any, completeUrl: string) {
        BaseApiService.pendingRequests.delete(completeUrl);
        return Promise.resolve(data);
    }

    private handleError(data: any, completeUrl: string) {
        const documentWindow: any = window;
        BaseApiService.pendingRequests.delete(completeUrl);
        if (401 === data.response.status && documentWindow.ISADMIN) {
            store.dispatch(ActM.AppActions.hideSpinner());
            store.dispatch(ActM.RouteActions.goHome());
        } else if (410 === data.response.status && documentWindow.ISADMIN) {
            store.dispatch(ActM.AppActions.hideSpinner());
            store.dispatch(ActM.DialogActions.close(StM.AdminDialogNames.EditUser));
        } 
        else {
            const state = store.getState() as StM.IGlobalStoreState;
            const isAdmin = documentWindow.ISADMIN && !this.authSrv.isInRole(StM.Roles.Member, state.user);

            if(!isAdmin && 401 === data.response.status){
                if(_.some(this.exclusionsForUnauthorizedRequests, (ex) => completeUrl.indexOf(ex) >= 0)) {
                    return Promise.reject(data);
                }
            }

            const responseData = data.response && data.response.data;
            let message = null;
            if(isAdmin && responseData) {
                message = responseData.exceptionMessage || responseData.message || responseData.error_description || responseData;
            } else if (responseData && responseData.exceptionMessage && ClientExceptionMarkers.some(marker => responseData.exceptionMessage.indexOf(marker) >= 0)) {
                message = responseData.exceptionMessage.substring(responseData.exceptionMessage.indexOf(":") + 2);
            }
            store.dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, {
                        msgKey: StM.MessagesKey.GenericRequestError,
                        messageType: StM.MessageTypes.Error,
                        message: message
                    }, true));
            store.dispatch(ActM.AppActions.hideSpinner());       
            return Promise.reject(data);
        }
    }

    private initNoCacheInterceptor(){
        this.service.interceptors.request.use((config) => {
                config.headers['X-Requested-With'] = 'XMLHttpRequest';
                config.headers['Expires'] = '-1';
                config.headers['Cache-Control'] = "no-cache,no-store,must-revalidate,max-age=-1,private";
                if (IS_IE && config.method == 'get') {
                    let url = config.url;
                    url = url + (-1 === url.indexOf('?') ? '?' : '&') + "__=" + Number(new Date());
                    config.url = url;
                }
                return config;
            }, (err) => {
                return Promise.reject(err);
            });
    }

    protected encodeUriParam(uri: string): string{
        let encoded = encodeURIComponent(uri);
        encoded = encoded.replace(/\./g, '%2E');
        encoded = encoded.replace(/\-/g, '%2D');
        return encoded;
    }

    private getProcessedConfig(config: axios.AxiosRequestConfig): any {
        let currentConfig = <axios.AxiosRequestConfig>_.assign({}, config, this.config);
        currentConfig.headers = currentConfig.headers || {};
        currentConfig.headers = this.getAuthHeaders(currentConfig.headers);
        return currentConfig;
    }

    private getAuthHeaders(headers: any): any {
        let state = <StM.IGlobalStoreState>store.getState();
        let authorization = 
            state.authenticationTicket && state.authenticationTicket.access_token ? 'Bearer ' + state.authenticationTicket.access_token : '';
        let updatedHeaders = <any>_.assign({}, headers, {
            Authorization: authorization,
            withCredentials: !!authorization
        });
        return updatedHeaders;
    }

    private encodeFormData(data: any): string {
        var encoded = '';
        _.forIn(data, (value: any, key: string) => {
            var encodedProp = encodeURIComponent(value);
            encoded += (!!encoded ? '&' : '') + (<any>'{0}={1}').format(key, encodedProp)
        });
        return encoded;
    }
}
