import moment from 'moment';
import * as _ from 'lodash';

import * as StM from '../../models/store';
import * as SrvM from '../../services';
import { SessionInfoPolicy } from "./sessionInfoPolicy";
import { IBasePolicy, BasePolicy } from '../basePolicy';
import { GroupInfoPolicy } from '../groupInfoPolicy';

export class SessionAvailabilityPolicy extends BasePolicy implements IBasePolicy {
    private authSrv: SrvM.AuthenticationService;
    private groupPolicy = new GroupInfoPolicy();

    constructor(private session: StM.ISessionStoreState, private basketSessions: StM.ISessionStoreState[] = [], private user?: StM.IUserStoreState) {
        super();
        this.authSrv = new SrvM.AuthenticationService();
        if (!user) { this.user = this.getCurrentUser(); }
        if (!basketSessions || !basketSessions.length) this.basketSessions = this.getBasketSessions();
    }

    handle(): boolean {
        const isBasket = this.getIsBasket();
        const isOwner = this.getIsOwner();
        const isInvited = this.getIsInvited();
        const isOpen = !this.session.isHidden;
        const isGroupSession = this.getIsGroupSession();
        const isGroupMemberSession = this.getIsGroupMemberSession();
        const result = isBasket || isOwner || isInvited || isOpen || isGroupSession || isGroupMemberSession;
        return result;
    }

    public getIsOwner(user: StM.IPublicUserStoreState = null): boolean {
        const needUser = user || this.user;
        const isOwner = !!this.session && !!needUser && ((!!this.session.ownerId && this.session.ownerId == needUser.id) || (!!this.session.owner && !!this.session.owner.id && this.session.owner.id == needUser.id));
        return isOwner;
    }

    public getIsBoughtUser(): boolean {
        if (!this.user || !this.session) return false;
        const isBooking = !!this.session.bookings && !!this.session.bookings.length
            && this.session.bookings.some((booking: StM.IBookingStoreState) => {
                return !!booking && !!booking.userId
                    && ((booking.userId === this.user.id) || (!!this.user.group && booking.groupId === this.user.group.id))
                    && this.utils.isActiveBooking(this.session, booking, true);
            });

        return isBooking;
    }

    public getIsTrainer(): boolean {
        if (!this.user || !this.session) return false;
        const isTrainer = this.session.trainerId === this.user.id
            || (this.session.assistants && _.some(this.session.assistants, { id: this.user.id }));

        return isTrainer;
    }

    public getIsInvited(): boolean {
        let isInvited = false;
        if (this.user && this.session && this.session.invitedUsers && this.session.invitedUsers.length > 0) {
            _.each(this.session.invitedUsers, (invitedUser: StM.IUserStoreState) => {
                if (invitedUser.id === this.user.id) {
                    isInvited = true;
                }
            });
        }
        return isInvited;
    }

    public getIsOpenBoard(): boolean {
        const isOpenSession = this.session && this.session.type
            && (
                ((this.session.type === StM.SessionType.Play
                    || this.session.type === StM.SessionType.Private)
                    && !!this.session.seekingId
                )
                || (this.session.type === StM.SessionType.Clinic && !this.session.isHidden)
                || (this.session.type === StM.SessionType.Custom && !this.session.isHidden)
            );
        return this.session && isOpenSession;
    }

    public getIsBasket(): boolean {
        if (this.session && this.session.id) return !!this.getSessionFromBasket();
        return !!this.session && !this.session.id && !!this.session.basketId;
    }

    public getIsMaxPlayers(): boolean {
        let isMaxPlayers = false;
        const bookings = _.filter(this.session.bookings, (booking: StM.IBookingStoreState) => {
            return this.utils.isActiveBooking(this.session, booking);
        });

        if (bookings && bookings.length >= this.session.maxUserCount) {
            isMaxPlayers = true;
        }

        return isMaxPlayers;
    }

    public getIsCanChangeMaxPlayers(): boolean {
        let isCanChangeMaxPlayers = true;
        const bookings = _.filter(this.session.bookings, (booking: StM.IBookingStoreState) => {
            return this.utils.isActiveBooking(this.session, booking);
        });

        if (this.session && this.session.type && this.session.type == StM.SessionType.Play && bookings && bookings.length >= 2) {
            isCanChangeMaxPlayers = false;
        }

        if (this.session && this.session.type
            && this.session.type == StM.SessionType.Private
            && bookings && bookings.length >= 5
        ) {
            isCanChangeMaxPlayers = false;
        }

        return isCanChangeMaxPlayers;
    }

    public getIsHaveFreePlaces() {
        let isHaveFreePlaces = true;
        if (this.user && this.session) {
            const bookings = !!this.session.bookings ? _.filter(this.session.bookings, (booking: StM.IBookingStoreState) => {
                return booking && this.utils.isActiveBooking(this.session, booking);
            }) : [];
            isHaveFreePlaces = bookings && bookings.length < this.session.maxUserCount;
        }
        return isHaveFreePlaces;
    }

    public getIsCanCancelSession(): boolean {
        let isCanCancelSession = true;
        if (this.user && this.session && this.session.bookings && this.session.bookings.length > 0) {
            _.each(this.session.bookings, (booking: StM.IBookingStoreState) => {
                if (booking && (booking.status == StM.BookingStatus.CheckedIn || booking.status == StM.BookingStatus.Paid)) {
                    isCanCancelSession = false;
                }
            });
        }
        return isCanCancelSession;
    }

    public getIsCanDropOut(): boolean {
        let isCanDropOut = true;
        if (this.user && this.session && this.session.bookings && this.session.bookings.length > 0) {
            _.each(this.session.bookings, (booking: StM.IBookingStoreState) => {
                if (booking && booking.status == StM.BookingStatus.CheckedIn) {
                    isCanDropOut = false;
                }
            });
        }
        return isCanDropOut;
    }

    public getIsCanInvitePlayers(): boolean {
        if (!this.user || !this.user.id || !this.session) return false;
        const sessionInfoPolicy = new SessionInfoPolicy(this.session, this.user);
        const isCanChangeMaxPlayers = this.getIsCanChangeMaxPlayers() && (this.getIsOwner() || this.getIsBoughtUser());
        const hasCredits = !!this.session.checkoutCredits;
        const isPrivate = this.session.type === StM.SessionType.Private;
        const isPlay = this.session.type === StM.SessionType.Play && sessionInfoPolicy.getPlayers().length > 1;
        const isGroup = this.groupPolicy.getIsAuthenticated();
        return (isCanChangeMaxPlayers && !(hasCredits && (isPrivate || isPlay)))
            || (!this.session.isHidden && isGroup);
    }

    public getIsAdminCanCancelSession(): boolean {
        let isCanCancel = true;
        if (this.user && this.session) {
            const isBookingsChecked = this.getIsCanCancelSession();
            const isStatusChecked = _.includes([
                StM.SessionStatus.CheckedOut
                , StM.SessionStatus.CheckedOutNoHold
                , StM.SessionStatus.Reopened
            ], this.session.status);
            isCanCancel = this.authSrv.hasPermission('CancelSession', this.user) && isBookingsChecked && isStatusChecked;
        }
        return isCanCancel;
    }

    public getIsAdminCanCloseSession(): boolean {
        let isCanClose = true;
        if (this.user && this.session) {
            const currentDate = this.utils.getCurrentClubDateTime(this.getCurrentClub());
            const isStatusChecked = _.includes([StM.SessionStatus.CheckedOut, StM.SessionStatus.Reopened], this.session.status);
            const isEndedSession = currentDate.isAfter(this.session.endDateTime) || (this.session.series && this.session.series.isCourse && currentDate.isAfter(this.session.series.startDate));
            const hasNoNewParticipants = !!this.session.bookings && !_.some(this.session.bookings, (booking) => {
                return booking.status == StM.BookingStatus.New;
            });
            isCanClose = this.authSrv.hasPermission('CloseSession', this.user) && isStatusChecked && isEndedSession && hasNoNewParticipants;
        }
        return isCanClose;
    }

    public getIsAdminCanSaveSession(isUpdate: boolean = false): boolean {
        let isCanSave = false;
        if (this.user && this.session) {
            const isStatusChecked = _.includes([
                StM.SessionStatus.CheckedOut
                , StM.SessionStatus.CheckedOutNoHold
                , StM.SessionStatus.None
                , StM.SessionStatus.Reopened
            ], this.session.status);
            isCanSave = this.authSrv.hasPermission(isUpdate ? 'UpdateSession' : 'AddSession', this.user) && isStatusChecked;
        }
        return isCanSave;
    }

    public getIsAdminCanReopenSession(): boolean {
        let isCanReopen = false;
        if (this.user && this.session) {
            const isStatusChecked = _.includes([StM.SessionStatus.Closed], this.session.status);
            isCanReopen = this.authSrv.hasPermission('CloseSession', this.user) && isStatusChecked;
        }
        return isCanReopen;
    }

    public getIsFuture(): boolean {
        if (!this.session) return false;

        const isCourse = this.session.series && this.session.series.isCourse;
        const canJoinTillSessionEnd = _.includes([StM.SessionType.Play, StM.SessionType.Private, StM.SessionType.Custom], this.session.type);
        const sessionEndDateTime = this.session.endDateTime.clone();
        let date = moment();
        if (isCourse) {
            const courseStartDate = this.session.series.startDate.clone();
            date = canJoinTillSessionEnd
                ? sessionEndDateTime.set({ year: courseStartDate.year(), month: courseStartDate.month(), date: courseStartDate.date() })
                : courseStartDate;
        } else {
            date = canJoinTillSessionEnd ? sessionEndDateTime : this.session.startDateTime.clone();
        }

        const time = moment.duration(date.format('H:mm'));
        return this.utils.getIsFuture(this.getCurrentClub(), date, time);
    }

    public getIsGroupSession(): boolean {
        const isGroupAccount = this.groupPolicy.getIsAuthenticated();
        const user = this.getCurrentUser();
        return this.session && isGroupAccount && !!user.group && this.session.bookings.some(b => this.utils.isActiveBooking(this.session, b) && b.groupId === user.group.id);
    }

    public getIsGroupMemberSession(checkBookingGroup: boolean = true, excludeNewBookings: boolean = false, includeLateCancel: boolean = false): boolean {
        const isGroupAccount = this.groupPolicy.getIsAuthenticated();
        const user = this.getCurrentUser();
        return this.session && isGroupAccount && !!user.group
            && this.session.bookings.some(b => this.utils.isActiveBooking(this.session, b, excludeNewBookings, false, includeLateCancel) 
            && (!checkBookingGroup || b.groupId === user.group.id)
            && user.group.members.some(m => m.invitationStatus === StM.InvitationStatus.Accepted && m.user && m.user.id === b.userId
        ));
    }

    public getIsOwnerGroupMember(): boolean {
        const isGroupAccount = this.groupPolicy.getIsAuthenticated();
        const user = this.getCurrentUser();
        
        if (!this.session || !isGroupAccount || !this.groupPolicy.getIsMember(this.session.ownerId)) return false;
        
        const ownerId = this.session.ownerId;
        const ownerBooking = _.find(this.session.bookings, b => b.userId == ownerId);

        return ownerId && ownerBooking && !!user.group && ownerBooking.groupId === user.group.id;
    }

    public getSessionGroupMemberBookings(useRemoved: boolean = true, excludeNew: boolean = false, includeLateCancel: boolean = false) {
        return this.getIsGroupMemberSession(true, excludeNew, includeLateCancel) 
            ? this.session.bookings.filter(b => {
                const isActive = this.utils.isActiveBooking(this.session, b, excludeNew, false, includeLateCancel);
                const isMember = !!b.groupId && this.groupPolicy.getIsMember(b.userId);
                const isRemoved = this.session.removedUsers.some(ru => ru.id === b.userId);
                return isActive && isMember && (useRemoved || !isRemoved);
            })
            : [];
    }

    public getIsCreatedByGroupSession(): boolean {
        return this.session.bookings.some(b => this.utils.isActiveBooking(this.session, b) && !!b.groupId && b.userId === this.session.ownerId);
    }

    public getSessionFromBasket() {
        return this.basketSessions.find((b) => {
            const sessionIdChecked = !!this.session.id && this.session.id === b.id;
            const basketIdChecked = this.session.basketId === b.basketId; 
            const isCourse = b.series && b.series.isCourse && this.session.series && b.series.id === this.session.series.id;
            return sessionIdChecked || basketIdChecked || isCourse;
        });
    }

    public isMemberTierMatched() {
        const hasMembershipRestriction = this.session.playerQualification?.membershipLevels?.length > 0;
        return !hasMembershipRestriction
            || (this.user?.level?.id
                && this.session.playerQualification.membershipLevels.find(value => value === this.user.level.id));
    }
}
