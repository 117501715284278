import './formError.scss';

import * as React from 'react';

import * as SrvM from '../../../services';
import { ValidationMessages } from '../../../models/store/strings';

const validator = new SrvM.ValidationService();

interface IFormErrorProps {
    message?: string;
    errors?: any;
}

export const FormError = (props: IFormErrorProps) => {
    if (!validator.hasErrors(props.errors) && !props.message) return null;
    const message = props.errors && props.errors.server
        ? props.errors.server
        : (props.message || ValidationMessages.Default);
    return (
        <div className="error-message-wrapper" >
            <span className="error-message-title">{message}</span>
        </div>
    );
};

export default FormError;
