import { ThunkService } from '../services/thunkService';
import { history } from '../store/store';

export class RouteActions {
    static push(url: string): (dispatch: any, getState: any, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            history.push(url);
        };
    }

    static replace(url: string): (dispatch: any, getState: any, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            history.replace(url);
        };
    }

    static goHome(forceAdmin: boolean = false): (dispatch: any, getState: any, thunkService: ThunkService) => void {
        const customWindow: any = window;
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            history.push(forceAdmin || customWindow.ISADMIN ? '/admin' : '/');
        };
    }
}
