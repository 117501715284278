export interface ICreditsWalletStoreState{
        id: number;
        user: IPublicUserDto;
        userId: string;
        club: IClubDto;
        clubId: number;
        status: string;
        creditsWalletBalances: ICreditsWalletBalanceDto[];
        creditsTransactions: ICreditsTransactionDto[];
        comments: string;
        expires: string;
        updated: string;
}

export class CreditsWalletStoreState implements ICreditsWalletStoreState{
        id: number;
        user: IPublicUserDto;
        userId: string;
        club: IClubDto;
        clubId: number;
        status: string;
        creditsWalletBalances: ICreditsWalletBalanceDto[];
        creditsTransactions: ICreditsTransactionDto[];
        comments: string;
        expires: string;
        updated: string;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.user = dto && dto.user ? dto.user : null ;
        this.userId = dto && dto.userId ? dto.userId : '' ;
        this.club = dto && dto.club ? dto.club : null ;
        this.clubId = dto && dto.clubId ? dto.clubId : '' ;
        this.status = dto && dto.status ? dto.status : '' ;
        this.creditsWalletBalances = dto && dto.creditsWalletBalances ? dto.creditsWalletBalances : [] ;
        this.creditsTransactions = dto && dto.creditsTransactions ? dto.creditsTransactions : [] ;
        this.comments = dto && dto.comments ? dto.comments : '' ;
        this.expires = dto && dto.expires ? dto.expires : '' ;
        this.updated = dto && dto.updated ? dto.updated : '' ;
    }
}
