import * as React from 'react';

interface IProps {
    checked?: boolean;
    disabled?: boolean;
    title?: string;

    onChange?: (e: any) => void;
}

export const Checkbox = (props: IProps) => {
    return (
        <label className='checkbox-wrapper'>
            <input 
                type='checkbox' 
                disabled={props.disabled} 
                checked={props.checked} 
                onChange={(e) => props.onChange(e)} 
                title={props.title} 
                className='visually-hidden' 
            />
            <span>{props.title}</span>
        </label>
    );
};
