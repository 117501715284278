import './tabs.scss';

import * as React from 'react';
import ClassNames from 'classnames';

export interface ITabsItem {
    name: string,
    title: string;
    classes?:string;
    disabled?: boolean;

    render: () => JSX.Element;
    getIsShow?: () => boolean;
}

interface ITabsProps {
     tabs: ITabsItem[];
     active?: string;

     onChange?: (name:string) => void;
}

export class Tabs extends React.Component<ITabsProps> {
    private map: any = {};
    private activeTab: ITabsItem;

    componentDidUpdate(prevProps: ITabsProps) {
        if (this.activeTab.getIsShow && !this.activeTab.getIsShow() && this.props.tabs && this.props.tabs.length) {
            if (!prevProps.tabs || prevProps.tabs.length !== this.props.tabs.length) {
                this.activeTab = this.map[this.props.tabs[0].name];
            }
        }
    }

    constructor(props:ITabsProps) {
        super(props);

        for (let i = 0; i < props.tabs.length; i++) {
            let item = props.tabs[i];
            this.map[item.name] = item;
        }
        if (props.active) {
            this.activeTab = this.map[props.active];
        } else {
            this.activeTab = props.tabs[0];
        }
    }

    public render () {
        return (
            <div className="tabs-wrapper">
                <ul className="tabs-navigation">
                    {this.props.tabs.map((item, index)=> this.renderMenuItem(item, index))}
                </ul>
                <div className="tabs-content">
                    <div className="tab-content">{this.activeTab.render()}</div>
                </div>
            </div>
        );
    }

    private renderMenuItem(tab: ITabsItem, key: number) {
        if(tab && tab.getIsShow && !tab.getIsShow()) return null;
        const classes = ClassNames(tab.classes, {
            'active': this.activeTab.name === tab.name,
            disabled: tab.disabled
        })

        return (
            <li className={classes} key={key} data-testid={`tab${tab.title}`}>
                <a href="#" onClick={(e) => this.setTabClick(e, tab)}>{tab.title}</a>
            </li>
        );
    }

    private setTabClick(e:any, tab:ITabsItem) {
        if(e) {e.stopPropagation(); e.preventDefault();}
        if(tab.disabled) return;
        this.activeTab = this.map[tab.name];
        if(this.props.onChange){
            this.props.onChange(tab.name);
        }
        this.forceUpdate();
    }
}