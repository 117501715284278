import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class SubscriptionNotificationDialogReducer extends BaseReducer {
    static handle(state: StM.ISubscriptionNotificationDialogStoreState = new StM.SubscriptionNotificationDialogStoreState(), action: any): StM.ISubscriptionNotificationDialogStoreState {
        switch (action.type) {
            case 'Dialogs/Admin/SubscriptionNotification/Open': {
                return <StM.ISubscriptionNotificationDialogStoreState>_.assign({}, state, { 
                    isOpened: true,
                    id: action.id,
                    sendNotificationCallback: action.sendNotificationCallback
                });
            }
            case 'Dialogs/Admin/SubscriptionNotification/Close': {
                return <StM.ISubscriptionNotificationDialogStoreState>_.assign({}, state, { isOpened: false });
            }
            default: {
                return state;
            }
        }
    }
}
