import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';

export class HistoryQueryDialogActions {
    static open(paymentId: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/HistoryQuery/Open',
                paymentId: paymentId
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/HistoryQuery/Close',
            });
        };
    }

    static sendRequest(message: string, payment: StM.IUserPaymentStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<void>{
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const store = <StM.IGlobalStoreState>getState();
            const club = store && store.club ? store.club : null;
            return thunkService.api.session.sendQueryRequest(message, payment, club)
                .then(() => {
                    return Promise.resolve();
                })
        };
    }
}
