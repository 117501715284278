import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class AuthDialogReducer extends BaseReducer{

    static handle(state: StM.IAuthDialogStoreState = new StM.AuthDialogStoreState(), action: any): StM.IAuthDialogStoreState {
        switch (action.type) {
            case 'Dialogs/Auth/Open': {
                let tab = action.payload.tab;
                let returnUrl = action.payload.returnUrl;
                return <StM.IAuthDialogStoreState>_.assign({}, state, { isOpened: true, tab: tab, returnUrl: returnUrl });
            }
            case 'Dialogs/Auth/Close': {
                
                return <StM.IAuthDialogStoreState>_.assign({}, state, { isOpened: false});
            }
            default: {
                return state;
            }
        }
    }
}
