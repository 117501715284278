export class AddonDefinitionDto implements IAddonDefinitionDto{
    id: number;
    createdBy: IPublicUserDto;
    created: string;
    updatedBy: IPublicUserDto;
    updated: string;
    isActive: boolean;
    isArchived: boolean;
    club: IClubDto;
    price: number;
    title: string;
    description: string;
    alias: string;
    order: number;
    
    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.title = dto && dto.title ? dto.title : '';
        this.description = dto && dto.description ? dto.description : '';
        this.isActive = dto && dto.isActive ? dto.isActive : false;
        this.isArchived = dto && dto.isArchived ? dto.isArchived : false;
        this.created = dto && dto.created ? dto.created : '';
        this.updated = dto && dto.updated ? dto.updated : '';
        this.createdBy = dto && dto.createdBy ? dto.createdBy : null;
        this.updatedBy = dto && dto.updatedBy ? dto.updatedBy : null;
        this.price = dto && dto.price ? dto.price : 0;
        this.alias = dto && dto.alias ? dto.alias : 0;
        this.order = dto && dto.order ? dto.order : 0;
    }
}