import BaseReducer from './baseReducer';
import * as StM from '../models/store';

export default class GroupAccountsReducer extends BaseReducer {
    public static handle(state: Array<StM.IGroupStoreState> = new Array<StM.IGroupStoreState>(), action: any): Array<StM.IGroupStoreState> {
        switch (action.type) {
            case 'GroupAccounts/Init': {
                return [...action.payload] as StM.IGroupStoreState[];
            }
            case 'GroupAccounts/Clear': {
                return [];
            }
            default: {
                return state;
            }
        }
    }
}
