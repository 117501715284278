export interface ISubscriptionOfferStateModel {
    id: number;
    status: string;
    currentVersion: number;
    title: string;
    description: string;
    price: number;
    billingPeriod: string;
    mandatoryIntroPeriod: number;
    coachFeeTierId: number;
    creditsDefinitions: IRecurrentPackageCreditsDefinitionDto[];
    statusHistory: any[];
}

export class SubscriptionOfferStateModel implements ISubscriptionOfferStateModel {
    id: number;
    status: string;
    currentVersion: number;
    title: string;
    description: string;
    price: number;
    billingPeriod: string;
    mandatoryIntroPeriod: number;
    coachFeeTierId: number;
    creditsDefinitions: IRecurrentPackageCreditsDefinitionDto[];
    statusHistory: any[];

    constructor(dto?: any) {
        this.id = dto?.id ?? 0;
        this.status = dto?.status ?? '';
        this.currentVersion = dto?.currentVersion ?? 0;
        this.title = dto?.title ?? '';
        this.description = dto?.description ?? '';
        this.price = dto?.price ?? 0;
        this.billingPeriod = dto?.billingPeriod ?? '';
        this.mandatoryIntroPeriod = dto?.mandatoryIntroPeriod ?? 0;
        this.coachFeeTierId = dto?.coachFeeTierId ?? 0;
        this.creditsDefinitions = dto?.creditsDefinitions ?? [];
        this.statusHistory = dto?.statusHistory ?? [];
    }
}
