export class BillingAddressDto implements IBillingAddressDto {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    company: string;
    fax: string;
    street: string;

    constructor(dto?: any) {
        this.firstName = dto && dto.firstName ? dto.firstName : '';
        this.lastName = dto && dto.lastName ? dto.lastName : '';
        this.email = dto && dto.email ? dto.email : '';
        this.phone = dto && dto.phone ? dto.phone : '';
        this.city = dto && dto.city ? dto.city : '';
        this.state = dto && dto.state ? dto.state : '';
        this.zip = dto && dto.zip ? dto.zip : '';
        this.country = dto && dto.country ? dto.country : '';
        this.company = dto && dto.company ? dto.company : '';
        this.fax = dto && dto.fax ? dto.fax : '';
        this.street = dto && dto.street ? dto.street : '';
    }
}
