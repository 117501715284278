import * as StM from '../../models/store';
import * as _ from 'lodash';

export default class VidePageReducer {

    static handle(state: StM.IVideoPageStoreState = new StM.VideoPageStoreState(), action: any): StM.IVideoPageStoreState {
        switch (action.type) {
            case 'Pages/Video/Init': {
                return state;
            }
            case 'Pages/Video/Session': {
                let session = <StM.ISessionStoreState>action.payload;
                let selectedYoutubeId = session.youtubeIds && session.youtubeIds.length > 0 ? session.youtubeIds[0] : null;
                return {
                    ...state,
                    session: session,
                    selectedYoutubeId: selectedYoutubeId
                };
            }
            case 'Pages/Video/SetYoutubeId': {
                let youtubeId = <StM.IYoutubeIdStoreState>action.payload;
                return { ...state, selectedYoutubeId: youtubeId }
            }
            default: {
                return state;
            }
        }
    }
}
