import * as Redux from 'redux';

import * as StM from '../../../models/store';

export class GroupInfoDialogActions {
    public static open(from: StM.GroupInfoFromType, inviteToken: string): (dispatch: any) => void {
        return (dispatch: any) => {
            dispatch({ type: 'Dialogs/GroupInfo/Open', payload: { from, inviteToken } });
        };
    }

    public static close(): (dispatch: any) => void {
        return (dispatch: any) => {
            dispatch({ type: 'Dialogs/GroupInfo/Close' });
        };
    }
}
