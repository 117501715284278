import {ThunkService} from '../services/thunkService';

export class BannerActions {
    constructor() {
    }

    static init(mainClubBanner: string, rightClubBanner: string): (dispatch: any, getState: any, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Banner/Init',
                    payload: {
                        mainClubBanner: mainClubBanner,
                        rightClubBanner: rightClubBanner
                    }
            });
        };
    }

    static close(): (dispatch: any, getState: any, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            const currentState = getState().banner;

            dispatch({
                type: 'Banner/Close',
                payload: {
                    mainClubBanner: currentState.mainClubBanner,
                    rightClubBanner: currentState.rightClubBanner
                }
            });
        };
    }

     static open(): (dispatch: any, getState: any, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            const currentState = getState().banner;
            
            dispatch({
                type: 'Banner/Open',
                payload: {
                    mainClubBanner: currentState.mainClubBanner,
                    rightClubBanner: currentState.rightClubBanner
                }
            });
        };
    }
}
