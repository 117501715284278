import BaseReducer from './baseReducer';
import * as StM from '../models/store';

export default class CoachesReducer extends BaseReducer {
    static handle(state: Array<StM.ICoachStoreState> = new Array<StM.ICoachStoreState>(), action: any): Array<StM.ICoachStoreState> {
        switch (action.type) {
            case 'Coaches/Init': {
                var coaches = <Array<StM.ICoachStoreState>>action.payload;
                return [...coaches];
            }
            default: {
                return state;
            }
        }
    }
}
