import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class TermsAndConditionsDialogReducer extends BaseReducer{

    static handle(state: StM.ITermsAndConditionsDialogStoreState= new StM.TermsAndConditionsDialogStoreState(), action: any): StM. ITermsAndConditionsDialogStoreState{
        switch (action.type) {
            case 'Dialogs/TermsAndConditions/Open': {
                return {...state, ...{ isOpened: true}};
            }
            case 'Dialogs/TermsAndConditions/OpenAndHideConfirmation': {
                return {...state, ...{ isOpened: true, hideConfimation: true }};
            }
            case 'Dialogs/TermsAndConditions/Close': {
                return {...state, ...{ isOpened: false}};
            }
            case 'Dialogs/TermsAndConditions/Accept': {
                return {...state, ...{ isAccepted: true}};
            }
            case 'Dialogs/TermsAndConditions/Decline': {
                return {...state, ...{ isAccepted: false}};
            }
            default: {
                return state;
            }
        }
    }
}
