import { IBaseDialogStoreState, BaseDialogStoreState } from './baseDialogStoreState';

export interface ICancelConfirmationStoreState extends IBaseDialogStoreState {
    msgKey: string;
    messageType: string;
    message: string;
    isOpenByService?: boolean;
    acceptButtonText?: string;
    rejectButtonText?: string;
    yesCallback?: () => void;
}

export class CancelConfirmationStoreState extends BaseDialogStoreState implements ICancelConfirmationStoreState {
    msgKey: string;
    messageType: string;
    message: string;
    isOpenByService?: boolean;
    acceptButtonText?: string;
    rejectButtonText?: string;
    yesCallback?: () => void;
    constructor(){
        super();
        this.msgKey = '';
        this.messageType = '';
        this.message = null;
        this.acceptButtonText = '';
        this.rejectButtonText = '';
    }
}