import * as StM from '../../store';
import moment from 'moment';

export interface ICreditsTransactionStoreState{
    id: number;
    creditsWallet: StM.ICreditsWalletStoreState;
    creditsWalletId: number;
    creditsWalletBalance: ICreditsWalletBalanceDto;
    creditsWalletBalanceId: number;
    credits: number;
    creditsBefore: number;
    user: StM.IPublicUserStoreState;
    userId: string;
    booking: StM.IBookingStoreState;
    bookingId: number;
    package: IPackageDto;
    packageId: number;
    price: number; 
    type: string;
    comments: string;
    created: moment.Moment;
    status: string;
    createdBy: StM.IPublicUserStoreState;
    createdById: string;
    refundAmount: number;
    refundReason: string;
    paymentSystemId: number;   
}

export class CreditsTransactionStoreState implements ICreditsTransactionStoreState{
    id: number;
    creditsWallet: StM.ICreditsWalletStoreState;
    creditsWalletId: number;
    creditsWalletBalance: ICreditsWalletBalanceDto;
    creditsWalletBalanceId: number;
    credits: number;
    creditsBefore: number;
    user: StM.IPublicUserStoreState;
    userId: string;
    booking: StM.IBookingStoreState;
    bookingId: number;
    package: IPackageDto;
    packageId: number;
    price: number; 
    type: string;
    comments: string;
    created: moment.Moment;
    status: string;
    createdBy: StM.IPublicUserStoreState;
    createdById: string;
    refundAmount: number;
    refundReason: string;
    paymentSystemId: number;   

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.creditsWallet = dto && dto.creditsWallet ? new StM.CreditsWalletStoreState(dto.creditsWallet) : null;
        this.creditsWalletId = dto && dto.creditsWalletId ? dto.creditsWalletId : 0;
        this.creditsWalletBalance = dto && dto.creditsWalletBalance ? dto.creditsWalletBalance : null;
        this.creditsWalletBalanceId = dto && dto.creditsWalletBalanceId ? dto.creditsWalletBalanceId : 0;
        this.credits = dto && dto.credits ? dto.credits : 0;
        this.creditsBefore = dto && dto.creditsBefore ? dto.creditsBefore : 0;
        this.user = dto && dto.user ? new StM.PublicUserStoreState(dto.user) : null;
        this.userId = dto && dto.userId ? dto.userId : 0;
        this.booking = dto && dto.booking ? new StM.BookingStoreState(dto.booking) : null;
        this.bookingId = dto && dto.bookingId ? dto.bookingId : 0;
        this.package = dto && dto.package ? dto.package : null;
        this.packageId = dto && dto.packageId ? dto.packageId : 0;
        this.price = dto && dto.price ? dto.price : null;
        this.type = dto && dto.type ? dto.type : '';
        this.comments = dto && dto.comments ? dto.comments : false;
        this.created = dto && dto.created ? moment(dto.created) : moment.min();
        this.status = dto && dto.status ? dto.status : '';
        this.createdBy = dto && dto.createdBy ? new StM.PublicUserStoreState(dto.createdBy) : null;
        this.createdById = dto && dto.createdById ? dto.createdById : 0;
        this.refundAmount = dto && dto.refundAmount ? dto.refundAmount : 0;
        this.refundReason = dto && dto.refundReason ? dto.refundReason : '';
        this.paymentSystemId = dto && dto.paymentSystemId ? dto.paymentSystemId : 0;
    }
}
