import * as _ from 'lodash';

import * as StM from '../models/store';

export default class ValidateBasketReducer {
    static handle(state: StM.IBasketStoreState = new StM.BasketStoreState(), action: any): StM.IBasketStoreState {
       
        switch (action.type) {
            case 'ValidatedBasket/Update': {
                return {...action.basket};
            }
            case 'ValidatedBasket/UpdateSessions': {
                const goods = [...action.payload];
                return {...state, goods: goods};
            }
            default: { 
                return state;
            }
        }
    }
}
