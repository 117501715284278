import _ from 'lodash';
import moment from 'moment';

import { Constants, DtoM, StM } from '../modules';
import { PricesPolicy } from '../policies';
import { Utils } from './utils';

export class MapperService {
    private static _instance: MapperService;
    private utils = new Utils();
    constructor() {
        if (typeof MapperService._instance === "undefined") {
            MapperService._instance = this;
        }
        return MapperService._instance;
    }

    map<T>(creator: new(model?: any) => T, dto: any): T {
        return new creator(dto);
    }

    maps<T>(creator: new(model?: any) => T, dtos: any[]): T[] {
        if (dtos && dtos.length > 0) {
            const result = _.map(dtos, (dto: any) => {
                return new creator(dto);
            });
            return result;
        }
        return [];
    }
    //#region Common Dictionary
    getCommonDictionariesModelFromDto(dto: ICommonDictionariesDto): StM.ICommonDictionariesStoreState {
        const handledDto = this.map(DtoM.CommonDictionariesDto, dto);

        const model = this.map(StM.CommonDictionariesStoreState, handledDto);
        if (dto) {
            model.club = this.getClubModelFromDto(handledDto.club);
            model.coaches = this.getCoachModelsFromDtos(handledDto.coaches);
            model.coachFeeTiers = this.getCoachFeeTierModelsFromDto(handledDto.coachFeeTiers);
            model.membershipLevels = this.getMembershipLevelModelsFromDto(handledDto.membershipLevels);
            model.packageDefinitions = this.getPackageDefinitionModelsFromDtos(handledDto.packageDefinitions);
            model.subscriptionOfferDefinitions = this.getSubscriptionOfferDefinitionModelsFromDtos(handledDto.recurrentPackageOffers);
            model.addonDefinitions = this.getAddonDefinitionModelsFromDtos(handledDto.addonDefinitions);
            model.packageSessionTypes = this.getPackageSessionTypeModelsFromDtos(handledDto.packageSessionTypes);
            model.customSessions = this.maps(StM.CustomSessionTypeStoreState, handledDto.customSessions ? JSON.parse(handledDto.customSessions) : []);
            model.permissions = this.maps(StM.RoleDefinitionStoreState, handledDto.permissions ? JSON.parse(handledDto.permissions) : []);
            
            _.each(model.packageDefinitions, (x) => x.club = model.club);
        }
        return model;
    }
    //#endregion

    //#region User Dto -> Model
    getPublicUserModelsFromDtos(dtos: Array<IPublicUserDto>): Array<StM.IPublicUserStoreState> {
        const models = _.map(dtos, (dto: IPublicUserDto) => {
            return this.getPublicUserModelFromDto(dto);
        });
        return models;
    }

    getPublicUserModelFromDto(dto: IPublicUserDto): StM.IPublicUserStoreState {
        const handledDto = this.map(DtoM.PublicUserDto, dto);
        const model = this.map(StM.PublicUserStoreState, handledDto);
        return model;
    }

    getUserModelsFromDtos(dtos: Array<IUserDto>): Array<StM.IUserStoreState> {
        const models = _.map(dtos, (dto: IUserDto) => {
            return this.getUserModelFromDto(dto);
        });
        return models;
    }
tiers
    getUserModelFromDto(dto: IUserDto): StM.IUserStoreState {

        const handledDto = this.map(DtoM.UserDto, dto);
        if (dto) {
            handledDto.level = this.map(DtoM.MembershipLevelDto, dto.level);
            handledDto.membershipLevels = this.maps(DtoM.MembershipLevelDto, dto.membershipLevels);
            handledDto.permissions = this.maps(DtoM.PermissionDto, dto.permissions);
            handledDto.creditsWallets = this.maps(DtoM.CreditsWalletDto, dto.creditsWallets)
            handledDto.group = this.map(DtoM.GroupDto, dto.group);
        }
        const model = this.map(StM.UserStoreState, handledDto);
        if (dto) {
            model.level = this.getMembershipLevelModelFromDto(handledDto.level);
            model.membershipLevels = this.getMembershipLevelModelsFromDto(handledDto.membershipLevels);
            model.permissions = this.getPermissionModelsFromDtos(handledDto.permissions);
            model.creditsWallet = this.getCreditWalletModelFromDtos(handledDto.creditsWallets);
            model.group = this.getGroupModelFromDto(handledDto.group);
            if (model.dateOfBirth) model.dateOfBirth = this.utils.toDateOfBirth(model.dateOfBirth);
        }
        return model;
    }

    getCoachModelsFromDtos(dtos: Array<ICoachDto>): Array<StM.ICoachStoreState> {
        const models = _.map(dtos, (dto: ICoachDto) => {
            return this.getCoachModelFromDto(dto);
        });
        return models;
    }

    getCoachModelFromDto(dto: ICoachDto): StM.ICoachStoreState {
        const handledDto = this.map(DtoM.CoachDto, dto);
        if (dto) {
            handledDto.coachFeeTier = this.map(DtoM.CoachFeeTierDto, dto.coachFeeTier);
        }
        const model = this.getPublicUserModelFromDto(dto) as StM.ICoachStoreState;
        if (dto) {
            model.coachFeeTier = this.getCoachFeeTierModelFromDto(dto.coachFeeTier);
            model.coachFeeTierId = dto.coachFeeTierId;
            model.description = dto.description;
            model.lastRecurrentAvailabilityApplicationDate = dto.lastRecurrentAvailabilityApplicationDate ? moment(dto.lastRecurrentAvailabilityApplicationDate, Constants.DateTime.API_FORMAT) : null;
        }
        return model;
    }

    getUserEditableModelsFromDtos(dtos: Array<IUserEditableDto>): Array<StM.IUserEditableStoreState> {
        const models = _.map(dtos, (dto: IUserEditableDto) => {
            return this.getUserEditableModelFromDto(dto);
        });
        return models;
    }

    getUserEditableModelFromDto(dto: IUserEditableDto): StM.IUserEditableStoreState {
        const handledDto = this.map(DtoM.UserEditiableDto, dto);
        if (dto) {
            handledDto.coachFeeTier = this.map(DtoM.CoachFeeTierDto, dto.coachFeeTier);
        }
        const model = this.getUserModelFromDto(dto) as StM.IUserEditableStoreState;
        if (dto) {
            model.coachFeeTier = this.getCoachFeeTierModelFromDto(dto.coachFeeTier);
            model.coachFeeTierId = dto.coachFeeTierId;
            model.email = handledDto.email;
            model.age = handledDto.age;
        }
        return model;
    }

    getUserManagableModelFromDto(dto: IUserManagableDto): StM.IUserManagableStoreState {
        const handledDto = this.map(DtoM.CoachDto, dto);
        const model = this.map(StM.UserManagableStoreState, handledDto) as StM.IUserManagableStoreState;
        if (model.dateOfBirth) model.dateOfBirth = this.utils.toDateOfBirth(model.dateOfBirth);
        return model;
    }

    //#endregion

    //#region User Model -> Dto
    getPublicUserDtosFromModels(dtos: Array<IPublicUserDto>): Array<StM.IPublicUserStoreState> {
        const models = _.map(dtos, (dto: IPublicUserDto) => {
            return this.getPublicUserModelFromDto(dto);
        });
        return models;
    }

    getPublicUserDtoFromModel(model: StM.IPublicUserStoreState): IPublicUserDto {
        const handledDto = this.map(DtoM.PublicUserDto, model);
        return handledDto;
    }
    
    getCoachDtoFromModel(model: StM.ICoachStoreState): ICoachDto {
        const handledDto = this.map(DtoM.CoachDto, model);
        return handledDto;
    }

    getUserManagableDtoFromModel(model: StM.IUserManagableStoreState): IUserManagableDto {
        const handledDto = this.map(DtoM.UserManagableDto, model);
        handledDto.dateOfBirth = model.dateOfBirth?.format(Constants.DateTime.DATE_FORMAT)
        return handledDto;
    }

    getUserDtoFromModel(model: StM.IUserStoreState): IUserDto {
        const handledDto = this.map(DtoM.UserDto, model);
        return handledDto;
    }

    getUserEditableDtoFromModel(model: StM.IUserStoreState): IUserEditableDto {
        const handledDto = this.map(DtoM.UserEditiableDto, model);
        handledDto.dateOfBirth = model.dateOfBirth?.format(Constants.DateTime.DATE_FORMAT)
        return handledDto;
    }

    //#endregion

    //#region Membership Level
    getMembershipLevelModelsFromDto(dtos: Array<IMembershipLevelDto>): Array<StM.IMembershipLevelStoreState> {
        const models = _.map(dtos, (dto: IMembershipLevelDto) => {
            return this.getMembershipLevelModelFromDto(dto);
        });
        return models;
    }

    getMembershipLevelModelFromDto(dto: IMembershipLevelDto): StM.IMembershipLevelStoreState {
        const handledDto = this.map(DtoM.MembershipLevelDto, dto);
        const model = this.map(StM.MembershipLevelStoreState, handledDto);
        return model;
    }

    getMembershipLevelDtoFromModel(model: StM.IMembershipLevelStoreState): IMembershipLevelDto {
        const handledDto = this.map(DtoM.MembershipLevelDto, model);
        return handledDto;
    }

    getCoachFeeTierDtoFromModel(model: StM.ICoachFeeTierStoreState): ICoachFeeTierDto {
        const handledDto = this.map(DtoM.CoachFeeTierDto, model);
        return handledDto;
    }

    geClubTimeDtoFromModel(model: StM.IClubTimeStoreState): IClubTimeDto {
        const handledDto = this.map(DtoM.ClubTimeDto, model);
        handledDto.startTime = '{0}:{1}:{2}'.format(moment.duration(handledDto.startTime).hours(), moment.duration(handledDto.startTime).minutes(),  moment.duration(handledDto.startTime).seconds());
        handledDto.endTime = '{0}:{1}:{2}'.format(moment.duration(handledDto.endTime).hours(), moment.duration(handledDto.endTime).minutes(),  moment.duration(handledDto.endTime).seconds());
        return handledDto;
    }

    getClubTimeDtosFromModels(models: Array<StM.IClubTimeStoreState>): Array<IClubTimeDto> {
        const dtos = _.map(models, (model: StM.IClubTimeStoreState) => {
            return this.geClubTimeDtoFromModel(model);
        });
        return dtos;
    }
    //#endregion

    //#region Club Dto -> Model
    getClubModelFromDto(dto: IClubDto): StM.IClubStoreState {
        const handledDto = this.map(DtoM.ClubDto, dto);
        if (dto) {
            handledDto.courts = this.maps(DtoM.CourtDto, dto.courts);
            handledDto.aliasedTimeZone = this.map(DtoM.AliasedTimeZoneDto, dto.aliasedTimeZone);
            handledDto.clubTimes = this.maps(DtoM.ClubTimeDto, dto.clubTimes);
            handledDto.salesTaxes = this.maps(DtoM.SalesTaxDto, dto.salesTaxes);
        }
        const model = this.map(StM.ClubStoreState, handledDto);
        if (dto) {
            model.courts = this.getCourtModelsFromDtos(handledDto.courts);
            model.aliasedTimeZone = this.map(StM.AliasedTimeZoneStoreState, model.aliasedTimeZone);
            model.clubTimes = this.maps(StM.ClubTimeStoreState, model.clubTimes);
            model.salesTaxes = this.maps(StM.SalesTaxStoreState, model.salesTaxes);
        }
        model.courts = model.courts.sort((a, b) => { return a.order - b.order });
        return model;
    }

    getClubModelsFromDto(dtos: Array<IClubDto>): Array<StM.IClubStoreState> {
        const models = _.map(dtos, (dto: IClubDto) => {
            return this.getClubModelFromDto(dto);
        });
        return models;
    }

    getClubDtoFromModel(model: StM.IClubStoreState): IClubDto {
        const handledDto = this.map(DtoM.ClubDto, model);
        if (model) {
            handledDto.clubTimes = this.getClubTimeDtosFromModels(model.clubTimes);
        }
        handledDto.customSessions = null;
        return handledDto;
    }
    //#endregion

    //#region New Club Dto -> Model
    getNewClubModelFromDto(dto: INewClubDto): StM.INewClubStoreState {
        const handledDto = this.map(DtoM.NewClubDto, dto);
        const model = this.map(StM.NewClubStoreState, handledDto);
        return model;
    }


    getNewClubDtoFromModel(model: StM.INewClubStoreState): INewClubDto {
        const handledDto = this.map(DtoM.NewClubDto, model);
        return handledDto;
    }
    //#endregion

    //#region PricingTierPeriod Model -> Dto
      getPricingTierPeriodDtosFromModels(models: Array<StM.IPricingTierPeriodStoreState>): Array<IPricingTierPeriodDto> {
        const dtos = _.map(models, (model: StM.IPricingTierPeriodStoreState) => {
            return this.getPricingTierPeriodDtoFromModel(model);
        });
        return dtos;
    }

    getPricingTierPeriodDtoFromModel(model: StM.IPricingTierPeriodStoreState): IPricingTierPeriodDto {
        const handledDto = this.map(DtoM.PricingTierPeriodDto, model);
        handledDto.start = '{0}:{1}:{2}'.format(moment.duration(handledDto.start).hours(), moment.duration(handledDto.start).minutes(), moment.duration(handledDto.start).seconds());
        handledDto.end = '{0}:{1}:{2}'.format(moment.duration(handledDto.end).hours(), moment.duration(handledDto.end).minutes(), moment.duration(handledDto.end).seconds());
        return handledDto;
    }
    //#endregion

     //#region PricingTier Model -> Dto
     getPricingTierDtosFromModels(models: Array<StM.IPricingTierStoreState>): Array<IPricingTierDto> {
        const dtos = _.map(models, (model: StM.IPricingTierStoreState) => {
            return this.getPricingTierDtoFromModel(model);
        });
        return dtos;
    }

    getPricingTierDtoFromModel(model: StM.IPricingTierStoreState): IPricingTierDto {
        const handledDto = this.map(DtoM.PricingTierDto, model);
        handledDto.schedule = this.getPricingTierPeriodDtosFromModels(model.schedule);
        return handledDto;
    }
    //#endregion

    
     //#region CoachFeeTierPrice Model -> Dto
     getCoachFeeTierPriceDtosFromModels(models: Array<StM.ICoachFeeTierPriceStoreState>): Array<ICoachFeeTierPriceDto> {
        const dtos = _.map(models, (model: StM.ICoachFeeTierPriceStoreState) => {
            return this.getCoachFeeTierPriceDtoFromModel(model);
        });
        return dtos;
    }

    getCoachFeeTierPriceDtoFromModel(model: StM.ICoachFeeTierPriceStoreState): ICoachFeeTierPriceDto {
        const handledDto = this.map(DtoM.CoachFeeTierPriceDto, model);
        return handledDto;
    }
    //#endregion

    //#region Court Dto -> Model
    getCourtModelsFromDtos(dtos: Array<ICourtDto>): Array<StM.ICourtStoreState> {
        const models = _.map(dtos, (dto: ICourtDto) => {
            return this.getCourtModelFromDto(dto);
        });
        return models;
    }

    getCourtModelFromDto(dto: ICourtDto): StM.ICourtStoreState {
        const handledDto = this.map(DtoM.CourtDto, dto);
        const model = this.map(StM.CourtStoreState, handledDto);
        return model;
    }
    //#endregion

    //#region Court Model -> Dto
    getCourtDtosFromModels(models: Array<StM.ICourtStoreState>): Array<ICourtDto> {
        const dtos = _.map(models, (model: StM.ICourtStoreState) => {
            return this.getCourtDtoFromModel(model);
        });
        return dtos;
    }

    getCourtDtoFromModel(model: StM.ICourtStoreState): ICourtDto {
        const handledDto = this.map(DtoM.CourtDto, model);
        return handledDto;
    }
    //#endregion

    //#region Booking Dto -> Model
    getBookingModelsFromDtos(dtos: Array<IBookingDto>, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Array<StM.IBookingStoreState> {
        const models = _.map(dtos, (dto: IBookingDto) => {
            return this.getBookingModelFromDto(dto, club, tiers);
        });
        return models;
    }

    getBookingModelFromDto(dto: IBookingDto, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): StM.IBookingStoreState {
        if (!dto) { return null; }

        const handledDto = this.map(DtoM.BookingDto, dto);
        handledDto.user = this.map(DtoM.PublicUserDto, handledDto.user);
        handledDto.group = this.map(DtoM.GroupDto, handledDto.group);

        const model = this.map(StM.BookingStoreState, handledDto);
        model.session = this.getSessionModelFromDto(handledDto.session, club, tiers);
        model.group = this.getGroupModelFromDto(handledDto.group, club, tiers);
       
        return model;
    }
    //#endregion

    //#region YoutubeId Dto -> Model
    getYoutubeIdModelsFromDtos(dtos: Array<IYoutubeIdDto>, club: StM.IClubStoreState): Array<StM.IYoutubeIdStoreState> {
        const models = _.map(dtos, (dto: IYoutubeIdDto) => {
            return this.getYoutubeIdModelFromDto(dto, club);
        });
        return models;
    }

    getYoutubeIdModelFromDto(dto: IYoutubeIdDto, club: StM.IClubStoreState): StM.IYoutubeIdStoreState {
        if (!dto) return null;

        const handledDto = this.map(DtoM.YoutubeIdDto, dto);
        const model = this.map(StM.YoutubeIdStoreState, handledDto);
        return model;
    }
    //#endregion

    //#region Session Dto -> Model
    getSessionModelsFromDtos(dtos: Array<ISessionDto>, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Array<StM.ISessionStoreState> {
        dtos = _.orderBy(dtos, (dto: ISessionDto) => { return moment(dto.startDateTime, Constants.DateTime.API_FORMAT).valueOf() });
        const models = _.map(dtos, (dto: ISessionDto) => {
            return this.getSessionModelFromDto(dto, club, tiers);
        });

        this.processCourseSessions(models);
        _.remove(models, _.isUndefined);
        return models;
    }

    processCourseSessions(models: Array<StM.ISessionStoreState>) {
        _.each(models, (model) => {
            if (model.series && model.series.isCourse) {
                const courseSessions = _.filter(models, (session) => {
                    return session.series && session.series.id === model.series.id && session.id !== model.id;
                });
                _.each(courseSessions, (session) => {
                    session.bookings = model.bookings;
                    session.price = model.price;
                    session.credits = model.credits;
                    session.checkoutPrice = model.checkoutPrice;
                    session.checkoutCredits = model.checkoutCredits;
                    session.invitedUsers = model.invitedUsers;
                });
            }
        });
    }

    getSessionModelFromDto(dto: ISessionDto, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): StM.ISessionStoreState {
        if (!dto) { return null; }
        const handledDto = this.map(DtoM.SessionDto, dto);
        handledDto.seeking = !!handledDto.seeking ? this.map(DtoM.SeekingDto, handledDto.seeking) : null;
        handledDto.playerQualification = !!handledDto.playerQualification ? this.map(DtoM.PlayerQualificationDto, handledDto.playerQualification) : null;
        handledDto.series = !!handledDto.series ? this.map(DtoM.SeriesDto, handledDto.series) : null;

        let model = this.map(StM.SessionStoreState, handledDto);
        
        model.seeking = !!model.seeking ? this.map(StM.SeekingStoreState, model.seeking) : null;
        model.playerQualification = !!model.playerQualification ? this.map(StM.PlayerQualificationStoreState, model.playerQualification) : null;
        model.series = !!handledDto.series ? this.getSeriesModelFromDto(handledDto.series, club, tiers) : null;
        model = this.handleSessionBookings(model, handledDto, club, tiers);
        this.handleDoubleSessionForModel(model, handledDto, tiers);
        model = this.handleSessionVideoIds(model, handledDto, club);
        const pricePolicy = new PricesPolicy(model.isDoubledSession, model);
        model.noServiceCheckoutPrice = model.checkoutPrice - pricePolicy.handle().servicesPrice;
        return model;
    }

    getSeriesModelFromDto(dto: ISeriesDto, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): StM.ISeriesStoreState {
        if (!dto) { return null; }
        const handledDto = this.map(DtoM.SeriesDto, dto);
        handledDto.playerQualification = !!handledDto.playerQualification ? this.map(DtoM.PlayerQualificationDto, handledDto.playerQualification) : null;

        const model = this.map(StM.SeriesStoreState, handledDto);
        model.playerQualification = !!model.playerQualification ? this.map(StM.PlayerQualificationStoreState, model.playerQualification) : null;

        if (handledDto.sessions && handledDto.sessions.length > 0) {
            model.sessions = this.getSessionModelsFromDtos(handledDto.sessions, club, tiers);
        }
        return model;
    }

    private handleSessionBookings(model: StM.ISessionStoreState, handledDto: DtoM.SessionDto, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): StM.ISessionStoreState {
        if (handledDto.bookings && handledDto.bookings.length > 0) {
            model.bookings = this.getBookingModelsFromDtos(handledDto.bookings, club, tiers);
        }
        return model;
    }

    private handleDoubleSessionForModel(model: StM.ISessionStoreState, handledDto: DtoM.SessionDto, tiers: StM.IPricingTierStoreState[]) {
        const tier = tiers.find(t => t.id === model.pricingTierId);
        const duration = moment(handledDto.endDateTime).diff(moment(handledDto.startDateTime), 'minutes');
        const blockSize = tier ? tier.blockSize : duration;
        model.isDoubledSession = !!handledDto && handledDto.type !== StM.SessionType.Custom && duration > blockSize;
    }

    private handleSessionVideoIds(model: StM.ISessionStoreState, handledDto: DtoM.SessionDto, club: StM.IClubStoreState): StM.ISessionStoreState {
        if (handledDto.youtubeIds && handledDto.youtubeIds.length > 0) {
            model.youtubeIds = this.getYoutubeIdModelsFromDtos(handledDto.youtubeIds, club);
        }
        return model;
    }
    //#endregion 

    //#region Session Model -> Dto
    getSessionDtosFromModels(models: Array<StM.ISessionStoreState>, club: StM.IClubStoreState): Array<ISessionDto> {
        const dtos = _.map(models, (model: StM.ISessionStoreState) => {
            return this.getSessionDtoFromModel(model, club);
        });
        return dtos;
    }

    getSessionDtoFromModel(model: StM.ISessionStoreState, club: StM.IClubStoreState): ISessionDto {
        const dto = new DtoM.SessionDto(model);
        dto.startDateTime = model.startDateTime.format(Constants.DateTime.API_FORMAT);
        dto.endDateTime = model.endDateTime.format(Constants.DateTime.API_FORMAT);

        if (dto.owner) { dto.owner = this.getPublicUserDtoFromModel(model.owner); }
        if (dto.court) { dto.court = this.getCourtDtoFromModel(model.court); }
        if (dto.club) { dto.club = this.getClubDtoFromModel(model.club);}
        if (dto.trainer) { dto.trainer = this.getCoachDtoFromModel(model.trainer); }

        dto.recurrencyDates = !!model.recurrencyDates != null ? model.recurrencyDates.map(x => x.format(Constants.DateTime.API_FORMAT)) : null;
        dto.seeking = model.seeking;
        dto.playerQualification = model.playerQualification;
        dto.bookings = this.getBookingDtosFromModels(model.bookings, club);
        return dto;
    }
    //#endregion

    getBookingDtosFromModels(models: Array<StM.IBookingStoreState>, club: StM.IClubStoreState): Array<IBookingDto> {
        const dtos = _.map(models, (model: StM.IBookingStoreState) => {
            return this.getBookingDtoFromModel(model, club);
        });
        return dtos;
    }

    getBookingDtoFromModel(model: StM.IBookingStoreState, club: StM.IClubStoreState): IBookingDto {
        const dto = new DtoM.BookingDto(model);
        dto.cancellationDateTime = dto.transactionDate = dto.refundDateTime = dto.session = null;
        
        if (dto.user) { dto.user = this.getPublicUserDtoFromModel(model.user); }
       
        dto.addons = this.getAddonDtosFromModels(model.addons, club);
        return dto;
    }

    getAddonDtosFromModels(models: Array<StM.IAddonStoreState>, club: StM.IClubStoreState): Array<IAddonDto> {
        const dtos = _.map(models, (model: StM.IAddonStoreState) => {
            return this.getAddonDtoFromModel(model, club);
        });
        return dtos;
    }
    
    getAddonDtoFromModel(model: StM.IAddonStoreState, club: StM.IClubStoreState): IAddonDto {
        const dto = new DtoM.AddonDto(model);
        dto.created = dto.refundDateTime = dto.transactionDate = dto.updated = null;
        if (dto.user) { dto.user = this.getPublicUserDtoFromModel(model.user); }
    
        return dto;
    }

    //#region PlayerQualification
    getPlayerQualificationModelFromDto(dto: IPlayerQualificationDto): StM.IPlayerQualificationStoreState {
        const handledDto = this.map(DtoM.PlayerQualificationDto, dto);
        const model = this.map(StM.PlayerQualificationStoreState, handledDto);
        return model;
    }
    //#endregion

    //#region Seeking Dto -> Model
    getSeekingModelFromDto(dto: ISeekingDto): StM.ISeekingStoreState {
        const handledDto = this.map(DtoM.SeekingDto, dto);
        const model = this.map(StM.SeekingStoreState, handledDto);
        if (dto) {
            model.owner = this.map(StM.PublicUserStoreState, handledDto.owner);
        }
        return model;
    }
    //#endregion

    //#region Coach Fee Tier Dto -> Model
    getCoachFeeTierModelsFromDto(dtos: Array<ICoachFeeTierDto>): Array<StM.ICoachFeeTierStoreState> {
        const models = _.map(dtos, (dto: ICoachFeeTierDto) => {
            return this.getCoachFeeTierModelFromDto(dto);
        });
        return models;
    }

    getCoachFeeTierModelFromDto(dto: ICoachFeeTierDto): StM.ICoachFeeTierStoreState {
        if(!dto) return null;
        const handledDto = this.map(DtoM.CoachFeeTierDto, dto);
        handledDto.courts = this.maps(DtoM.CourtDto, dto.courts);
        const model = this.map(StM.CoachFeeTierStoreState, handledDto);
        model.courts = model.courts.sort((a, b) => a.order - b.order);
       
        return model;
    }
    //#endregion
    
    //#region Coach Fee Tier Price Dto -> Model
    getCoachFeeTierPriceModelsFromDtos(dtos: Array<ICoachFeeTierPriceDto>): Array<StM.ICoachFeeTierPriceStoreState> {
        const models = _.map(dtos, (dto: ICoachFeeTierPriceDto) => {
            return this.getCoachFeeTierPriceModelFromDto(dto);
        });
        return models;
    }

    getCoachFeeTierPriceModelFromDto(dto: ICoachFeeTierPriceDto): StM.ICoachFeeTierPriceStoreState {
        if(!dto) return null;
        const handledDto = this.map(DtoM.CoachFeeTierPriceDto, dto);
        
        const model = this.map(StM.CoachFeeTierPriceStoreState, handledDto);

        return model;
    }
    //#endregion

    //#region Pricing Tier Dto -> Model
     getPricingTierModelsFromDtos(dtos: Array<IPricingTierDto>): Array<StM.IPricingTierStoreState> {
        const models = _.map(dtos, (dto: IPricingTierDto) => {
            return this.getPricingTierModelFromDto(dto);
        });
        return models;
    }

    getPricingTierModelFromDto(dto: IPricingTierDto): StM.IPricingTierStoreState {
        if(!dto) return null;
        const handledDto = this.map(DtoM.PricingTierDto, dto);
        handledDto.schedule = this.maps(DtoM.PricingTierPeriodDto, dto.schedule);
        
        const model = this.map(StM.PricingTierStoreState, handledDto);
        model.schedule = this.maps(StM.PricingTierPeriodStoreState, handledDto.schedule);
        _.each(model.schedule, (p) => p.type = model.type);

        return model;
    }
    //#endregion
    
    //#region Pricing Tier Period Dto -> Model
    getPricingTierPeriodModelsFromDto(dtos: Array<IPricingTierPeriodDto>): Array<StM.IPricingTierPeriodStoreState> {
        const models = _.map(dtos, (dto: IPricingTierPeriodDto) => {
            return this.getPricingTierPeriodModelFromDto(dto);
        });
        return models;
    }
    
    getPricingTierPeriodModelFromDto(dto: IPricingTierPeriodDto): StM.IPricingTierPeriodStoreState {
        if(!dto) return null;
        const handledDto = this.map(DtoM.PricingTierPeriodDto, dto);
        
        const model = this.map(StM.PricingTierPeriodStoreState, handledDto);
        
        return model;
    }
    //#endregion

    //#region Available Times Dto -> Model
    getAvailableTimeModelsFromDto(dtos: Array<IAvailableTimeDto>, club: StM.IClubStoreState): Array<StM.IAvailableTimeStoreState> {
        const models = _.map(dtos, (dto: IAvailableTimeDto) => {
            return this.getAvailableTimeModelFromDto(dto, club);
        });
        return models;
    }

    getAvailableTimeModelFromDto(dto: IAvailableTimeDto, club: StM.IClubStoreState): StM.IAvailableTimeStoreState {
        const handledDto = this.map(DtoM.AvailableTimeDto, dto);
        if (dto) {
            handledDto.owner = this.map(DtoM.PublicUserDto, handledDto.owner);
            handledDto.club = this.map(DtoM.ClubDto, handledDto.club);
        }
        const model = this.map(StM.AvailableTimeStoreState, handledDto);
        if (dto) {
            model.owner = this.map(StM.PublicUserStoreState, handledDto.owner);
            model.club = this.getClubModelFromDto(handledDto.club);
        }
        return model;
    }
    //#endregion

    //#region Addon Definition Dto -> Model

    getAddonDefinitionModelFromDto(dto: IAddonDefinitionDto): StM.IAddonDefinitionStoreState {
        if (!dto) { return null; }
        const handledDto = this.map(DtoM.AddonDefinitionDto, dto);

        const model = this.map(StM.AddonDefinitionStoreState, handledDto);
        return model;
    }

    getAddonDefinitionModelsFromDtos(dtos: Array<IAddonDefinitionDto>): Array<StM.IAddonDefinitionStoreState> {
        const models = _.map(dtos, (dto: IAddonDefinitionDto) => {
            return this.getAddonDefinitionModelFromDto(dto);
        });
        _.remove(models, _.isUndefined);
        return models;
    }

    //#endregion

    //#region Addon Dto -> Model

    getAddonModelFromDto(dto: IAddonDto): StM.IAddonStoreState {
        if (!dto) { return null; }
        const handledDto = this.map(DtoM.AddonDto, dto);
        handledDto.user = this.map(DtoM.PublicUserDto, handledDto.user);

        const model = this.map(StM.AddonStoreState, handledDto);
        return model;
    }

    getAddonModelsFromDtos(dtos: Array<IAddonDto>): Array<StM.IAddonStoreState> {
        const models = _.map(dtos, (dto: IAddonDto) => {
            return this.getAddonModelFromDto(dto);
        });
        _.remove(models, _.isUndefined);
        return models;
    }

    //#endregion   

    //#region Package Definition Dto -> Model

    getPackageDefinitionModelFromDto(dto: IPackageDefinitionDto): StM.IPackageDefinitionStoreState {
        if (!dto) { return null; }
        const handledDto = this.map(DtoM.PackageDefinitionDto, dto);
        const model = this.map(StM.PackageDefinitionStoreState, handledDto);
        return model;
    }

    getSubscriptionOfferModelFromDto(dto: IRecurrentPackageOfferListDto): StM.ISubscriptionOfferStateModel {
        if (!dto) { return null; }
        const handledDto = this.map(DtoM.SubscriptionOfferDto, dto);
        const model = this.map(StM.SubscriptionOfferStateModel, handledDto);
        return model;
    }

    getPackageDefinitionModelsFromDtos(dtos): Array<StM.IPackageDefinitionStoreState> {
        const models = _.map(dtos, (dto: IPackageDefinitionDto) => {
            return this.getPackageDefinitionModelFromDto(dto);
        });
        _.remove(models, _.isUndefined);
        return models;
    }

    getSubscriptionOfferDefinitionModelsFromDtos(dtos: Array<IRecurrentPackageOfferListDto>): StM.ISubscriptionOfferStateModel[] {
        return _(dtos).map((dto) => this.getSubscriptionOfferModelFromDto(dto)).compact().valueOf()
    }


    getPackageDefinitionDtosFromModels(models: Array<StM.IPackageDefinitionStoreState>): Array<IPackageDefinitionDto> {
        const dtos = _.map(models, (model: StM.IPackageDefinitionStoreState) => {
            return this.getPackageDefinitionDtoFromModel(model);
        });
        return dtos;
    }

    getPackageDefinitionDtoFromModel(model: StM.IPackageDefinitionStoreState): IPackageDefinitionDto {
        const handledDto = this.map(DtoM.PackageDefinitionDto, model);
        handledDto.expirationTimeSpan = model.expirationTimeSpan.days();
        return handledDto;
    }

    getPackageSessionTypeModelFromDto(dto: IPackageSessionTypeDto): StM.IPackageSessionTypeStoreState {
        if (!dto) { return null; }
        const handledDto = this.map(DtoM.PackageSessionTypeDto, dto);

        const model = this.map(StM.PackageSessionTypeStoreState, handledDto);
        return model;
    }

    getPackageSessionTypeModelsFromDtos(dtos: Array<IPackageSessionTypeDto>): Array<StM.IPackageSessionTypeStoreState> {
        const models = _.map(dtos, (dto: IPackageSessionTypeDto) => {
            return this.getPackageSessionTypeModelFromDto(dto);
        });
        _.remove(models, _.isUndefined);
        return models;
    }
    //#endregion
    
    //#region Static Page Dto -> Model

    getStaticPageModelFromDto(dto: IStaticPageDto): StM.IStaticPageStoreState {
        if (!dto) { return null; }
        const handledDto = this.map(DtoM.StaticPageDto, dto);

        const model = this.map(StM.StaticPageStoreState, handledDto);
        return model;
    }

    getStaticPageModelsFromDtos(dtos: Array<IStaticPageDto>): Array<StM.IStaticPageStoreState> {
        const models = _.map(dtos, (dto: IStaticPageDto) => {
            return this.getStaticPageModelFromDto(dto);
        });
        _.remove(models, _.isUndefined);
        return models;
    }

    //#endregion
    
    //#region Recurrent Available Times Dto -> Model
    getRecurentAvailableTimeModelsFromDto(dtos: Array<IRecurrentAvailabilityDto>): Array<StM.IRecurrentAvailabilityStoreState> {
        const models = _.map(dtos, (dto: IRecurrentAvailabilityDto) => {
            return this.getRecurentAvailableTimeModelFromDto(dto);
        });
        return models;
    }

    getRecurentAvailableTimeModelFromDto(dto: IRecurrentAvailabilityDto): StM.IRecurrentAvailabilityStoreState {
        const handledDto = this.map(DtoM.RecurrentAvailabilityDto, dto);
        if (dto) {
            handledDto.coach = this.map(DtoM.PublicUserDto, handledDto.coach);
        }
        const model = this.map(StM.RecurrentAvailabilityStoreState, handledDto);
        return model;
    }
    //#endregion

    //#regionRecurrent Available Times Model -> Dto
    getRecurentAvailableDtosFromModels(models: Array<StM.IRecurrentAvailabilityStoreState>): Array<IRecurrentAvailabilityDto> {
        const dtos = _.map(models, (model: StM.IRecurrentAvailabilityStoreState) => {
            const dto = this.getRecurentAvailableDtoFromModel(model);
            dto.start = moment.utc(dto.start.as('milliseconds')).format('HH:mm:ss');
            dto.end = moment.utc(dto.end.as('milliseconds')).format('HH:mm:ss');
            return dto;
        });
        return dtos;
    }

    getRecurentAvailableDtoFromModel(model: StM.IRecurrentAvailabilityStoreState): IRecurrentAvailabilityDto {
        const dto = this.map(DtoM.RecurrentAvailabilityDto, model);
        return dto;
    }
    //#endregion

    //#region Credit Card Model -> Dto
    getCreditCardDtosFromModels(models: Array<StM.ICreditCardStoreState>): Array<ICreditCardDto> {
        const dtos = _.map(models, (model: StM.ICreditCardStoreState) => {
            return this.getCreditCardDtoFromModel(model);
        });
        return dtos;
    }

    getCreditCardDtoFromModel(model: StM.ICreditCardStoreState): ICreditCardDto {
        const dto = this.map(DtoM.CreditCardDto, model);
        return dto;
    }

    getBillingAddressDtosFromModels(models: Array<StM.IBillingAddressStoreState>): Array<IBillingAddressDto> {
        const dtos = _.map(models, (model: StM.IBillingAddressStoreState) => {
            return this.getBillingAddressDtoFromModel(model);
        });
        return dtos;
    }

    getBillingAddressDtoFromModel(model: StM.IBillingAddressStoreState): IBillingAddressDto {
        const dto = this.map(DtoM.BillingAddressDto, model);
        return dto;
    }

    getPaymentProfileInformationDtosFromModels(models: Array<StM.IPaymentProfileInformationStoreState>): Array<IPaymentProfileInformationDto> {
        const dtos = _.map(models, (model: StM.IPaymentProfileInformationStoreState) => {
            return this.getPaymentProfileInformationDtoFromModel(model);
        });
        return dtos;
    }

    getPaymentProfileInformationDtoFromModel(model: StM.IPaymentProfileInformationStoreState): IPaymentProfileInformationDto {
        const dto = this.map(DtoM.PaymentProfileInformationDto, model);
        if (dto.creditCard) { dto.creditCard = this.getCreditCardDtoFromModel(model.creditCard); }
        if (dto.billingAddress) { dto.billingAddress = this.getBillingAddressDtoFromModel(model.billingAddress); }
        return dto;
    }
    //#endregion

    //#region Payment Model -> Dto
    getUserPaymentModelsFromDtos(dtos: Array<IUserPaymentDto>, club: StM.IClubStoreState): Array<StM.IUserPaymentStoreState> {
        const models = _.map(dtos, (dto: IUserPaymentDto) => {
            return this.getUserPaymentModelFromDto(dto, club);
        });
        return models;
    }

    getUserPaymentModelFromDto(dto: IUserPaymentDto, club: StM.IClubStoreState): StM.IUserPaymentStoreState {
        if (!dto) return null;
        const model = this.map(StM.UserPaymentStoreState, dto);
        if (model.session) {
            model.session = this.getTransactionDetailsSessionModelFromDto(dto.session, club);
        }
        return model;
    }

    getTransactionModelFromDto(dto: ITransactionDto, club: StM.IClubStoreState): StM.ITransactionStoreState {
        if (!dto) return null;
        const model = this.map(StM.TransactionStoreState, dto);
        if (model.session) {
            model.session = this.getTransactionDetailsSessionModelFromDto(dto.session, club);
        }
        return model;
    }

    getTransactionModelsFromDto(dtos: Array<ITransactionDto>, club: StM.IClubStoreState): Array<StM.ITransactionStoreState> {
        const models = _.map(dtos, (dto: ITransactionDto) => {
            return this.getTransactionModelFromDto(dto, club);
        });
        return models;
    }

    getTransactionDetailsSessionModelFromDto(dto: ITransactionDetailsSessionDto, club: StM.IClubStoreState): StM.ITransactionDetailsSessionStoreState {
        if (!dto) { return null; }
        const handledDto = this.map(DtoM.TransactionDetailsSessionDto, dto);

        const model = this.map(StM.TransactionDetailsSessionDto, handledDto);
        
        return model;
    }

    getCreditsTransactionModelsFromDto(dtos: Array<ICreditsTransactionDto>): Array<StM.ICreditsTransactionStoreState> {
        const models = _.map(dtos, (dto: ICreditsTransactionDto) => {
            return this.getCreditsTransactionModelFromDto(dto);
        });
        return models;
    }

    getCreditsTransactionModelFromDto(dto: ICreditsTransactionDto): StM.ICreditsTransactionStoreState {
        if (!dto) { return null; }
        const handledDto = this.map(DtoM.CreditsTransactionDto, dto);
        const model = this.map(StM.CreditsTransactionStoreState, handledDto);
        return model;
    }
    //#endregion

    //#region Payment Model -> Dto
    getUserPaymentDtosFromModels(models: Array<StM.IUserPaymentStoreState>): Array<IUserPaymentDto> {
        const dtos = _.map(models, (model: StM.IUserPaymentStoreState) => {
            return this.getUserPaymentDtoFromModel(model);
        });
        return dtos;
    }

    getUserPaymentDtoFromModel(model: StM.IUserPaymentStoreState): IUserPaymentDto {
        const dto = this.map(DtoM.UserPaymentDto, model);
        return dto;
    }
    //#endregion

    //#region Notification Dto -> Model
    getNotificationModelsFromDtos(dtos: Array<INotificationDto>, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Array<StM.INotificationStoreState> {
        const models = _.map(dtos, (dto: INotificationDto) => {
            return this.getNotificationModelFromDto(dto, club, tiers);
        });
        return models;
    }

    getNotificationModelFromDto(dto: INotificationDto, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): StM.INotificationStoreState {
        if (!dto) { return null; }
        const handledDto = new DtoM.NotificationDto(dto);

        if (handledDto.club) { handledDto.club = new DtoM.ClubDto(handledDto.club); }
        if (handledDto.createdBy) { handledDto.createdBy = new DtoM.PublicUserDto(handledDto.createdBy); }
        if (handledDto.addressedTo) { handledDto.addressedTo = new DtoM.PublicUserDto(handledDto.addressedTo); }
        if (handledDto.target) {
            if (handledDto.targetType === StM.NotificationTargetType.Session) {
                handledDto.target = new DtoM.SessionDto(handledDto.target);
            }
        }

        const model = this.map(StM.NotificationStoreState, dto);
        if (model.club) { model.club = this.getClubModelFromDto(handledDto.club); }
        if (model.createdBy) { model.createdBy = this.getPublicUserModelFromDto(handledDto.createdBy); }
        if (model.addressedTo) { model.addressedTo = this.getPublicUserModelFromDto(handledDto.addressedTo); }
        
        if (model.target) {
            if (model.targetType === StM.NotificationTargetType.Session) {
                model.target = this.getSessionModelFromDto(handledDto.target as ISessionDto, club, tiers);
            }
        }

        return model;
    }

    getNotificationDtoFromModel(model: StM.INotificationStoreState): INotificationDto {

        const dto = this.map(DtoM.NotificationDto, model);

        if (dto.createdBy) { dto.createdBy = this.getPublicUserDtoFromModel(model.createdBy); }
        if (dto.addressedTo) { dto.addressedTo = this.getPublicUserDtoFromModel(model.addressedTo); }
        dto.club = null;
        return dto;
    }
    //#endregion

    //#region Permission Dto -> Model
    getPermissionModelsFromDtos(dtos: Array<IPermissionDto>): Array<StM.IPermissionStoreState> {
        const models = _.map(dtos, (dto: IPermissionDto) => {
            return this.getPermissionModelFromDto(dto);
        });
        return models;
    }

    getPermissionModelFromDto(dto: IPermissionDto): StM.IPermissionStoreState {
        const handledDto = new DtoM.PermissionDto(dto);
        const model = this.map(StM.PermissionStoreState, dto);
        return model;
    }
    //#endregion


    //#region CreditWallets Dto -> Model
    getCreditWalletModelFromDtos(dtos: Array<ICreditsWalletDto>): StM.ICreditsWalletStoreState {
        if (!dtos || !dtos.length) return null;

        const model = this.map(StM.CreditsWalletStoreState, _.first(dtos));
        return model;
    }
    //#endregion

    //#region AliasedTimeZone Dto -> Model
    getAliasedTimeZoneModelFromDto(dto: IAliasedTimeZoneDto): StM.IAliasedTimeZoneStoreState {
        const handledDto = new DtoM.AliasedTimeZoneDto(dto);
        const model = this.map(StM.AliasedTimeZoneStoreState, dto);
        return model;
    }

    getAliasedTimeZoneModelsFromDtos(dtos: Array<IAliasedTimeZoneDto>): Array<StM.IAliasedTimeZoneStoreState> {
        const models = _.map(dtos, (dto: IAliasedTimeZoneDto) => {
            return this.getAliasedTimeZoneModelFromDto(dto);
        })
        return models;
    }
    //#endregion

    // Groups

    getGroupModelsFromDtos(dtos: Array<IGroupDto>, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Array<StM.IGroupStoreState> {
        const models = _.map(dtos, (dto: IGroupDto) => {
            return this.getGroupModelFromDto(dto, club, tiers);
        });

        _.remove(models, _.isUndefined);
        return models;
    }

    getGroupModelFromDto(dto: IGroupDto, club: StM.IClubStoreState = null, tiers: StM.IPricingTierStoreState[] = []): StM.IGroupStoreState {
        if (!dto) { return null; }
      
        const handledDto = this.map(DtoM.GroupDto, dto);
        const model = this.map(StM.GroupStoreState, handledDto);

        model.groupAccount = this.map(StM.PublicUserStoreState, model.groupAccount);
        model.createdBy = this.map(StM.PublicUserStoreState, model.createdBy);
        model.lastUpdatedBy = this.map(StM.PublicUserStoreState, model.lastUpdatedBy);

        model.members = this.getGroupMemberModelsFromDtos(handledDto.members, club, tiers);
        if(!!club) model.events =  this.getGroupEventModelsFromDtos(handledDto.events, club, tiers);
        return model;
    }

    getGroupMemberModelsFromDtos(dtos: Array<IGroupMemberDto>, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Array<StM.IGroupMemberStoreState> {
        const models = _.map(dtos, (dto: IGroupMemberDto) => {
            return this.getGroupMemberModelFromDto(dto, club, tiers);
        });

        _.remove(models, _.isUndefined);
        return models;
    }

    getGroupMemberModelFromDto(dto: IGroupMemberDto, club: StM.IClubStoreState = null, tiers: StM.IPricingTierStoreState[] = []): StM.IGroupMemberStoreState {
        if (!dto) { return null; }
        const handledDto = this.map(DtoM.GroupMemberDto, dto);
        
        const model = this.map(StM.GroupMemberStoreState, handledDto);
       
        model.group = this.getGroupModelFromDto(handledDto.group, club, tiers);

        model.user = this.map(StM.PublicUserStoreState, handledDto.user);
        model.invitedBy = this.map(StM.PublicUserStoreState, handledDto.invitedBy);
        model.addedBy = this.map(StM.PublicUserStoreState, handledDto.addedBy);
        model.lastUpdatedBy = this.map(StM.PublicUserStoreState, handledDto.lastUpdatedBy);

        return model;
    }

    getGroupEventModelsFromDtos(dtos: Array<IGroupEventDto>, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Array<StM.IGroupEventStoreState> {
        const models = _.map(dtos, (dto: IGroupEventDto) => {
            return this.getGroupEventModelFromDto(dto, club, tiers);
        });

        _.remove(models, _.isUndefined);
        return models;
    }

    getGroupEventModelFromDto(dto: IGroupEventDto, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): StM.IGroupEventStoreState {
        if (!dto) { return null; }
        const handledDto = this.map(DtoM.GroupEventDto, dto);
        const model = this.map(StM.GroupEventStoreState, handledDto);
        
        model.group = this.getGroupModelFromDto(handledDto.group, club, tiers);
        model.session = this.getSessionModelFromDto(handledDto.session, club, tiers);
        model.booking = this.getBookingModelFromDto(handledDto.booking, club, tiers);

        model.user = this.map(StM.PublicUserStoreState, model.user);
        model.createdBy = this.map(StM.PublicUserStoreState, model.createdBy);
       
        return model;
    }

    getGroupDtoFromModel(model: StM.IGroupStoreState, club: StM.IClubStoreState): IGroupDto {
        const dto = new DtoM.GroupDto(model);
        
        dto.members =  _.map(model.members, (m) => { 
            const user = this.getPublicUserDtoFromModel(m.user); 
            const member = new DtoM.GroupMemberDto(m);
            member.user = user; 
            return member; }
        );

        return dto;
    }

    //#region Payment Systems
    
    getPaymentSystemModelsFromDtos(dtos: Array<IPaymentSystemDto>, club: StM.IClubStoreState): Array<StM.IPaymentSystemStoreState> {
        const models = _.map(dtos, (dto: IPaymentSystemDto) => {
            return this.getPaymentSystemModelFromDto(dto, club);
        });

        _.remove(models, _.isUndefined);
        return models;
    }

    getPaymentSystemModelFromDto(dto: IPaymentSystemDto, club: StM.IClubStoreState = null): StM.IPaymentSystemStoreState {
        if (!dto) { return null; }
      
        const handledDto = this.map(DtoM.PaymentSystemDto, dto);
        const model = this.map(StM.PaymentSystemStoreState, handledDto);
        return model;
    }

    getPaymentSystemEditableModelsFromDtos(dtos: Array<IPaymentSystemEditableDto>, club: StM.IClubStoreState): Array<StM.IPaymentSystemEditableStoreState> {
        const models = _.map(dtos, (dto: IPaymentSystemEditableDto) => {
            return this.getPaymentSystemEditableModelFromDto(dto, club);
        });

        _.remove(models, _.isUndefined);
        return models;
    }

    getPaymentSystemEditableModelFromDto(dto: IPaymentSystemEditableDto, club: StM.IClubStoreState = null): StM.IPaymentSystemEditableStoreState {
        if (!dto) { return null; }
      
        const handledDto = this.map(DtoM.PaymentSystemEditableDto, dto);
        const model = this.map(StM.PaymentSystemEditableStoreState, handledDto);
        return model;
    }

    //#endregion 
}
