import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';
import * as ActM from '../';

export class DemographicInformationDialogActions {
    static open(returnUrl?: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/DemographicInformation/Open'
                , payload: { returnUrl: returnUrl }
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/DemographicInformation/Close',
            });
        };
    }

    static save(model: StM.IUserManagableStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.user.saveDemographicInformation(model)
            .then((response: any) => {
                return dispatch(ActM.UserActions.getCurrent());
            });
        }
    }
}
