import * as React from 'react';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as StM from '../../../models/store';
import * as ActM from '../../../actions';

export interface IPasswordRecoveryDialogProps extends IBasePasswordRecoveryDialogState {
    isShown: boolean;

    closeDialog: () => void;
    onResetPassClick: (e: any) => void;
    onBackClick: (e: any) => void;
    onFieldChange: (name: string, value: any) => void;
}

export interface IBasePasswordRecoveryDialogProps {
    isShown: boolean;
    
    closeDialog: () => void;
    openAuthDialog: () => Promise<any>;
    showSpinner: () => any;
    hideSpinner: () => any;
    resetPass: (email: string) => Promise<any>;
    showAlert: (msgKey:string) => Promise<any>;
    goHome: () => any;
    closeAlert: () => void;
}

export interface IBasePasswordRecoveryDialogState {
    errors: any;
    email : string;
}

const withBasePasswordRecoveryDialog = (Component: React.ComponentType<IPasswordRecoveryDialogProps>) => {
    return class BaseDemographicInformationDialog extends React.Component<IBasePasswordRecoveryDialogProps, IBasePasswordRecoveryDialogState> {
        private customWindow: any = window;
        constructor(props: IBasePasswordRecoveryDialogProps) {
            super(props);
            this.state = {
                errors: null,
                email: ''
            }
        }

        
        public componentDidUpdate(prevProps:IBasePasswordRecoveryDialogProps){
            if (this.props.isShown !== prevProps.isShown) {
                this.setState({ errors: null, email: '' });
            }
        }

        public render() {
            return (
                <Component
                    {...this.state}
                    isShown={this.props.isShown}
                    closeDialog={() => this.props.closeDialog()}
                    onResetPassClick={(e) => this.handleResetPassClick(e)}
                    onBackClick={(e) => this.handleBackClick(e)}
                    onFieldChange={(name, value) => this.handleFieldChange(name, value)}
                />
            );
        }
    
        private handleFieldChange(name: string, value: any) {
            let errors = !!this.state.errors && { ...this.state.errors };
            if (errors) delete errors[name];
            this.setState({
                ...this.state,
                [name]: value,
                errors
            });
        }
    
        private handleBackClick(e: any) {
            if(e) { e.preventDefault(); e.stopPropagation(); }
            this.props.closeDialog();
            if(this.customWindow.ISADMIN) this.props.goHome();
            else this.props.openAuthDialog();
        }
    
        private handleResetPassClick(e: any){
            if(e) { e.preventDefault(); e.stopPropagation(); }
            this.setState({ ...this.state, errors: null });
            if (!this.state.email) {
                this.setState({ ...this.state, errors: { email: 'Email is empty' }});
                return false;
            }
            this.props.showSpinner();
            this.props.resetPass(this.state.email)
                .then(() => {
                    this.props.hideSpinner();
                    this.props.closeDialog();
                    this.props.showAlert(StM.MessagesKey.PasswordRecoveryEmailWasSend);
                }).catch((errorData) => {
                    this.props.hideSpinner();
                    this.props.closeAlert();
                    let errors = {
                        server: errorData ? errorData.toString() : 'Some error ocurred.'
                    }
                    if(errorData && errorData.response && errorData.response.data){
                        errors.server = errorData.response.data;
                    }
                    this.setState({ ...this.state, errors });
                });
        }
    };
};

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        isShown: state.dialogs.passwordRecovery.isOpened
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.PasswordRecovery))
        , openAuthDialog: () => dispatch(ActM.DialogActions.open(StM.DialogNames.Auth, { tab: StM.AuthDialogTabs.SignIn, returnUrl: '' }))
        , showSpinner: () => dispatch(ActM.AppActions.showSpinner())
        , hideSpinner: () => dispatch(ActM.AppActions.hideSpinner())
        , resetPass: (userMail: string) => dispatch(ActM.AccountActions.resetPass(userMail))
        , showAlert: (msgKey: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, {msgKey: msgKey, messageType: StM.MessageTypes.Success}))
        , goHome: () => dispatch(ActM.RouteActions.goHome())
        , closeAlert: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Alert, null, true))
    };
};

export const withPasswordRecoveryDialog = compose(
    connect(mapStateToProps, mapDispatchToProps)
    , withBasePasswordRecoveryDialog);