import moment from 'moment';
import * as _ from 'lodash';

import * as StM from '../../models/store';
import { IBasePolicy, BasePolicy } from '../basePolicy';
import { SessionAvailabilityPolicy } from '../sessions';
import * as SrvM from '../../services';

export class UserAvailabilityPolicy extends BasePolicy implements IBasePolicy {
    private authSrv = new SrvM.AuthenticationService();

    constructor(
        private sessions: Array<StM.ISessionStoreState>
        , private basketSessions: Array<StM.ISessionStoreState>
        , private time: StM.ICourtTimeBlockStoreState
        , private court: StM.ICourtStoreState
        , private user?: StM.IUserStoreState
    ) {
        super();
        if (!user) { this.user = this.getCurrentUser(); }
    }

    handle(): boolean {
        const isUserFree = this.getIsUserFree();
        return isUserFree;
    }

    getIsUserFree(): boolean {
        if(this.authSrv.isInRole(StM.Roles.GroupAccount, this.user)) return true;
        let isUserFree = true;
        const timeStartTime = this.time.date.clone().startOf('day').set({ 'hour': this.time.start.hours(), 'minute'  :  this.time.start.minutes() });
        const timeEndTime = this.time.date.clone().startOf('day').set({ 'hour': this.time.end.hours(), 'minute'  :  this.time.end.minutes() });

        const currentTimeSessions = this.getSessionsInTime(timeStartTime, timeEndTime);

        if (currentTimeSessions && currentTimeSessions.length > 0) {
            _.each(currentTimeSessions, (existSession: StM.ISessionStoreState) => {
                const isBasket = new SessionAvailabilityPolicy(existSession, this.basketSessions, this.user).getIsBasket();
                const isBought = this.getIsBoughtUser(existSession);
                const isTrainer = this.getIsTrainer(existSession);
                isUserFree = !isBasket && !isBought && !isTrainer;
                return isUserFree;
            });
        }
        return isUserFree;
    }

    getIsBoughtUser(session: StM.ISessionStoreState): boolean {
        const sessionAvailabilityPolicy = new SessionAvailabilityPolicy(session, [], this.user);
        const isBought = sessionAvailabilityPolicy.getIsBoughtUser();
        return isBought;
    }

    getIsTrainer(session: StM.ISessionStoreState): boolean {
        const sessionAvailabilityPolicy = new SessionAvailabilityPolicy(session, [], this.user);
        return sessionAvailabilityPolicy.getIsTrainer();
    }

    private getSessionsInTime(timeStartTime: moment.Moment, timeEndTime: moment.Moment) {

        let foundedSessions = _.filter(this.sessions.concat(this.basketSessions), (session: StM.ISessionStoreState) => {
            return session.startDateTime.isBefore(timeEndTime) && session.endDateTime.isAfter(timeStartTime)
        });

        return foundedSessions;
    }
}