import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/es/storage';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import appSettings from '../appSettings';
import { HAND_HYDRATED_STATE, handledRootReducer } from '../reducers';
import { ThunkService } from '../services/thunkService';
import { IGlobalStoreState } from '@models/store';

const logger = createLogger({
    collapsed: true,
    duration: true,
    diff: true,
    // use like this:
    // predicate: (getState, action) => !action.type.toString().startsWith('Auth'),
    predicate: (getState, action) => false,
});

const thunkService = new ThunkService({ baseURL: appSettings.Api.BASE_API_URL });

export const history = createBrowserHistory();
const enhancer = compose(
    applyMiddleware(thunk.withExtraArgument(thunkService)),
    applyMiddleware(logger));

const persistConfig: PersistConfig<IGlobalStoreState> = {
    key: 'sof',
    storage,
    stateReconciler: autoMergeLevel2,
    whitelist: ['authenticationTicket', 'basket', 'temporarySession'],
};

const persistedReducer = persistReducer<IGlobalStoreState>(persistConfig, handledRootReducer(history));

export const store = createStore(persistedReducer, enhancer);
export const persistor = persistStore(store, {}, () => {
    store.dispatch({
        type: HAND_HYDRATED_STATE,
    });

    store.dispatch({
        type: 'App/Dehydrated'
    });
});
