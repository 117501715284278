import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';

export default class SubscriptionPurchaseDialogReducer extends BaseReducer{

    static handle(state: StM.ISubscriptionCheckoutDialogStoreState = new StM.SubscriptionCheckoutDialogStoreState(), action: any): StM.ISubscriptionCheckoutDialogStoreState {
        switch (action.type) {
            case 'Dialogs/Subscriptions/Purchase/Open': {
                return { ...state, ...{ isOpened: true, subscriptionDefinition:  action.payload.subscription, subscriptionDefinitionId: action.payload.id }};
            }
            case 'Dialogs/Subscriptions/Purchase/Close': {
                return { ...state, ...{ isOpened: false}};
            }
            default: {
                return state;
            }
        }
    }
}
