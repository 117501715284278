import moment from 'moment';

export interface ICoachAvailabilityTimeItem {
    startTime: moment.Moment;
    endTime: moment.Moment;
    rect?: any;
}

export interface ICoachAvailabilityStoreState {
    range: Array<ICoachAvailabilityTimeItem>;
    hoverItem: ICoachAvailabilityTimeItem;
}

export class CoachAvailabilityStoreState implements ICoachAvailabilityStoreState {
    range = [];
    hoverItem = null;
    constructor(opt?: any) { }
}