import * as StM from '../models/store';
import { ThunkService } from '../services/thunkService';

export class BuildVersionActions {
    static init(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => 
        Promise<any> {
            return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => thunkService.api.common.getBuildVersion()
                .then((version: any) => {
                    return version;
                });
            }
}

