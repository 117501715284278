import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';

export class TransactionRefundDialogActions {
    static open(transaction: any, closeCallback: any): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Transaction/Refund/Open',
                transaction: transaction,
                closeCallback: closeCallback
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Transaction/Refund/Close',
            });
        };
    }
}
