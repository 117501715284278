export class PackageSessionTypeDto implements IPackageSessionTypeDto{
        id: number;
        sessionType: string;
        isActive: any;
        group: string;
        title: string;
        shortTitle: string;
        description: string;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.sessionType = dto && dto.sessionType ? dto.sessionType : '';
        this.isActive = dto && dto.isActive ? dto.isActive : false;
        this.group = dto && dto.group ? dto.group : '';
        this.title = dto && dto.title ? dto.title : '';
        this.shortTitle = dto && dto.shortTitle ? dto.shortTitle : '';
        this.description = dto && dto.description ? dto.description : null;
    }
}