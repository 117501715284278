import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class CreateSessionDialogReducer extends BaseReducer{

    static handle(state: StM.ICreateSessionDialogStoreState = new StM.CreateSessionDialogStoreState(), action: any): StM.ICreateSessionDialogStoreState {
        switch (action.type) {
            case 'Dialogs/CreateSession/Open': {
                return <StM.ICreateSessionDialogStoreState>{...state, ...{ 
                    isOpened: true, 
                    timeKey: action.timeKey, 
                    courtId: action.courtId, 
                    sessionType: action.sessionType, 
                    sessionSubFilter: action.sessionSubFilter, 
                    sessionSubFilterId: action.sessionSubFilterId,
                    sessionId: action.sessionId
                }};
            }
            case 'Dialogs/CreateSession/Close': {
                return <StM.ICreateSessionDialogStoreState>_.assign({}, state, { isOpened: false});
            }
            default: {
                return state;
            }
        }
    }
}
