import * as memoryCache from 'memory-cache';
import moment from 'moment';

export class RequestCacheService {
    private static _instance: RequestCacheService;

    constructor() {
        if (typeof RequestCacheService._instance == "undefined") {
            RequestCacheService._instance = this;
        }
        return RequestCacheService._instance;
    }

    public set(key: string, object: any, startDate: moment.Moment, endDate: moment.Moment) {
        memoryCache.put(key, { startDate, endDate, object }, 1000 * 60 * 5);
    }

    public setItem(key: string, object: any) {
        memoryCache.put(key, object, 1000 * 60 * 5);
    }

    public get(key: string, startDate: moment.Moment, endDate?: moment.Moment): any {
        let item = memoryCache.get(key);

        if (item && (item.startDate as moment.Moment).isSameOrBefore(startDate) && (item.endDate as moment.Moment).isAfter(endDate || startDate)) return item.object;

        memoryCache.del(key);

        return null;
    }

    public getItem(key: string): any {
        return memoryCache.get(key);
    }
    public removeItem(key: string): any {
        return memoryCache.del(key);
    }

    public reset() {
        memoryCache.clear();
    }

    public resetExcept(skip: string) {
        memoryCache.keys().forEach(key => {
            if (key !== skip) memoryCache.del(key);
        });
    }
}
