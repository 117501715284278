import * as React from 'react';
import ClassNames from 'classnames';

interface IDisplayFieldProps {
    className?: string;
    title?: string;
    value?: string;
}

export class DisplayField extends React.Component<IDisplayFieldProps> {
    constructor(props: IDisplayFieldProps) {
        super(props);
    }

    render() {
        const wrapperClasses = ClassNames('display-field-item', this.props.className);
        return (
            <div className={wrapperClasses} >
                <div className="display-field-item-title">{this.props.title}</div>
                <div className="display-field-item-value">
                    {this.props.children || this.props.value}
                </div>
            </div>
        );
    }
};

export default DisplayField;
