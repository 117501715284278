import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class BillingInfoDialogReducer extends BaseReducer{

    static handle(state: StM.IBillingInfoDialogStoreState = new StM.BillingInfoDialogStoreState(), action: any): StM.IBillingInfoDialogStoreState {
        switch (action.type) {
            case 'Dialogs/BillingInfo/Open': {
                const returnUrl = action.returnUrl;
                return { ...state, 
                    ...{ 
                        isOpened: true, 
                        returnUrl,
                        type: action.paymentSystem
                    }
                };
            }
            case 'Dialogs/BillingInfo/Close': {
                return {...state, ...{ isOpened: false, returnUrl: ''}};
            }
            default: {
                return state;
            }
        }
    }
}
