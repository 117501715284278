import * as StM  from '../../../store';

export interface IClubDashboardPageStoreState {
    sessions: Array<StM.ISessionStoreState>;
    timeSlots: Array<StM.ICourtTimeSlotStoreState>;
}

export class ClubDashboardPageStoreState implements IClubDashboardPageStoreState {
    sessions: Array<StM.ISessionStoreState>;
    timeSlots:Array<StM.ICourtTimeSlotStoreState>;
    constructor() {
        this.sessions = new Array();
        this.timeSlots = new Array();
    }
}
