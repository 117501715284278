import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';
import * as AdminActM from '../admin';

export class EditCoachFeeTierDialogActions {
    static open(id?: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Admin/EditCoachFeeTier/Open',
                id: id
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Admin/EditCoachFeeTier/Close',
            });
        };
    }

    static save(coachFeeTier: StM.ICoachFeeTierStoreState, prices: StM.ICoachFeeTierPriceStoreState[], skipValidation: boolean): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.saveCoachFeeTier(coachFeeTier, skipValidation)
                .then((response) => {
                    const savingPrices = !coachFeeTier.id ? prices.map(p => { return { ...p, coachFeeTierId: response.id }}) : prices;
                    return prices.length ? thunkService.api.pricing.saveCoachFeeTierPrices(savingPrices) : Promise.resolve();
                }).then(() => {
                    const tiersP = dispatch(AdminActM.SettingsActions.getCoachFeeTiers());
                    const pricesP = !!prices.length && dispatch(AdminActM.AdminCoachFeeTierPricesActions.getAll());
                    return Promise.all([tiersP, pricesP]);
                });
        };
    }
}
