import { BaseDialogStoreState, IBaseDialogStoreState } from './baseDialogStoreState';

export interface ISubscribersListDialogStoreState extends IBaseDialogStoreState {
    id: number;
}

export class SubscribersListDialogStoreState extends BaseDialogStoreState implements ISubscribersListDialogStoreState {
    id: number;
    
    constructor() {
        super();
        this.id = 0;
    }
}