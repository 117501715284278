import moment from 'moment';

export interface IClubTimeStoreState {
        id: number;
        club: IClubDto;
        dayOfWeek: number;
        startTime: moment.Duration;
        endTime: moment.Duration;
}


export class ClubTimeStoreState implements IClubTimeStoreState {
        id: number;
        club: IClubDto;
        dayOfWeek: number;
        startTime: moment.Duration;
        endTime: moment.Duration;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.club = dto && dto.club ? dto.club : null;
        this.dayOfWeek = dto && dto.dayOfWeek ? dto.dayOfWeek : 0;
        this.startTime = dto && dto.startTime ? moment.duration(dto.startTime) : moment.duration();
        this.endTime = dto && dto.endTime ? moment.duration(dto.endTime) : moment.duration();
    }
}
