import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class SessionInfoDialogReducer extends BaseReducer{

    static handle(state: StM.ISessionInfoDialogStoreState = new StM.SessionInfoDialogStoreState(), action: any): StM.ISessionInfoDialogStoreState {
        switch (action.type) {
            case 'Dialogs/SessionInfo/Open': {
                return <StM.ISessionInfoDialogStoreState>{...state, ...{ isOpened: true, from: action.from, id: action.id}};
            }
            case 'Dialogs/SessionInfo/Close': {
                return <StM.ISessionInfoDialogStoreState>{...state, ...{ isOpened: false}};
            }
            case 'Dialogs/SessionInfo/Session': {
                const session = <StM.ISessionStoreState>action.payload;
                return <StM.ISessionInfoDialogStoreState>{... state, ...{ session: session, id: session.id}};
            }
            case 'Dialogs/SessionInfo/InviteToken': {
                const inviteToken = <string>action.payload;
                return <StM.ISessionInfoDialogStoreState>{... state, ...{ inviteToken: inviteToken}};
            }
            case 'Dialogs/SessionInfo/Notification': {
                const notification = <StM.INotificationStoreState>action.payload;
                return <StM.ISessionInfoDialogStoreState>{... state, ...{ notification: notification}};
            }
            default: {
                return state;
            }
        }
    }
}
