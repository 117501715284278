import * as _ from 'lodash';
import moment from 'moment';

import { Constants, DtoM, SrvM, StM } from '../../modules';
import { BaseApiService } from '../baseApiService';

export class SessionApiService extends BaseApiService {
    private static _instance: SessionApiService;

    private cache: SrvM.RequestCacheService;

    constructor(config?: any) {
        if (typeof SessionApiService._instance == "undefined") {
            super(config);
            SessionApiService._instance = this;
            this.cache = new SrvM.RequestCacheService();
        }
        return SessionApiService._instance;
    }


    public getByPeriod(start: moment.Moment, end: moment.Moment, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[], requestForClubDashboard: boolean = false, clearCache: boolean = false): Promise<Array<StM.ISessionStoreState>> {

        let startDate = start;
        let endDate = end;

        if (!requestForClubDashboard && !clearCache) {
            let cachedRequest = this.cache.get(StM.CacheKey.SessionsByPeriod, start, end);

            if (cachedRequest)
                return new Promise((resolve) => {
                    let requiredCachedItems = _.filter(cachedRequest as Array<StM.ISessionStoreState>,
                        (item) => item.startDateTime.isSameOrAfter(start) && item.startDateTime.isSameOrBefore(end));
                    resolve(requiredCachedItems);
                });

            startDate = start.clone().add(-1, "weeks");
            endDate = end.clone().add(1, "weeks");
        }

        if (clearCache) this.cache.removeItem(StM.CacheKey.SessionsByPeriod);

        let startFormat = startDate.format(Constants.DateTime.API_FORMAT);
        let endFormat = endDate.format(Constants.DateTime.API_FORMAT);

        return this.get('/period/{0}/{1}'.format(startFormat, endFormat))
            .then((response: any) => {
                let models = this.mapper.getSessionModelsFromDtos(<Array<ISessionDto>>response.data, club, tiers);
                if (requestForClubDashboard) {
                    return models;
                }
                this.cache.set(StM.CacheKey.SessionsByPeriod, models, startDate, endDate);
                let requiredCachedItems = _.filter(models,
                    (item) => item.startDateTime.isSameOrAfter(start) && item.endDateTime.isSameOrBefore(end));

                return requiredCachedItems;
            });
    }

    public getLeagues(club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<Array<StM.ISessionStoreState>> {
        return this.get('/leagues')
            .then((response: any) => {
                let models = this.mapper.getSessionModelsFromDtos(<Array<ISessionDto>>response.data, club, tiers);
                return models;
            });
    }

    public getUserSessionsByPeriod(start: moment.Moment, end: moment.Moment, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<Array<StM.ISessionStoreState>> {
        let startDate = start;
        let endDate = end;

        let cachedRequest = this.cache.get("MYSESSIONS_BY_PERIOD", start, end);

            if (cachedRequest)
                return new Promise((resolve) => {
                    let requiredCachedItems = _.filter(cachedRequest as Array<StM.ISessionStoreState>,
                        (item) => item.startDateTime.isSameOrAfter(start) && item.startDateTime.isSameOrBefore(end));
                    resolve(requiredCachedItems);
                });

            startDate = start.clone().add(-1, "weeks");
            endDate = end.clone().add(1, "weeks");

        let startFormat = startDate.format(Constants.DateTime.API_FORMAT);
        let endFormat = endDate.format(Constants.DateTime.API_FORMAT);

        return this.get('/my/{0}/{1}'.format(startFormat, endFormat))
            .then((response: any) => {
                let models = this.mapper.getSessionModelsFromDtos(<Array<ISessionDto>>response.data, club, tiers);
               
                this.cache.set("MYSESSIONS_BY_PERIOD", models, startDate, endDate);
                let requiredCachedItems = _.filter(models,
                    (item) => item.startDateTime.isSameOrAfter(start) && item.endDateTime.isSameOrBefore(end));

                return requiredCachedItems;
            });
    }

    public getClinics(club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<Array<StM.ISessionStoreState>> {
        return this.get('/clinics')
            .then((response: any) => {
                let models = this.mapper.getSessionModelsFromDtos(<Array<ISessionDto>>response.data, club, tiers);
                return models;
            });
    }

    public getById(id: number, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.ISessionStoreState> {
        return this.get('/{0}'.format(id))
            .then((response: any) => {
                let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
                return model;
            });
    }

    public getByVideoId(id: string, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.ISessionStoreState> {
        return this.get('/video/{0}'.format(id))
            .then((response: any) => {
                let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
                return model;
            });
    }

    public getByInviteToken(inviteToken: string, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.ISessionStoreState> {
        return this.get('/{0}/inviteToken'.format(inviteToken))
            .then((response: any) => {
                let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
                return model;
            });
    }

    public acceptInviteByInviteToken(inviteToken: string, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.ISessionStoreState> {
        return this.post('/{0}/acceptInviteToken'.format(inviteToken))
            .then((response: any) => {
                let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
                return model;
            });
    }

    public rejectInviteByInviteToken(inviteToken: string, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.ISessionStoreState> {
        return this.delete('/{0}/rejectInviteToken'.format(inviteToken))
            .then((response: any) => {
                let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
                return model;
            });
    }

    public getInviteToken(sessionId: number): Promise<string> {
        return this.post('/{0}/inviteToken'.format(sessionId))
            .then((response: any) => {
                let inviteToken = <string>response.data;
                return inviteToken;
            });
    }

    public save(model: StM.ISessionStoreState, club: StM.IClubStoreState): Promise<any> {
        const dto = this.mapper.getSessionDtoFromModel(model, club);
        return this.put('/{0}'.format(dto.id), dto);
    }

    public saveInvitedUsers(users: Array<StM.IPublicUserStoreState>, sessionId: number, token: string): Promise<any> {
        const userDtos = this.mapper.getPublicUserDtosFromModels(users);
        const dto = <IAddInvitedUsersToSessionDto>{
            users: userDtos
            , token: ''
        };
        return this.put('/{0}/invitedUsers'.format(sessionId), dto);
    }

    public checkout(sessions: Array<StM.ISessionStoreState>, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<Array<StM.ISessionStoreState>> {
        const dtos = this.mapper.getSessionDtosFromModels(sessions, club);

        return this.put('/', dtos)
            .then((response: any) => {
                let models = this.mapper.getSessionModelsFromDtos(<Array<ISessionDto>>response.data, club, tiers);
                return models;
            });
    }

    public dropOut(
        session: StM.ISessionStoreState, 
        club: StM.IClubStoreState, 
        tiers: StM.IPricingTierStoreState[],
        agreeWithCancelationFee: boolean
        ): Promise<StM.ISessionStoreState> {

        return this.put('/{0}/dropingOut/{1}'.format(session.id, agreeWithCancelationFee))
            .then((response: any) => {
                let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
                return model;
            });
    }

    public dropOutUser(session: StM.ISessionStoreState, user: StM.IPublicUserStoreState, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.ISessionStoreState> {
        return this.put(`/${session.id}/dropOut/${user.id}`)
            .then((response: any) => {
                return this.mapper.getSessionModelFromDto(response.data as ISessionDto, club, tiers);
            })
    }

    public verifyCancellation(session: StM.ISessionStoreState, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.ISessionStoreState> {

        return this.put('/{0}/verifyCancellation'.format(session.id))
            .then((response: any) => {
                let model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
                return model;
            });
    }

    public cancel(
        session: StM.ISessionStoreState,
        club: StM.IClubStoreState,
        tiers: StM.IPricingTierStoreState[],
        agreeWithCancelationFee: boolean
      ): Promise<StM.ISessionStoreState> {
          return this.put('/{0}/cancel'.format(session.id) + `/${agreeWithCancelationFee}`)
            .then((response: any) => {
              const model = this.mapper.getSessionModelFromDto(<ISessionDto>response.data, club, tiers);
              return model;
            });
      }

    public validateBasket(sessions: Array<StM.ISessionStoreState>, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<Array<StM.ISessionStoreState>> {
        const dtos = this.mapper.getSessionDtosFromModels(sessions, club);
        return this.post('/basketValidation', dtos)
            .then((response: any) => {
                let models = this.mapper.getSessionModelsFromDtos(<Array<ISessionDto>>response.data, club, tiers);
                return models;
            });
    }

    public rejectInvitation(sessionId: number, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.ISessionStoreState> {
        return this.delete('/{0}/invitation'.format(sessionId))
            .then((response: { data: ISessionDto }) => {
                let model = this.mapper.getSessionModelFromDto(response.data, club, tiers);
                return model;
            });
    }

    public sendLeagueRequest(name: string, email: string, phone: string, skill: string, leagueName: string, comment: string, session: StM.ISessionStoreState, club: StM.IClubStoreState): Promise<any> {
        let dto = new DtoM.LeagueRequestDto();
        dto.name = name;
        dto.email = email;
        dto.phone = phone;
        dto.skill = skill;
        dto.comment = comment;
        dto.leagueName = leagueName;
        return this.post('/leagueRequest', dto)
            .then((response: any) => {
                return null;
            });
    }

    public sendQueryRequest(message: string, payment: StM.IUserPaymentStoreState, club: StM.IClubStoreState): Promise<any> {
        let dto = new DtoM.QueryRequestDto();
        dto.message = message;
        dto.payment = this.mapper.getUserPaymentDtoFromModel(payment);
        return this.post('/queryRequest', dto)
            .then((response: any) => {
                return null;
            });
    }

    public getTransactions(start: moment.Moment, end: moment.Moment): Promise<Array<any>> {

        let startFormat = start.format(Constants.DateTime.API_FORMAT);
        let endFormat = end.format(Constants.DateTime.API_FORMAT);

        return this.get('/transactions/{0}/{1}'.format(startFormat, endFormat))
            .then((response: any) => {
                return response.data;
            });
    }
}
