import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';
import * as StM from '../../store'; 

export interface IHistoryQueryDialogStoreState extends IBaseDialogStoreState{
    paymentId: number;
}

export class HistoryQueryDialogStoreState extends BaseDialogStoreState{
    paymentId: number;
    constructor(){
        super();
    }
}