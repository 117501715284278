import { INotificationStoreState } from './notifications';

export interface INotificationBoardStoreState {
    notifications: Array<INotificationStoreState>;
    isShown: boolean;
}
export class NotificationBoardStoreState implements INotificationBoardStoreState {
    notifications: Array<INotificationStoreState>;
    isShown: boolean = true;
    constructor(opt?: any) {
        this.notifications = opt && opt.notifications ? opt.notifications : [];
        this.isShown = opt && opt.isShown ? opt.isShown : false; 
    }
}
