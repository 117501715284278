import moment from 'moment';
import { ICoachStoreState } from './userStoreState';

export interface IRecurrentAvailabilityStoreState {
    id: number;
    title: string;
    start: moment.Duration;
    end: moment.Duration;
    coachId: string;
    coach: ICoachStoreState;
    isMonday: boolean;
    isTuesday: boolean;
    isWednesday: boolean;
    isThursday: boolean;
    isFriday: boolean;
    isSaturday: boolean;
    isSunday: boolean;
}

export class RecurrentAvailabilityStoreState implements IRecurrentAvailabilityStoreState{
    id: number;
    title: string;
    start: moment.Duration;
    end: moment.Duration;
    coachId: string;
    coach: ICoachStoreState;
    isMonday: boolean;
    isTuesday: boolean;
    isWednesday: boolean;
    isThursday: boolean;
    isFriday: boolean;
    isSaturday: boolean;
    isSunday: boolean;
    
    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.title = dto && dto.title ? dto.title : '';
        this.start = dto && dto.start ? moment.duration(dto.start) : moment.duration();
        this.end = dto && dto.end ? moment.duration(dto.end) : moment.duration();
        this.coachId = dto && dto.coachId ? dto.coachId : 0;
        this.coach = dto && dto.coach ? dto.coach : null;
        this.isMonday = dto && dto.isMonday ? dto.isMonday : false;
        this.isTuesday = dto && dto.isTuesday ? dto.isTuesday : false;
        this.isWednesday = dto && dto.isWednesday ? dto.isWednesday : false;
        this.isThursday = dto && dto.isThursday ? dto.isThursday : false;
        this.isFriday = dto && dto.isFriday ? dto.isFriday : false;
        this.isSaturday = dto && dto.isSaturday ? dto.isSaturday : false;
        this.isSunday = dto && dto.isSunday ? dto.isSunday : false;
    }
}
