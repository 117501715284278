import moment from 'moment';

import { IBaseDialogStoreState, BaseDialogStoreState } from './baseDialogStoreState';
import { ISessionStoreState } from 'src/models/store';

export interface ICreateSessionDialogStoreState extends IBaseDialogStoreState{
    courtId: number;
    timeKey: string;
    sessionType: string;
    sessionSubFilter: string;
    sessionSubFilterId: string;
    sessionId: number;
}

export class CreateSessionDialogStoreState extends BaseDialogStoreState{
    courtId: number;
    timeKey: string;
    sessionType: string;
    sessionSubFilter:string;
    sessionSubFilterId:string;
    sessionId: number;
    constructor(){
        super();
    }
}

export interface ICreateSessionAdminDialogStoreState extends IBaseDialogStoreState{
    courtId: number;
    timeKey: string;
    time: moment.Moment;
    sessionId: number;
}

export class CreateSessionAdminDialogStoreState extends BaseDialogStoreState{
    courtId: number;
    timeKey: string;
    time: moment.Moment;
    sessionId: number;
    constructor(){
        super();
    }
}

export interface ISessionInfoAdminDialogStoreState extends IBaseDialogStoreState{
    sessionId: number;
    session: ISessionStoreState;
}

export class SessionInfoAdminDialogStoreState extends BaseDialogStoreState implements ISessionInfoAdminDialogStoreState{
    sessionId: number;
    session: ISessionStoreState;
    constructor(){
        super();
    }
}