import * as axios from 'axios';
import * as Redux from 'redux';

import {BaseApiService} from './baseApiService';
import {ThunkService} from '../services/thunkService';
import * as ActM from '../actions';
import * as StM from '../models/store';
import * as SrvM from '../services';

let dispatch:any;
export class KioskService{
    private state: StM.IGlobalStoreState;

    constructor(disp: any, state: StM.IGlobalStoreState) {
        dispatch = disp;
        this.state = state;
        let wnd : any = window;
        if(wnd.forceLogout) this.logout();
    }

    logout(isLoggedOut : boolean = false) {
        if (!isLoggedOut) dispatch(ActM.AccountActions.logout());
        
        let wnd : any = window;

        const routeSrv = new SrvM.RouteService();

        const bookTodayRoute = routeSrv.getBookTodayRoute();
        
        dispatch(ActM.RouteActions.push(bookTodayRoute));
    }
}
