import React from 'react';
import VirtualList from './virtualList';

export interface IBaseInputSearchProps {
    max?: number;
    placeholder?: string;
    classes?: string;
    multiple?: boolean;
    notFoundText?: string;
    addBtnText?: string;
    readOnly?: boolean;
    disabled?: boolean;
    isNeedSort?: boolean;
    autoClose?: boolean;
    rowHeight?: number;
    sortBy?: string;
    canSelectItem?: boolean;
}

export interface IBaseInputSearchState {
    filter?: string;
    isShowList: boolean;
}

interface BaseInputSearchListProps {
    rowHeight?: number;
    itemsCount: number;
    notFoundText?: string;

    renderItem: (index: number) => React.ReactNode;
}

export class BaseInputSearchList extends React.PureComponent<BaseInputSearchListProps> {
    private MAX_LIST_HEIGHT = 260;
    private DEFAULT_ROW_HEIGHT = 37;

    constructor(props: BaseInputSearchListProps) {
        super(props);
    }

    render() {
        const rowHeight = this.props.rowHeight || this.DEFAULT_ROW_HEIGHT;
        const listHeight = Math.min(this.MAX_LIST_HEIGHT, this.props.itemsCount * rowHeight);

        return (
            <div className='search-list search-list-control' ref={this.setListPosition}>
                <VirtualList
                    height={listHeight}
                    itemHeight={rowHeight}
                    itemCount={this.props.itemsCount}
                    renderItem={this.props.renderItem}
                    noItemsRenderer={this.renderNotFound}
                />
            </div>
        );
    }

    private renderNotFound = () => {
        if (!this.props.notFoundText) return null;
        return (
            <div className='search-list-item' key={0}>
                <div className='name'>{this.props.notFoundText}</div>
            </div>
        );
    }

    private setListPosition = (element: any) => {
        if (!element) {
            return false;
        }
        const $input = element.parentElement.getElementsByTagName('input')[0];
        let listHeight = element.offsetHeight;
        let inputHeigth = $input.offsetHeight;
        let coords = $input.getBoundingClientRect();

        let bottomOffset = window.innerHeight - (inputHeigth + coords.top);

        if (bottomOffset < listHeight) {
            element.style.top = 'auto';
            element.style.bottom = '100%';
        }
    }
}
