export class PackageDefinitionDto implements IPackageDefinitionDto{
        id: number;
        title: string;
        description: string;
        creditsDefinitions: IPackageSessionTypeCreditsDefinitionDto[];
        isActive: boolean;
        isArchived: boolean;
        createDate: string;
        expirationTimeSpan: number;
        excludedCoaches: IPublicUserDto[];
        credits: number;
        coachFeeTier: ICoachFeeTierDto;
        createdBy: IPublicUserDto;
        changeDate: string;
        changedBy: IPublicUserDto;
        price: number;
        sortOrder: number;
        validationResult: IValidationResultDto;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.title = dto && dto.title ? dto.title : '';
        this.description = dto && dto.description ? dto.description : '';
        this.creditsDefinitions = dto && dto.creditsDefinitions ? dto.creditsDefinitions : [];
        this.isActive = dto && dto.isActive ? dto.isActive : false;
        this.isArchived = dto && dto.isArchived ? dto.isArchived : false;
        this.createDate = dto && dto.createDate ? dto.createDate : '';
        this.expirationTimeSpan = dto && dto.expirationTimeSpan ? dto.expirationTimeSpan : 0;
        this.createdBy = dto && dto.createdBy ? dto.createdBy : null;
        this.changeDate = dto && dto.changeDate ? dto.changeDate : '';
        this.changedBy = dto && dto.changedBy ? dto.changedBy : '';
        this.coachFeeTier = dto && dto.coachFeeTier ? dto.coachFeeTier : null;
        this.price = dto && dto.price ? dto.price : 0;
        this.sortOrder = dto && dto.sortOrder ? dto.sortOrder : 0;
        this.validationResult = dto && dto.validationResult ? dto.validationResult : null;
    }
}