import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class ConfirmDialogReducer extends BaseReducer {
    public static handle(state: StM.IConfirmDialogStoreState = new StM.ConfirmDialogStoreState(), action: any): StM.IConfirmDialogStoreState {
        switch (action.type) {
            case 'Dialogs/Confirm/Open': {
                return <StM.IConfirmDialogStoreState>_.assign({}, state, {
                    isOpened: true,
                    msg: action.msg,
                    yesBtnTxt: action.yesBtnTxt,
                    noBtnTxt: action.noBtnTxt,
                    yesCallback: action.yesCallback,
                    noCallback: action.noCallback,
                    showExitBtn: action.showExitBtn
                });
            }
            case 'Dialogs/Confirm/Close': {
                return <StM.IConfirmDialogStoreState>_.assign({}, state, { isOpened: false });
            }
            default: {
                return state;
            }
        }
    }
}
