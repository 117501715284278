export interface ICustomSessionTypeStoreState {
        id: number;
        alias: string;
        title: string;
        colorText: string;
        colorBackground: string;
        placeholderText: string;
        isPublic: boolean;
}

export class CustomSessionTypeStoreState implements ICustomSessionTypeStoreState {
        id: number;
        alias: string;
        title: string;
        colorText: string;
        colorBackground: string;
        placeholderText: string;
        isPublic: boolean;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.alias = dto && dto.alias ? dto.alias : '';
        this.title = dto && dto.title ? dto.title : '';
        this.colorText = dto && dto.colorText ? dto.colorText : '';
        this.colorBackground = dto && dto.colorBackground ? dto.colorBackground : '';
        this.placeholderText = dto && dto.placeholderText ? dto.placeholderText : '';
        this.isPublic = dto && dto.isPublic ? dto.isPublic : false;
    }
}
