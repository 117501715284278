import { BasketActions } from './../../actions/basketActions';
import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class BookDialogReducer extends BaseReducer{

    static handle(state: StM.IBookDialogStoreState = new StM.BookDialogStoreState(), action: any): StM.IBookDialogStoreState {
        switch (action.type) {
            case 'Dialogs/Book/Open': {
                return <StM.IBookDialogStoreState>_.assign({}, state, { isOpened: true, state});
            }
            case 'Dialogs/Book/Close': {
                return <StM.IBookDialogStoreState>_.assign({}, state, { isOpened: false});
            }
            default: {
                return state;
            }
        }
    }
}
