export class SeriesDto implements ISeriesDto {
    id: number;
    status: string;
    startDate: string;
    headCoach: IPublicUserDto;
    assistants: IPublicUserDto[];
    courts: ICourtDto[];
    sessions: ISessionDto[];
    sessionCount: number;
    minUserCount: number;
    maxUserCount: number;
    playerQualification: IPlayerQualificationDto;
    description: string;
    title: string;
    created: string;
    club: IClubDto;
    isHidden: boolean;
    invitedUsers: IPublicUserDto[];
    price: number;
    isCourse: boolean;
    
    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.headCoach = dto && dto.headCoach ? dto.headCoach : null;
        this.assistants = dto && dto.assistants ? dto.assistants : [];
        this.sessions = dto && dto.sessions ? dto.sessions : [];
        this.sessionCount = dto && dto.sessionCount ? dto.sessionCount : 0;
        this.startDate = dto && dto.startDate ? dto.startDate : '';
        this.courts = dto && dto.courts ? dto.courts : [];
        this.club = dto && dto.club ? dto.club : null;
        this.status = dto && dto.status ? dto.status : '';
        this.minUserCount = dto && dto.minUserCount ? dto.minUserCount : 0;
        this.maxUserCount = dto && dto.maxUserCount ? dto.maxUserCount : 0;
        this.price = dto && dto.price ? dto.price : 0;
        this.playerQualification = dto && dto.playerQualification ? dto.playerQualification : null;
        this.invitedUsers = dto && dto.invitedUsers ? dto.invitedUsers : [];
        this.isHidden = dto && dto.isHidden ? dto.isHidden : false;
        this.created = dto && dto.created ? dto.created : '';
        this.title = dto && dto.title ? dto.title : '';
        this.description = dto && dto.description ? dto.description : '';
        this.isCourse = dto && dto.isCourse ? dto.isCourse : false;
    }
}