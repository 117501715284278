import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';
import * as StM from '../../store'; 

export interface ITransactionRefundDialogStoreState extends IBaseDialogStoreState{
    transaction: StM.ITransactionStoreState;
    closeCallback: any;
}

export class TransactionRefundDialogStoreState extends BaseDialogStoreState implements ITransactionRefundDialogStoreState{
    transaction: StM.ITransactionStoreState;
    closeCallback: any;
    
    constructor(){
        super();
        this.transaction = null;
        this.closeCallback = null;
    }
}