export interface INewClubStoreState {
    title: string;
    adminEmail: string;
    adminFirstName: string;
    adminLastName: string;
}

export class NewClubStoreState implements INewClubStoreState {
    title: string;
    adminEmail: string;
    adminFirstName: string;
    adminLastName: string;

    constructor(dto?: any) {
        this.title = dto && dto.title ? dto.title : '';
        this.adminEmail = dto && dto.adminEmail ? dto.adminEmail : '';
        this.adminFirstName = dto && dto.adminFirstName ? dto.adminFirstName : '';
        this.adminLastName = dto && dto.adminLastName ? dto.adminLastName : '';
    }
}
