import { ThunkService } from '../../services';
import * as StM from '../../models/store';
import * as ActM from '../';

export class PackagePurchaseDialogActions {

    static open(packageDefinition: StM.IPackageDefinitionStoreState, packageDefinitionId: number = 0): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch ({
                type: 'Dialogs/Packages/Purchase/Open',
                payload: { packageDefinition, packageDefinitionId }
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Packages/Purchase/Close',
            });
        };
    }

    static checkout(packageDefinition: StM.IPackageDefinitionStoreState, params: any): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any>{
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(ActM.AppActions.showSpinner());
            return thunkService.api.package.checkout(packageDefinition)
            .then(() => {
                const up = dispatch(ActM.UserActions.getCurrent());
                const np = dispatch(ActM.NotificationActions.getForCurrentUser());
                return Promise.all([up, np]);
            }).then(() => {
                dispatch(ActM.AppActions.hideSpinner());
            })
            .catch((error: any)=>{
                dispatch(ActM.AppActions.hideSpinner());

                if(error && error.response && error.response.data && error.response.data.exceptionMessage 
                    && error.response.data.exceptionMessage.indexOf('Please check your billing information') > -1){
                    dispatch(ActM.UserActions.getCurrent());
                }
                
                thunkService.logger.error(error);
                throw error.response.data.exceptionMessage ? error.response.data.exceptionMessage : 'Error submiting your request';
            });
        };
    }

    static checkoutMultiple(packageDefinitions: Array<StM.IPackageDefinitionStoreState>, params: any): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<StM.IPackageDefinitionStoreState>>{
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            if(!packageDefinitions || !packageDefinitions.length) return Promise.resolve(new Array<StM.IPackageDefinitionStoreState>());
            return thunkService.api.package.checkoutMultiple(packageDefinitions)
            .then((packageDefinitions: Array<StM.IPackageDefinitionStoreState>) => {
                if(!!packageDefinitions && packageDefinitions.length) {
                    dispatch(ActM.BasketActions.replacePackages(packageDefinitions));
                    if(packageDefinitions[0].validationResult.errors[0].message.indexOf('Please check your billing information') > -1){
                        dispatch(ActM.UserActions.getCurrent());
                        dispatch(ActM.AppActions.hideSpinner());
                    }   
                }
                return packageDefinitions;
            })
            .catch((error: any)=>{
                dispatch(ActM.AppActions.hideSpinner());
                
                thunkService.logger.error(error);
                throw error.response.data.exceptionMessage ? error.response.data.exceptionMessage : 'Error submiting your request';
            });
        };
    }
}
