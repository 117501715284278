import * as redux from 'redux';

import * as StM from '../../models/store';
import AuthDialogReducer from './authDialogReducer';
import CreateSessionDialogReducer from './createSessionDialogReducer';
import BookDialogReducer from './bookDialogReducer';
import PackagePurchaseDialogReducer from './packagePurchaseDialogReducer';
import BillingInfoDialogReducer from './billingInfoDialogReducer';
import SessionInfo from './sessionInfoDialogReducer';
import UserEditReducer from './userEditDialogReducer';
import PasswordRecoveryReducer from './passwordRecoveryDialogReducer';
import PasswordChangeReducer from './passwordChangeDialogReducer';
import HistoryQueryDialogReducer from './historyQueryDialogReducer';
import LeagueRequestToJoinDialogReducer from './leagueRequestToJoinDialogReducer';
import ContactUsDialogReducer from './contacUsDialogReducer';
import ConfirmDeleteBasketItemDialogReducer from './confirmDeleteBasketItemDialogReducer';
import AlertDialogReducer from './alertDialogReducer';
import SessionCreateSuccessDialogReducer from './sessionCreateSuccessDialogReducer';
import PackageAddedDialogReducer from './packageAddedDialogReducer';
import WaiverDialogReducer from './waiverDialogReducer';
import TermsAndConditionsDialogReducer from './termsAndConditionsDialogReducer';
import CreditTermsDialogReducer from './creditTermsDialogReducer';
import PrivacyPolicyDialogReducer from './privacyPolicyDialogReducer';
import DemographicInformationDialogReducer from './demographicInformationDialogReducer';
import openedDialogsReducer from './openedDialogsReducer';
import TransactionRefundDialogReducer from './transactionRefundDialogReducer';
import CreateSessionAdminDialogReducer from './createSessionAdminDialogReducer';
import SessionInfoAdminDialogReducer from './sessionInfoAdminDialogReducer';
import AddUserDialogReducer from './addUserDialogReducer';
import EditUserAdminDialogReducer from './editUserAminDialogReducer';
import ConfirmDialogReducer from './confirmDialogReducer';
import EditCoachFeeTierDialogReducer from './editCoachFeeTierDialogReducer';
import EditPackageDialogReducer from './editPackageDialogReducer';
import EditSubscriptionDialogReducer from './editSubscriptionDialogReducer';
import SubscriptionHistoryReducer from './subscriptionHistoryReducer';
import SubscriptionNotificationDialogReducer from './subscriptionNotificationDialogReducer';
import EditAddonDialogReducer from './editAddonDialogReducer';
import CreateNotificationDialogReducer from './createNotificationDialogReducer';
import NewClubDialogReducer from './newClubDialogReducer';
import AssignCoachFeeTierToCourtsDialogReducer from './assignCoachFeeTierToCourtsDialogReducer';
import * as GroupAccountReducers from './groupAccount';
import EditGroupDialogReducer from './editGroupDialogReducer';
import CancelConfirmationReducer from './cancelConfirmationReducer';
import PublishConfirmationDialogReducer from './publishConfirmationDialogReducer';
import SubscriptionAddedDialogReducer from './subscriptionAddedDialogReducer';
import SubscriptionPurchaseDialogReducer from './subscriptionPurchaseDialogReducer';
import SubscribersListDialogReducer from './subscribersListDialogReducer';

export const rootDialogsReducer = redux.combineReducers<StM.IDialogsStoreState>({
    addUser: AddUserDialogReducer.handle,
    alert: AlertDialogReducer.handle,
    assignCoachFeeTierToCourts: AssignCoachFeeTierToCourtsDialogReducer.handle,
    auth: AuthDialogReducer.handle,
    billingInfo: BillingInfoDialogReducer.handle,
    book: BookDialogReducer.handle,
    cancelConfirmation: CancelConfirmationReducer.handle,
    confirm: ConfirmDeleteBasketItemDialogReducer.handle,
    confirmDlg: ConfirmDialogReducer.handle,
    contactUs: ContactUsDialogReducer.handle,
    createGroupAccount: GroupAccountReducers.CreateGroupAccountDialogReducer.handle,
    createNotification: CreateNotificationDialogReducer.handle,
    createSessionAdmin: CreateSessionAdminDialogReducer.handle,
    creditTerms: CreditTermsDialogReducer.handle,
    demographicInformation: DemographicInformationDialogReducer.handle,
    editAddon: EditAddonDialogReducer.handle,
    editCoachFeeTier: EditCoachFeeTierDialogReducer.handle,
    editGroup: EditGroupDialogReducer.handle,
    editGroupAccount: GroupAccountReducers.EditGroupAccountDialogReducer.handle,
    editPackage: EditPackageDialogReducer.handle,
    editSubscription: EditSubscriptionDialogReducer.handle,
    editUserAdmin: EditUserAdminDialogReducer.handle,
    groupInfo: GroupAccountReducers.GroupInfoDialogReducer.handle,
    historyQuery: HistoryQueryDialogReducer.handle,
    inviteGroupMember: GroupAccountReducers.InviteGroupMemberDialogReducer.handle,
    leagueRequestToJoin: LeagueRequestToJoinDialogReducer.handle,
    newClub: NewClubDialogReducer.handle,
    openedDialogs: openedDialogsReducer.handle,
    packageAdded: PackageAddedDialogReducer.handle,
    packagePurchase: PackagePurchaseDialogReducer.handle,
    passwordChanging: PasswordChangeReducer.handle,
    passwordRecovery: PasswordRecoveryReducer.handle,
    privacyPolicy: PrivacyPolicyDialogReducer.handle,
    publishConfirmation: PublishConfirmationDialogReducer.handle,
    sessionCreateSuccess: SessionCreateSuccessDialogReducer.handle,
    sessionInfo: SessionInfo.handle,
    sessionInfoAdmin: SessionInfoAdminDialogReducer.handle,
    subscriptionHistory: SubscriptionHistoryReducer.handle,
    subscriptionAdded: SubscriptionAddedDialogReducer.handle,
    subscriptionPurchase: SubscriptionPurchaseDialogReducer.handle,
    termsAndConditions: TermsAndConditionsDialogReducer.handle,
    transactionRefund: TransactionRefundDialogReducer.handle,
    userEdit: UserEditReducer.handle,
    waiver: WaiverDialogReducer.handle,
    сreateSession: CreateSessionDialogReducer.handle,
    subscriptionNotification: SubscriptionNotificationDialogReducer.handle,
    subscribersList: SubscribersListDialogReducer.handle
});
