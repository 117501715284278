import * as Redux from 'redux';

import { ThunkService } from '../../services/thunkService';
import { StM } from '../../modules';

export class SessionInfoAdminDialogActions {
    constructor() {

    }

    static open(sessionId: number) {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Admin/Session/Open',
                sessionId: sessionId
            });
        };
    }

    static close() {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Admin/Session/Close',
            });
        };
    }

    static getSessionById(id: number)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.ISessionStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            let promSession: StM.ISessionStoreState = null;
            return thunkService.api.admin.getSesion(id, club, pricingTiers)
            .then((session: StM.ISessionStoreState) => {
                dispatch({
                    type: 'Dialogs/Admin/Session/Session',
                    payload: session
                });
                promSession = session;
            }).then(() => {
                return promSession;
            }).catch(() => {
                 return promSession;
            });
        };
    }
}
