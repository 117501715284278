import * as _ from 'lodash';

import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';

export default class CancelConfirmationReducer extends BaseReducer{
    static handle(state: StM.ICancelConfirmationStoreState= new StM.CancelConfirmationStoreState(), action: any): StM. ICancelConfirmationStoreState{
        switch (action.type) {
            case 'Dialogs/CancelConfirmation/Open': {
                return <StM. ICancelConfirmationStoreState>_.assign({}, state, { isOpened: true, ...action });
            }
            case 'Dialogs/CancelConfirmation/Close': {
                return <StM.ICancelConfirmationStoreState>_.assign({}, state, { isOpened: false});
            }
            default: {
                return state;
            }
        }
    }
}
