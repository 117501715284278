import * as StM from '../../models/store';
import * as _ from 'lodash';

export default class SeekingPlayerBoardPageReducer {

    static handle(state: StM.ISeekingPlayerBoardPageStoreState = new StM.SeekingPlayerBoardPageStoreState(), action: any): StM.ISeekingPlayerBoardPageStoreState {
        switch (action.type) {
            case 'Pages/SeekingPlayerBoard/Init': {
                return state;
            }
            case 'Pages/SeekingPlayerBoard/Sessions': {
                let sessions = <Array<StM.ISessionStoreState>>action.payload;
                return <StM.ISeekingPlayerBoardPageStoreState>_.assign({}, state, { sessions: sessions });
            }
            default: {
                return state;
            }
        }
    }
}
