import * as _ from 'lodash';

import { ThunkService } from '../../services/thunkService';
import { ActM, StM } from '../../modules';

export class BookDialogActions {
    public static open(): (dispatch: any) => void {
        return (dispatch: any) => {
            dispatch({
                type: 'Dialogs/Book/Open',
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Book/Close',
            });
        };
    }

    static checkout(checkoutSessions: Array<StM.ISessionStoreState>, params: any, updateBasket: boolean = true): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers, basket } = getState();
            const basketPackages = basket.packages;
            dispatch(ActM.AppActions.showSpinner());
            // purchase packages
            (basketPackages.length
                    ? thunkService.api.package.checkoutMultiple(basketPackages).then((packageDefinitions: Array<StM.IPackageDefinitionStoreState>) => {
                        if (!!packageDefinitions && packageDefinitions.length) {
                            dispatch(ActM.BasketActions.replacePackages(packageDefinitions));
                            throw { message: packageDefinitions[0].validationResult.errors[0].message, isValidationError: true };
                        }
                    })
                    : Promise.resolve()
            ).then(() => {
                // purchase individual sessions
                if (checkoutSessions.length) {
                    return thunkService.api.session.checkout(checkoutSessions, club, pricingTiers)
                        .then((sessions: Array<StM.ISessionStoreState>) => {
                            if (sessions && sessions.length) {
                                if (sessions.some(s => !s.validationResult.isValid || s.validationResult.errors.length > 0)) {
                                    sessions.forEach((item, index) => {
                                        item.basketId = checkoutSessions[index].basketId;
                                    })
                                    if (updateBasket) {
                                        dispatch(ActM.BasketActions.update(sessions));
                                    }
                                    throw {
                                        message: sessions && sessions.length && sessions[0].validationResult
                                                 && sessions[0].validationResult.errors && sessions[0].validationResult.errors[0].message,
                                        isValidationError: true
                                    };
                                }
                                sessions.forEach((item, index) => {
                                    item.basketId = sessions[index].basketId;
                                })
                                if (updateBasket) {
                                    dispatch(ActM.BasketActions.update(sessions));
                                }
                                dispatch(ActM.SessionActions.updateForCurrentPage(params));
                                if (sessions.length > 1) {
                                    dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, {
                                        msgKey: StM.MessagesKey.CheckoutSuccessPartial,
                                        messageType: StM.MessageTypes.Warning
                                    }));
                                }
                            }
                            return Promise.resolve(dispatch(ActM.SessionActions.updateForCurrentPage(params)));
                        });
                }
            }).then(() => {
                dispatch(ActM.DialogActions.close(StM.DialogNames.Book));
                if (updateBasket) {
                    dispatch(ActM.BasketActions.clear());
                }
                dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, {
                    msgKey: StM.MessagesKey.CheckoutSuccess,
                    messageType: StM.MessageTypes.Success
                }));
            }).then(() => {
                dispatch(ActM.UserActions.getCurrent());
                dispatch(ActM.AppActions.hideSpinner());
            }).catch((error: any) => {
                dispatch(ActM.UserActions.getCurrent());
                dispatch(ActM.AppActions.hideSpinner());
                if (error && error.response && error.response.data && error.response.data.exceptionType.indexOf('OutstandingBalanceException') >= 0) {
                    dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, {
                        msgKey: StM.MessagesKey.OutstandingBalanceError,
                        messageType: StM.MessageTypes.Error
                    }));
                } else if (error) {
                    dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, {
                        msgKey: StM.MessagesKey.GenericRequestError,
                        messageType: StM.MessageTypes.Error,
                        message: error.message
                    }));
                }
                thunkService.logger.error(error);
            });
        };
    }

    public static validateBasket(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers, basket } = getState();
            const basketSessions = _.orderBy(basket.goods,
                (session: StM.ISessionStoreState) => session.startDateTime.valueOf()
            );
            dispatch(ActM.AppActions.showSpinner());
            return thunkService.api.session.validateBasket(basketSessions, club, pricingTiers)
                .then((validatedSessions: Array<StM.ISessionStoreState>) => {
                    validatedSessions.forEach((item, index) => {
                        const basketSession = basketSessions[index];
                        item.basketId = basketSession.basketId;
                        item.removedUsers = basketSession.removedUsers;
                        item.addedUsers = basketSession.addedUsers;
                        item.noServiceCheckoutPrice = basketSession.noServiceCheckoutPrice;
                    })
                    dispatch(ActM.ValidatedBasketActions.updateSessions(validatedSessions));
                    dispatch(ActM.AppActions.hideSpinner());
                })
                .catch((error: any) => {
                    thunkService.logger.error(error);
                    dispatch(ActM.AppActions.hideSpinner());
                });
        };
    }
}
