import * as StM from '../models/store';
import BaseReducer from './baseReducer';
import * as _ from 'lodash';

export default class HoverLineReducer {
    static handle(state: StM.IHoverLineStoreState = new StM.HoverLineStoreState(), action: any): StM.IHoverLineStoreState {
        switch (action.type) {
            case 'HoverLine/Show': {
                return <StM.IHoverLineStoreState>_.assign({}, state, { isShown: true, top:action.top, item: action.item, key: action.item.key, right: action.right});
            }
            case 'HoverLine/Hide': {
                return <StM.IHoverLineStoreState>_.assign({}, { isShown: false });
            }
            default: { 
                return state;
            }
        }
    }
}
