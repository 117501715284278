import * as _ from 'lodash';

import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';

export default class AlertDialogReducer extends BaseReducer{
    static handle(state: StM.IAlertDialogStoreState= new StM.AlertDialogStoreState(), action: any): StM. IAlertDialogStoreState{
        switch (action.type) {
            case 'Dialogs/Alert/Open': {
                return <StM. IAlertDialogStoreState>_.assign({}, state, { isOpened: true, ...action });
            }
            case 'Dialogs/Alert/Close': {
                return <StM.IAlertDialogStoreState>_.assign({}, state, { isOpened: false});
            }
            default: {
                return state;
            }
        }
    }
}
