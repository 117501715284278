import * as Redux from 'redux';
import * as _ from 'lodash';

import { ThunkService } from '../services/thunkService';
import * as StM from '../models/store';


export class CoachFeeTierPricesActions {
    constructor() {
    }

    static init(pricingTiers: Array<StM.ICoachFeeTierPriceStoreState>): (dispatch: any, getState: any, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'CoachFeeTierPrices/Init',
                payload: pricingTiers
            });
        };
    }
}