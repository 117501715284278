import * as _ from 'lodash';

import * as StM from '../../models/store';
import * as SrvM from '../../services';
import * as PolM from '../../policies';
import { IBasePolicy, BasePolicy } from '../basePolicy';
import { PublicUserStoreState } from '../../models/store';

export class SessionInfoPolicy extends BasePolicy implements IBasePolicy {
    private authSrv: SrvM.AuthenticationService;

    constructor(private session: StM.ISessionStoreState, private user?: StM.IUserStoreState) {
        super();
        this.authSrv = new SrvM.AuthenticationService();
        if (!user) { this.user = this.getCurrentUser(); }
    }

    public handle() {

    }

    public getUserBooking(userId: string): StM.IBookingStoreState {
        return !!this.session && !!this.session.bookings 
            && this.session.bookings.find(b => b.userId === userId && this.utils.isActiveBooking(this.session, b));
    }

    public getActiveBookingsCount(): number {
        let result = 0;
        if (this.session && this.session.bookings) {
            result = this.session.bookings.filter((booking) => {
                return this.utils.isActiveBooking(this.session, booking);
            }).length;
        }
        return result;
    }

    public getUserBookingFinalPrice(): number {
        if (this.session && this.session.bookings && this.user) {
            let booking = _.find(this.session.bookings, { userId: this.user.id });
            if (booking && this.utils.isActiveBooking(this.session, booking)) {

                const club = this.getCurrentClub();
                let discount = this.user.actualDetails.discount * booking.amount / 100;
                const doubleDiscount = booking.amount * 2 * club.doubleSessionsDiscount / 100;
                const result = this.session.isDoubledSession ? booking.amount * 2 - doubleDiscount : booking.amount - discount;

                return result;
            }
        }
        return 0;
    }

    public getPlayers(includeAddedUsers: boolean = true, excludeNewBookings: boolean = false): Array<StM.IPublicUserStoreState> {
        let players = new Array<StM.IPublicUserStoreState>();
        if(!this.session) return [];
        if (this.session.bookings) {
            players = this.session.bookings
                .filter((booking) => this.utils.isActiveBooking(this.session, booking, excludeNewBookings))
                .map((booking) => booking.user || new PublicUserStoreState());
            players = _.compact(players);
        }
        if(includeAddedUsers && this.session.addedUsers) {
            players.push(...this.session.addedUsers as StM.IPublicUserStoreState[]);
        }
        return players;
    }

    public getInvitedUsers(): Array<StM.IPublicUserStoreState> {
        let invitedUsers = this.session && this.session.invitedUsers ? this.session.invitedUsers : [];
        invitedUsers = _.filter(invitedUsers, (invitedUser: StM.IPublicUserStoreState) => {
            if (this.session && this.session.bookings) {
                const activeBookings = _.filter(this.session.bookings, (booking) => {
                    return booking.userId === invitedUser.id && this.utils.isActiveBooking(this.session, booking);
                });
                return !activeBookings.length;
            }
            return true;
        });
        return invitedUsers;
    }

    public getDeclinedUsers(): Array<StM.IPublicUserStoreState> {
        let declinedUsers = this.session && this.session.declinedUsers ? this.session.declinedUsers : [];
        declinedUsers = _.filter(declinedUsers, (declinedUser: StM.IPublicUserStoreState) => {
            if (this.session && this.session.bookings) {
                let booking = _.find(this.session.bookings, { userId: declinedUser.id });
                return !(booking && this.utils.isActiveBooking(this.session, booking));
            }
            return true;
        });
        return declinedUsers;
    }

    public static getMaxPlayerCount(sessionType: StM.SessionType): number {
        switch (sessionType) {
            case StM.SessionType.Play: { return 2; }
            default: { return 5; }
        }
    }

    public getIsCanCheckInBooking(userId: string) {
        const booking = this.getUserBooking(userId);
        return this.authSrv.hasPermission('CheckinPlayer', this.user) && booking && booking.status === StM.BookingStatus.CheckedOut;
    }

    public getIsCanCheckOutBooking(userId: string) {
        const booking = this.getUserBooking(userId);
        return this.authSrv.hasPermission('CheckinPlayer', this.user) && booking && booking.status === StM.BookingStatus.CheckedIn;
    }

    public getIsCanPayBooking(userId: string) {
        const booking = this.getUserBooking(userId);
        if(booking && booking.paymentType == StM.PaymentTypes.Credits) {
            return false;
        }
        const isValidBookingStatus = booking && _.includes([
            StM.BookingStatus.CheckedIn
            , StM.BookingStatus.PayFail
            , StM.BookingStatus.NoShowPayError
            , StM.BookingStatus.LateCancel
        ], booking.status);
        const isValidSessionStatus = this.session && _.includes([
            StM.SessionStatus.CheckedOut
            , StM.SessionStatus.Reopened
        ], this.session.status);
        return this.authSrv.hasPermission('CanMarkAsPaidInCash', this.user) && isValidSessionStatus && isValidBookingStatus;
    }

    public getIsCanCancelPayBooking(userId: string) {
        const booking = this.getUserBooking(userId);
        const isValidBookingStatus = booking && _.includes([
            StM.BookingStatus.Paid
            , StM.BookingStatus.LateCancel
        ], booking.status);
        const isValidSessionStatus = this.session && _.includes([StM.SessionStatus.CheckedOut], this.session.status);
        return this.authSrv.hasPermission('CanMarkAsPaidInCash', this.user) && !!booking && ((isValidSessionStatus && isValidBookingStatus)
            || (booking.status === StM.BookingStatus.NoShow && this.session.status === StM.SessionStatus.Reopened))
            && booking.paymentType === StM.PaymentTypes.Cash;
    }

    public getIsCanUnjoinBooking(userId: string) {
        const booking = this.getUserBooking(userId);
        const isValidBookingStatus = booking && !_.includes([
            StM.BookingStatus.CheckedIn
            , StM.BookingStatus.Paid
            , StM.BookingStatus.PayFail
            , StM.BookingStatus.NoShow
            , StM.BookingStatus.NoShowPayError
            , StM.BookingStatus.LateCancel
            , StM.BookingStatus.LateCancelPayFail
        ], booking.status);
        const isValidSessionStatus = this.session && !_.includes([
            StM.SessionStatus.Closed
            , StM.SessionStatus.Cancel
        ], this.session.status);

        return this.authSrv.hasPermission('DropoutPlayer', this.user) && isValidBookingStatus && isValidSessionStatus;
    }

    public getIsCanShowPriceDescription() {
        if (!this.session) return false;
        const sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(this.session, [], this.user);
        return this.session.maxUserCount > 1
            && this.session.status !== StM.SessionStatus.Closed
            && this.session.type != StM.SessionType.Clinic
            && (this.session.type != StM.SessionType.Custom || this.session.splitPrice)
            && (!this.session.isPaidByOwner || sessionAvailabilityPolicy.getIsOwner() || sessionAvailabilityPolicy.getIsGroupSession());
    }

    public getSessionNotification() {
        if (!this.session) return null;
        const notifications = this.getNotifications();
        const isCourse = this.session.series && this.session.series.isCourse;
        return notifications.find((n) => {
            const isSameId = isCourse
                ? (n.target && n.target.series && n.target.series.isCourse && n.target.series.id === this.session.series.id)
                : (n.targetId === this.session.id);
            const isSession = n.targetType === StM.NotificationTargetType.Session;
            const isEvent = n.event === StM.NotificationEventType.SessionYouInvited;
            return isSameId && isSession && isEvent;
        });
    }
}