import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class CreateSessionAdminDialogReducer extends BaseReducer{

    static handle(state: StM.ICreateSessionAdminDialogStoreState = new StM.CreateSessionAdminDialogStoreState(), action: any): StM.ICreateSessionAdminDialogStoreState {
        switch (action.type) {
            case 'Dialogs/Admin/CreateSession/Open': {
                return <StM.ICreateSessionAdminDialogStoreState>{...state, ...{ 
                    isOpened: true, 
                    timeKey: action.timeKey, 
                    courtId: action.courtId, 
                    time: action.time,
                    sessionId: action.sessionId
                }};
            }
            case 'Dialogs/Admin/CreateSession/Close': {
                return <StM.ICreateSessionAdminDialogStoreState>_.assign({}, state, { isOpened: false});
            }
            default: {
                return state;
            }
        }
    }
}
