import * as StM from '../../../store';

export interface IScoreboardPageStoreState {
    sessions: Array<StM.ISessionStoreState>;
}

export class ScoreboardPageStoreState implements IScoreboardPageStoreState {
    sessions: Array<StM.ISessionStoreState>;
    constructor() {
        this.sessions = [];
    }
}
