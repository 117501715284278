import * as StM from '../../models/store';

export default class PackagesPageReducer {

    static handle(state: StM.IPackagesStoreState = new StM.PackagesStoreState(), action: any): StM.IPackagesStoreState {
        switch (action.type) {
            case 'Pages/Packages/Init': {
                return state;
            }
            default: {
                return state;
            }
        }
    }
}
