

import { BaseApiService } from '../baseApiService';
import * as SerM from '..';

export class BannerApiService extends BaseApiService {
    private static _instance: BannerApiService;

    private cache: SerM.RequestCacheService;

    constructor(config?: any) {
        if (typeof BannerApiService._instance === "undefined") {
            super(config);
            BannerApiService._instance = this;
            this.cache = new SerM.RequestCacheService();
        }

        return BannerApiService._instance;
    }

    public getMainClubBanner(): Promise<string> {
        const cachedRequest = this.cache.getItem("MAIN_BANNER");

        if(cachedRequest) {
            return new Promise((resolve) => { resolve(cachedRequest) });
        }

        return this.get('/main')
            .then((response: any) => {
                this.cache.setItem("MAIN_BANNER", response.data);
                return response.data;
        });
    }

    public getRightClubBanner(): Promise<string> {
        const cachedRequest = this.cache.getItem("RIGHT_BANNER");

        if(cachedRequest) {
            return new Promise((resolve) => { resolve(cachedRequest) });
        }

        return this.get('/right')
            .then((response: any) => {
                this.cache.setItem("RIGHT_BANNER", response.data);
                return response.data;
        });
    }
}
