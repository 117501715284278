import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';

export class PasswordChangingDialogActions {
    static open(hash: string, email: string): (d: any, g: () => StM.IGlobalStoreState, t: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/PasswordChanging/Open'
                , payload: {hash: hash, email: email}
            });
        };
    }

    static close(): (d: any, g: () => StM.IGlobalStoreState, t: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/PasswordChanging/Close'
            });
        };
    }

    static recovery(email: string, password: string, hash: string): (d: any, g: () => StM.IGlobalStoreState, t: ThunkService) => Promise<void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.account.recoveryPassword(email, password, hash);
        };
    }

    static change(oldPassword: string, newPassword: string): (d: any, g: () => StM.IGlobalStoreState, t: ThunkService) => Promise<void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.account.changePassword(oldPassword, newPassword);
        };
    }
}
