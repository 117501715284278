import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class NewClubDialogReducer extends BaseReducer {
    static handle(state: StM.INewClubDialogStoreState = new StM.NewClubDialogStoreState(), action: any): StM.INewClubDialogStoreState {
        switch (action.type) {
            case 'Dialogs/Admin/NewClub/Open': {
                return <StM.INewClubDialogStoreState>_.assign({}, state, { isOpened: true });
            }
            case 'Dialogs/Admin/NewClub/Close': {
                return <StM.INewClubDialogStoreState>_.assign({}, state, { isOpened: false });
            }
            default: {
                return state;
            }
        }
    }
}
