import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class UserEditDialogReducer extends BaseReducer{
    static handle(state: StM.IUserEditDialogStoreState = new StM.UserEditDialogStoreState(), action: any): StM.IUserEditDialogStoreState{
        switch (action.type) {
            case 'Dialogs/UserEdit/Open': {
                return <StM.IUserEditDialogStoreState>_.assign({}, state, { isOpened: true});
            }
            case 'Dialogs/UserEdit/Close': {
                return <StM.IUserEditDialogStoreState>_.assign({}, state, { isOpened: false});
            }
            default: {
                return state;
            }
        }
    }
}
