import * as Redux from 'redux';
import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';


export class EditAddonDialogActions {
    static open(id?: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Admin/EditAddon/Open',
                id: id
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Admin/EditAddon/Close',
            });
        };
    }

    static save(model: IAddonDefinitionDto): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<IAddonDefinitionDto> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.saveAddon(model);
        };
    }
}
