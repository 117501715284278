import moment from 'moment';
import { createBrowserHistory } from 'history';
import * as _ from 'lodash';

import { RouteDialogService, KioskService } from '../services';
import { ThunkService } from '../services';
import { history } from '../store/store';
import * as StM from '../models/store';
import {
    RouteActions,
    BaseActions,
    UserActions,
    ActiveUsersActions,
    AccountActions,
    ClubActions,
    CoachesActions,
    CoachFeeTiersActions,
    MembershipLevelsActions,
    PackagesActions,
    AddonsActions,
    CustomSessionsActions,
    DialogActions,
    AdminUsersActions,
    PermissionsActions,
    PricingTiersActions,
    CoachFeeTierPricesActions,
    BannerActions,
    BookPageActions
} from '../actions';
import * as AdminActM from './admin';
import * as SrvM from '../services';
import { Constants } from '../constants';
import * as DtoM from '../models/dto';
import { SubscriptionOffersActions } from './subscriptionOffersActions';

const utils = new SrvM.Utils();

export class AppActions {
    static init(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return async (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const routeSrv = new SrvM.RouteService();

            const state = getState();
            const history = createBrowserHistory()
            let route = history.location.pathname;

            let redirectFromOldBookmarkedDate = false;

            if (!document.referrer) {
                const dateMatch = route.match(/(\d{1,2}-\d{1,2}-\d{4})/);
                if (dateMatch) {
                    const date = dateMatch[0];
                    if (moment(date, Constants.DateTime.DATE_FORMAT).isBefore(moment.utc().startOf('day').add(-7, 'days'))) {
                        redirectFromOldBookmarkedDate = true;
                    }
                }
            }

            if (((!route || route === '/') && !utils.isMobile()) || redirectFromOldBookmarkedDate) {
                route = routeSrv.getBookTodayRoute();
                dispatch(RouteActions.push(route));
            }

            const isBookPage = route.indexOf(StM.Pages.Book) >= 0;
            const isOpenBoard = route.indexOf(StM.Pages.OpenSessionsBoard) >= 0;
            const isVideoPage = route.indexOf(StM.Pages.Video) >= 0;
            const isStandalonePage = routeSrv.isStandalonePage(route);
            if (!isStandalonePage) {
                dispatch(BaseActions.hideSplash());
            }

            dispatch({
                type: 'App/Init'
            });

            dispatch(AppActions.showSpinner());

            const final: any = () => {

                dispatch(UserActions.checkUpdateLastAction());

                AppActions.listenBrowserHistory();
                if (!isStandalonePage) {
                    dispatch(AppActions.checkShowUserDialogs());
                    if (!isBookPage && !isOpenBoard && !isVideoPage) {
                        dispatch(AppActions.hideSpinner());
                    }
                }
                const wnd: any = window;
                wnd.api = new KioskService(dispatch, state);

                dispatch({
                    type: 'App/Finalize'
                });
                dispatch(AppActions.hideSpinner());

                if (navigator.userAgent.indexOf('kiosk') >= 0) {
                    setTimeout(() => {
                        _.each(document.getElementsByTagName('iframe'), (irame) => {
                            setTimeout(() => {
                                for (let els = irame.contentWindow.document.getElementsByTagName('a'), i = els.length; i--;) {
                                    if (els[i].href.indexOf('http') >= 0) {
                                        els[i].href = 'javascript:void(0);';
                                        els[i].target = '_self';
                                        els[i].onclick = () => { return void (0); }
                                    }
                                }
                            }, 1000)
                        });
                    }, 1000)

                    setTimeout(() => {
                        for (let els = document.getElementsByTagName('a'), i = els.length; i--;) {
                            if (els[i].href.indexOf('http') >= 0) {
                                els[i].href = 'javascript:void(0);';
                                els[i].target = '_self';
                                els[i].onclick = () => { return void (0); }
                            }
                        }
                    }, 100)
                }
            };

            if (!isStandalonePage) {
                dispatch(ActiveUsersActions.get());
            }
            Promise.resolve(dispatch(UserActions.getCurrent()))
                .then(() => {
                    const user = getState().user;
                    return (user && user.isAcceptedWaiver) || !_.find(user.permissions, (i) => i.role === StM.Roles.Member)
                        ? dispatch(UserActions.init(user)) : dispatch(AccountActions.logout(false));
                });

            const club = await thunkService.api.club.getCurrent();
            const currentDate = routeSrv.getRouteDate('today', club);

            try {
                const [
                    dictionaries, 
                    pricingTiers, 
                    coachFeeTierPrices, 
                    mainClubBanner, 
                    rightClubBanner
                ] = await Promise.all([
                    thunkService.api.common.getDictionaries(),
                    thunkService.api.pricing.getPricingTiers(),
                    thunkService.api.pricing.getCoachFeeTierPrices(),
                    thunkService.api.banner.getMainClubBanner(),
                    thunkService.api.banner.getRightClubBanner(),
                    dispatch(BookPageActions.init(currentDate))
                ]);

                dictionaries.club.courts = dictionaries.club.courts.sort((a, b) => a.order - b.order);

                await Promise.all([
                    dispatch(ClubActions.init(dictionaries.club)),
                    dispatch(CoachesActions.init(dictionaries.coaches)),
                    dispatch(CoachFeeTiersActions.init(dictionaries.coachFeeTiers)),
                    dispatch(MembershipLevelsActions.init(dictionaries.membershipLevels)),
                    dispatch(PackagesActions.init(dictionaries.packageDefinitions, dictionaries.packageSessionTypes)),
                    dispatch(SubscriptionOffersActions.init(dictionaries.subscriptionOfferDefinitions, dictionaries.packageSessionTypes)),
                    dispatch(AddonsActions.init(dictionaries.addonDefinitions)),
                    dispatch(CustomSessionsActions.init(dictionaries.customSessions)),
                    dispatch(PermissionsActions.init(dictionaries.permissions)),
                    dispatch(PricingTiersActions.init(pricingTiers)),
                    dispatch(CoachFeeTierPricesActions.init(coachFeeTierPrices)),
                    dispatch(BannerActions.init(mainClubBanner, rightClubBanner))
                ]);

                const videoAddon = _.find(getState().addons, (x) => x.alias === 'video');
                if (videoAddon) {
                    Constants.VideoPrice = videoAddon.price;
                }
        
                return final();
            } catch (error) {
                thunkService.logger.error(error);
                return final();
            }
        };
    }

    static initAdmin(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {

            const state = getState();
            let route = history.location.pathname;

            if (route.indexOf('kiosk') >= 0) {
                return Promise.resolve(dispatch(AppActions.initKiosk()));
            }

            const redirectNeeded = !route || route.match('\/admin(\/)*$');

            if (!utils.isMobile() && redirectNeeded) {
                route = new SrvM.RouteService().getScheduleTodayRoute(state.club, state.user);
                dispatch(RouteActions.push(route));
            }

            dispatch(AppActions.showSpinner());

            const final: any = () => {
                dispatch(BaseActions.hideSplash());
                dispatch(AppActions.hideSpinner());
                dispatch({
                    type: 'App/Init'
                });

                dispatch(UserActions.checkUpdateLastAction());

                AppActions.listenBrowserHistory();

                dispatch({
                    type: 'App/Finalize'
                });
                if (!getState().app.isAuthorized) {
                    dispatch(RouteActions.goHome());
                }
            };

            const userP = Promise.resolve(dispatch(UserActions.getCurrent(false, false)))
                .then(() => {
                    const user = getState().user;
                    return user ? dispatch(UserActions.init(user)) : dispatch(AccountActions.logout(false));
                });

            return thunkService.api.common.getDictionaries()
                .then((dictionaries: StM.ICommonDictionariesStoreState) => {
                    const clubP = dispatch(ClubActions.init(dictionaries.club));

                    const coachesP = dispatch(CoachesActions.init(dictionaries.coaches));
                    const coachFeeTiersP = dispatch(CoachFeeTiersActions.init(dictionaries.coachFeeTiers));
                    const pricingTiersP = dispatch(AdminActM.AdminPricingTiersActions.getAll());
                    const coachFeeTierPricesP = dispatch(AdminActM.AdminCoachFeeTierPricesActions.getAll());
                    const membershipLevelsP = dispatch(MembershipLevelsActions.init(dictionaries.membershipLevels));
                    const packagesP = dispatch(PackagesActions.init(dictionaries.packageDefinitions, dictionaries.packageSessionTypes));
                    const addonsP = dispatch(AddonsActions.init(dictionaries.addonDefinitions));
                    const customSessions = dispatch(CustomSessionsActions.init(dictionaries.customSessions));
                    const permissions = dispatch(PermissionsActions.init(dictionaries.permissions));

                    const allP = Promise.all([clubP, coachesP, coachFeeTiersP, membershipLevelsP, packagesP, addonsP
                        , userP, customSessions, permissions, pricingTiersP, coachFeeTierPricesP
                    ]).then(() => {
                        // only after resolve club promise

                        const videoAddon = _.find(getState().addons, (x) => x.alias === 'video');

                        if (videoAddon) {
                            Constants.VideoPrice = videoAddon.price;
                        }
                    });
                    return allP;
                })
                .then(() => {
                    final();
                }).catch((error) => {
                    thunkService.logger.error(error);
                    final();
                });
        };
    }

    static initAuthAdmin(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const state = getState();
            dispatch(AppActions.showSpinner());
            const final: any = () => {
                dispatch(AppActions.hideSpinner());
            };

            const adminUsers = dispatch(AdminUsersActions.get(state.club.id));
            const adminPackages = dispatch(AdminActM.PackagesActions.getAll());
            const adminSubscriptions = dispatch(AdminActM.SubscriptionsActions.getAll());
            const adminAddons = dispatch(AdminActM.AddonsActions.getAll());
            const adminGroups = dispatch(AdminActM.AdminGroupsActions.get());

            let resultP = Promise.all([adminUsers, adminPackages, adminSubscriptions, adminAddons, adminGroups]).then(() => {
                // only after resolve club promise
                return Promise.resolve();
            });

            if (state.club.title) {
                resultP = Promise.resolve();
            }

            return resultP.then(() => {
                return final();
            }).catch((error) => {
                thunkService.logger.error(error);
                return final();
            });
        };
    }

    static getPricing(): (d: any, g: () => StM.IGlobalStoreState, t: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club } = getState();
            if (!club.priceList) {
                return Promise.resolve();
            }
            dispatch(AppActions.showSpinner());
            return thunkService.api.common.getPricing(club.priceList)
                .then((result) => {
                    dispatch(AppActions.hideSpinner());
                    return result;
                }).catch(() => {
                    dispatch(AppActions.hideSpinner());
                });
        };
    }

    static getStatic(): (d: any, g: () => StM.IGlobalStoreState, t: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(AppActions.showSpinner());
            return thunkService.api.common.getStatic().then((data) => {
                if (!new SrvM.RouteService().isInvitationWithoutLoginLink(window.location.hash)) {
                    dispatch(AppActions.hideSpinner());
                }
                return data;
            });
        };
    }

    static showSpinner(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => any {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return Promise.resolve(dispatch({
                type: 'App/Spinner/Show'
            }));
        };
    }

    static hideSpinner(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => any {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return Promise.resolve(dispatch({
                type: 'App/Spinner/Hide'
            }));
        };
    }

    static initOwnProps(ownProps: any): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => any {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return Promise.resolve(dispatch({
                type: 'App/Spinner/OwnProps'
                , payload: ownProps
            }));
        };
    }

    static updateRouteMatch(match: any): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => any {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return Promise.resolve(dispatch({
                type: 'App/Match/Update',
                payload: match
            }));
        };
    }

    private static initKiosk(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => any {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {

            const login = (username: string, password: string) => {
                return thunkService.api.base.postForm('/token',
                        {
                            grant_type: 'password'
                            , username
                            , password
                        })
                    .then((response: any) => {
                        const authTicket = response.data as DtoM.IAuthenticationTicketDto;
                        dispatch({
                            type: 'AuthTicket/Init',
                            payload: authTicket
                        });
                        dispatch({
                            type: 'AuthTicket/LastAction'
                            , payload: moment()
                        });
                    })
                    .then((response: any) => {
                        return response
                    })
            };

            const findUser = (username: string) => {
                return thunkService.api.admin.findUser(username)
                    .then((response: any) => {
                        return response
                    });
            };

            const getSessions = (userId: string) => {
                return thunkService.api.admin.getUserSessions(userId)
                    .then((response: any) => {
                        return response
                    });
            };

            const checkIn = (userId: string) => {
                return thunkService.api.admin.checkIn(userId)
                    .then((response: any) => {
                        return response
                    });
            };

            const customWindow: any = window;
            customWindow.kiosk = { login, findUser, getSessions, checkIn };

            _.each(document.getElementsByTagName('iframe'), (i) => {
                i.contentWindow.open = function(url?, windowName?, windowFeatures?, replace?) {
                    alert('!!');
                    return null;
                };
            });

            customWindow.open = function(url, windowName, windowFeatures) {
                alert('!!');
                return null;
            };

            return Promise.resolve(true);
        };
    }

    private static checkShowUserDialogs(): (dispatch: any, getState: () => StM.IGlobalStoreState) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState) => {
            const isAuth = getState().app.isAuthorized;
            const user = getState().user;
            if (isAuth && user) {
                if (utils.getIsOpenDemographicInfoDialog(user)) {
                    dispatch(DialogActions.open(StM.DialogNames.DemographicInformation));
                }
            }
        };
    }

    private static listenBrowserHistory() {
        let hashState: any = {};
        let prevHash: any = {};
        const routeDialogService = new RouteDialogService();

        const handleHashChanched = () => {
            if (prevHash !== location.hash) {
                hashState = routeDialogService.handleLocation(location, hashState);
                prevHash = location.hash;
            }
        };
        const wnd: any = window;
        wnd.handleHashChanched = handleHashChanched;

        history.listen((location: any) => {
            handleHashChanched();
        });
        window.addEventListener('hashchange', function() {
            handleHashChanched();
        }, false);

        handleHashChanched();
    }
}
