import { CourtTimeBlockStoreState } from './courtTimes/courtTimeBlockStoreState';

export interface IHoverLineStoreState {
    isShown: boolean;
    item?: CourtTimeBlockStoreState;
    top?: number;
    key?: string;
    right?: number;
}
export class HoverLineStoreState implements IHoverLineStoreState {
    isShown: boolean = true;
    constructor(opt?: any) {
        this.isShown = opt && opt.isShown ? opt.isShown : false; 
    }
}
