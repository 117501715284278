

import * as SerM from '..';
import * as DtoM from '../../models/dto';
import * as StoreModule from '../../models/store';
import { BaseApiService } from '../baseApiService';

export class CommonApiService extends BaseApiService {
    private static _instance: CommonApiService;

    private cache: SerM.RequestCacheService;

    constructor(config?: any) {
        if (typeof CommonApiService._instance === "undefined") {
            super(config);
            CommonApiService._instance = this;
            this.cache = new SerM.RequestCacheService();
        }

        return CommonApiService._instance;
    }

    getDictionaries(): Promise<StoreModule.ICommonDictionariesStoreState> {

        const cachedRequest = this.cache.getItem("COMMON_DICTIONARIES");

        if (cachedRequest) {
            return new Promise((resolve) => {
                resolve(cachedRequest as StoreModule.ICommonDictionariesStoreState);
            });
        }

        return this.get('/dictionaries')
            .then((response: any) => {
                const model = this.mapper.getCommonDictionariesModelFromDto(response.data as ICommonDictionariesDto);
                this.cache.setItem("COMMON_DICTIONARIES", model);
                return model;
            });
    }

    public sendContacUsRequest(firstName: string, lastName: string, email: string, message: string) {
        const dto = new DtoM.ContactUsRequestDto();
        dto.firstName = firstName;
        dto.lastName = lastName;   
        dto.mail = email;
        dto.message = message;
        return this.post('/contactUsRequest', dto)
        .then((response: any) => {
            return null;
        });
    }

    public getPricing(url: string): Promise<string> {
        const cachedRequest = this.cache.getItem("PRICING");

        if(cachedRequest) {
            return new Promise((resolve) => { resolve(cachedRequest);});
        }

        return this.service.get(url).then((response: any) => {
            this.cache.setItem("PRICING", response.data);
            return response.data;
        });
    }

    public getStatic(): Promise<Array<IStaticPageDto>> {
        const cachedRequest = this.cache.getItem("STATIC");

        if(cachedRequest) {
            return new Promise((resolve) => { resolve(cachedRequest);});
        }

        return this.get('/static')
            .then((response: any) => {
                const models = this.mapper.getStaticPageModelsFromDtos(response.data as Array<IStaticPageDto>);
                this.cache.setItem("STATIC", models);
                return models;
        });
    }
    
    public getBuildVersion(): Promise<any> {
        return this.get('/buildVersion')
            .then((response: any) => {
                return response.data;
        });
    }
}
