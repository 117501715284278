import './timePicker.scss';
import React from 'react';
import moment from 'moment';
import { InputSearch } from '.';

interface ITimePickerProps {
    time: moment.Moment;
    onTimeChanged?: (time: moment.Moment, e: any) => void;
}

interface ITimePickerState {
    hours: string;
    minutes: string;
    format: string;
}

export class TimePicker extends React.Component<ITimePickerProps, ITimePickerState> {
    private hoursList: Array<any> = [
        { key: 1, value: 1 },
        { key: 2, value: 2 },
        { key: 3, value: 3 },
        { key: 4, value: 4 },
        { key: 5, value: 5 },
        { key: 6, value: 6 },
        { key: 7, value: 7 },
        { key: 8, value: 8 },
        { key: 9, value: 9 },
        { key: 10, value: 10 },
        { key: 11, value: 11 },
        { key: 12, value: 12 }
    ];
    private minutesList: Array<any> = [
        { key: 0, value: '00' },
        { key: 15, value: '15' },
        { key: 30, value: '30' },
        { key: 45, value: '45' }
    ];
    private formatList: Array<any> = [
        { key: 'am', value: 'AM' },
        { key: 'pm', value: 'PM' }
    ];

    constructor(props: ITimePickerProps) {
        super(props);

        this.state = {
            hours: '12',
            minutes: '00',
            format: 'am'
        };

        if (props.time) {
            this.state = {
                hours: props.time.format('h'),
                minutes: props.time.format('mm'),
                format: props.time.format('a')
            };
        }
    }

    shouldComponentUpdate(nextProps: ITimePickerProps) {
        const isHourChanged = !this.props.time.isSame(nextProps.time, 'hour');
        const isMinuteChanged = !this.props.time.isSame(nextProps.time, 'minute');

        return isHourChanged || isMinuteChanged;
    }

    componentDidUpdate(prevProps: ITimePickerProps) {
        const isHourChanged = !prevProps.time.isSame(this.props.time, 'hour');
        const isMinuteChanged = !prevProps.time.isSame(this.props.time, 'minute');

        if (isHourChanged || isMinuteChanged) {
            this.setState({
                ...this.state,
                hours: this.props.time.format('h'),
                minutes: this.props.time.format('mm'),
                format: this.props.time.format('A')
            });
        }
    }

    render() {
        return (
            <div className="time-picker">
                <InputSearch
                    classes="hours-select-wrapper"
                    selected={this.state.hours}
                    array={this.hoursList}
                    readOnly={true}
                    onSelectChange={(item, e) => this.changeHours(item, e)} />
                <InputSearch
                    classes="minutes-select-wrapper"
                    selected={this.state.minutes}
                    array={this.minutesList}
                    readOnly={true}
                    onSelectChange={(item, e) => this.changeMnutes(item, e)} />
                <InputSearch
                    classes="format-select-wrapper"
                    selected={this.state.format}
                    array={this.formatList}
                    readOnly={true}
                    onSelectChange={(item, e) => this.changeTimeFormat(item, e)} />
            </div>
        );
    }

    // handlers

    private changeHours(item: any, e: any) {
        this.handleTimeChange(item.key.toString(), this.state.minutes, this.state.format, e);
    }

    private changeMnutes(item: any, e: any) {
        this.handleTimeChange(this.state.hours, item.key.toString(), this.state.format, e);
    }

    private changeTimeFormat(item: any, e: any) {
        this.handleTimeChange(this.state.hours, this.state.minutes, item.key, e);
    }

    private handleTimeChange(hours: string, minutes: string, format: string, e: any) {
        if (this.props.onTimeChanged) {
            const timeString = "{0}:{1} {2}".format(hours, minutes, format);
            const time = moment(timeString, 'hh:mm a');
            const dateTime = this.props.time.set({ hours: time.hours(), minutes: time.minutes() });

            this.props.onTimeChanged(dateTime, e);
        }

        this.setState({
            ...this.state,
            hours: hours,
            minutes: minutes,
            format: format
        });
    }
}