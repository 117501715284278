import * as StM from '../../models/store';
import * as _ from 'lodash';

export default class LeagueListViewPageReducer {

    static handle(state: StM.ILeagueListViewPageStoreState = new StM.LeagueListViewPageStoreState(), action: any): StM.ILeagueListViewPageStoreState {
        switch (action.type) {
            case 'Pages/LeagueListView/Init': {
                return state;
            }
            case 'Pages/LeagueListView/Sessions': {
                let sessions = <Array<StM.ISessionStoreState>>action.payload;
                return <StM.ILeagueListViewPageStoreState>_.assign({}, state, { sessions: sessions });
            }
            default: {
                return state;
            }
        }
    }
}
