import * as Redux from 'redux';
import * as _ from 'lodash';

import { ThunkService } from '../services/thunkService';
import { IRoleDefinitionStoreState } from '../models/store/clubs/roleDefinitionStoreState';

export class PermissionsActions {

    static init(permissions: Array<IRoleDefinitionStoreState>): (dispatch: any, getState: any, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Permissions/Init',
                payload: permissions
            });
        };
    }
}
