import * as StM from '../../store';

export interface IUserPaymentStoreState{
    id: number;
    transactionDate: string;
    displayName: string;
    status: string;
    paymentAmount: string;
    amount: number;
    credits: number;
    noShowFee: number;
    lateCancelFee: number;
    excessiveFee: number;
    userDiscount: string;
    transactionId: string;
    session: StM.ITransactionDetailsSessionStoreState; 
    isCreditsTransaction: boolean;   
}

export class UserPaymentStoreState implements IUserPaymentStoreState{
    id: number;
    transactionDate: string;
    displayName: string;
    status: string;
    paymentAmount: string;
    amount: number;
    credits: number;
    noShowFee: number;
    lateCancelFee: number;
    excessiveFee: number;
    userDiscount: string;
    transactionId: string;
    session: StM.ITransactionDetailsSessionStoreState;
    isCreditsTransaction: boolean;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.transactionDate = dto && dto.transactionDate ? dto.transactionDate : '';
        this.displayName = dto && dto.displayName ? dto.displayName : '';
        this.status = dto && dto.status ? dto.status : '';
        this.paymentAmount = dto && dto.paymentAmount ? dto.paymentAmount : '';
        this.amount = dto && dto.amount ? dto.amount : 0;
        this.credits = dto && dto.credits ? dto.credits : 0;
        this.noShowFee = dto && dto.noShowFee ? dto.noShowFee : 0;
        this.lateCancelFee = dto && dto.lateCancelFee ? dto.lateCancelFee : 0;
        this.excessiveFee = dto && dto.excessiveFee ? dto.excessiveFee : 0;
        this.userDiscount = dto && dto.userDiscount ? dto.userDiscount : '';
        this.transactionId = dto && dto.transactionId ? dto.transactionId : '';
        this.session = dto && dto.session ? new StM.TransactionDetailsSessionDto(dto.session) : null;
        this.isCreditsTransaction = dto && dto.isCreditsTransaction ? dto.isCreditsTransaction : false;
    }
}
