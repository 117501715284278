import moment from 'moment';

import Constants from '../../../constants';
import { IPublicUserStoreState } from '../users';
import { ICourtStoreState, IClubStoreState } from '../clubs';
import { ISessionStoreState } from './sessionStoreState';
import { IPlayerQualificationStoreState } from './playerQualificationStoreState';

export interface ISeriesStoreState{
    id: number;
    status: string;
    startDate: moment.Moment;
    headCoach: IPublicUserStoreState;
    assistants: Array<IPublicUserStoreState>;
    courts: Array<ICourtStoreState>;
    sessions: Array<ISessionStoreState>;
    minUserCount: number;
    maxUserCount: number;
    playerQualification: IPlayerQualificationStoreState;
    description: string;
    title: string;
    created: moment.Moment;
    club: IClubStoreState;
    isHidden: boolean;
    invitedUsers: Array<IPublicUserStoreState>;
    price: number;
    isCourse: boolean;
    sessionCount: number;
}

export class SeriesStoreState implements ISeriesStoreState {
    id: number;
    status: string;
    startDate: moment.Moment;
    headCoach: IPublicUserStoreState;
    assistants: Array<IPublicUserStoreState>;
    courts: Array<ICourtStoreState>;
    sessions: Array<ISessionStoreState>;
    minUserCount: number;
    maxUserCount: number;
    playerQualification: IPlayerQualificationStoreState;
    description: string;
    title: string;
    created: moment.Moment;
    club: IClubStoreState;
    isHidden: boolean;
    invitedUsers: Array<IPublicUserStoreState>;
    price: number;
    isCourse: boolean;
    sessionCount: number;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.courts = dto && dto.courts ? dto.courts : new Array();
        this.headCoach = dto && dto.headCoach ? dto.headCoach : null;
        this.assistants = dto && dto.assistants ? dto.assistants : [];
        this.sessions = dto && dto.sessions ? dto.sessions : [];
        this.club = dto && dto.club ? dto.club : null;
        this.status = dto && dto.status ? dto.status : '';
        this.minUserCount = dto && dto.minUserCount ? dto.minUserCount : 0;
        this.maxUserCount = dto && dto.maxUserCount ? dto.maxUserCount : 0;
        this.price = dto && dto.price ? dto.price : 0;
        this.playerQualification = dto && dto.playerQualification ? dto.playerQualification : null;
        this.invitedUsers = dto && dto.invitedUsers ? dto.invitedUsers : [];
        this.isHidden = dto && dto.isHidden ? dto.isHidden : false;
        this.created = dto && dto.created ? moment(dto.created, Constants.DateTime.API_FORMAT) : moment.min();
        this.startDate = dto && dto.startDate ? moment(dto.startDate, Constants.DateTime.API_FORMAT) : moment.min();
        this.title = dto && dto.title ? dto.title : '';
        this.description = dto && dto.description ? dto.description : '';
        this.isCourse = dto && dto.isCourse ? dto.isCourse : false;
        this.sessionCount = dto && dto.sessionCount ? dto.sessionCount : 0;
    }
}
