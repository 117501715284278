import * as Redux from 'redux';

export class AssignCoachFeeTierToCourtsDialogActions {
    public static open(id?: number): (dispatch: any) => void {
        return (dispatch: any) => {
            dispatch({
                type: 'Dialogs/Admin/AssignCoachFeeTierToCourts/Open',
                id: id
            });
        };
    }

    public static close(): (dispatch: any) => void {
        return (dispatch: any) => {
            dispatch({
                type: 'Dialogs/Admin/AssignCoachFeeTierToCourts/Close',
            });
        };
    }
}
