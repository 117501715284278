import * as Redux from 'redux';
import * as _ from 'lodash';

import {ThunkService} from '../services/thunkService';
import * as DtoModule from '../models/dto';
import * as StoreModule from '../models/store';

export class hoverLineActions {

    static show(item: any, top:number, right:number): (dispatch: any, getState: () => StoreModule.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'HoverLine/Show',
                item: item,
                top: top,
                right: right
            });
        };
    }

    static hide(): (dispatch: any, getState: () => StoreModule.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'HoverLine/Hide',
            });
        };
    }
}