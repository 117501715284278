import * as Redux from 'redux';
import * as _ from 'lodash';

import {ThunkService} from '../services/thunkService';
import * as StoreModule from '../models/store';

export class MembershipLevelsActions {

    static init(membershipLevels: Array<StoreModule.IMembershipLevelStoreState>): (dispatch: any, getState: any, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'MembershipLevels/Init',
                payload: membershipLevels
            });
        };
    }
}
