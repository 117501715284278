export class SessionDto implements ISessionDto {
        id: number;
        type: string;
        owner: IPublicUserDto;
        ownerId: string;
        startDateTime: string;
        endDateTime: string;
        court: ICourtDto;
        courtId: number;
        courts: ICourtDto[];
        bookings: IBookingDto[];
        club: IClubDto;
        clubId: number;
        trainer: ICoachDto;
        trainerId: string;
        seekingId: number;
        seeking: ISeekingDto;
        status: string;
        minUserCount: number;
        maxUserCount: number;
        pricingTierId: number;
        pricingTierType: string;
        price: number;
        credits: number;
        isPaidByOwner: boolean;
        cancellationReasonText: string;
        notes: string;
        playerQualification: IPlayerQualificationDto;
        invitedUsers: IPublicUserDto[];
        addedUsers:IAddedUserDto[];
        declinedUsers: IPublicUserDto[];
        isHidden: boolean;
        created: string;
        createdById: string;
        validationResult: IValidationResultDto;
        title: string;
        salesTax: number;
        customTextColor: string;
        customBackgroundColor: string;
        recurrencyDates: string[];
        assistants: ICoachDto[];
        typeAlias: string;
        series: ISeriesDto;
        youtubeIds: IYoutubeIdDto[];
        isCourse: boolean;
        seriesOrder: number;
        recordVideo: boolean;
        originalSessionId: number;
        videoLinkId: string;
        videoWasRecorded: boolean;
        splitPrice: boolean;
        maxActivePlayers: number;
        checkoutPrice: number;
        checkoutCredits: number;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.type = dto && dto.type ? dto.type : '';
        this.owner = dto && dto.owner ? dto.owner : null;
        this.ownerId = dto && dto.ownerId ? dto.ownerId : '';
        this.startDateTime = dto && dto.startDateTime ? dto.startDateTime : '';
        this.endDateTime = dto && dto.endDateTime ? dto.endDateTime : '';
        this.court = dto && dto.court ? dto.court : null;
        this.courtId = dto && dto.courtId ? dto.courtId : 0;
        this.courts = dto && dto.courts ? dto.courts : [];
        this.bookings = dto && dto.bookings ? dto.bookings : [];
        this.club = dto && dto.club ? dto.club : null;
        this.clubId = dto && dto.clubId ? dto.clubId : 0;
        this.trainer = dto && dto.trainer ? dto.trainer : null;
        this.trainerId = dto && dto.trainerId ? dto.trainerId : '';
        this.seekingId = dto && dto.seekingId ? dto.seekingId : null;
        this.seeking = dto && dto.seeking ? dto.seeking : null;
        this.status = dto && dto.status ? dto.status : '';
        this.minUserCount = dto && dto.minUserCount ? dto.minUserCount : 0;
        this.maxUserCount = dto && dto.maxUserCount ? dto.maxUserCount : 0;
        this.pricingTierId = dto && dto.pricingTierId ? dto.pricingTierId : 0;
        this.pricingTierType = dto && dto.pricingTierType ? dto.pricingTierType : '';
        this.price = dto && dto.price ? dto.price : 0;
        this.credits = dto && dto.credits ? dto.credits : 0;
        this.isPaidByOwner = dto && dto.isPaidByOwner ? dto.isPaidByOwner : false;
        this.cancellationReasonText = dto && dto.cancellationReasonText ? dto.cancellationReasonText : '';
        this.notes = dto && dto.notes ? dto.notes : '';
        this.playerQualification = dto && dto.playerQualification ? dto.playerQualification : null;
        this.invitedUsers = dto && dto.invitedUsers ? dto.invitedUsers : [];
        this.addedUsers = dto && dto.addedUsers ? dto.addedUsers : [];
        this.declinedUsers = dto && dto.declinedUsers ? dto.declinedUsers : [];
        this.isHidden = dto && dto.isHidden ? dto.isHidden : false;
        this.created = dto && dto.created ? dto.created : '';
        this.createdById = dto && dto.createdById ? dto.createdById : '';
        this.validationResult = dto && dto.validationResult ? dto.validationResult : null;
        this.title = dto && dto.title ? dto.title : '';
        this.salesTax = dto && dto.salesTax ? dto.salesTax : 0;
        this.customTextColor = dto && dto.customTextColor ? dto.customTextColor : '';
        this.customBackgroundColor = dto && dto.customBackgroundColor ? dto.customBackgroundColor : '';
        this.recurrencyDates = dto && dto.recurrencyDates ? dto.recurrencyDates : [];
        this.assistants = dto && dto.assistants ? dto.assistants : [];
        this.typeAlias = dto && dto.typeAlias ? dto.typeAlias : '';
        this.series = dto && dto.series ? dto.series : null;
        this.seriesOrder = dto && dto.seriesOrder ? dto.seriesOrder : 0;
        this.youtubeIds = dto && dto.youtubeIds ? dto.youtubeIds : [];
        this.isCourse = dto && dto.isCourse ? dto.isCourse : false;
        this.recordVideo = dto && dto.recordVideo ? dto.recordVideo : false;
        this.originalSessionId = dto && dto.originalSessionId ? dto.originalSessionId : 0;
        this.videoLinkId = dto && dto.videoLinkId ? dto.videoLinkId : 0;
        this.videoWasRecorded = dto && dto.videoWasRecorded ? dto.videoWasRecorded : false;
        this.splitPrice = dto && dto.splitPrice ? dto.splitPrice : false;
        this.maxActivePlayers = dto && dto.maxActivePlayers ? dto.maxActivePlayers : 0;
        this.checkoutPrice = dto && dto.checkoutPrice ? dto.checkoutPrice : 0;
        this.checkoutCredits = dto && dto.checkoutCredits ? dto.checkoutCredits : 0;
        this.pricingTierId = dto && dto.pricingTierId ? dto.pricingTierId : 0;
        this.pricingTierType = dto && dto.pricingTierType ? dto.pricingTierType : '';
    }
}