import BaseReducer from './baseReducer';
import * as StM from '../models/store';

export default class ClubsReducer extends BaseReducer {
     static handle(state: Array<StM.IClubStoreState> = new Array<StM.ClubStoreState>(), action: any): StM.IClubStoreState[] {
        switch (action.type) {
            case 'Clubs/Init': {
                return [...action.payload] as StM.IClubStoreState[];
            }
            default: {
                return state;
            }
        }
    }
}
