import * as _ from 'lodash';

import { StM, SrvM } from '../../modules';

export class FormValidationService {
    private validator: SrvM.ValidationService;
    private static _instance: FormValidationService;
    private defaultDisplayName = 'This';

    constructor() {
        if (typeof FormValidationService._instance === 'undefined') {
            FormValidationService._instance = this;
            this.validator = new SrvM.ValidationService();
        }
        return FormValidationService._instance;
    }

    public validateEditUserProfileForm(profile: StM.IUserManagableStoreState, club: StM.IClubStoreState, validateBirthday: boolean = true) {
        if (!profile) return null;
        return {
            ...this.validator.validateRequired(profile.firstName, 'firstName', 'First Name'),
            ...this.validator.validateRequired(profile.lastName, 'lastName', 'Last Name'),
            ...this.validator.validateEmail(profile.email, 'email', 'Email'),
            ...(validateBirthday && this.validator.validateBirthDay(profile.dateOfBirth, 'dateOfBirth', club, 'Date of Birth', true)),
            ...this.validator.validatePhone(profile.phone, 'phone', 'Phone'),
            ...this.validator.validatePhone(profile.emergencyContactPhoneNumber, 'emergencyContactPhoneNumber', 'Emergency Contact Phone'),
            ...this.validator.validateRequired(profile.gender, 'gender', 'Gender'),
            ...this.validator.validateRequired(profile.skill, 'skill', 'Skill'),
            ...this.validator.validateRequired(profile.emergencyContactFullName, 'emergencyContactFullName', 'Emergency Contact Name'),
            ...this.validator.validateRequired(
                profile.emergencyContactRelationship,
                'emergencyContactRelationship',
                'Emergency Contact Relationship'
            ),
        };
    }

    public validateAccountInformationForm(profile: StM.IUserManagableStoreState, club: StM.IClubStoreState, validateBirthday: boolean = false) {
        if (!profile) return null;
        return {
            ...this.validator.validatePhone(profile.phone, 'phone', 'Phone'),
            ...this.validator.validatePhone(profile.emergencyContactPhoneNumber, 'emergencyContactPhoneNumber', 'Phone'),
            ...this.validator.validateRequired(profile.gender, 'gender', 'Gender'),
            ...this.validator.validateRequired(profile.skill, 'skill', 'Skill'),
            ...this.validator.validateRequired(profile.emergencyContactFullName, 'emergencyContactFullName', 'Name'),
            ...this.validator.validateRequired(profile.emergencyContactRelationship, 'emergencyContactRelationship', 'Relationship'),
            ...(validateBirthday && this.validator.validateBirthDay(profile.dateOfBirth, 'dateOfBirth', club, 'Date of Birth', true)),
        };
    }

    public validateSignUpForm(model: any, club: StM.IClubStoreState) {
        if (!model) return null;
        return {
            ...this.validator.validateRequired(model.firstName, 'firstName', 'First Name'),
            ...this.validator.validateRequired(model.lastName, 'lastName', 'Last Name'),
            ...this.validator.validateEmail(model.email, 'email'),
            ...this.validator.validateFieldsMatch(model.repeatEmail, model.email, 'repeatEmail', 'Emails'),
            ...this.validator.validatePassword(model.password, 'password'),
            ...this.validator.validateFieldsMatch(model.confirmPassword, model.password, 'confirmPassword', 'Passwords'),
            ...this.validator.validateBirthDay(model.dateOfBirth, 'dateOfBirth', club),
        };
    }

    public validateSimplifiedSignUpForm(model: any, club: StM.IClubStoreState) {
        if (!model) return null;
        return {
            ...this.validator.validateEmail(model.email, 'email'),
        };
    }

    public validateCreateGroupAccountForm(model: StM.IGroupStoreState) {
        if (!model) return null;
        return {
            ...this.validator.validateRequired(model.title, 'title', 'Group Name'),
        };
    }

    public validateBillingForm(model: StM.IPaymentProfileInformationStoreState, validateCard?: boolean, validateAddress?: boolean) {
        if (!model) return null;

        let result = {};
        if (validateAddress) {
            result = this.validator.validateRequired(model.billingAddress.street, 'billingAddress.street', this.defaultDisplayName , result);
            result = this.validator.validateRequired(model.billingAddress.state, 'billingAddress.state', this.defaultDisplayName, result);
            result = this.validator.validateRequired(model.billingAddress.city, 'billingAddress.city', this.defaultDisplayName, result);
            result = this.validator.validateRequired(model.billingAddress.zip, 'billingAddress.zip', this.defaultDisplayName, result);
        }

        if (validateCard) {
            result = this.validator.validate(
                'creditCard.number',
                () => (!/^\d+$/.test(model.creditCard.number) || !model.creditCard.type) && 'Payment System is not supported',
                result
            );
            result = this.validator.validateLength(model.creditCard.number, 'creditCard.number', 'Card Number', 12, 19, result);
            result = this.validator.validateIncorrect(/^[A-Za-z_\s]+$/.test(model.creditCard.fullName), 'creditCard.fullName', this.defaultDisplayName, result);
            result = this.validator.validateRequired(model.creditCard.number, 'creditCard.number', this.defaultDisplayName, result);
            result = this.validator.validateIncorrect(/^\d+$/.test(model.creditCard.securityCode), 'creditCard.securityCode', 'CVV', result);
            result = this.validator.validateLength(model.creditCard.securityCode, 'creditCard.securityCode', 'CVV', 3, 4, result);
            result = this.validator.validateRequired(model.creditCard.securityCode, 'creditCard.securityCode', 'CVV', result);
            result = this.validator.validateRequired(model.creditCard.expirationMonth, 'creditCard.expirationMonth', 'Expiration Month', result);
            result = this.validator.validateRequired(model.creditCard.expirationYear, 'creditCard.expirationYear', 'Expiration Year', result);
        }

        result = this.validator.validateRequired(model.creditCard.fullName, 'creditCard.fullName', this.defaultDisplayName, result);
        result = this.validator.validateRequired(model.billingAddress.country, 'billingAddress.country', this.defaultDisplayName, result);

        return result;
    }
}
