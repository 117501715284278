import * as Redux from 'redux';
import * as _ from 'lodash';

import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';
import { AppActions } from '../appActions';
import { ICoachFeeTierPriceStoreState } from '../../models/store';

export class AdminCoachFeeTierPricesActions {
    constructor() {
    }

    static getAll(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<ICoachFeeTierPriceStoreState>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(AppActions.showSpinner())
            return thunkService.api.pricing.getCoachFeeTierPrices()
                .then((tiers) => {
                    dispatch({
                        type: 'CoachFeeTierPrices/Init',
                        payload: tiers
                    });
                    dispatch(AppActions.hideSpinner());
                    return tiers;
                }).catch((error) => {
                    dispatch(AppActions.hideSpinner());
                    throw error;
                });
        };
    }

    static get(coachTierId: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<ICoachFeeTierPriceStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(AppActions.showSpinner())
            return thunkService.api.pricing.getCoachFeeTierPrice(coachTierId)
                .then((tier) => {
                    return tier;
                }).catch((error) => {
                    dispatch(AppActions.hideSpinner());
                    throw error;
                });
        };
    }


    static save(tiers: Array<ICoachFeeTierPriceStoreState>): (dispatch: any, 
        getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<ICoachFeeTierPriceStoreState>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(AppActions.showSpinner())
            return thunkService.api.pricing.saveCoachFeeTierPrices(tiers)
            .then((tiers) => {
                dispatch({
                    type: 'CoachFeeTierPrices/Init',
                    payload: tiers
                })
                dispatch(AppActions.hideSpinner());
                return tiers;
            }).catch((error) => {
                dispatch(AppActions.hideSpinner());
                throw error;
            });
        };
    }
}