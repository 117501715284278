export class GroupMemberDto implements IGroupMemberDto{
    id: number;
    type: string;
    user: IPublicUserDto;
    group: IGroupDto;
    invited: string;
    invitedBy: IUserDto;
    canLoginAsGroupAccount: boolean;
    notes: string;
    invitationStatus: string;
    disabled: boolean;
    added: string;
    addedBy: IPublicUserDto;
    lastUpdated: string;
    lastUpdatedBy: IPublicUserDto;
    email: string;
    age: number;
    gender: string;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.type = dto && dto.type ? dto.type : '';
        this.user = dto && dto.user ? dto.user : null;
        this.group = dto && dto.group ? dto.group : null;
        this.invited = dto && dto.invited ? dto.invited : '';
        this.invitedBy = dto && dto.invitedBy ? dto.invitedBy : null;
        this.canLoginAsGroupAccount = dto && dto.canLoginAsGroupAccount ? dto.canLoginAsGroupAccount : false;
        this.notes = dto && dto.notes ? dto.notes : '';
        this.invitationStatus = dto && dto.invitationStatus ? dto.invitationStatus : '';
        this.disabled = dto && dto.disabled ? dto.disabled : false;
        this.added = dto && dto.added ? dto.added : '';
        this.addedBy = dto && dto.addedBy ? dto.addedBy : null;
        this.lastUpdated = dto && dto.lastUpdated ? dto.lastUpdated : '';
        this.lastUpdatedBy = dto && dto.lastUpdatedBy ? dto.lastUpdatedBy : null;
        this.email = dto && dto.email ? dto.email : '';
        this.age = dto && dto.age ? dto.age : 0;
        this.gender = dto && dto.gender ? dto.gender : '';
    }
}