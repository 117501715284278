import './playerTooltip.scss';
import * as React from 'react';
import * as StoreModule from '../../models/store';
import ClassNames from 'classnames';

interface IPlayerTooltipProps {
    user: StoreModule.IPublicUserStoreState;
}

interface IPlayerTooltipState {
}
export default class PlayerTooltip extends React.Component<IPlayerTooltipProps, IPlayerTooltipState> {
    constructor(props:IPlayerTooltipProps) {
        super(props);
    }
    getSkillClass(skill:string) {
        switch(skill) {
            case StoreModule.UserSkill.Beginner: {
                return 'beginner';
            }
            case StoreModule.UserSkill.Intermediate: {
                return 'intermediate';
            }
            case StoreModule.UserSkill.Advanced: {
                return 'advanced';
            }
            default: {
                return 'untitled-lvl';
            }
        }
    }
    render() {
        let user = this.props.user;
        let skillClass = this.getSkillClass(user.skill)
        let classes = ClassNames("lvl", skillClass);
        let wnd = window as any;
        let image = user.imageId ? user.imageId : '';
        let imageUrl = image ? `/api/v2/blobs/${image}/content` : '/content/img/nophoto-2.png';
        return (
            <span className="tooltip">
                <img src={imageUrl} alt={user.displayName}/>
                <span>{user.displayName}</span>
                <div className={classes}>
                    {user.skill!="0"?user.skill: <span>&nbsp;</span>}
                </div>
            </span>
        );
    } 
}