
import * as Redux from 'redux';
import * as _ from 'lodash';

import * as StM from '../../models/store';
import { ThunkService } from '../../services/thunkService';
import { AppActions } from '../appActions';

export class AdminGroupsActions {
    
    static init(groups: Array<StM.IGroupStoreState>): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Admin/Groups/Init',
                payload: groups
            });
        }
    }

    public static get(onlyArchived: boolean = false):
        (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.IGroupStoreState[]> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkSrv: ThunkService) => {
            dispatch(AppActions.showSpinner());

            const { club, pricingTiers } = getState();
            const getGroupsP = onlyArchived ? thunkSrv.api.admin.getArchivedGroups(club, pricingTiers) :  thunkSrv.api.admin.getGroups(club, pricingTiers);

            return Promise.resolve(getGroupsP)
                .then((groups: Array<StM.IGroupStoreState>) => {
                    if(!onlyArchived) dispatch(this.init(groups));
                    dispatch(AppActions.hideSpinner());
                    return groups;
                }).catch((error) => {
                    dispatch(AppActions.hideSpinner());
                    throw error;
                });
        };
    }

    public static archiveGroups(groupIds: number[]):
        (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkSrv: ThunkService) => {
            dispatch(AppActions.showSpinner());
            return thunkSrv.api.admin.archiveGroups(groupIds)
                .then((response) => {
                    dispatch(AppActions.hideSpinner());
                    return response;
                })
                .catch((error) => {
                    dispatch(AppActions.hideSpinner());
                    throw error.data;
                });
        };
    }

    public static removeGroups(groupIds: number[]):
        (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkSrv: ThunkService) => {
            dispatch(AppActions.showSpinner());
            return thunkSrv.api.admin.deleteGroups(groupIds)
                .then((response) => {
                    dispatch(AppActions.hideSpinner());
                    return response;
                })
                .catch((error) => {
                    dispatch(AppActions.hideSpinner());
                    throw error.data;
                });
        };
    }
}
