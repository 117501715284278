import * as StM from '../../../models/store';
import BaseReducer from '../../baseReducer';

export class EditGroupAccountDialogReducer extends BaseReducer {
    public static handle(state: StM.IEditGroupAccountDialogStoreState = new StM.EditGroupAccountDialogStoreState(), action: any): StM.IEditGroupAccountDialogStoreState {
        switch (action.type) {
            case 'Dialogs/EditGroupAccount/Open': {
                return {
                    ...state,
                    isOpened: true
                } as StM.IEditGroupAccountDialogStoreState;
            }
            case 'Dialogs/EditGroupAccount/Close': {
                return {
                    ...state,
                    isOpened: false
                } as StM.IEditGroupAccountDialogStoreState
            }
            default: {
                return state;
            }
        }
    }
}
