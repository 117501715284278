import * as _ from 'lodash';

import BaseReducer from './baseReducer';
import { StM } from '../modules';

export default class ActiveUsersReducer extends BaseReducer {
    static handle(state: Array<StM.IUserStoreState> = new Array<StM.IUserStoreState>(), action: any): Array<StM.IUserStoreState> {
        switch (action.type) {
            case 'ActiveUsers/Init': {
                const activeUsers =  _.uniqBy(action.payload as StM.IUserStoreState[], u => u.id);
                return [...activeUsers];
            }
            case 'ActiveUsers/Clear': {
                return [];
            }
            default: {
                return state;
            }
        }
    }
}
