import * as _ from 'lodash';
import * as StM from '../models/store';
import { store } from '../store/store';

export class AuthenticationService {
    private static _instance: AuthenticationService;

   
    constructor() {
        if (typeof AuthenticationService._instance == "undefined") {
            AuthenticationService._instance = this;
        }
        return AuthenticationService._instance;
    }

    public isInRole(role: string, user: StM.IUserStoreState) : boolean {
        if(!role) return true;
        return _.some(user.permissions, { role: role});
    }

    public isInAllRoles(roles: string[], user: StM.IUserStoreState) : boolean {
        if(!roles || !roles.forEach.length) return true;
        return _.every(roles, (role) => this.isInRole(role, user));
    }

    public isInRoles(roles: string[], user: StM.IUserStoreState) : boolean {
        if(!roles || !roles.forEach.length) return true;
        return _.some(roles, (role) => this.isInRole(role, user));
    }

    public hasPermission(permission: string, user: StM.IUserStoreState) : boolean {
        if(!permission || !user) return true;
        const state = store.getState() as StM.IGlobalStoreState;
        const permissions = state.permissions;
        const matchingRoleDefinitions = _.filter(permissions, (x) => _.some(x.permissions, (y) => y.toLowerCase() == permission.toLowerCase()));
        return _.some(matchingRoleDefinitions, (x) => _.some(user.permissions, (y) => y.role.toLowerCase() == x.name.toLowerCase()));
    }

    public hasPermissions(permissions: string[], user: StM.IUserStoreState) : boolean {
        if(!permissions || !permissions.forEach.length || !user) return true;
        return _.every(permissions, (permission) => this.hasPermission(permission, user));
    }

    public hasSomePermissions(permissions: string[], user: StM.IUserStoreState) : boolean {
        if(!permissions || !permissions.forEach.length || !user) return true;
        return _.some(permissions, (permission) => this.hasPermission(permission, user));
    }

    public getOwnerPrefix(user: StM.IUserStoreState) {
        return this.isInRole(StM.Roles.GroupAccount, user) ? 'Group' : 'My';
    }
}
