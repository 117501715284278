export class CreditsTransactionDto implements ICreditsTransactionDto{
        id: number;
        creditsWallet: ICreditsWalletDto;
        creditsWalletId: number;
        creditsWalletBalance: ICreditsWalletBalanceDto;
        creditsWalletBalanceId: number;
        credits: number;
        creditsBefore: number;
        user: IPublicUserDto;
        userId: string;
        booking: IBookingDto;
        bookingId: number;
        package: IPackageDto;
        packageId: number;
        price: number; 
        type: string;
        comments: string;
        created: string;
        status: string;
        createdBy: IPublicUserDto;
        createdById: string;
        refundAmount: number;
        refundReason: string;
        paymentSystemId: number;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.creditsWallet = dto && dto.creditsWallet ? dto.creditsWallet : null ;
        this.creditsWalletId = dto && dto.creditsWalletId ? dto.creditsWalletId : '' ;
        this.creditsWalletBalance = dto && dto.creditsWalletBalance ? dto.creditsWalletBalance : null ;
        this.creditsWalletBalanceId = dto && dto.creditsWalletBalanceId ? dto.creditsWalletBalanceId : '' ;
        this.credits = dto && dto.credits ? dto.credits : 0 ;
        this.creditsBefore = dto && dto.creditsBefore ? dto.creditsBefore : 0 ;
        this.user = dto && dto.user ? dto.user : null ;
        this.userId = dto && dto.userId ? dto.userId : '' ;
        this.booking = dto && dto.booking ? dto.booking : null ;
        this.bookingId = dto && dto.bookingId ? dto.bookingId : '' ;
        this.package = dto && dto.package ? dto.package : null ;
        this.packageId = dto && dto.packageId ? dto.packageId : '' ;
        this.type = dto && dto.type ? dto.type : '' ;
        this.comments = dto && dto.comments ? dto.comments : '' ;
        this.created = dto && dto.created ? dto.created : '' ;
        this.createdBy = dto && dto.createdBy ? dto.createdBy : null ;
        this.createdById = dto && dto.createdById ? dto.createdById : '' ;
        this.status = dto && dto.status ? dto.status : '' ;
        this.refundAmount = dto && dto.refundAmount ? dto.refundAmount : 0;
        this.refundReason = dto && dto.refundReason ? dto.refundReason : 0;
        this.paymentSystemId = dto && dto.paymentSystemId ? dto.paymentSystemId : 0;
    }
}