import { PaymentSystem } from '../enums';

export interface IPaymentSystemStoreState {
    id: number;
    type: PaymentSystem;
    publicApiKey: string;
    supportsSecurePayments: boolean;
}

export class PaymentSystemStoreState implements IPaymentSystemStoreState {
    id: number;
    type: PaymentSystem;
    publicApiKey: string;
    supportsSecurePayments: boolean;
  
    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.type = dto && dto.type ? dto.type : '';
        this.publicApiKey = dto && dto.publicApiKey ? dto.publicApiKey : '';
        this.supportsSecurePayments = dto && dto.supportsSecurePayments ? dto.supportsSecurePayments : false;
    }
}