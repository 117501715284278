import * as _ from 'lodash';
import moment from 'moment';

import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';
import * as SrvM from '../../services';
import * as ActM from '../';

const utils = new SrvM.Utils();

export class BookPageActions {
    static init(
        currentDate: moment.Moment, 
        isNeedSpinner: boolean = true
    ) : (
        dispatch: any, 
        getState: () => StM.IGlobalStoreState, 
        thunkService: ThunkService
    ) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(ActM.AppActions.showSpinner());

            const start = currentDate.clone().startOf('day');
            const end = start.clone().add(1, 'days');

            const final = () => {
                dispatch({ type: 'Pages/Book/Init', payload: null });
                dispatch(ActM.AppActions.hideSpinner());
                dispatch(ActM.BaseActions.hideSplash());
            };

            const sessionP = Promise.resolve(dispatch(BookPageActions.getSessions(start, end)));
            const availableTimesP = Promise.resolve(dispatch(BookPageActions.getAvailableTimes(start, end)));
            return Promise.all([sessionP, availableTimesP])
                .then(() => {
                    dispatch(BookPageActions.sortCoaches());
                    return dispatch(BookPageActions.fillCourtTimeSlots(currentDate));
                }).then(() => {
                    return final();
                }).catch((error) => {
                    thunkService.logger.error(error);
                    return final();
                });
        };
    }

    static getSessions(start: moment.Moment, end: moment.Moment, clearCache?: boolean): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            dispatch(ActM.AppActions.showSpinner());
            return thunkService.api.session.getByPeriod(start, end, club, pricingTiers, false, clearCache)
                .then((response: any) => {
                    const sessions = <Array<StM.ISessionStoreState>>response;
                    dispatch(BookPageActions.sortCoaches());
                    dispatch({ type: 'Pages/Book/Sessions', payload: sessions });
                    dispatch(ActM.AppActions.hideSpinner());
                }).catch(() => {
                    dispatch(ActM.AppActions.hideSpinner());
                });
        };
    }

    static getTransactions(start: moment.Moment, end: moment.Moment): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            let store = <StM.IGlobalStoreState>getState();
            let club = store && store.club ? store.club : null;
            return thunkService.api.session.getTransactions(start, end);
        };
    }

    static getAvailableTimes(start: moment.Moment, end: moment.Moment, force: boolean = false)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            let store = <StM.IGlobalStoreState>getState();
            let club = store && store.club ? store.club : null;

            dispatch(ActM.AppActions.showSpinner());
            return thunkService.api.user.getAvailableTimes(start, end, club, force)
                .then((response: any) => {
                    const availableTimes = <Array<StM.IAvailableTimeStoreState>>response;
                    dispatch(BookPageActions.sortCoaches());
                    dispatch({ type: 'Pages/Book/AvailableTimes', payload: availableTimes });
                    dispatch(ActM.AppActions.hideSpinner());

                    thunkService.cache.removeItem("AVAILABLE_TIMES_SPLIT" + start.clone().startOf('day').valueOf());
                    return availableTimes;
                }).catch(() => {
                    dispatch(ActM.AppActions.hideSpinner());
                });
        };
    }

    static sortCoaches()
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            let store = <StM.IGlobalStoreState>getState();
            let times = thunkService.cache.getItem("AVAILABLE_TIMES") ? thunkService.cache.getItem("AVAILABLE_TIMES").object as Array<StM.IAvailableTimeStoreState> : null;
            let sessions = thunkService.cache.getItem("SESSIONS_BY_PERIOD") ? thunkService.cache.getItem("SESSIONS_BY_PERIOD").object as Array<StM.ISessionStoreState> : null;
            let clubTime = store.club ? utils.getCurrentClubDateTime(store.club) : moment(new Date(0));
            const clubTimeMins = utils.getDurationTime(clubTime).asMinutes();

            if (!times || !sessions) return;

            const availabilityLookup: IDictionary = {};

            times.forEach((time) => {
                if (time.begin.isSameOrAfter(clubTime, 'day')) {
                    const startMins = utils.getDurationTime(time.begin).asMinutes();
                    const endMins = startMins + time.duration.asMinutes();
                    if (endMins < clubTimeMins) return;

                    const dayIndex = utils.getDateFormat(time.begin.clone().startOf('day'));
                    if (!availabilityLookup[time.ownerId]) availabilityLookup[time.ownerId] = <IDictionary>{};

                    if (!availabilityLookup[time.ownerId][dayIndex]) {
                        availabilityLookup[time.ownerId][dayIndex] = 0;
                    }

                    var duration = time.begin.clone().startOf('day').isSame(clubTime.startOf('day')) && startMins < clubTimeMins && endMins >= clubTimeMins
                        ? Math.max(endMins - clubTimeMins, 0)
                        : time.duration.asMinutes();

                    _.each(sessions, (session) => {
                        const hasCoach = session.trainerId === time.ownerId || session.assistants.some(a => a.id === time.ownerId);
                        const isBefore = session.startDateTime.isSameOrBefore(time.begin.clone().add(time.duration));
                        const isAfter = session.endDateTime.isSameOrAfter(time.begin);
                        if (hasCoach && isBefore && isAfter) {
                            var startOfIntersection = session.startDateTime.isSameOrBefore(time.begin) ? time.begin.clone() : session.startDateTime.clone();
                            var endOfIntersection = session.endDateTime.isSameOrBefore(time.begin.clone().add(time.duration)) ? session.endDateTime.clone() : time.begin.clone().add(time.duration);
                            var occupiedDuration = moment.duration(endOfIntersection.diff(startOfIntersection).valueOf()).asMinutes();
                            duration -= occupiedDuration;
                        }
                    });

                    availabilityLookup[time.ownerId][dayIndex] += duration;
                }
            });

            return dispatch({
                type: 'Pages/Book/AvailableTimesLookup',
                payload: availabilityLookup
            });
        };
    }

    static fillCourtTimeSlots(date: moment.Moment, isRefresh?: boolean)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Array<StM.CourtTimeSlotStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const store = <StM.IGlobalStoreState>getState();
            const club = store && store.club ? store.club : null;
            const user = store && store.user ? store.user : null;
            const coachFeeTiers = store && store.coachFeeTiers ? store.coachFeeTiers : [];
            const sessions = store && store.pages && store.pages.book && store.pages.book.sessions ? store.pages.book.sessions : [];
            const availableTimes = store && store.pages && store.pages.book && store.pages.book.availableTimes ? store.pages.book.availableTimes : [];
            const basketSessions = store && store.basket && store.basket.goods ? store.basket.goods : [];
            const filter = store && store.app && store.app.match && store.app.match.params ? store.app.match.params : null;
            const coaches = store && store.coaches ? store.coaches : [];
            const pricingTiers = store && store.pricingTiers ? store.pricingTiers : [];

            const timeSlots = thunkService.courtTime.getCourtDaySlots(
                date,
                club,
                sessions,
                basketSessions,
                availableTimes,
                user,
                coachFeeTiers,
                filter,
                pricingTiers,
                true,
                coaches,
            );

            dispatch({
                type: isRefresh ? 'Pages/Book/CourtTimeSlotsRefresh' : 'Pages/Book/CourtTimeSlots',
                payload: timeSlots
            });

            return timeSlots;
        };
    }
}
