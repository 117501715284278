import { StM } from '../../modules';
import { BaseApiService } from '../baseApiService';


export class GroupApiService extends BaseApiService {
    private static _instance: GroupApiService;
    constructor(config?: any) {
        if (typeof GroupApiService._instance == "undefined") {
            super(config);
            GroupApiService._instance = this;
        }
        return GroupApiService._instance;
    }

    public update(group: StM.IGroupStoreState,  club: StM.IClubStoreState): Promise<StM.IGroupStoreState> {
        const dto = this.mapper.getGroupDtoFromModel(group, club);
        return this.post('', dto)
            .then((response: any) => {
                let model = this.mapper.getGroupModelFromDto(<IGroupDto>response.data, club);
                return model;
            }).catch((error) => {
                throw (error.response);
            });
    }

    public create(group: StM.IGroupStoreState, club: StM.IClubStoreState): Promise<StM.IGroupStoreState> {
        const dto = this.mapper.getGroupDtoFromModel(group, club);
        return this.put('/', dto)
            .then((response: any) => {
                let model = this.mapper.getGroupModelFromDto(<IGroupDto>response.data, club);
                return model;
            }).catch((error) => {
                throw (error.response);
            });
    }

    public getGroups(club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<Array<StM.IGroupStoreState>> {
        return this.get('/')
            .then((response: any) => {
                let models = this.mapper.getGroupModelsFromDtos(<Array<IGroupDto>>response.data, club, tiers);
                return models;
            }).catch((error) => {
                throw (error.response);
            });
    }
    
    public searchUsers(query: IGroupMemberSearchDto): Promise<IGroupMemberSearchDto> {
        return this.post('/search', query)
            .then((response: any) => {
                return <IGroupMemberSearchDto>response.data;
            }).catch((error) => {
                throw (error.response);
            });
    }

    public updateImage(file: any, groupId: number, club: StM.IClubStoreState): Promise<StM.IGroupStoreState> {
        return this.post('/updateImage/{0}'.format(groupId), file, { headers: { 'Content-Type': file.type } })
            .then((response: any) => {
                let model = this.mapper.getGroupModelFromDto(<IGroupDto>response.data, club);
                return model;
            }).catch((error) => {
                throw (error.response);
            });
    }

    public deleteGroup(groupId: number, club: StM.IClubStoreState): Promise<any> {
        return this.delete('/{0}'.format(groupId))
            .catch((error) => {
                throw (error.response);
            });
    }

    public rejectInvitation(groupId: number, club: StM.IClubStoreState): Promise<any> {
        return this.delete(`${groupId}/invitation`)
            .catch((error) => {
                throw (error.response);
            });
    }

    public acceptInvitation(groupId: number, club: StM.IClubStoreState): Promise<StM.IGroupStoreState> {
        return this.post(`acceptInvite/${groupId}`)
            .then((response: any) => {
                let model = this.mapper.getGroupModelFromDto(<IGroupDto>response.data, club);
                return model;
            }).catch((error) => {
                throw (error.response);
            });
    }

    public getByInviteToken(inviteToken: string, club: StM.IClubStoreState): Promise<StM.IGroupStoreState> {
        return this.get('{0}/inviteToken'.format(inviteToken))
            .then((response: any) => {
                let model = this.mapper.getGroupModelFromDto(<IGroupDto>response.data, club);
                return model;
            }).catch((error) => {
                throw (error.response);
            });
    }

    public acceptInviteByInviteToken(inviteToken: string, club: StM.IClubStoreState): Promise<StM.IGroupStoreState> {
        return this.post('{0}/acceptInviteToken'.format(inviteToken))
            .then((response: any) => {
                let model = this.mapper.getGroupModelFromDto(<IGroupDto>response.data, club);
                return model;
            }).catch((error) => {
                throw (error.response);
            });
    }

    public rejectInviteByInviteToken(inviteToken: string, club: StM.IClubStoreState): Promise<StM.IGroupStoreState> {
        return this.delete(`${inviteToken}/rejectInviteToken`)
            .then((response: any) => {
                let model = this.mapper.getGroupModelFromDto(<IGroupDto>response.data, club);
                return model;
            }).catch((error) => {
                throw (error.response);
            });
    }

    public leaveGroup(groupId: number): Promise<void> {
        return this.delete(`/leave/${groupId}`)
            .catch((error) => {
                throw (error.response);
            });
    }

    public resendInvite(memberId: number): Promise<void> {
        return this.post(`/resendInvite/${memberId}`)
            .catch((error) => {
                throw error.response;
            })
    }
}
