import './switcher.scss';
import * as React from 'react';
import ClassNames from 'classnames';

interface ISwitcherProps {
    value: boolean;
    leftLabel?: string;
    rightLabel?: string;
    disabled?: boolean;
    inactive?: boolean;
    dataTestId?: string;
    onSwitch?: (value: boolean, e?: any) => void;
}

interface ISwitcherState {
    value: boolean;
}

export class Switcher extends React.Component<ISwitcherProps, ISwitcherState>{
    private defaultLeftLabel = 'Off';
    private defaultRightLabel = 'On';
    constructor(props: ISwitcherProps) {
        super(props);

        this.state = {
            value: false
        };
    }

    componentDidMount() {
        this.setState({ value: this.props.value });
    }

    componentDidUpdate(prevProps: ISwitcherProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({ value: this.props.value });
        }
    }

    render() {
        const switcherClasses = ClassNames('switcher', { disabled: this.props.disabled, inactive: this.props.inactive });
        const leftLabelClasses = ClassNames('switch-label left-label', { active: !this.state.value });
        const rightLabelClasses = ClassNames('switch-label right-label', { active: this.state.value });
        return (
            <div className={switcherClasses}>
                <div className={leftLabelClasses}>
                    <label onClick={(e) => this.onSwitch(e, false)}>{this.props.leftLabel || this.defaultLeftLabel}</label>
                </div>
                <label className="switch" data-testid={this.props.dataTestId}>
                    <input type="checkbox" onChange={(e) => this.handleSwitch(e)} checked={this.state.value} />
                    <div className="slider round"></div>
                </label>
                <div className={rightLabelClasses}>
                    <label onClick={(e) => this.onSwitch(e, true)}>{this.props.rightLabel || this.defaultRightLabel}</label>
                </div>
            </div>
        );
    }

    // handlers

    private onSwitch(e: any, value: boolean) {
        if(this.props.disabled) return null;
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.setState({ value });
        if(this.props.onSwitch) this.props.onSwitch(value, e);
    }

    private handleSwitch(e: any) {
        if(this.props.disabled) return null;
        this.setState({ value: !this.state.value });
        if(this.props.onSwitch) this.props.onSwitch(!this.state.value, e);
    }
}

export default Switcher;