import moment from 'moment';

import { IMembershipLevelStoreState } from './membershipLevelStoreState';
import { IGroupStoreState } from '../groups/groupStoreState';
import Constants from '../../../constants';

export interface IUserListStoreState {
    id: string;
    displayName: string;
    age: number;
    imageId: number;
    skill: string;
    hasPaymentInfo: boolean;
    email: string;
    emailConfirmed: boolean;
    dateOfBirth: moment.Moment;
    disabled: boolean;
    gender: string;
    discount: number;
    level: IMembershipLevelStoreState;
    membershipLevels: IMembershipLevelStoreState[];
    permissions: IPermissionListDto[];
    registrationDate: moment.Moment;
    creditsWallet: ICreditsWalletListDto;
    group: IGroupStoreState;
    state: string;
    outstandingBalance: number;
    groupId: number;
    membershipLevelLevelId: number;
    role: string;
    newsletterSubscribe: boolean;
}

export class UserListStoreState implements IUserListStoreState {
    id: string;
    displayName: string;
    age: number;
    imageId: number;
    skill: string;
    hasPaymentInfo: boolean;
    email: string;
    emailConfirmed: boolean;
    dateOfBirth: moment.Moment;
    disabled: boolean;
    gender: string;
    discount: number;
    level: IMembershipLevelStoreState;
    membershipLevels: IMembershipLevelStoreState[];
    permissions: IPermissionListDto[];
    registrationDate: moment.Moment;
    creditsWallet: ICreditsWalletListDto;
    group: IGroupStoreState;
    state: string;
    outstandingBalance: number;
    groupId: number;
    membershipLevelLevelId: number;
    role: string;
    newsletterSubscribe: boolean;

    constructor(dto?: IUserListDto) {
        this.id = dto && dto.id ? dto.id : '';
        this.displayName = dto && dto.displayName ? dto.displayName : '';
        this.imageId = dto && dto.imageId ? dto.imageId : 0;
        this.skill = dto && dto.skill ? dto.skill : '';
        this.hasPaymentInfo = dto && dto.hasPaymentInfo;
        this.email = dto && dto.email ? dto.email : '';
        this.emailConfirmed = dto && dto.emailConfirmed;
        this.dateOfBirth = dto && dto.dateOfBirth ? moment(dto.dateOfBirth, Constants.DateTime.API_FORMAT) : moment.min();
        this.disabled = dto && dto.disabled;
        this.gender = dto && dto.gender ? dto.gender : '';
        this.discount = dto && dto.discount ? dto.discount : 0;
        this.permissions = dto && dto.permissions ? dto.permissions : [];
        this.creditsWallet = dto && dto.creditsWallets && dto.creditsWallets.length ? dto.creditsWallets[0] : null;
        this.registrationDate = dto && dto.registrationDate ? moment(dto.registrationDate, Constants.DateTime.API_FORMAT) : null;
        this.state = dto && dto.state ? dto.state : '';
        this.outstandingBalance = dto && dto.outstandingBalance ? dto.outstandingBalance : 0;
        this.newsletterSubscribe = dto && dto.newsletterSubscribe;
        this.age = moment().diff(this.dateOfBirth, 'years');
        this.level = null;
        this.membershipLevels = [];
        this.group = null;
        this.role = dto && dto.role ? dto.role : '';
        this.groupId = dto && dto.groupId;
        this.membershipLevelLevelId = dto && dto.membershipLevelLevelId;
    }
}
