import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';
import * as StM from '../../store'; 

export interface IPrivacyPolicyDialogStoreState extends IBaseDialogStoreState {
}

export class PrivacyPolicyDialogStoreState extends BaseDialogStoreState implements IPrivacyPolicyDialogStoreState{
    constructor(){
        super();
    }
}