export class GroupEventDto implements IGroupEventDto{
    id: number;
    group: IGroupDto;
    user: IPublicUserDto;
    eventType: string;
    created: string;
    createdBy: IPublicUserDto;
    session: ISessionDto;
    booking: IBookingDto;
    package: IPackageDto;
    error: boolean;
    details: string;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.user = dto && dto.user ? dto.user : null;
        this.group = dto && dto.group ? dto.group : null;
        this.eventType = dto && dto.eventType ? dto.eventType : '';
        this.session = dto && dto.session ? dto.session : null;
        this.booking = dto && dto.booking ? dto.booking : null;
        this.package = dto && dto.package ? dto.package : null;
        this.error = dto && dto.error ? dto.error : false;
        this.created = dto && dto.created ? dto.created : '';
        this.createdBy = dto && dto.createdBy ? dto.createdBy : null;
    }
}