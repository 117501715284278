import * as Redux from 'redux';
import * as _ from 'lodash';

import { ThunkService } from '../services/thunkService';
import * as DtoModule from '../models/dto';
import * as StM from '../models/store';
import * as ActM from '../actions';

export class CoachAvailabilityActions {

    static setTime(timeItem: StM.ICoachAvailabilityTimeItem): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'CoachAvailability/SetTime',
                item: timeItem
            });
        };
    }

    static hoverTime(timeItem: StM.ICoachAvailabilityTimeItem): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'CoachAvailability/HoverTime',
                item: timeItem
            });
        };
    }

    static reset(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'CoachAvailability/Reset'
            });
        };
    }

    static markAsAvailable(coach: StM.ICoachStoreState, start: moment.Moment, duration: moment.Duration): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.markAsAvailable(coach, start, duration)
                .then((response: any) => {
                    dispatch(this.reset());
                    return response;
                })
                .catch((error) => {
                    dispatch(this.reset());
                });
        };
    }

    static markAsUnavailable(coach: StM.ICoachStoreState, start: moment.Moment, duration: moment.Duration): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            let store = <StM.IGlobalStoreState>getState();
            let club = store && store.club ? store.club : null;
            return thunkService.api.admin.markAsUnavailable(coach, start, duration)
                .then((response: any) => {
                    dispatch(this.reset());
                    return response;
                })
                .catch((error) => {
                    dispatch(this.reset());
                });
        };
    }

    static getRecurrentAvailableTimes(coachId: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<StM.IRecurrentAvailabilityStoreState>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.getRecurrentAvailableTimes(coachId)
                .then((response: Array<StM.IRecurrentAvailabilityStoreState>) => {
                    return response;
                })
        }
    }

    static deleteRecurrentAvailableTime(id: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.deleteRecurrentAvailableTime(id)
                .then((response: Array<StM.IRecurrentAvailabilityStoreState>) => {
                    return response;
                })
        }
    }

    static updateRecurrentAvailableTimes(records: Array<StM.IRecurrentAvailabilityStoreState>): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<StM.IRecurrentAvailabilityStoreState>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.updateRecurrentAvailableTimes(records)
                .then((response: Array<StM.IRecurrentAvailabilityStoreState>) => {
                    return response;
                })
        }
    }
}