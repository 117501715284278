import BaseReducer from './baseReducer';
import * as StM from '../models/store';

export default class ClubReducer extends BaseReducer {
     static handle(state: StM.IClubStoreState = new StM.ClubStoreState(), action: any): StM.IClubStoreState {
        switch (action.type) {
            case 'Club/Init': {
                const club = <StM.IClubStoreState>action.payload;
                return {...club};
            }
            default: {
                return state;
            }
        }
    }
}
