import moment from 'moment';
import * as ClubModule from '../clubs';
import { ICourtTimeBlockStoreState } from './courtTimeBlockStoreState';

export interface ICourtTimeSlotStoreState {
    court: ClubModule.ICourtStoreState;
    date: moment.Moment;
    start: moment.Duration;
    end: moment.Duration;
    sessions: Array<any>;
    timeBlocks: Array<ICourtTimeBlockStoreState>;
}

export class CourtTimeSlotStoreState implements ICourtTimeSlotStoreState {
    court: ClubModule.ICourtStoreState;
    date: moment.Moment;
    start: moment.Duration;
    end: moment.Duration;
    sessions: Array<any>;
    timeBlocks: Array<ICourtTimeBlockStoreState>;

    constructor() {
        this.court = new ClubModule.CourtStoreState();
        this.date = moment.min();
        this.start = moment.duration();
        this.end = moment.duration();
        this.sessions = new Array();
        this.timeBlocks = new Array();
    }
};
