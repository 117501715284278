import * as _ from 'lodash';

import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';

export default class AssignCoachFeeTierToCourtsDialogReducer extends BaseReducer {
    public static handle(state: StM.IAssignCoachFeeTierToCourtsDialogStoreState = new StM.AssignCoachFeeTierToCourtsDialogStoreState(), action: any): StM.IAssignCoachFeeTierToCourtsDialogStoreState {
        switch (action.type) {
            case 'Dialogs/Admin/AssignCoachFeeTierToCourts/Open': {
                return <StM.IAssignCoachFeeTierToCourtsDialogStoreState>_.assign({}, state, { isOpened: true, id: action.id });
            }
            case 'Dialogs/Admin/AssignCoachFeeTierToCourts/Close': {
                return <StM.IAssignCoachFeeTierToCourtsDialogStoreState>_.assign({}, state, { isOpened: false });
            }
            default: {
                return state;
            }
        }
    }
}
