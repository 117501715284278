import './packageBalanceItem.scss';
import * as React from 'react';
import * as _ from 'lodash';

import * as SrvM from '../../services';
import * as StM from '../../models/store'
import { ICustomSessionTypeStoreState } from '../../models/store'
import Credits from './credits';

interface IPackageBalanceItemProps {
    id?: number;
    credits: number; // shown in circle
    creditsBalance?: number; // shown below in remaining section
    isRemainingVisible?: boolean;
    title: string;
    description: string;
    sessionType: string;
    customSessionTypes: Array<ICustomSessionTypeStoreState>;
    alias?: string;
    coachFeeTier?: StM.ICoachFeeTierStoreState;
    enableAdjusting?: boolean;
    onBalanceChanged?: (id: number, credits: number) => void;
}

interface IPackageBalanceItemState {
    credits: number;
}

export default class PackageBalanceItem extends React.Component<IPackageBalanceItemProps, IPackageBalanceItemState> {
    private _integerPattern = '/^[0-9\b]+$/';
    private utils = new SrvM.Utils();

    constructor(props: IPackageBalanceItemProps) {
        super(props);
        this.state = {
            credits: 0
        }
    }

    componentDidMount() {
        this.init(this.props)
    }

    render() {
        const sessionType = this.props.sessionType;
        const tier = sessionType === StM.SessionType.Private && this.props.coachFeeTier ? this.props.coachFeeTier.title : '';
        let title = this.props.title;
        let description = this.props.description;

        if (tier) {
            description = description ? `${description}, ${tier}` : tier;
        }

        let creditsBgColor: string;
        let creditsTextColor: string;
        const customSessionType = _.find(this.props.customSessionTypes, { alias: this.props.alias });
        if (sessionType === StM.SessionType.Custom && customSessionType) {
            creditsBgColor = customSessionType.colorBackground;
            creditsTextColor = customSessionType.colorText;
        }

        return (<>
                <div className="package-balance-item-wrapper">
                    <div className="balance-wrapper">
                        <Credits credits={this.props.credits} type={sessionType} bgColor={creditsBgColor} txtColor={creditsTextColor}/>
                        <div className="title-wrapper">
                            <span className="credits-title">{title}</span>
                            <span className="desc">{description}</span>
                        </div>
                    </div>
                    {this.renderCreditsControls(this.state.credits)}
                </div>
                {this.props.isRemainingVisible && <div className={'credits--remaining ' + this.utils.getSessionClassByType(sessionType, customSessionType)}>
                  <div className={`title-wrapper`}>
                      {this.props.creditsBalance ? `${this.props.creditsBalance} remaining` : 'No credits left'}
                  </div>
                </div>}
            </>
        );
    }

    renderCreditsControls(credits: number) {
        if (!this.props.enableAdjusting) {
            return null;
        }
        return (
            <div className="credits-controls-wrapper">
                <button className="btn-black btn-default btn-credits" disabled={credits == 0}
                    onClick={(e) => credits > 0 && this.handleSubCreditsClick(e)}>-
                </button>
                <input className="credits-input"
                    type="number"
                    min={0}
                    step={1}
                    pattern={this._integerPattern}
                    value={credits}
                    onChange={(e) => this.handleCreditsChanging(e)}
                    onBlur={(e) => this.handleCreditsBlur(e)}
                />
                <button className="btn-black btn-default btn-credits" onClick={(e) => this.handleAddCreditsClick(e)}> +</button>
            </div>
        );
    }

    private init(props: IPackageBalanceItemProps) {
        this.setState({
            ...this.state,
            credits: props.credits
        });
    }

    //handlers
    private handleCreditsChanging(e: any) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (e.target.validity.valid) {
            let credits = e.target.value;
            credits = credits >= 0 ? credits : 0;
            this.updateCredits(credits);
        }
    }

    private handleCreditsBlur(e: any) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.updateCredits(+e.target.value);

    }

    private handleAddCreditsClick(e: any) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.updateCredits(this.state.credits + 1);
    }

    private handleSubCreditsClick(e: any) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        let credits = this.state.credits - 1;
        credits = credits >= 0 ? credits : 0;
        this.updateCredits(credits);
    }

    private updateCredits(credits: number) {
        this.setState(
            { ...this.state, credits },
            () => {
                if (this.props.onBalanceChanged) {
                    this.props.onBalanceChanged(this.props.id, credits);
                }
            }
        );
    }
}
