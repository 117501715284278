import * as Redux from 'redux';
import * as _ from 'lodash';

import {ThunkService} from '../services/thunkService';
import * as StoreModule from '../models/store';

export class PricingTiersActions {

    static init(pricingTiers: Array<StoreModule.IPricingTierStoreState>): (dispatch: any, getState: any, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'PricingTiers/Init',
                payload: pricingTiers
            });
        };
    }
}
