export class GroupDto implements IGroupDto{
    id: number;
    type: string;
    groupAccount: IPublicUserDto;
    groupAccountId: string;
    members: IGroupMemberDto[];
    club: IClubDto;
    title: string;
    description: string;
    notes: string;
    disabled: boolean;
    created: string;
    createdBy: IPublicUserDto;
    lastUpdated: string;
    lastUpdatedBy: IPublicUserDto;
    events: IGroupEventDto[];
    archived: boolean;
    outstandingBalance: number;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.type = dto && dto.type ? dto.type : '';
        this.groupAccount = dto && dto.groupAccount ? dto.groupAccount : null;
        this.groupAccountId = dto && dto.groupAccountId ? dto.groupAccountId : 0;
        this.members = dto && dto.members ? dto.members : [];
        this.club = dto && dto.club ? dto.club : null;
        this.title = dto && dto.title ? dto.title : '';
        this.description = dto && dto.description ? dto.description : '';
        this.notes = dto && dto.notes ? dto.notes : '';
        this.disabled = dto && dto.disabled ? dto.disabled : false;
        this.created = dto && dto.created ? dto.created : '';
        this.createdBy = dto && dto.createdBy ? dto.createdBy : null;
        this.lastUpdated = dto && dto.lastUpdated ? dto.lastUpdated : '';
        this.lastUpdatedBy = dto && dto.lastUpdatedBy ? dto.lastUpdatedBy : null;
        this.events = dto && dto.events ? dto.events : [];
        this.archived = dto && dto.archived ? dto.archived : false;
        this.outstandingBalance = dto && dto.outstandingBalance ? dto.outstandingBalance : 0;
    }
}