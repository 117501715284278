import { IBaseDialogStoreState, BaseDialogStoreState } from './baseDialogStoreState';

export class EditUserAdminDialogSections {
    public static Profile: string = 'profile';
    public static BillingInfo: string = 'billing-info';
    public static History: string = 'history';
    public static GroupMembers: string = 'group-members';
}

export interface IUserEditDialogStoreState extends IBaseDialogStoreState {
}

export class UserEditDialogStoreState extends BaseDialogStoreState {
    constructor() {
        super();
    }
}

export interface IAddUserDialogStoreState extends IBaseDialogStoreState {
}

export class AddUserDialogStoreState extends BaseDialogStoreState {
    constructor() {
        super();
    }
}

export interface IEditUserAdminDialogStoreState extends IBaseDialogStoreState {
    userId: string;
    sectionType: string;
}

export class EditUserAdminDialogStoreState extends BaseDialogStoreState {
    userId: string;
    sectionType: string;
    constructor() {
        super();
    }
}