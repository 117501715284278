import BaseReducer from './baseReducer';
import * as StM from '../models/store';

export default class UserReducer extends BaseReducer {
    static handle(state: StM.IUserStoreState = new StM.UserStoreState(), action: any): StM.IUserStoreState {
        switch (action.type) {
            case 'Users/Init': {
                const user = <StM.IUserStoreState>action.payload;
                return { ...user };
            }
            case 'Users/AcceptWaiver': {
                return { ...state, isAcceptedWaiver: true };
            }
            case 'Users/Clear': {
                return new StM.UserStoreState();
            }
            case 'Users/Group/Update': {
                return { ...state, group: action.payload } as StM.IUserStoreState;
            }
            case 'Users/CardValidation/Start': {
                return { ...state, isCreditCardValidating: true };
            }
            case 'Users/CardValidation/Stop': {
                return { ...state, isCreditCardValidating: false };
            }
            default: {
                return state;
            }
        }
    }

    static handleProfileCompletionConfirmation(state: boolean = false, action: any): boolean {
        switch (action.type) {
            case 'Users/SetProfileCompletionConfirmation': {
                return true;
            }
            case 'Users/ResetProfileCompletionConfirmation': {
                return false;
            }
            default: {
                return state;
            }
        }
    }
}
