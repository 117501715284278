import * as _ from 'lodash';
import { LogService } from './logService';
import * as ActM from '../actions';
import { store, history }  from '../store/store';
import { IDialogRoute, DialogRouteSettings, DialogHashParam }  from '../appSettings';

export class RouteDialogService {
    private static _instance: RouteDialogService;

    private logService: LogService;
    constructor() {
        if (typeof RouteDialogService._instance == "undefined") {
            RouteDialogService._instance = this;
            this.logService = new LogService();
        }
        return RouteDialogService._instance;
    }

    handleLocation(location: any, prevDialogsState?: any): any {
        const hash = location.hash;
        let nextDialogsState = this.parseHashAsDialogs(hash);

        this.trackChanges(prevDialogsState, nextDialogsState);

        return nextDialogsState;
    }

    getIsRouteDialog(dialogName: string): boolean {
        const routeDialogSetting = _.find(DialogRouteSettings, (dialogRouteSetting: IDialogRoute) => {
            return dialogRouteSetting.name.toUpperCase() === dialogName.toUpperCase();
        });
        return !!routeDialogSetting;
    }

    openRouteDialog(dialogName: string, options: any){
        const hash = window.location.hash;
        const dialogsData = this.parseHashAsDialogs(hash);
        const currentDialogData =  _.find(dialogsData, (dialogData: any) => {
            return dialogData.name.toUpperCase() === dialogName.toUpperCase();
        })
        if(currentDialogData){
        }
    }

    closeRouteDialog(dialogName: string){
    }

    getOpenDialogs() {
        return this.parseHashAsDialogs(window.location.hash) || [];
    }

    parseHashAsDialogs(hash: string): any{
        if(hash){
            const clearHash = hash.substring(1);
            const hashParams = clearHash.split('&');
            if(hashParams && hashParams.length > 0){
                const dialogHashes = _.filter(hashParams, (hashParam: string) => {
                    return hashParam && hashParam.indexOf(DialogHashParam) > -1;
                })
                if(dialogHashes && dialogHashes.length > 0){
                    const dialogDatas = _.map(dialogHashes, (dialogHash: string) => {
                        return this.parseHashAsDialog(dialogHash);
                    });
                    return dialogDatas;
                }
            }
        }
        return void 0;
    }

    private parseHashAsDialog(dialogHash: string): any{
        let dialogData: any = {};
        const dialogHashDatas = dialogHash.split('=');
        if(dialogHashDatas && dialogHashDatas.length > 0){
            const paramValue = dialogHashDatas[1];
            const dialogParams = paramValue.split('/');
            if(dialogParams && dialogParams.length > 0){
                const dialogName = dialogParams[0];
                const routeSettings = this.getDialogRouteSettings(dialogName);
                if(!dialogName || !routeSettings){
                    this.logService.error('Dialog Route Parse Error - Hash:{0} ### Route Settings: {1}'.format(JSON.stringify(dialogParams), JSON.stringify(routeSettings)));
                }

                dialogData.name = dialogName;
                for(let i=1; i < dialogParams.length; i++){
                    const routeSetting = routeSettings.params[i-1];
                    const routeValue = dialogParams[i];
                    const routeParam = this.getRouteParam(routeValue, routeSetting);

                    dialogData = _.assign({}, dialogData, routeParam);
                }
            }
        }

        return dialogData;
    }

    private getRouteParam(param: string, routeParamSettings: string): any{

        if(param){
            let paramValue: any;
            let paramKey = '';

            if(routeParamSettings.indexOf(':') > -1){
                const settingData = routeParamSettings.split(':');
                paramKey = settingData[0];
                const paramType = settingData[1];
                switch(paramType){
                    case 'number':{
                        paramValue = Number(param);
                        break;
                    }
                    case 'string':{
                        paramValue = param;
                        break;
                    }
                    case 'boolean':{
                        paramValue = param && param.toUpperCase() === 'TRUE';
                        break;
                    }
                    case 'moment':{
                        paramValue = param;
                        break;
                    }
                    default:{
                        paramValue = param;
                        break;
                    }
                }
            }else{
                paramKey = routeParamSettings;
                if(isNaN(<any>param)){
                    paramValue = param;
                }else{
                    paramValue = Number(param);
                }
            }
            let routeParam: any = {};
            routeParam[paramKey] = paramValue;
            return routeParam;
        }
        return void 0;
    }

    private getDialogRouteSettings(dialogName: string): IDialogRoute {
        const dialogRouteSettings = _.find(DialogRouteSettings, (dialogRoute: IDialogRoute) => {
            return dialogRoute.name.toUpperCase() === dialogName.toUpperCase();
        });

        return dialogRouteSettings;
    }

    private trackChanges(prevDialogsState: Array<any>, nextDialogsState: Array<any>){
        const isChanged = JSON.stringify(prevDialogsState || '').toUpperCase() !== JSON.stringify(nextDialogsState || '').toUpperCase();
        if(isChanged){
            _.each(nextDialogsState, (nextDialogRouteData: any) => {
                const foundPrevDialogRouteData = _.find(prevDialogsState, (prevDialogRouteData: any)=> {
                    return prevDialogRouteData.name.toUpperCase() === nextDialogRouteData.name.toUpperCase();
                });
                const isStateIsChanged = JSON.stringify(nextDialogRouteData||'').toUpperCase() !== JSON.stringify(foundPrevDialogRouteData||'').toUpperCase()
                if(!foundPrevDialogRouteData || isStateIsChanged){
                    this.openDialog(nextDialogRouteData);
                }
            });

            _.each(prevDialogsState, (prevDialogRouteData: any) => {
                const foundNextDialogRouteData = _.find(nextDialogsState, (nextDialogRouteData: any)=> {
                    return nextDialogRouteData.name.toUpperCase() === prevDialogRouteData.name.toUpperCase();
                });
                if(!foundNextDialogRouteData){
                    this.closeDialog(prevDialogRouteData);
                }
            });
        }
    }

    changeHashByOpeningDialog(dialogName: string, options: any){
        let hash = window.location.hash;
        let newHash = '';

        let dialogRouteData = this.getDialogRouteSettings(dialogName);
        if(hash){
            let isDialogAlreadyOpen = false;
            const clearHash = hash.substring(1);
            let hashParams = clearHash.split('&');
            hashParams = _.map(hashParams, (hashParam: string) => {
                const hashParamData = hashParam.split('/')[0].split('=');
                const dialogNameFromHashParam = hashParamData && hashParamData.length > 0 ? hashParamData[1] : '';
                //already opened
                if(dialogRouteData && dialogNameFromHashParam && dialogNameFromHashParam.toUpperCase() ===  dialogRouteData.name.toUpperCase() ){
                    isDialogAlreadyOpen = true;
                    let newDialogHashParam = this.getDialogHashParam(dialogRouteData, options);
                    return newDialogHashParam;
                }
                return hashParam;
            });

            if(!isDialogAlreadyOpen){
                let newDialogHashParam = this.getDialogHashParam(dialogRouteData, options);
                hashParams.push(newDialogHashParam);
            }

            newHash = hashParams.join('&');
        }else{
            newHash = this.getDialogHashParam(dialogRouteData, options);
        }
        if(newHash){
            hash = '#{0}'.format(newHash);
            window.location.hash = hash;
        }

        IS_IE && handleHashChanched();
    }
    
    getDialogHash(dialogName: string, options: any) {
        let dialogRouteData = this.getDialogRouteSettings(dialogName);
        return this.getDialogHashParam(dialogRouteData, options);
    }

    changeHashByClosingDialog(dialogName: string){
        let hash = window.location.hash;
        let newHash = '';

        let dialogRouteData = this.getDialogRouteSettings(dialogName);

        if(hash){
            const clearHash = hash.substring(1);
            let hashParams = clearHash.split('&');
            hashParams = _.filter(hashParams, (hashParam: string) => {
                if(hashParam.toLowerCase().indexOf(DialogHashParam) > -1){
                    let hashParamData = hashParam.split('/')[0].split('=');
                    let dialogNameFromHashParam = hashParamData && hashParamData.length >1 ? hashParamData[1]: '';
                    if(dialogNameFromHashParam.toUpperCase() ===  dialogRouteData.name.toUpperCase() ){
                        return false;
                    }
                }
                return true;
            });
            
            newHash = hashParams.join('&');
            if(newHash){
                hash = '#{0}'.format(newHash);
                window.location.hash = hash;
            }else{
                this.removeHash();
            }
            IS_IE && handleHashChanched();
        }
    }

    public closeOpenedDialogs() {
        const dialogs: any[] = this.getOpenDialogs();
        dialogs.forEach((dialog) => {
            store.dispatch(ActM.DialogActions.close(dialog.name))
        })
    }

    private getDialogHashParam(dialogRouteData: IDialogRoute, options: any){
        let newDialogHashParam = '{0}={1}'.format(DialogHashParam, dialogRouteData.name);
        _.each(dialogRouteData.params, (param) => {
            let paramSettingData = param.split(':');
            let paramSettingKey = paramSettingData[0] && paramSettingData.length > 0 ? paramSettingData[0] : '';
            if(paramSettingKey){
                let paramSettingValue = !options || !options[paramSettingKey] || options[paramSettingKey] === 0 ? '' : options[paramSettingKey];
                newDialogHashParam = '{0}/{1}'.format(newDialogHashParam, paramSettingValue)
            }
        });
        return newDialogHashParam.length > 50 ? newDialogHashParam : newDialogHashParam.toLowerCase();
    }

    private getDialogAsHashParam(dialogRouteData: any){
        let settings = this.getDialogRouteSettings(dialogRouteData.name);
    }

    private openDialog(dialogRouteData: any){
        setTimeout(() => {
            store.dispatch(ActM.DialogActions.open(dialogRouteData.name, dialogRouteData, true));
        }, 0);
    }

    private closeDialog(dialogRouteData: any){
        setTimeout(() => {
            store.dispatch(ActM.DialogActions.close(dialogRouteData.name, dialogRouteData));
        }, 0);
    }

    private removeHash () { 
        history.push('{0}{1}'.format(window.location.pathname, window.location.search));
    }
}
