export class ClubTimeDto implements IClubTimeDto {
        id: number;
        club: IClubDto;
        dayOfWeek: number;
        startTime: any;
        endTime: any;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.club = dto && dto.club ? dto.club : null;
        this.dayOfWeek = dto && dto.dayOfWeek ? dto.dayOfWeek : 0;
        this.startTime = dto && dto.startTime ? dto.startTime : '';
        this.endTime = dto && dto.endTime ? dto.endTime : '';
    }
}
