import moment from 'moment';

import { ActM, StM } from '../../modules';
import { ThunkService } from '../../services/thunkService';

export class MySessionsPageActions {
    static init(start?: moment.Moment, end?: moment.Moment)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {

            dispatch(ActM.AppActions.showSpinner());

            const currentDate = moment();
            const startDate = start ? start.clone() : currentDate.clone().startOf('day');
            const endDate = end ? end.clone() : startDate.clone().add(1, 'month');

            const final = () => {
                dispatch({ type: 'Pages/MySessions/Init', payload: null });
                dispatch(ActM.AppActions.hideSpinner());
                dispatch(ActM.BaseActions.hideSplash());
            }

            const resultP = getState().app.isAuthorized 
                ? Promise.resolve(dispatch(MySessionsPageActions.getSessions(startDate, endDate)))
                : null

            return Promise.resolve(resultP).then(() => {
                final();
            }).catch(() => {
                final();
            });
        }
    }

    static getSessions(start: moment.Moment, end: moment.Moment): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.session.getUserSessionsByPeriod(start, end, club, pricingTiers)
                .then((response: any) => {
                    const sessions = <Array<StM.ISessionStoreState>>response;
                    dispatch({
                        type: 'Pages/MySessions/Sessions',
                        payload: sessions
                    });
                });
        };
    }
}
