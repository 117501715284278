import moment from 'moment';
export interface IRoleDefinitionStoreState {
    name: string;
    permissions: Array<string>;
}

export class RoleDefinitionStoreState implements IRoleDefinitionStoreState {
    name: string;
    permissions: Array<string>;

    constructor(dto?: any) {
        this.name = dto && dto.name ? dto.name : '';
        this.permissions = dto && dto.permissions ? dto.permissions : [];
    }
}
