import * as redux from 'redux';
import * as StM from '../../models/store';

import BookPageReducer from './bookPageReducer';
import LeagueListViewPageReducer from './leagueListViewPageReducer';
import CoachPageReducer from './coachPageReducer';
import MySessionsPageReducer from './mySessionsPageReducer';
import EmailConfirmationPageReducer from './emailConfirmationPageReducer';
import ProfilePageReducer from './profilePageReducer';
import HistoryPageReducer from './historyPageReducer';
import SeekingPlayerBoardPageReducer from './seekingPlayerBoardPageReducer';
import PackagesPageReducer from './packagesPageReducer';
import ClubDashboardPageReducer from './clubDashboardPageReducer';
import ScoreboardPageReducer from './scoreboardPageReducer';
import VideoPageReducer from './videoPageReducer';

export const rootPagesReducer = redux.combineReducers<StM.IPagesStoreState>({
    book: BookPageReducer.handle
    , mySessions: MySessionsPageReducer.handle
    , leagues: LeagueListViewPageReducer.handle
    , history: HistoryPageReducer.handle
    , seekingPlayerBoard: SeekingPlayerBoardPageReducer.handle
    , packages: PackagesPageReducer.handle 
    , clubDashboard: ClubDashboardPageReducer.handle
    , scoreboard: ScoreboardPageReducer.handle
    , video: VideoPageReducer.handle
    , clinics: null
});