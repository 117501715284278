
import { ISessionStoreState } from './sessions/sessionStoreState';
import { IPackageDefinitionStoreState } from './packages/packageDefinitionStoreState';

export interface IBasketStoreState {
    goods: Array<ISessionStoreState>;
    packages: Array<IPackageDefinitionStoreState>;
    isShown: boolean;
}
export class BasketStoreState implements IBasketStoreState {
    goods: Array<ISessionStoreState>;
    packages: Array<IPackageDefinitionStoreState>;
    isShown: boolean = true;
    constructor(opt?: any) {
        this.goods = opt && opt.goods ? opt.goods : [];
        this.packages = opt && opt.packages ? opt.packages : [];
        this.isShown = opt && opt.isShown ? opt.isShown : true;
    }
}

export interface IBasketPrices {
    subtotalPrice: number;
    totalSalesTaxesPrice: number;
    totalServicesPrice: number;
    discountPrice: number;
    discountPercentage: number;
    totalPrice: number;
}
