export interface IBadgeTooltipStoreState {
    isShown: boolean;
    key: string;
    sessions?: Array<any>;
    targetRect?: any;
}
export class BadgeTooltipStoreState implements IBadgeTooltipStoreState {
    isShown: boolean = true;
    key: string = '';
    constructor(opt?: any) {
        this.isShown = opt && opt.isShown ? opt.isShown : false;
    }
}
