import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class SessionCreateSuccessDialogReducer extends BaseReducer{

    static handle(state: StM.ISessionCreateSuccessDialogStoreState = new StM.SessionCreateSuccessDialogStoreState(), action: any): StM.ISessionCreateSuccessDialogStoreState{
        switch (action.type) {
            case 'Dialogs/SessionCreateSuccess/Open': {
                return { ...state, isOpened: true, isExist: action.payload.isExist};
            }
            case 'Dialogs/SessionCreateSuccess/Close': {
                return {...state, isOpened: false, isExist: false};
            }
            default: {
                return state;
            }
        }
    }
}
