import moment from 'moment';

import { ISessionStoreState } from '../sessions';
import { IPublicUserStoreState } from '../users';
import { IPricingTierStoreState } from '../clubs';

export interface ICourtTimeBlockStoreState {
    key: string;
    date: moment.Moment;
    start: moment.Duration;
    end: moment.Duration;
    duration: number;
    courtId: number;
    session: ISessionStoreState;
    userInviter: IPublicUserStoreState;
    isPeak: boolean;

    price: number;
    credits: number;
    isAvailableTime: boolean;
    isFutureTime: boolean;
    isAvailableSession: boolean;
    isAvailableUser: boolean;
    isInvited: boolean;
    isBought: boolean;
    isOwner: boolean;
    isSessionFromBasket: boolean;
    isOpenBoard: boolean;
    isFilter: boolean;
    isOffset: boolean;
    isNotAvailable: boolean;
    isAvailableDoubleCreation: boolean;
    pricingTier?: IPricingTierStoreState;
}

export class CourtTimeBlockStoreState implements ICourtTimeBlockStoreState {
    key: string;
    date: moment.Moment;
    start: moment.Duration;
    end: moment.Duration;
    duration: number;
    courtId: number;
    session: ISessionStoreState;
    userInviter: IPublicUserStoreState;
    isPeak: boolean;

    price: number;
    credits: number;
    isAvailableTime: boolean;
    isFutureTime: boolean;
    isAvailableSession: boolean;
    isInvited: boolean;
    isAvailableUser: boolean;
    isBought: boolean;
    isOwner: boolean;
    isSessionFromBasket: boolean;
    isOpenBoard: boolean;
    isFilter: boolean;
    isOffset: boolean;
    isNotAvailable: boolean;
    isAvailableDoubleCreation: boolean;
    pricingTier?: IPricingTierStoreState;

    constructor() {
        this.key = '';
        this.date = moment();
        this.start = moment.duration();
        this.end = moment.duration();
        this.duration = 0;
        this.session = null;
        this.userInviter = null;
        this.isPeak = false;

        this.isAvailableTime = false;
        this.isFutureTime = false;
        this.isAvailableSession = false;
        this.isBought = false;
        this.isOwner = false;
        this.isSessionFromBasket = false;
        this.isOpenBoard = false;
        this.isFilter = false;
        this.isInvited = false;
        this.isOffset = false;
        this.isNotAvailable = false;
        this.pricingTier = null;
    }
};
