import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class ConfirmDeleteBasketItemDialogReducer extends BaseReducer{

    static handle(state: StM.IConfirmDeleteBasketItemStoreState= new StM.ConfirmDeleteBasketItemStoreState(), action: any): StM.IConfirmDeleteBasketItemStoreState{
        switch (action.type) {
            case 'Dialogs/ConfirmDeleteBasketItem/Open': {
                return <StM.IConfirmDeleteBasketItemStoreState>_.assign({}, state, { isOpened: true, basketIndex: action.basketIndex, isPackage: action.isPackage });
            }
            case 'Dialogs/ConfirmDeleteBasketItem/Close': {
                return <StM.IConfirmDeleteBasketItemStoreState>_.assign({}, state, { isOpened: false});
            }
            default: {
                return state;
            }
        }
    }
}
