import { ISessionStoreState } from './../models/store/sessions/sessionStoreState';
import * as Redux from 'redux';
import * as _ from 'lodash';

import {ThunkService} from '../services/thunkService';
import * as StoreModule from '../models/store';
import * as ActM from './';

export class BasketActions {

    static add(item: any): (dispatch: any, getState: () => StoreModule.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: !!getState().user.group && !!getState().user.group.id && !!item.addedUsers && !item.addedUsers.length ? 'Basket/Remove' : 'Basket/Add',
                item: item
            });

            dispatch({
                type: 'ValidatedBasket/Update',
                basket: getState().basket
            });
        };
    }

    static remove(id: any): (dispatch: any, getState: () => StoreModule.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Basket/Remove',
                id
            });

            dispatch({
                type: 'ValidatedBasket/Update',
                basket: getState().basket
            });
        };
    }

    static removePackage(id: any): (dispatch: any, getState: () => StoreModule.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Basket/RemovePackage',
                id
            });

            const { basket, validatedBasket } = getState() as StoreModule.IGlobalStoreState;
            dispatch({
                type: 'ValidatedBasket/Update',
                basket: {
                    ...basket,
                    goods: validatedBasket.goods,
                }
            });
        };
    }

    static replacePackages(items: Array<StoreModule.IPackageDefinitionStoreState>): (dispatch: any, getState: () => StoreModule.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Basket/ReplacePackages',
                items: items
            });

            dispatch({
                type: 'ValidatedBasket/Update',
                basket: getState().basket
            });
        };
    }

    static removeById(basketId: any): (dispatch: any, getState: () => StoreModule.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Basket/RemoveById',
                basketId: basketId
            });

            dispatch({
                type: 'ValidatedBasket/Update',
                basket: getState().basket
            });
        };
    }

    static edit(item: StoreModule.ISessionStoreState): (dispatch: any, getState: () => StoreModule.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: !!getState().user.group && !!getState().user.group.id && !!item.addedUsers && !item.addedUsers.length ? 'Basket/Remove' : 'Basket/Edit',
                item: item
            });

            dispatch({
                type: 'ValidatedBasket/Update',
                basket: getState().basket
            });

            dispatch({
                type: 'ValidatedBasket/Update',
                basket: getState().basket
            });
        };
    }

    static clear(): (dispatch: any, getState: () => StoreModule.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Basket/Clear'
            });

            dispatch({
                type: 'ValidatedBasket/Update',
                basket: getState().basket
            });
        };
    }

    static showToggle() {
         return (dispatch: any, getState: any, thunkService: ThunkService) => {
            let state = getState();
            if (state.notificationBoard.isShown) {
                dispatch({
                    type: 'Notification/Close', 
                });
            }
            dispatch(ActM.BaseActions.setContentHeight());
            dispatch({
                type: 'Basket/ShowToggle', 
            });
        };   
    }

    static close() {
         return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Basket/Close', 
            });
        };   
    }

    static update(sessions: Array<ISessionStoreState>): (dispatch: any, getState: () => StoreModule.IGlobalStoreState, thunkService: ThunkService) => void  {
         return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Basket/Update',
                payload: sessions
            });

            dispatch({
                type: 'ValidatedBasket/UpdateSessions',
                payload: sessions
            });
        };   
    }
}
