import * as StM from '../../store';
import moment from 'moment';
import Constants from '../../../constants';

export interface IGroupMemberStoreState {
    id: number;
    type: StM.GroupMemberType;
    user: StM.IPublicUserStoreState;
    group: StM.IGroupStoreState;
    invited: moment.Moment;
    invitedBy:  StM.IPublicUserStoreState;
    canLoginAsGroupAccount: boolean;
    notes: string;
    invitationStatus: StM.InvitationStatus;
    disabled: boolean;
    added: moment.Moment;
    addedBy: StM.IPublicUserStoreState;
    lastUpdated: moment.Moment;
    lastUpdatedBy: StM.IPublicUserStoreState;
    email: string;
    age: number;
    gender: string;
}

export class GroupMemberStoreState implements IGroupMemberStoreState  {
    id: number;
    type: StM.GroupMemberType;
    user: StM.IPublicUserStoreState;
    group: StM.IGroupStoreState;
    invited: moment.Moment;
    invitedBy:  StM.IPublicUserStoreState;
    canLoginAsGroupAccount: boolean;
    notes: string;
    invitationStatus: StM.InvitationStatus;
    disabled: boolean;
    added: moment.Moment;
    addedBy: StM.IPublicUserStoreState;
    lastUpdated: moment.Moment;
    lastUpdatedBy: StM.IPublicUserStoreState;
    email: string;
    age: number;
    gender: string;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.type = dto && dto.type ? dto.type : StM.GroupMemberType.Regular;
        this.group = dto && dto.group ? dto.group : null;
        this.invited = dto && dto.invited ? moment(dto.invited, Constants.DateTime.API_FORMAT) : moment.min();
        this.invitedBy = dto && dto.invitedBy ? dto.invitedBy : null;
        this.canLoginAsGroupAccount = dto && dto.canLoginAsGroupAccount ? dto.canLoginAsGroupAccount : false;
        this.notes = dto && dto.notes ? dto.notes : '';
        this.disabled = dto && dto.disabled ? dto.disabled : false;
        this.added = dto && dto.added ? moment(dto.added, Constants.DateTime.API_FORMAT) : moment.min();
        this.lastUpdated = dto && dto.lastUpdated ? moment(dto.startDate, Constants.DateTime.API_FORMAT) : moment.min();
        this.addedBy = dto && dto.addedBy ? dto.addedBy : null;
        this.lastUpdatedBy = dto && dto.lastUpdatedBy ? dto.lastUpdatedBy : null;
        this.invitationStatus = dto && dto.invitationStatus ? dto.invitationStatus : StM.InvitationStatus.Pending;
        this.email = dto && dto.email ? dto.email : '';
        this.age = dto && dto.age ? dto.age : 0;
        this.gender = dto && dto.gender ? dto.gender : '';
    }
}