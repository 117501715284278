import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class SessionInfoAdminDialogReducer extends BaseReducer{

    static handle(state: StM.ISessionInfoAdminDialogStoreState = new StM.SessionInfoAdminDialogStoreState(), action: any): StM.ISessionInfoAdminDialogStoreState {
        switch (action.type) {
            case 'Dialogs/Admin/Session/Session': {
                const session = <StM.ISessionStoreState>action.payload;
                return <StM.ISessionInfoAdminDialogStoreState>{... state, ...{ session: session}};
            }
            case 'Dialogs/Admin/Session/Open': {
                return <StM.ISessionInfoAdminDialogStoreState>{...state, ...{ 
                    isOpened: true,
                    sessionId: action.sessionId
                }};
            }
            case 'Dialogs/Admin/Session/Close': {
                return <StM.ISessionInfoAdminDialogStoreState>_.assign({}, state, { isOpened: false});
            }
            default: {
                return state;
            }
        }
    }
}
