import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';
import * as StM from '../../store'; 

export interface ISessionCreateSuccessDialogStoreState extends IBaseDialogStoreState{
    isExist: boolean;
}

export class SessionCreateSuccessDialogStoreState extends BaseDialogStoreState implements ISessionCreateSuccessDialogStoreState{
    isExist: boolean;
    constructor(){
        super();
        this.isExist = false;
    }
}