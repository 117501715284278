export class PricingTierPeriodDto implements IPricingTierPeriodDto {
    title: string;
    isReadOnly: boolean;
    start: any;
    end: any;
    isMonday: boolean;
    isTuesday: boolean;
    isWednesday: boolean;
    isThursday: boolean;
    isFriday: boolean;
    isSaturday: boolean;
    isSunday: boolean;

    constructor(dto?: any) {
        this.title = dto && dto.title ? dto.title : '';
        this.isReadOnly = dto && !!dto.isReadOnly;
        this.start = dto && dto.start ? dto.start : '';
        this.end = dto && dto.end ? dto.end : '';
        this.isMonday = dto && !!dto.isMonday;
        this.isTuesday = dto && !!dto.isTuesday;
        this.isWednesday = dto && !!dto.isWednesday;
        this.isThursday = dto && !!dto.isThursday;
        this.isFriday = dto && !!dto.isFriday;
        this.isSaturday = dto && !!dto.isSaturday;
        this.isSunday = dto && !!dto.isSunday;
    }
}
