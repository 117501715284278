export class UserRegistrationDto implements IUserRegistrationDto {
    userName: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    phone: string;
    dateOfBirth: string;
    gender: string;
    email: string;
    displayName: string;
    memberId: string;
    newsletterSubscribe: boolean;
    emergencyContactPhoneNumber: string;
    emergencyContactFullName: string;
    emergencyContactRelationship: string;
    isAvailableForInvitation: boolean;

    constructor(model?: any) {
        this.userName = model && model.userName ? model.userName : '';
        this.password = model && model.password ? model.password : '';
        this.confirmPassword = model && model.confirmPassword ? model.confirmPassword : '';
        this.firstName = model && model.firstName ? model.firstName : '';
        this.lastName = model && model.lastName ? model.lastName : '';
        this.phone = model && model.phone ? model.phone : '';
        this.dateOfBirth = model && model.dateOfBirth ? model.dateOfBirth : '';
        this.gender = model && model.gender ? model.gender : '';
        this.email = model && model.email ? model.email : '';
        this.displayName = model && model.displayName ? model.displayName : '';
        this.memberId = model && model.memberId ? model.memberId : '';
        this.newsletterSubscribe = model && model.newsletterSubscribe ? model.newsletterSubscribe : false;
        this.emergencyContactPhoneNumber = model && model.emergencyContactPhoneNumber ? model.emergencyContactPhoneNumber : '';
        this.emergencyContactFullName = model && model.emergencyContactFullName ? model.emergencyContactFullName : '';
        this.emergencyContactRelationship = model && model.emergencyContactRelationship ? model.emergencyContactRelationship : '';
        this.isAvailableForInvitation = model && model.isAvailableForInvitation ? model.isAvailableForInvitation : false;
    }
}