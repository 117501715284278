import * as _ from 'lodash';
import BaseReducer from './baseReducer';
import * as DtoModule from '../models/dto';
import * as StoreModule from '../models/store';

export default class NotificationReducer extends BaseReducer {
    static handle(state: StoreModule.NotificationBoardStoreState = new StoreModule.NotificationBoardStoreState(), action: any): StoreModule.NotificationBoardStoreState {
        switch (action.type) {
            case 'Notification/Init': {
                var coaches = <Array<StoreModule.INotificationStoreState>>action.payload;
                let notifications = NotificationReducer.updateArray<StoreModule.INotificationStoreState>(state.notifications, coaches);
                return <StoreModule.NotificationBoardStoreState>_.assign({}, state, { notifications: notifications });
            }
            case 'Notification/Clear': {
                return <StoreModule.NotificationBoardStoreState>_.assign({}, state, { notifications: [] });
            }
            case 'Notification/Dismiss': {
                let nId: number = action.payload;
                return <StoreModule.NotificationBoardStoreState>_.assign({}, state, { notifications: _.filter(state.notifications, (n) => n.id !== nId) });
            }
            case 'Notification/Remove': {
                let nId: number = action.payload;
                return <StoreModule.NotificationBoardStoreState>_.assign({}, state, { notifications: _.filter(state.notifications, (n) => n.id !== nId) });
            }
            case 'Notification/MarkAsRead': {
                _.find(state.notifications, (n) => n.id === action.payload).messageStatus = 'Watched';
                return <StoreModule.NotificationBoardStoreState>_.assign({}, state, { notifications: state.notifications });
            }
            case 'Notification/Toggle': {
                return <StoreModule.NotificationBoardStoreState>_.assign({}, state, { isShown: !state.isShown });
            }
            case 'Notification/Close': {
                return <StoreModule.NotificationBoardStoreState>_.assign({}, state, { isShown: false });
            }
            default: {
                return state;
            }
        }
    }
}
