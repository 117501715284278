import { ThunkService } from '../../services';
import * as StM from '../../models/store';
import * as ActM from '../';
import { SubscriptionOffersActions } from '../subscriptionOffersActions';
import { PackagesActions } from '../';

export class SubscriptionPurchaseDialogActions {

    static open(subscription: StM.ISubscriptionOfferStateModel, id: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Subscriptions/Purchase/Open',
                payload: { subscription, id }
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Subscriptions/Purchase/Close',
            });
        };
    }

    static checkout(subscription: StM.ISubscriptionOfferStateModel, params: any): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(ActM.AppActions.showSpinner());
            return thunkService.api.subscription.checkout(subscription)
                .then(() => {
                    const up = dispatch(ActM.UserActions.getCurrent());
                    const np = dispatch(ActM.NotificationActions.getForCurrentUser());
                    return Promise.all([up, np]);
                })
                .then(() => {
                    dispatch(ActM.AppActions.hideSpinner());
                    dispatch(PackagesActions.getCurrentSubscriptions());
                    dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, {
                        msgKey: StM.MessagesKey.CheckoutSuccess,
                        messageType: StM.MessageTypes.Success
                    }));
                })
                .catch((error: any) => {
                    if (error?.response?.data?.exceptionType?.indexOf('OutstandingBalanceException') >= 0) {
                        dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, {
                            msgKey: StM.MessagesKey.OutstandingBalanceError,
                            messageType: StM.MessageTypes.Error
                        }));
                    } else if (error) {
                        const message = error.response?.data?.message || error.message;
                        dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, {
                            msgKey: StM.MessagesKey.GenericRequestError,
                            messageType: StM.MessageTypes.Error,
                            message
                        }));
                    }
                    thunkService.logger.error(error);
                });
        };
    }
}
