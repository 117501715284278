import './menu.scss';

import * as React from 'react';
import ClassNames from 'classnames';

import { Utils } from '../../../services/utils';

interface IMenuProps {
    isOpen?: boolean;
    children?: React.ReactNode;
}

const utils = new Utils();

export const Menu = (props: IMenuProps) => {
    return (
        <ul className={`menu${utils.isMobile() ? '-mobile' : ''}`}>
            {props.children}
        </ul>
    );
}

interface IMenuItemProps {
    label?: string;
    collapse?: boolean;
    children?: React.ReactNode;
}

export const MenuItem = (props: IMenuItemProps) => {
    const [open, setOpen] = React.useState(false);
    const classes = ClassNames(`menu-item${utils.isMobile() ? '-mobile' : ''}`, { collapse: props.collapse, open: props.collapse && open });
    return (
        <li className={classes}>
            {props.collapse && 
                <a 
                    className={`menu-item-collapse-btn${utils.isMobile() ? '-mobile' : ''}`} 
                    onClick={(e) => { props.collapse && setOpen(!open); }} 
                    title={`${open ? 'Collapse' : 'Expand'} item`}
                >
                    {props.label}
                </a>
            }
            <div className={`menu-item${utils.isMobile() ? '-mobile' : ''}-content`}>{props.children}</div>
        </li>
    );
}