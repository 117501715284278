import BaseReducer from './baseReducer';
import * as StM from '../models/store';

export default class AddonsReducer extends BaseReducer {
    static handle(state: Array<StM.IAddonDefinitionStoreState> = new Array<StM.IAddonDefinitionStoreState>(), action: any): Array<StM.IAddonDefinitionStoreState> {
        switch (action.type) {
            case 'Addons/Init': {
                var payload = <Array<StM.IAddonDefinitionStoreState>>action.payload;
                return payload;
            }
            default: {
                return state;
            }
        }
    }
}
