import { BasketActions } from './../../actions/basketActions';
import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';

export default class LeagueRequestToJoinDialogReducer extends BaseReducer{

    static handle(state: StM.ILeagueRequestToJoinDialogStoreState = new StM.LeagueRequestToJoinDialogStoreState(), action: any): StM.ILeagueRequestToJoinDialogStoreState {
        switch (action.type) {
            case 'Dialogs/LeagueRequestToJoin/Open': {
                const sessionId = action.payload.sessionId;
                return {...state, ...{ isOpened: true, sessionId: sessionId}};
            }
            case 'Dialogs/LeagueRequestToJoin/Close': {
                return {...state, ...{ isOpened: false}};
            }
            default: {
                return state;
            }
        }
    }
}
