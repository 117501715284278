import { BaseDialogStoreState, IBaseDialogStoreState } from './baseDialogStoreState';

export interface IConfirmDialogStoreState extends IBaseDialogStoreState {
    msg: string;
    yesCallback: () => void;
    noCallback: () => void;
    yesBtnTxt?: string;
    noBtnTxt?: string;
    showExitBtn?: boolean;
}

export class ConfirmDialogStoreState extends BaseDialogStoreState implements ConfirmDialogStoreState {
    msg: string;
    yesCallback: () => void;
    noCallback: () => void;
    yesBtnTxt?: string;
    noBtnTxt?: string;
    showExitBtn?: boolean;
    constructor() {
        super();
        this.msg = '';
    }
}
