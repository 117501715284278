import * as DlgM from './';

export class AdminDialogNames {
    static NewSession: string = 'create-session';
    static TransactionRefund: string = 'refund-transaction';
    static SessionInfo: string = 'open-session';
    static AddUser: string = 'add-user';
    static EditUser: string = 'edit-user';
    static EditCoachFeeTier: string = 'edit-coach-fee-tier';
    static EditPackage: string = 'edit-package';
    static EditSubscription: string = 'edit-subscription';
    static SubscriptionHistory: string = 'subscription-history';
    static EditService: string = 'edit-service';
    static CreateNotification: string = 'create-notification';
    static AssignCoachFeeTierToCourts: string = 'assign-coach-fee-tier-to-courts';
    static EditGroup: string = 'edit-group';
    static PublishConfirmation: string = 'publish-confirmaton';
    static SubscriptionNotification: string = 'subscription-notification';
    static SubscribersList: string = 'subscribers-list';
}

export interface IAdminDialogsStoreState {
    сreateSession: DlgM.ICreateSessionAdminDialogStoreState;
    sessionInfo: DlgM.ISessionInfoAdminDialogStoreState;
    transactionRefund: DlgM.ITransactionRefundDialogStoreState;
    addUser: DlgM.IAddUserDialogStoreState;
    editUser: DlgM.IEditUserAdminDialogStoreState;
    editCoachFeeTier: DlgM.IEditCoachFeeTierDialogStoreState;
    editPackage: DlgM.IEditPackageDialogStoreState;
    editSubscription: DlgM.IEditSubscriptionDialogStoreState;
    subscriptionHistory: DlgM.ISubscriptionHistoryDialogStoreState;
    editAddon: DlgM.IEditAddonDialogStoreState;
    createNotification: DlgM.ICreateNotificationDialogStoreState;
    assignCoachFeeTierToCourts: DlgM.IAssignCoachFeeTierToCourtsDialogStoreState;
    editGroup: DlgM.IEditGroupDialogStoreState;
    publishConfirmation: DlgM.IPublishConfirmationDialogStoreState;
    subscriptionNotification: DlgM.ISubscriptionNotificationDialogStoreState;
    subscribersList: DlgM.ISubscribersListDialogStoreState;
}

export class AdminDialogsStoreState {
    сreateSession: DlgM.ICreateSessionAdminDialogStoreState;
    sessionInfo: DlgM.ISessionInfoAdminDialogStoreState;
    transactionRefund: DlgM.ITransactionRefundDialogStoreState;
    addUser: DlgM.IAddUserDialogStoreState;
    editUser: DlgM.IEditUserAdminDialogStoreState;
    editCoachFeeTier: DlgM.IEditCoachFeeTierDialogStoreState;
    editPackage: DlgM.IEditPackageDialogStoreState;
    editSubscription: DlgM.IEditSubscriptionDialogStoreState;
    subscriptionHistory: DlgM.ISubscriptionHistoryDialogStoreState;
    editAddon: DlgM.IEditAddonDialogStoreState;
    createNotification: DlgM.ICreateNotificationDialogStoreState;
    assignCoachFeeTierToCourts: DlgM.IAssignCoachFeeTierToCourtsDialogStoreState;
    editGroup: DlgM.IEditGroupDialogStoreState;
    publishConfirmation: DlgM.IPublishConfirmationDialogStoreState;
    subscriptionNotification: DlgM.ISubscriptionNotificationDialogStoreState;
    subscribersList: DlgM.ISubscribersListDialogStoreState;

    costructor() {
        this.сreateSession = new DlgM.CreateSessionAdminDialogStoreState();
        this.sessionInfo = new DlgM.SessionInfoAdminDialogStoreState();
        this.transactionRefund = new DlgM.TransactionRefundDialogStoreState();
        this.addUser = new DlgM.AddUserDialogStoreState();
        this.editUser = new DlgM.EditUserAdminDialogStoreState();
        this.editCoachFeeTier = new DlgM.EditCoachFeeTierDialogStoreState();
        this.editPackage = new DlgM.EditPackageDialogStoreState();
        this.editSubscription = new DlgM.EditSubscriptionDialogStoreState();
        this.subscriptionHistory = new DlgM.SubscriptionHistoryDialogStoreState();
        this.editAddon = new DlgM.EditAddonDialogStoreState();
        this.createNotification = new DlgM.CreateNotificationDialogStoreState();
        this.assignCoachFeeTierToCourts = new DlgM.AssignCoachFeeTierToCourtsDialogStoreState();
        this.editGroup = new DlgM.EditGroupDialogStoreState();
        this.publishConfirmation = new DlgM.PublishConfirmationDialogStoreState();  
        this.subscriptionNotification = new DlgM.SubscriptionNotificationDialogStoreState();
        this.subscribersList = new DlgM.SubscribersListDialogStoreState();
    }
}
