import * as _ from 'lodash';

import {ThunkService} from '../../services/thunkService';

export class CreateSessionAdminDialogActions {
    constructor() {

    }

    static open(courtId: number, timeKey: string, time: moment.Moment, sessionId?: number) {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Admin/CreateSession/Open',
                courtId: courtId,
                timeKey: timeKey,
                time: time,
                sessionId: sessionId
            });
        };
    }

    static close() {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Admin/CreateSession/Close',
            });
        };
    }
}
