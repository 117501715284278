export class CoachFeeTierPriceDto implements ICoachFeeTierPriceDto {
    id: number;
    coachFeeTierId: number;
    pricingTierId: number;
    clinicSessionPrice: number;
    playSessionPrice: number;
    privateLessonPrice: number;
    semiPrivateLessonPrice: number;
    groupLessonPrice: number;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.coachFeeTierId = dto && dto.coachFeeTierId ? dto.coachFeeTierId : 0;
        this.pricingTierId = dto && dto.pricingTierId ? dto.pricingTierId : 0;
        this.clinicSessionPrice = dto && dto.clinicSessionPrice ? dto.clinicSessionPrice : 0;
        this.clinicSessionPrice = dto && dto.clinicSessionPrice ? dto.clinicSessionPrice : 0;
        this.playSessionPrice = dto && dto.playSessionPrice ? dto.playSessionPrice : 0;
        this.privateLessonPrice = dto && dto.privateLessonPrice ? dto.privateLessonPrice : 0;
        this.semiPrivateLessonPrice = dto && dto.semiPrivateLessonPrice ? dto.semiPrivateLessonPrice : 0;
        this.groupLessonPrice = dto && dto.groupLessonPrice ? dto.groupLessonPrice : 0;
    }
}
