import { IClubStoreState } from './clubs';
import { PaymentSystem } from '../store';

export class Strings {
    public static AvailableForInvitationTitle: string = "Available for invitation";
    public static AvailableForInvitationExplanation: string = "Allow other players to invite you to join sessions";
    public static CheckInForVideoReminder: string = "Don't forget to check in at the front desk to start your recording.";
    public static VideoAnalysisNote: string = 'A coach will review the footage and schedule a time with you to provide feedback on your game.'
    public static PaidByOwnerNote: string = "You will pay the full session price. Other players won't be charged";
    public static SharedPriceNote: string = "This price will be reduced when your invitation is accepted";
    public static GroupPaidByOwnerNote: string = "The invited players will not share the cost with you. You will keep paying for the entire session";
    public static VideoSharedEmailSubject: string = "A video has been shared with you";
    public static InvitationEmailSubject: string = "You have been invited to a squash session";
    public static PaidByOwnerNoteForJoining: string = "Session is paid for by host. You will not be charged";
    public static SharedPriceNoteForJoining: string = "Payment will be shared between players";
    public static WaiverDialogTitle: string = "Release Waiver and Grant of Rights";
    public static NoInfo: string = "No Info";
    public static FreePrice: string = 'Free';
    public static Enabled: string = 'Enabled';
    public static Disabled: string = 'Disabled';
    public static CourtPrefix: string = 'Court';
    public static MemberTier: string = 'Member Tier';
    public static MakeGroupAdmin: string = 'Make Group Admin';
    public static RemoveAdminStatus: string = 'Remove Admin Status';
    public static EmptyItem: string = '—';
    public static Yes: string = 'Yes';
    public static No: string = 'No';
    public static EmailNotConfirmed: string = "The email address has not been confirmed";
    public static Cancel: string = 'Cancel';
    public static Administrator: string = 'Administrator';
    public static Closed: string = 'Closed';
    public static Active: string = 'Active';
    public static PasswordPlaceholder: string = '*****';
    public static Other: string = 'Other';
    public static Video: string = 'Video';
    public static UnathorizedAccessTitle: string = 'Please sign in to continue';
    public static SessionPrice: string = 'Session Price';
    public static ServicesPrice: string = 'Additional Services Price';
    public static VideoAnalysis = 'Video Analysis';
    public static SimpleSignUp = 'You already have an account on the Advantage Booking platform. Please enter your password to continue sign-up process.';
    public static UnPublishConfirmationMessage = 'This action does not remove subscribers from the offer. It hides the offer for new purchases.';
    public static SubscriptionCancellation = 'This subscription will no longer be available to new subscribers. Existing subscribers will lose access to this subscription only after the end of their billing cycle, after which the subscription will be archived.';
    public static SubscriptionArchivation = 'The subscription will be sent to archive.';

    public static BookingNote(club: IClubStoreState): string {
        const minimalCancellationPeriod = club.minimalCancellationPeriod;
        const isFeeInPercent = club.isLateCancellationFeeInPercent;
        const lateCancellationFee =
        isFeeInPercent
        ? club.lateCancellationFee === 100
            ? "Full price"
            : club.lateCancellationFee + '% of the price'
        : club.lateCancellationFee + '$';

        return `The Booking can be amended up to ${minimalCancellationPeriod} hours before its start. ${lateCancellationFee} will be charged for any cancellations within ${minimalCancellationPeriod} hours of the Session. If you have invited other player/s the amount you will be charged will be reduced when your invitation is accepted.`;
    }

    public static getCheckSpamMailMessage(club: IClubStoreState): string {
        const baseMessage = "Please check your inbox for your confirmation email and click the activation link. If you don't see it, check your Spam folder. This link expires in 24 hours, so activate your account today.";
        const welcomePrefix = club && club.title ? `Welcome to ${club.title}! ` : "";
        return `${welcomePrefix}${baseMessage}`;
    }

    public static getUnderageAlertMessage(club: IClubStoreState): string {
        if (!club) return null;
        return `To create an account for a child under the age of 18, we ask that a parent or legal guardian contact us at ${club.phone} or ${club.generalContactEmail} to sign our parental consent and verify all account information and permissions.`;
    }

    public static getReceiveNewsTitle(club: IClubStoreState) : string {
        if(!club) return null;
        return `Receive ${club.title} News`;
    }

    public static getCancellationNote(minimumNotificationPeriod: number): string {
        return `This cancellation will affect subscribers after ${minimumNotificationPeriod} days and notification has been sent to the subscribers.`;
    }

    public static getSubscriptionUpdateNote(minimumNotificationPeriod: number): string {
        return `Subscribers will have ${minimumNotificationPeriod} days to opt-out and a notification will be sent if they cancel their subscription.`;
    }

    public static PaymentSystems = new Map<PaymentSystem, string>([
        [PaymentSystem.AuthorizeNet, 'Authorize.Net'], 
        [PaymentSystem.Stripe, 'Stripe']
    ]);
}

export class ValidationMessages {
    public static Default: string = 'Please check your information and try again';
    public static AccountInfo: string = 'Please fill in all marked fields to continue';
    public static CoachAssignationError: string = 'Coach is not available for selected courts';
    public static PackageCoachTierValidationError: string = 'Coach Tier field is required, when any Clinic or Lesson credit is added';
}

export default Strings;
