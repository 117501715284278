import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';

export class ContactUsDialogActions {
    static open(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/ContactUs/Open',
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/ContactUs/Close',
            });
        };
    }

    static sendRequest(firstName: string, lastName: string, email: string, message: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<void>{
        
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.common.sendContacUsRequest(firstName, lastName, email, message)
                .then(() => {
                    return Promise.resolve();
                });
        };
    }

}
