import moment from 'moment';

import { IAuthenticationTicketDto } from '../../models/dto/users';
import { IAuthenticationTicketStoreState } from '../../models/store';
import { Constants, DtoM, StM } from '../../modules';
import { BaseApiService } from '../baseApiService';

export class AccountApiService extends BaseApiService {
    private static _instance: AccountApiService;
    constructor(config?: any) {
        if (typeof AccountApiService._instance == "undefined") {
            super(config);
            AccountApiService._instance = this;
        }
        return AccountApiService._instance;
    }

    public getIsUniqueEmail(email: string): Promise<boolean> {
        const handledEmail = this.encodeUriParam(email);
        return this.get('/is-unique-email?email={0}'.format(handledEmail))
            .then((response: any) => {
                const isUniqueEmail = <boolean>response.data;
                return isUniqueEmail;
            });
    }

    public getIsSharedEmail(email: string): Promise<boolean> {
        const handledEmail = this.encodeUriParam(email);
        return this.get('/is-shared-email?email={0}'.format(handledEmail))
            .then((response: any) => {
                const isSharedEmail = <boolean>response.data;
                return isSharedEmail;
            });
    }

    public signUp(firstName: string, lastName: string, email: string, password: string, confirmPassword: string, 
        newsletterSubscribe: boolean, dateOfBirth: moment.Moment, gender: string, phone: string, isAvailableForInvitation: boolean): Promise<StM.IAuthenticationTicketStoreState> {
        const dto = new DtoM.UserRegistrationDto({
            firstName: firstName
            , lastName: lastName
            , email: email
            , password: password
            , confirmPassword: confirmPassword
            , newsletterSubscribe: newsletterSubscribe
            , dateOfBirth: dateOfBirth?.format(Constants.DateTime.DATE_FORMAT)
            , gender: gender
            , phone: phone
            , isAvailableForInvitation: isAvailableForInvitation
        });

        return this.post('/registration', dto)
            .then((response: { data: IAuthenticationTicketDto }) => {
                const ticket: any = response.data;
                const model: StM.IAuthenticationTicketStoreState = ticket;
                return model;
            });
    }

    public getSharedUser(email: string, password: string): Promise<IUserRegistrationDto> {
        const handledEmail = this.encodeUriParam(email);
        const handledPassword = this.encodeUriParam(password);
        return this.get('/shared-user?email={0}&password={1}'.format(handledEmail, handledPassword))
            .then((response: {data: IUserRegistrationDto}) => {
            const user: any = response.data;
            const model: IUserRegistrationDto = user;
            return model;
        });
    }

    public sendPasswordResetEmail(email: string): Promise<void> {
        return this.post('/password-reset-email', <IPasswordResetRequestDto>{ email: email });
    }

    public recoveryPassword(email: string, password: string, hash: string): Promise<void> {
        return this.post('/password-recovery', <IPasswordRecoveryDto>{
            email: email
            , password: password
            , hash: hash
        });
    }

    public changePassword(oldPassword: string, newPassword: string): Promise<void> {
        return this.post('/password-changing', <IPasswordChangingDto>{
            oldPassword: oldPassword
            , newPassword: newPassword
        });
    }

    public resendValidationEmail(email: string): Promise<void> {
        return this.post('/validation-email', <IValidationEmailResendingDto>{
            email: email
        });
    }

    public signInOnBehalf(userId: string, asAdmin: boolean = false): Promise<StM.IAuthenticationTicketStoreState> {
        return this.post('/signInOnBehalf/{0}/{1}'.format(userId, asAdmin))
        .then((response: { data: IAuthenticationTicketDto }) => {
            const ticket: any = response.data;
            const model: StM.IAuthenticationTicketStoreState = ticket;
            return model;
        });
    }

    public revertToSelf(): Promise<StM.IAuthenticationTicketStoreState> {
        return this.post('/revertToSelf')
            .then((response: {data: IAuthenticationTicketDto }) => {
                const ticket: any = response.data;
                return ticket as IAuthenticationTicketStoreState;
            });
    }
}
