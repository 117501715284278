import {IMembershipLevelStoreState, MembershipLevelStoreState} from '../models/store';
import * as _ from 'lodash';

export default class MembershipLevelsReducer {
    static handle(state: Array<IMembershipLevelStoreState> = new Array<IMembershipLevelStoreState>(), action: any): Array<IMembershipLevelStoreState> {
        switch (action.type) {
            case 'MembershipLevels/Init': {
                const levels = <Array<IMembershipLevelStoreState>>action.payload;
                return <Array<IMembershipLevelStoreState>>_.assign([], levels);
            }
            case 'MembershipLevels/Clear': {
                return new Array<IMembershipLevelStoreState>();
            }
            default: {
                return state;
            }
        }
    }
}
