import '../containers/dialogs/dialogs.scss';

import * as React from 'react';
import * as _ from 'lodash';
import ClassNames from 'classnames';

import { DialogHashParam } from '../appSettings';
import * as SrvM from '../services';
import Footer from '../containers/footer.mobile';

interface IModalProps {
    dialogName: string;
    classes?: string;
    style?: any;
    customZIndex?: number;
    size?: 'small';
    showMobileHeader?: boolean;

    closeHandler?: (e?: any) => void;
    children?: React.ReactNode;
}

interface IModalState { 
    isOverlayClicked: boolean;
}

export class Modal extends React.Component<IModalProps, IModalState> {
    private BaseZIndex = 999;
    private isOpen: boolean = false;
    private _DOM: HTMLDivElement = null;
    private _LAY: HTMLDivElement = null;
    private utils = new SrvM.Utils();

    constructor(props: IModalProps) {
        super(props);
        this.isOpen = false;

        this.state = {
            isOverlayClicked: false,
        }
    }

    public componentWillUnmount() {
        if (!this.utils.isMobile()) {
            this.removeDocumentClickHandler();
        }
    }

    public componentDidMount() {
        if (!this.utils.isMobile()) {
            this.updateDialogPositioning();
        }
    }

    public render() {
        if (this.utils.isMobile()) return this.renderMobileDialog();
        const order = this.getDialogOrder();
        const sizeClass = this.props.size ? `dialog-${this.props.size}` : '';
        const classes = ClassNames("modal-dialog-wrapper", "modal-dialog-wrapper-custom", this.props.classes, this.props.dialogName, sizeClass);
        var zIndexStyleNew = this.props.customZIndex ? this.props.customZIndex : this.BaseZIndex + order;
        /** @type {{search: React.CSSProperties}} */
        let styleOverlay = { zIndex: zIndexStyleNew, height: document.getElementById("root").clientHeight + "px", position: "initial" as "initial" | "absolute" | "fixed" };
        let dialogStyles = {
            ...this.props.style,
            top: 0
        };
        if (this.utils.getIsTouchDetected()) {
            styleOverlay = { zIndex: zIndexStyleNew, height: document.getElementById("root").clientHeight + "px", position: "absolute" as "initial" | "absolute" | "fixed" };
            if (!this.isOpen) {
                dialogStyles.top = (window.pageYOffset || document.documentElement.scrollTop) + 50 + "px";
                this.isOpen = true;
            }
        } else {
            styleOverlay = { zIndex: zIndexStyleNew, height: "", position: "fixed" as "initial" | "absolute" | "fixed" };
            if (!this.isOpen) {
                dialogStyles.top = this._DOM ? Math.max(0, (document.documentElement.clientHeight - this._DOM.clientHeight) / 2) + "px" : 0;
                this.isOpen = true;
            }
        }

        return (
            <div className={"overlay"} ref={(elem) => this.setHandlers(elem)} style={styleOverlay}>
                <div className="dialog-position-relative-wrapper">
                    <div className={classes} style={dialogStyles} ref={(elem) => this._DOM = elem}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }

    private renderMobileDialog() {
        const order = this.getDialogOrder();
        const modalClasses = ClassNames("modal-mobile", { "full-screen": this.props.showMobileHeader != undefined && !this.props.showMobileHeader });
        const classes = ClassNames("modal-mobile-dialog-wrapper", this.props.classes);
        var zIndexStyle = this.props.customZIndex ? { zIndex: this.props.customZIndex } : { zIndex: this.BaseZIndex + order };

        return (
            <div className={modalClasses} style={zIndexStyle}>
                <div className={classes} ref={(elem) => this._DOM = elem}>
                    <div className="modal-dialog-content-mobile">
                        {this.props.children}
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }

    private updateDialogPositioning() {
        let dialogWrapper: any = document.getElementsByClassName('modal-dialog-wrapper-custom ' + this.props.dialogName)[0];
        let dialogHeight = dialogWrapper.clientHeight;
        let viewPortHeight = document.documentElement.clientHeight;
        let topPos = 0;

        if (this.utils.getIsTouchDetected()) {
            topPos = (viewPortHeight - dialogHeight) / 2 + (window.pageYOffset || document.documentElement.scrollTop) + 50;
        } else {
            topPos = this._DOM ? Math.max(0, (document.documentElement.clientHeight - this._DOM.clientHeight) / 2) : 0;
        }

        dialogWrapper.style.top = `${topPos}px`;
    }

    private handleDocumentClick = (evt: any) => {
        if (!!this && !!this._DOM && !!this._DOM.contains && !this._DOM.contains(evt.target) && this.state.isOverlayClicked) {
            if (this.props.closeHandler) {
                this.props.closeHandler();
            }
            this.setState({...this.state, isOverlayClicked: false});
        }
    }

    private handleDocumentMouseDown = (e: any) => {
        if(!!this && !!this._DOM && !!this._DOM.contains && !this._DOM.contains(e.target)) {
            this.setState({...this.state, isOverlayClicked: true})
        }
    }

    private setDocumentClickHandler() {
        if (!!this._LAY) {
            this._LAY.addEventListener('click', (e) => this.handleDocumentClick(e));
            this._LAY.addEventListener('mousedown', (e) => this.handleDocumentMouseDown(e))
        }
    }

    private removeDocumentClickHandler() {
        if (!!this._LAY) {
            this._LAY.removeEventListener('click', (e) => this.handleDocumentClick(e));
            this._LAY.removeEventListener('mousedown', (e) => this.handleDocumentMouseDown(e));
        }
    }

    private setHandlers(elem: HTMLDivElement) {
        this._LAY = elem;
        if (!elem) return false;
        this.setDocumentClickHandler();
    }

    private getDialogOrder() {
        const routeData = window.location.href.split('#');
        const dialogData = routeData && routeData.length > 1 ? routeData[1].split('&') : [];
        const dialogs = _.filter(dialogData, (dialogData: string) => dialogData.indexOf(DialogHashParam) > -1);
        const currentDialogRouteString = `${DialogHashParam}=${this.props.dialogName}`;
        const currentDialogRoute = _.find(dialogs, (dialog: string) => dialog.indexOf(currentDialogRouteString) > -1);
        let order = dialogs.indexOf(currentDialogRoute);
        order = order > -1 ? order : 0;
        return order;
    }
}