export interface ISubscriptionStoreState {
    offerId: number;
    currentVersion: number;
    title: string;
    description: string;
    price: number;
    billingPeriod: string;
    mandatoryIntroPeriod: number;
    coachFeeTierId: number;
    creditsDefinitions: IRecurrentPackageCreditsDefinitionDto[];
    createdAt: string;
    createdById: string;
    createdByDisplayName: string;
    status: string;
    discount: number;
    daysInAdvance: number;
}

export class SubscriptionStoreState implements ISubscriptionStoreState  {
    offerId: number;
    currentVersion: number;
    title: string;
    description: string;
    price: number;
    billingPeriod: string;
    mandatoryIntroPeriod: number;
    coachFeeTierId: number;
    creditsDefinitions: IRecurrentPackageCreditsDefinitionDto[];
    createdAt: string;
    createdById: string;
    createdByDisplayName: string;
    status: string;
    discount: number;
    daysInAdvance: number;

    constructor(dto?: any) {
        this.title = dto?.title ?? '';
        this.description = dto?.description ?? '';
        this.price = dto?.price ?? 0;
        this.billingPeriod = dto?.billingPeriod ?? '';
        this.mandatoryIntroPeriod = dto?.mandatoryIntroPeriod ?? 0;
        this.coachFeeTierId = dto?.coachFeeTierId ?? 0;
        this.creditsDefinitions = dto?.creditsDefinitions ?? [];
        this.offerId = dto?.offerId ?? 0;
        this.currentVersion = dto?.currentVersion ?? 0;
        this.createdAt = dto?.createdAt ?? '';
        this.createdById = dto?.createdById ?? '';
        this.createdByDisplayName = dto?.createdByDisplayName ?? '';
        this.status = dto?.status ?? '';
        this.discount = dto?.discount ?? 0;
        this.daysInAdvance = dto?.daysInAdvance ?? 0;
    }
}