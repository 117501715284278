import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';
import * as StM from '../../store'; 

export interface IWaiverDialogStoreState extends IBaseDialogStoreState {
    isAccepted: boolean;
}

export class WaiverDialogStoreState extends BaseDialogStoreState implements IWaiverDialogStoreState{
    isAccepted: boolean;
    constructor(){
        super();
        this.isAccepted = false;
    }
}