import * as StM from '../../models/store';

export default class ScoreboardPageReducer {

    static handle(state: StM.IScoreboardPageStoreState = new StM.ScoreboardPageStoreState(), action: any): StM.IScoreboardPageStoreState {
        switch (action.type) {
            case 'Pages/Scoreboard/Init': {
                return state;
            }
            case 'Pages/Scoreboard/Sessions': {
                let sessions = <Array<StM.ISessionStoreState>>action.payload;
                return { ...state, sessions: sessions };
            }
            default: {
                return state;
            }
        }
    }
}
