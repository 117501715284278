import * as StM from '../models/store';
import BaseReducer from './baseReducer';
import * as _ from 'lodash';

export default class CoachAvailabilityReducer {
    static handle(state: StM.ICoachAvailabilityStoreState = new StM.CoachAvailabilityStoreState(), action: any): StM.ICoachAvailabilityStoreState {
        switch (action.type) {
            case 'CoachAvailability/SetTime': {
                let timesRange = [...state.range];
                let hoverItem = state.hoverItem;
                switch (state.range.length) {
                    case 0:
                        if (action.item) timesRange.push(action.item);
                        break;
                    case 1:
                        if (!action.item && hoverItem) {
                            timesRange.push(hoverItem);
                        } else if (action.item && timesRange[0].startTime.diff(action.item.endTime, 'milliseconds') == 0
                            && action.item.startTime.diff(timesRange[0].endTime, 'milliseconds') == 0) {
                            timesRange = [];
                            hoverItem = null;
                        } else if(action.item) {
                            timesRange.push(action.item);
                        } else {
                            timesRange = [];
                            hoverItem = null;
                        }
                        break;
                    case 2:
                    default:
                        if (action.item) {
                            timesRange = [action.item];
                            hoverItem = null;
                        }
                        break;
                }
                return <StM.ICoachAvailabilityStoreState>_.assign({}, state, {
                    range: timesRange,
                    hoverItem: hoverItem
                });
            }
            case 'CoachAvailability/HoverTime': {
                if (state.range.length < 1 || state.range.length > 2) {
                    return state;
                }
                return <StM.ICoachAvailabilityStoreState>_.assign({}, state, {
                    hoverItem: action.item
                });
            }
            case 'CoachAvailability/Reset': {
                return <StM.ICoachAvailabilityStoreState>_.assign({}, state, {
                    hoverItem: null,
                    range: []
                });
            }
            default: {
                return state;
            }
        }
    }
}