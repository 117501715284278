import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';
import * as StM from '../../store'; 

export interface IPackageAddedDialogStoreState extends IBaseDialogStoreState{}

export class PackageAddedDialogStoreState extends BaseDialogStoreState implements IPackageAddedDialogStoreState{
    isExist: boolean;
    constructor(){
        super()
    }
}