export interface IStaticPageStoreState {
    id: number;
    name: string;
    title: string;
    content: string;
}

export class StaticPageStoreState implements IStaticPageStoreState {
    id: number;
    name: string;
    title: string;
    content: string;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : '';
        this.name = dto && dto.name ? dto.name : '';
        this.title = dto && dto.title ? dto.title : '';
        this.content = dto && dto.content ? dto.content : '';
    }
}
