import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';
import * as ActM from '../';

export class UserEditDialogActions {
    static open(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/UserEdit/Open',
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/UserEdit/Close',
            });
        };
    }

    static save(profile: StM.IUserManagableStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any>{
        
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.user.updateProfile(profile)
            .then(() => {
                return dispatch(ActM.UserActions.getCurrent());
            })
            .catch((error: any) => {
                throw error.response.data.exceptionMessage ? error.response.data.exceptionMessage : 'Error submiting your request';
            });
        };
    }
}
