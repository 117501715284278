import * as StM from '../../../store';

export class IBookPageRouteParams {
    sessionType: string;
    date: string;
    playSubfilter: string;
    lessonSubfilter: string;
    lessonSubfilterId: string;
    clinicSubfilter: string;
    clinicSubfilterId: string;
    otherSubfilter: string;
    memberId: string;
}

export class IBookPageRouteParamsMatch {
    isExact: boolean;
    path: string;
    url: string;
    params: IBookPageRouteParams;
}

export class BookPageRouteParams implements IBookPageRouteParams {
    sessionType: string;
    date: string;
    playSubfilter: string;
    lessonSubfilter: string;
    lessonSubfilterId: string;
    clinicSubfilter: string;
    clinicSubfilterId: string;
    otherSubfilter: string;
    memberId: string;
}

export class BookPageSessionType {
    static Play: string = 'play';
    static Clinic: string = 'clinic';
    static Lesson: string = 'lesson';
    static League: string = 'league';
    static Custom: string = 'custom';
    static Join: string = 'custom';
    static Availability: string = 'availability';
    static Group: string = 'group';
}

export class BookPagePlaySessionVisibility {
    static OnlyMySessions: string = 'only-my-sessions';
    static AllSessions: string = 'all-sessions';
}

export class BookPageLessonFilterType {
    static Coach: string = 'coach';
}

export class BookPageFilterTypeDefault {
    static SubFilter: string = 'no-subfilter';
    static SubFilterId: string = 'no-id';
}

export interface IBookPageStoreState {
    sessions: Array<StM.ISessionStoreState>;
    clinics: Array<StM.ISessionStoreState>;
    availableTimes: Array<StM.IAvailableTimeStoreState>;
    availableTimesLookup: IDictionary;
    timeSlots: Array<StM.ICourtTimeSlotStoreState>;
    refresh: boolean;
}

export class BookPageStoreState implements IBookPageStoreState {
    sessions: Array<StM.ISessionStoreState>;
    availableTimes: Array<StM.IAvailableTimeStoreState>;
    clinics: Array<StM.ISessionStoreState>;
    availableTimesLookup: IDictionary;
    timeSlots: Array<StM.ICourtTimeSlotStoreState>;
    refresh: boolean;
    constructor() {
        this.sessions = new Array();
        this.clinics = new Array();
        this.availableTimes = new Array();
        this.timeSlots = new Array();
        this.availableTimesLookup = {};
        this.refresh = false;
    }
}

export interface IBookPageParams {
    path: string;
    filter: IBookPageRouteParams;
}

export class BookPagePrams implements IBookPageParams {
    public path: string;
    public filter: IBookPageRouteParams;

    constructor(path: string = '', filter: IBookPageRouteParams = null) {
        this.path = path;
        this.filter = filter;
    }
}
