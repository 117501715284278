import moment from 'moment';
import * as _ from 'lodash';

import { SrvM, StM, Constants } from '../modules';
import { DialogHashParam } from '../appSettings';

export interface IDialogRoute {
    name: string;
    params: Array<string>;
}

export class RouteService {
    private static _instance: RouteService;

    private utils: SrvM.Utils;

    constructor() {
        if (typeof RouteService._instance == "undefined") {
            RouteService._instance = this;
            this.utils = new SrvM.Utils();
        }
        return RouteService._instance;
    }

    public getSwitchClubRoute(club: StM.IClubStoreState, token: string): string {
        return `${club.url}/switchclub/${token}`;
    }

    public getBookTodayRoute(): string {
        return `/book/${StM.BookPageSessionType.Play}/${Constants.TodayRouteParam}/${StM.BookPagePlaySessionVisibility.AllSessions}${window.location.hash}`;
    }

    public getHistoryDefaultRoute(club: StM.IClubStoreState): string {
        const utcOffsetValue = this.utils.getClubUtcOffset(club);
        const today = moment().utcOffset(utcOffsetValue);
        const month = today.clone().add(-1, 'months')
        return '{0}/{1}/{2}/{3}'.format(
            StM.Pages.User
            , StM.UserPages.History
            , month.format(Constants.DateTime.DATE_FORMAT)
            , today.format(Constants.DateTime.DATE_FORMAT)
        );
    }

    public getMySessionsDefaultRoute(club: StM.IClubStoreState, location?: any): string {
        const utcOffsetValue = this.utils.getClubUtcOffset(club);
        const today = moment().utcOffset(utcOffsetValue);
        const month = today.clone().add(1, 'months');
        const todayString = today.format(Constants.DateTime.DATE_FORMAT);
        const monthString = month.format(Constants.DateTime.DATE_FORMAT);
        return `${StM.Pages.MySessions}/${todayString}/${monthString}${location ? location.hash: ''}`;
    }

    public getTransactionsDefaultRoute(club: StM.IClubStoreState): string {
        const utcOffsetValue = this.utils.getClubUtcOffset(club);
        const today = moment().utcOffset(utcOffsetValue);
        const month = today.clone().add(-1, 'weeks')
        return '/{0}/{1}/{2}/{3}'.format(
            StM.AdminPages.Admin
            , StM.AdminPages.Transactions
            , month.format(Constants.DateTime.DATE_FORMAT)
            , today.format(Constants.DateTime.DATE_FORMAT)
        );
    }

    public getBookPage(
        filter: StM.IBookPageRouteParams
        , defaultCoach?: StM.ICoachStoreState
        , defaultMember?: StM.IGroupMemberStoreState
    ): StM.IBookPageParams {
        const handledSessionType = filter && filter.sessionType ? filter.sessionType.toLowerCase() : StM.BookPageSessionType.Play;
        const handledDate = filter && filter.date ? filter.date.toLowerCase() : Constants.TodayRouteParam;
        const playSubfilter = filter && filter.playSubfilter ? filter.playSubfilter.toLowerCase() : StM.BookPagePlaySessionVisibility.AllSessions;
        const lessonSubfilter = filter && filter.lessonSubfilter ? filter.lessonSubfilter.toLowerCase() : '';
        let lessonSubfilterId = filter && filter.lessonSubfilterId ? filter.lessonSubfilterId : '';
        const clinicSubfilter = filter && filter.clinicSubfilter ? filter.clinicSubfilter.toLowerCase() : '';
        const clinicSubfilterId = filter && filter.clinicSubfilterId ? filter.clinicSubfilterId.toLowerCase() : '';
        let memberId = filter && filter.memberId ? filter.memberId.toLowerCase() : '';
        const basePath = `/book/${handledSessionType}/${handledDate}`;

        let handledFilter = { ...filter };
        let handledPath = this.getBookTodayRoute();
        
        switch (handledSessionType){
            case StM.BookPageSessionType.Play: 
                handledPath = `${basePath}/${playSubfilter}`;
                break;
            case StM.BookPageSessionType.Lesson:
                if (!lessonSubfilter) {
                    handledPath = basePath;
                    break;
                }
                if (!lessonSubfilterId) lessonSubfilterId = !!defaultCoach ? defaultCoach.id : '';
                handledFilter.lessonSubfilterId = lessonSubfilterId;
                handledPath = `${basePath}/${lessonSubfilter}/${lessonSubfilterId}`;
                break;
            case StM.BookPageSessionType.Clinic:
                handledPath = !!clinicSubfilter ? `${basePath}/list/${clinicSubfilterId}` : basePath;
                break;
            case StM.BookPageSessionType.Group:
                if(!memberId) memberId = !!defaultMember ? defaultMember.id.toString() : '';
                handledPath = `${basePath}/member/${memberId}`;
                break;
            case StM.BookPageSessionType.League:
                handledPath = `/book/${handledSessionType}`;
                break;
            case StM.BookPageSessionType.Custom:
            case StM.BookPageSessionType.Join:
                handledPath = basePath;
                break;
            case StM.BookPageSessionType.Availability:
                handledPath = `/${handledSessionType}/${handledDate}`;
                break;
        }
        handledPath += window.location.hash;
        return new StM.BookPagePrams(handledPath, handledFilter);
    }

    public getDefaultPage(): string {
        const handledFilter: StM.IBookPageRouteParams = {
            sessionType: StM.BookPageSessionType.Play
            , date: null
            , playSubfilter: null
            , lessonSubfilter: null
            , lessonSubfilterId: null
            , clinicSubfilter: null
            , clinicSubfilterId: null
            , otherSubfilter: null
            , memberId: null
        };
        return this.getBookPage(handledFilter).path;
    }

    public getCurrentPageName(): string {
        let pageName = '';
        const routeData = this.getRouteData();
        if (routeData && routeData.length > 0) {
            pageName = routeData[0];
        }
        return pageName;
    }

    public getCurrentSubPageName(): string {
        let subPageName = '';
        const routeData = this.getRouteData();
        if (routeData && routeData.length > 0) {
            subPageName = routeData[1];
        }
        return subPageName;
    }

    public isActivePage(pageName: string, subPageName?: string, options?: Array<string>): boolean {
        if (window.location && window.location.pathname) {
            const routeData = window.location.pathname.substr(1).split('/');
            if (routeData && routeData.length > 0) {
                const currentPageName = routeData[0];
                const currentSubPageName = routeData[1];

                const isCurrentPage = pageName.toLowerCase().replace('/', '') === currentPageName.toLowerCase();

                if (!!subPageName) {
                    const isCurrentSubPage = !!currentSubPageName && subPageName.toLowerCase() === currentSubPageName.toLowerCase();

                    if (!!options) {
                        let isCurrentOptionsPage = true;
                        _.forEach(options, (opt: string) => {
                            const isExistOpt = routeData.indexOf(opt) > -1;
                            if (!isExistOpt) { isCurrentOptionsPage = false; }
                        });
                        return isCurrentPage && isCurrentSubPage && isCurrentOptionsPage;
                    }
                    return isCurrentPage && isCurrentSubPage;
                }
                return isCurrentPage;
            }
        }
        return false;
    }

    public getInviteLink(inviteToken: string, session: StM.ISessionStoreState, isRelative: boolean = false) {
        const baseUrl = !isRelative ? this.getBaseUrl() : '';
        const relativeCalendarLink = this.getRelativeCalendarLink(session)
        return (isRelative || baseUrl) 
            ? `${baseUrl}${relativeCalendarLink}#${DialogHashParam}=${StM.DialogNames.SessionInfo}/${StM.SessionInfoFromTypes.inviteLink}/${inviteToken}` 
            : '';
    }

    public getFBInviteLink(inviteToken: string) {
        const baseUrl = this.getBaseUrl();
        const todaysDate = moment().format('MM-DD-YYYY');
        return baseUrl + `/book/play/${todaysDate}/all-sessions#dialogs=sessioninfo/invite-link/${inviteToken}`;
    }

    public getRelativeCalendarLink(session: StM.ISessionStoreState): string {
        let relativeLink = '';
        const dateFormatted = session.startDateTime.format(Constants.DateTime.DATE_FORMAT);

        switch (session.type) {
            case StM.SessionType.Play: {
                const isActiveOnlyMy = this.isActivePage(StM.Pages.Book, StM.BookPageSessionType.Play, [StM.BookPagePlaySessionVisibility.OnlyMySessions]);
                const playSubfilter = isActiveOnlyMy
                    ? StM.BookPagePlaySessionVisibility.OnlyMySessions
                    : StM.BookPagePlaySessionVisibility.AllSessions;
                relativeLink = '{0}/{1}/{2}/{3}'.format(StM.Pages.Book, StM.BookPageSessionType.Play, dateFormatted, playSubfilter);
                break;
            }
            case StM.SessionType.Private: {
                let lessonSubfilter = '';
                let lessonSubfilterId = '';
                lessonSubfilter = StM.BookPageLessonFilterType.Coach;
                lessonSubfilterId = session.trainerId || StM.BookPageFilterTypeDefault.SubFilterId;

                relativeLink = '{0}/{1}/{2}/{3}/{4}'.format(StM.Pages.Book, StM.BookPageSessionType.Lesson, dateFormatted, lessonSubfilter, lessonSubfilterId.toString());
                break;
            }
            case StM.SessionType.Clinic: {
                relativeLink = '{0}/{1}/{2}'.format(StM.Pages.Book, StM.BookPageSessionType.Clinic, dateFormatted);
                break;
            }
            case StM.SessionType.Custom: {
                relativeLink = '{0}/{1}/{2}'.format(StM.Pages.Book, StM.BookPageSessionType.Custom, dateFormatted);
                break;
            }
            default: {
                const playSubfilter = StM.BookPagePlaySessionVisibility.OnlyMySessions;
                relativeLink = '{0}'.format(StM.Pages.OpenSessionsBoard);
                break;
            }
        }
        return relativeLink;
    }

    public getSessionVideoLink(token: string) {
        const baseUrl = this.getBaseUrl();
        return baseUrl ? '{0}{1}/{2}'.format(baseUrl, StM.Pages.Video, token) : '';
    }

    public getBaseUrl(): string {
        return location.origin || (location.protocol + "//" + location.host);
    }

    public getAdminSchedulePage(filter: StM.ISchedulePageRouteParams, user: StM.IUserStoreState)
        : { path: string, filter: StM.ISchedulePageRouteParams } {
        const handledDate = filter && filter.date ? filter.date.toLowerCase() : moment().format(Constants.DateTime.DATE_FORMAT);
        const handleViewType = filter && filter.viewType ? filter.viewType : this.getDefaultUserViewType(user);
        const handledFilter = { ...filter };
        return {
            filter: handledFilter,
            path: `/${StM.AdminPages.Admin}/${StM.AdminPages.Schedule}/${handleViewType}/${handledDate}`
        };
    }

    public getScheduleTodayRoute(club: StM.IClubStoreState, user: StM.IUserStoreState): string {
        let utcOffsetValue = this.utils.getClubUtcOffset(club);
        return '/admin/schedule/{0}/{1}'.format(
            this.getDefaultUserViewType(user)
            , moment().utcOffset(utcOffsetValue).format(Constants.DateTime.DATE_FORMAT)
        );
    }

    public getRouteData(): Array<string> {
        let routeData = window.location.pathname.split('/');
        routeData = _.filter(routeData, (routeSegment) => {
            return !!routeSegment;
        });
        return routeData;
    }

    public isAdminPage() {
        const isClubDashboard = this.isActivePage(StM.Pages.ClubDashboard);
        const isScoreboardPage = this.isActivePage(StM.Pages.Scoreboard);
        const isVideoPage = this.isActivePage(StM.Pages.Video);
        return !isClubDashboard && !isScoreboardPage && !isVideoPage;
    }

    public isAdminSettingsPage() {
        const settingsPages = [
            StM.AdminPages.Packages,
            StM.AdminPages.Subscriptions,
            StM.AdminPages.GeneralSettings,
            StM.AdminPages.BrandingSettings,
            StM.AdminPages.PricingSettings,
            StM.AdminPages.UserGroupSettings,
            StM.AdminPages.PeakTimeSettings,
            StM.AdminPages.Services,
            StM.AdminPages.LegalTexts
        ];
        return _.some(settingsPages, (page) => this.isActivePage(StM.AdminPages.Admin, page));
    }

    public isStandalonePage(route: string): boolean {
        if (!route) return false;
        const isClubDashboard = route.indexOf(StM.Pages.ClubDashboard) >= 0;
        const isScoreboardPage = route.indexOf(StM.Pages.Scoreboard) >= 0;
        const isVideoPage = route.indexOf(StM.Pages.Video) >= 0;
        
        return isClubDashboard || isScoreboardPage || isVideoPage;
    }
    
    public isInvitationWithoutLoginLink(hash: string): boolean {
        if (!hash) return false;
        
        return hash.indexOf('-invite-link') >= 0;
    }

    public scrollHashIntoView(timeout: number = 0) {
        const { hash } = location;
        if (!!hash) {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (!element) return;
                if (!this.utils.isMobile()) {
                    window.scroll({
                        top: element.offsetTop,
                        behavior: 'smooth'
                    })
                } else {
                    element.scrollIntoView({
                        behavior: 'smooth'
                    })
                }
            }, timeout);
        }
    }

    public getRouteDate(date: string, club: StM.IClubStoreState): moment.Moment {
        return (!date || date === Constants.TodayRouteParam)
            ? this.utils.getCurrentClubDateTime(club) 
            : moment(date, Constants.DateTime.DATE_FORMAT);
    }

    public getRouteDateParam(date: moment.Moment, club: StM.IClubStoreState): string {
        const today = this.utils.getCurrentClubDateTime(club);
        return date.isSame(today, 'date') ? Constants.TodayRouteParam : date.format(Constants.DateTime.DATE_FORMAT);
    }

    private isCoach(user: StM.IUserStoreState): boolean {
        return user && user.permissions && user.permissions.map((p) => p.role).some(r => ["Coach", "CoachAdmin"].indexOf(r) >= 0)
    }

    private getDefaultUserViewType(user: StM.IUserStoreState): string {
        return this.isCoach(user) ? StM.CalendarViewTypes.Week : StM.CalendarViewTypes.Day;
    }
}
