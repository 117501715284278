import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class WaiverDialogReducer extends BaseReducer{

    static handle(state: StM.IPrivacyPolicyDialogStoreState= new StM.PrivacyPolicyDialogStoreState(), action: any): StM.IPrivacyPolicyDialogStoreState{
        switch (action.type) {
            case 'Dialogs/PrivacyPolicy/Open': {
                return {...state, ...{ isOpened: true}};
            }
            case 'Dialogs/PrivacyPolicy/Close': {
                return {...state, ...{ isOpened: false}};
            }
            default: {
                return state;
            }
        }
    }
}
