import * as Redux from 'redux';
import moment from 'moment';

import { ThunkService } from '../services/thunkService';
import * as StM from '../models/store';
import * as ActM from './';
import * as SrvM from '../services';

export class UserActions {
    public static getCurrent(isLoadNotifications: boolean = true, isLoadGroups: boolean = true): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkSrv: ThunkService) => {
            let authTicket = getState().authenticationTicket;
            let accessToken = authTicket && authTicket.access_token
                ? authTicket.access_token
                : ''
                ;

            if (!accessToken) {
                dispatch({
                    type: 'Users/Clear',
                });
                return Promise.resolve(dispatch({
                    type: 'App/Disauthorize',
                    payload: null
                }));
            }

            dispatch(ActM.AppActions.showSpinner());
            return thunkSrv.api.user.getCurrent()
                .then((user: StM.IUserStoreState) => {
                    if (user) {
                        dispatch(this.init(user));
                        const authSrv = new SrvM.AuthenticationService();
                        if (authSrv.isInRole(StM.Roles.GroupAccount, user)) isLoadGroups = false;
                        const notificationsP = isLoadNotifications ? dispatch(ActM.NotificationActions.getForCurrentUser()) : null;
                        const groupsP = isLoadGroups ? dispatch(ActM.GroupAccountActions.getForCurrentUser()) : null;
                        const clubsP = dispatch(ActM.ClubActions.getForCurrentUser())
                        dispatch(ActM.AppActions.showSpinner());
                        return Promise.all([notificationsP, groupsP, clubsP])
                            .then(() => {
                                dispatch(ActM.AppActions.hideSpinner());
                                return user;
                            });
                    } else {
                        dispatch({ type: 'App/Disauthorize' });
                    }
                }).then((user) => {
                    dispatch(ActM.AppActions.hideSpinner());
                    return user
                }).catch(() => {
                    dispatch({ type: 'App/Disauthorize' });
                    dispatch(ActM.AppActions.hideSpinner());
                });
        };
    }

    public static init(user: StM.IUserStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkSrv: ThunkService) => {
            if (user && user.id) {
                user.lastAction = moment();
                dispatch({
                    type: 'Users/Init',
                    payload: user
                });
            } else {
                Promise.resolve(dispatch({
                    type: 'AuthTicket/Clear',
                    payload: null
                }));
            }

            const authTicket = getState().authenticationTicket;
            if (authTicket && authTicket.access_token) {
                dispatch({
                    type: 'App/Authorize',
                    payload: null
                });
            } else {
                dispatch({
                    type: 'Users/Clear',
                });
                dispatch({
                    type: 'App/Disauthorize',
                    payload: null
                });
            }
        };
    }

    public static checkUpdateLastAction(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkSrv: ThunkService) => {
            const state = getState();
            const lastAction = moment();
            const lastUtcOffset = state.app.utcOffset;

            if (!IS_MOBILE && !!state.authenticationTicket) {
                let prevLastAction = state.authenticationTicket.lastAction;

                if (state.app.isAuthorized) {
                    let diff = moment.duration(lastAction.diff(prevLastAction)).asMinutes();
                    let idleTimeout = moment.duration(IDLE_TIMEOUT_IN_MS).asMinutes();
                    if (diff > idleTimeout) {
                        dispatch(ActM.AccountActions.logout());
                    }
                    else {
                        dispatch({
                            type: 'AuthTicket/LastAction'
                            , payload: lastAction
                        });
                    }
                }
            }

            if (!state.app.isInitializing && !state.app.isLoading && lastAction.utcOffset() !== lastUtcOffset) {
                setTimeout(x => window.location.reload(), 200);
            }
        };
    }

    public static acceptWaiver(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkSrv: ThunkService) => {

            return thunkSrv.api.user.acceptWaiver()
                .then(() => {
                    dispatch({
                        type: 'Users/AcceptWaiver',
                    });
                });
        };
    }

    public static setProfileCompletionConfirmation(isNeedConfirmation: boolean): (dispatch: any) => void {
        return (dispatch: any) => {
            dispatch({
                type: isNeedConfirmation
                    ? 'Users/SetProfileCompletionConfirmation'
                    : 'Users/ResetProfileCompletionConfirmation'
            });
        };
    }

    public static updatePaymentProfile(profile: StM.IPaymentProfileInformationStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(ActM.AppActions.showSpinner());
            return thunkService.api.user.savePaymentProfile(profile)
                .then((response) => {
                    dispatch(ActM.AppActions.hideSpinner());
                    return response;
                }).catch(() => {
                    dispatch(ActM.AppActions.hideSpinner());
                });
        }
    }

    public static completePaymentMethod(success?: boolean): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(ActM.AppActions.showSpinner());
            return thunkService.api.user.completePaymentMethod(success)
                .then(() => {
                    dispatch(ActM.AppActions.hideSpinner());
                }).catch((error) => {
                    dispatch(ActM.AppActions.hideSpinner());
                    throw error;
                });
        }
    }

    public static startCreditCardValidation(): (dispatch: any) => void {
        return (dispatch: any) => {
            dispatch({ type: 'Users/CardValidation/Start' });
        }
    }

    public static stopCreditCardValidation(): (dispatch: any) => void {
        return (dispatch: any) => {
            dispatch({ type: 'Users/CardValidation/Stop' });
        }
    }
}
