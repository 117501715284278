import * as StM from '../../models/store';
import * as _ from 'lodash';

export default class MySessionsPageReducer {

    static handle(state: StM.IMySessionsPageStoreState = new StM.MySessionsPageStoreState(), action: any): StM.IMySessionsPageStoreState {
        switch (action.type) {
            case 'Pages/MySessions/Init': {
                return state;
            }
            case 'Pages/MySessions/Sessions': {
                let sessions = <Array<StM.ISessionStoreState>>action.payload;
                return <StM.IMySessionsPageStoreState>_.assign({}, state, { sessions: sessions });
            }
            default: {
                return state;
            }
        }
    }
}
