export class AddonDto implements IAddonDto{
    id: number;
    addonDefinition: IAddonDefinitionDto;
    createdBy: IPublicUserDto;
    updatedBy: IPublicUserDto;
    user: IPublicUserDto;
    created: string;
    updated: string;
    price: number;
    salesTax: number;
    userDiscount: number;
    amount: number;
    title: string;
    description: string;
    alias: string;
    booking: IBookingDto;
    status: string;
    changeStatusComment: string;
    transactionId: string;
    transactionComments: string;
    transactionDate: string;
    refundAmount: number;
    refundTransactionId: string;
    refundDateTime: string;
    waiveFee: boolean;
    addonDefinition_Id: number;
    
    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.addonDefinition = dto && dto.addonDefinition ? dto.addonDefinition : null;
        this.title = dto && dto.title ? dto.title : '';
        this.description = dto && dto.description ? dto.description : '';
        this.alias = dto && dto.alias ? dto.alias : 0;
        this.created = dto && dto.created ? dto.created : '';
        this.updated = dto && dto.updated ? dto.updated : '';
        this.user = dto && dto.user ? dto.user : '';
        this.createdBy = dto && dto.createdBy ? dto.createdBy : null;
        this.updatedBy = dto && dto.updatedBy ? dto.updatedBy : null;
        this.price = dto && dto.price ? dto.price : 0;
        this.amount = dto && dto.amount ? dto.amount : 0 ;
        this.userDiscount = dto && dto.userDiscount ? dto.userDiscount : 0 ;
        this.status = dto && dto.status ? dto.status : '' ;
        this.changeStatusComment = dto && dto.changeStatusComment ? dto.changeStatusComment : '';
        this.transactionId = dto && dto.transactionId ? dto.transactionId : '';
        this.transactionComments = dto && dto.transactionComments ? dto.transactionComments : '' ;
        this.transactionDate = dto && dto.transactionDate ? dto.transactionDate : '';
        this.refundAmount = dto && dto.refundAmount ? dto.refundAmount : 0 ;
        this.refundTransactionId = dto && dto.refundTransactionId ? dto.refundTransactionId : '' ;
        this.refundDateTime = dto && dto.refundDateTime ? dto.refundDateTime : '';
        this.salesTax = dto && dto.salesTax ? dto.salesTax : 0;
        this.waiveFee = dto && dto.waiveFee ? dto.waiveFee : false;
        this.addonDefinition_Id = dto && dto.addonDefinition_Id ? dto.addonDefinition_Id : 0;
    }
}