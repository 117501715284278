import * as StM from '../models/store';
import * as SrvM from '../services';
import { store } from '../store/store';

export class IBasePolicy{
    handle: (...args: any[]) => any;
}

export class BasePolicy {
    protected readonly utils = new SrvM.Utils();

    protected getState(): StM.IGlobalStoreState{
        const state = store.getState();
        return state as StM.IGlobalStoreState;
    }

    protected getCurrentUser(): StM.IUserStoreState{
        const user = this.getState().user;
        return user;
    }

    protected getCurrentClub(): StM.IClubStoreState{
        const club = this.getState().club;
        return club;
    }

    protected getCoachFeeTiers(): StM.ICoachFeeTierStoreState[] {
        return this.getState().coachFeeTiers;
    }

    protected getBasketSessions(): StM.ISessionStoreState[] {
        const basket = this.getState().basket;
        return basket ? basket.goods: [];
    }

    protected getCoaches(): StM.ICoachStoreState[] {
        return this.getState().coaches;
    }

    protected getBookPageFilter(): StM.IBookPageRouteParams {
        const match = this.getState().app.match;
        return match ? match.params : new StM.BookPageRouteParams();
    }

    protected getNotifications(): StM.INotificationStoreState[] {
        const notificationBoard = this.getState().notificationBoard;
        return notificationBoard ? notificationBoard.notifications : [];
    }

    protected getActiveUsers(): StM.IPublicUserStoreState[] {
        return this.getState().activeUsers;
    }

    protected getCoachFeeTierPrices(): StM.ICoachFeeTierPriceStoreState[] {
        return this.getState().coachFeeTierPrices;
    }
}