import * as StM from '../../../store';

export interface IVideoPageStoreState {
    session: StM.ISessionStoreState;
    selectedYoutubeId: StM.IYoutubeIdStoreState;
}

export class VideoPageStoreState implements IVideoPageStoreState {
    session: StM.ISessionStoreState;
    selectedYoutubeId: StM.IYoutubeIdStoreState;
    constructor() {
        this.session = null;
        this.selectedYoutubeId = null;
    }
}
