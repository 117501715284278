import { IBaseDialogStoreState, BaseDialogStoreState } from './baseDialogStoreState';
import { PaymentSystem } from '../../store/enums';

export interface IBillingInfoDialogStoreState extends IBaseDialogStoreState {
    type: PaymentSystem;
}

export class BillingInfoDialogStoreState extends BaseDialogStoreState {
    public type: PaymentSystem;

    constructor() {
        super();

        this.type = PaymentSystem.AuthorizeNet;
    }
}
