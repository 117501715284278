import { StM } from '../modules';
import { ThunkService } from '../services';
import * as ActM from './';

export class PackagesActions {
    static init(
        packageDefinitions: StM.IPackageDefinitionStoreState[],
        packageSessionTypes: StM.IPackageSessionTypeStoreState[]
    ): (dispatch: any) => void {
        return (dispatch: any) => {
            const types = Object.keys(StM.PackageSessionTypeGroup).reduce((result, group) => {
                const sessionTypes = packageSessionTypes.filter((t) => t.group === group);
                if (sessionTypes.length) {
                    result.push(...sessionTypes);
                }
                return result;
            }, [] as StM.IPackageSessionTypeStoreState[]);
            dispatch({
                type: 'Packages/Init',
                payload: { definitions: packageDefinitions, types },
            });
        };
    }

    static getCurrentSubscriptions(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) =>
        Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(ActM.AppActions.showSpinner());

            return thunkService.api.subscription.getCurrentSubscriptions()
                .then((v) => {
                    dispatch({
                        type: 'SubscriptionOffers/GetCurrentSubscriptionsSuccess',
                        payload: { subscriptions: v },
                    });
                })
                .then((v) => {
                    dispatch(ActM.AppActions.hideSpinner());
                    return v;
                })
                .catch((error: any) => {
                    thunkService.logger.error(error);
                });
        }
    }

    static onUserCommitmentCancellation(id: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(ActM.AppActions.showSpinner());
            return thunkService.api.subscription.onUserCommitmentCancellation(id)
                .then((v) => {
                    dispatch({
                        type: 'SubscriptionOffers/CommitmentCancellation'
                    });
                })
                .then((v) => {
                    dispatch(ActM.AppActions.hideSpinner());
                    return v;
                })
                .catch((error: any) => {
                    thunkService.logger.error(error);
                });
        }
    }
}