import moment from 'moment';
import * as _ from 'lodash';

import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';
import * as ActM from '..';
import * as SrvM from '../../services';

const utils = new SrvM.Utils();

export class ScoreboardPageActions {
    constructor() {
    }

    static init(courtId: number, startDate: moment.Moment, needSpinner: boolean = true):
        (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            if (needSpinner) dispatch(ActM.AppActions.showSpinner());

            dispatch(ActM.ScoreboardPageActions.getSessions(courtId, startDate));
            dispatch({ type: 'Pages/Scoreboard/Init', payload: null });
            dispatch(ActM.AppActions.hideSpinner());
            dispatch(ActM.BaseActions.hideSplash());

            return Promise.resolve();
        };
    }

    static getSessions(courtId: number, startDate: moment.Moment):
        (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            const start = startDate.clone().startOf('day');
            const end = start.clone().add(1, 'days');

            return thunkService.api.session.getByPeriod(start, end, club, pricingTiers, true)
                .then((result) => {
                    const currentTime = utils.getCurrentClubDateTime(club).format();
                    const sessions = result.filter((session) => {
                        const hasSameCourt = _.includes(session.courts.map(c => c.id), courtId);
                        const startTime = session.startDateTime.format();
                        const endTime = session.endDateTime.format();
                        const isCurrent = startTime <= currentTime && endTime >= currentTime;
                        const isAfter = startTime > currentTime;
                        return hasSameCourt && (isAfter || isCurrent);
                    });
                    dispatch({ type: 'Pages/Scoreboard/Sessions', payload: sessions });
                });
        };
    }

    static refreshDictionaries(date: moment.Moment): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.common.getDictionaries()
                .then((dictionaries: StM.ICommonDictionariesStoreState) => {
                    const clubP = dispatch(ActM.ClubActions.init(dictionaries.club));
                    const coachesP = dispatch(ActM.CoachesActions.init(dictionaries.coaches));
                    const coachFeeTiersP = dispatch(ActM.CoachFeeTiersActions.init(dictionaries.coachFeeTiers));
                    const membershipLevelsP = dispatch(ActM.MembershipLevelsActions.init(dictionaries.membershipLevels));
                    const packagesP = dispatch(ActM.PackagesActions.init(dictionaries.packageDefinitions, dictionaries.packageSessionTypes));

                    const allP = Promise.all([clubP, coachesP, coachFeeTiersP, membershipLevelsP, packagesP
                    ]).then(() => {
                        return dispatch({
                            type: 'App/Init'
                        });
                    });
                    return allP;
                }).catch((error) => {
                    thunkService.logger.error(error);
                });
        };
    }
}
