import * as Redux from 'redux';
import * as _ from 'lodash';
import moment from 'moment';
import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';
import * as SrvM from '../../services';
import * as ActM from '../';
import { Constants } from '../../constants';

export class SchedulePageActions {
    private static utils = new SrvM.Utils();

    static init(
        currentDate: moment.Moment,
        filter: StM.ISchedulePageRouteParams,
        isNeedSpinner: boolean = true
    )
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            isNeedSpinner && dispatch(ActM.AppActions.showSpinner());

            const start = currentDate.clone().startOf('day');
            const end = start.clone().add(1, 'days');

            const final = () => {
                dispatch({ type: 'Admin/Pages/Schedule/Init', payload: null });
                isNeedSpinner && dispatch(ActM.AppActions.hideSpinner());
                isNeedSpinner && dispatch(ActM.BaseActions.hideSplash());
            };

            const sessionP = dispatch(SchedulePageActions.getSessions(start, end));
            const availableTimesP = dispatch(SchedulePageActions.getAvailableTimes(start, end));
            return Promise.all([sessionP, availableTimesP])
                .then(() => {
                    return dispatch(SchedulePageActions.fillCourtTimeSlots(currentDate, filter));
                }).then(() => {
                    return final();
                }).catch((error) => {
                    thunkService.logger.error(error);
                    return final();
                });
        };
    }

    static getSessions(start: moment.Moment, end: moment.Moment): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.admin.getSessions(start, end, club, pricingTiers)
                .then((response: any) => {
                    let sessions = response;
                    dispatch({
                        type: 'Admin/Pages/Schedule/Sessions',
                        payload: sessions
                    });
                });
        };
    }

    static getAvailableTimes(start: moment.Moment, end: moment.Moment)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            let store = <StM.IGlobalStoreState>getState();
            let club = store && store.club ? store.club : null;
            return thunkService.api.admin.getCoachAvailableTimes(start, end, club)
                .then((response: any) => {
                    let availableTimes = <Array<StM.IAvailableTimeStoreState>>response;
                    dispatch({
                        type: 'Admin/Pages/Schedule/AvailableTimes',
                        payload: availableTimes
                    });
                    return availableTimes;
                });
        };
    }

    static sortCoaches()
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            let store = <StM.IGlobalStoreState>getState();
            const cache = new SrvM.RequestCacheService();
            let times = cache.getItem("AVAILABLE_TIMES") ? cache.getItem("AVAILABLE_TIMES").object as Array<StM.IAvailableTimeStoreState> : null;
            let sessions = cache.getItem("SESSIONS_BY_PERIOD") ? cache.getItem("SESSIONS_BY_PERIOD").object as Array<any> : null;
            let clubTime = store.club ? this.utils.getCurrentClubDateTime(store.club) : moment(new Date(0));

            if (!times || !sessions) return;

            const availabilityLookup: IDictionary = {};

            times.forEach((time) => {
                const dayIndex = this.utils.getDateFormat(time.begin.clone().startOf('day'));
                if (!availabilityLookup[time.ownerId]) availabilityLookup[time.ownerId] = <IDictionary>{};

                if (!availabilityLookup[time.ownerId][dayIndex]) {
                    availabilityLookup[time.ownerId][dayIndex] = 0;
                }

                if (!_.some(sessions, (session) => {
                    const startTime = moment(session.startDateTime);
                    const endTime = moment(session.endDateTime);

                    const isSameTrainer = session.trainer && session.trainer.Id == time.ownerId;
                    const isSameStartTime = startTime.isSameOrBefore(time.begin);
                    const isSameEndTime = endTime.isSameOrAfter(time.begin.clone().add(time.duration));
                    const isSameClubTime = time.begin.isSameOrAfter(clubTime);

                    return isSameTrainer && isSameStartTime && isSameEndTime && isSameClubTime;
                }))
                    availabilityLookup[time.ownerId][dayIndex] += time.duration.asMinutes();
            });

            return dispatch({
                type: 'Admin/Pages/Schedule/AvailableTimesLookup',
                payload: availabilityLookup
            });
        };
    }

    static fillCourtTimeSlots(date: moment.Moment, filter: StM.ISchedulePageRouteParams, isRefresh?: boolean)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Array<StM.CourtTimeSlotStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const store = <StM.IGlobalStoreState>getState();
            const club = store && store.club ? store.club : null;
            const user = store && store.user ? store.user : null;
            const coachFeeTiers = store && store.coachFeeTiers ? store.coachFeeTiers : [];
            const sessions = store && store.admin && store.admin.schedule && store.admin.schedule.sessions ? store.admin.schedule.sessions : [];
            const availableTimes = store && store.admin && store.admin.schedule && store.admin.schedule.availableTimes ? store.admin.schedule.availableTimes : [];
            const basketSessions = store && store.basket && store.basket.goods ? store.basket.goods : [];
            const pricingTiers = store && store.pricingTiers ? store.pricingTiers : [];

            const timeSlots = thunkService.courtTime.getCourtDaySlots(date
                , club
                , sessions
                , basketSessions
                , availableTimes
                , user
                , coachFeeTiers
                , null
                , pricingTiers
            );

            dispatch({
                type: isRefresh ? 'Admin/Pages/Schedule/CourtTimeSlotsRefresh' : 'Admin/Pages/Schedule/CourtTimeSlots',
                payload: timeSlots
            });

            dispatch({ payload: getState().dateTimeSlotsVersion + 1, type: 'App/Admin/DateTimeSlots/Changed' });

            return timeSlots;
        };
    }

    static fillDateCourtTimeSlots(dates: Array<moment.Moment>, filter: StM.ISchedulePageRouteParams, isRefresh?: boolean)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Array<StM.DateCourtTimeSlotStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const store = <StM.IGlobalStoreState>getState();
            const club = store && store.club ? store.club : null;
            const user = store && store.user ? store.user : null;
            const coachFeeTiers = store && store.coachFeeTiers ? store.coachFeeTiers : [];
            const sessions = store && store.admin && store.admin.schedule && store.admin.schedule.sessions ? store.admin.schedule.sessions : [];
            const basketSessions = store && store.basket && store.basket.goods ? store.basket.goods : [];
            const availableTimes = store && store.admin && store.admin.schedule && store.admin.schedule.availableTimes ? store.admin.schedule.availableTimes : [];
            const dateTimeSlots = Array<StM.DateCourtTimeSlotStoreState>();
            const coaches = store && store.coaches ? store.coaches : [];
            const pricingTiers = store && store.pricingTiers ? store.pricingTiers : [];

            dates.forEach((date) => {
                const dateTimeSlot = new StM.DateCourtTimeSlotStoreState();
                dateTimeSlot.date = date.clone();
                dateTimeSlot.courtTimeSlots = thunkService.courtTime.getCourtDaySlots(
                    date
                    , club
                    , sessions
                    , basketSessions
                    , availableTimes
                    , user
                    , coachFeeTiers
                    , null
                    , pricingTiers
                    , true
                    , coaches
                );
                dateTimeSlots.push(dateTimeSlot);
            });

            dispatch({
                type: isRefresh ? 'Admin/Pages/Schedule/CourtTimeSlotsRefresh' : 'Admin/Pages/Schedule/CourtTimeSlots',
                payload: dateTimeSlots
            });

            dispatch({ payload: getState().dateTimeSlotsVersion + 1, type: 'App/Admin/DateTimeSlots/Changed' });

            return dateTimeSlots;
        };
    }

    static createSession(session: StM.ISessionStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.ISessionStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.admin.createSession(session, club, pricingTiers)
                .then((response) => {
                    dispatch(ActM.AdminUsersActions.get())
                    return response;
                });
        };
    }

    static updateSession(session: StM.ISessionStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.ISessionStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.admin.updateSession(session, club, pricingTiers)
                .then((response) => {
                    dispatch(ActM.AdminUsersActions.get())
                    return response;
                });
        };
    }

    static cancelSession(session: StM.ISessionStoreState, force: boolean = false): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            let sessionToCancel = session;
            return thunkService.api.admin.cancelSession(sessionToCancel, club, pricingTiers, force)
                .then((response) => {
                    dispatch(ActM.AdminUsersActions.get())
                    return response;
                });
        };
    }

    static cancelSeries(session: StM.ISessionStoreState, force: boolean = false): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.admin.cancelSeries(session, club, pricingTiers, force)
                .then((response) => {
                    dispatch(ActM.AdminUsersActions.get())
                    return response;
                });
        };
    }

    static closeSession(session: StM.ISessionStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.admin.closeSession(session, club, pricingTiers)
                .then((response) => {
                    return response;
                });
        };
    }

    static reopenSession(session: StM.ISessionStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.admin.reopenSession(session, club, pricingTiers)
                .then((response) => {
                    return response;
                });
        };
    }

    static removeVideo(session: StM.ISessionStoreState, waiveFee: boolean = false): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.admin.removeVideo(session, club, pricingTiers, waiveFee)
                .then((response) => {
                    return response;
                });
        };
    }

    static addUser(session: StM.ISessionStoreState, user: StM.IAddedUserStoreState) {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.admin.checkoutSession(session, user, club, pricingTiers);
        };
    }

    static inviteUsers(session: StM.ISessionStoreState, users: Array<StM.IPublicUserStoreState>): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.ISessionStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.admin.inviteUsers(session, users, club, pricingTiers);
        };
    }

    static dropOutUser(session: StM.ISessionStoreState, user: StM.IPublicUserStoreState, force: boolean = true): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.ISessionStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.admin.dropOutUser(session, user, club, pricingTiers, force)
                .then((response) => {
                    dispatch(ActM.AdminUsersActions.getUser(user.id, true));
                    return response;
                });
        };
    }

    static checkInBooking(session: StM.ISessionStoreState, user: StM.IPublicUserStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.ISessionStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.admin.checkinUser(session, user, true, club, pricingTiers);
        };
    }

    static checkOutBooking(session: StM.ISessionStoreState, user: StM.IPublicUserStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.ISessionStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.admin.checkinUser(session, user, false, club, pricingTiers);
        };
    }

    static payBooking(session: StM.ISessionStoreState, user: StM.IPublicUserStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.ISessionStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.admin.payForUser(session, user, true, club, pricingTiers);
        };
    }

    static cancelPayBooking(session: StM.ISessionStoreState, user: StM.IPublicUserStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.ISessionStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.admin.payForUser(session, user, false, club, pricingTiers);
        };
    }

    static updateForCurrentPage(params: any): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const date: string = params && params.date ? params.date : '';
            const currentDate = date ? moment(date, Constants.DateTime.DATE_FORMAT) : moment();
            let start: moment.Moment;
            let end: moment.Moment;

            switch (params.viewType) {
                case StM.CalendarViewTypes.Day:
                    start = currentDate.startOf('day');
                    end = start.clone().add(1, 'day').endOf('day');
                    break;
                case StM.CalendarViewTypes.Week:
                case StM.CalendarViewTypes.List:
                    start = currentDate.startOf('week').startOf('day');
                    end = start.clone().endOf('week').add(1, 'day').endOf('day');
                    break;
                default:
                    start = currentDate.startOf('day');
                    end = start.clone().add(1, 'day').endOf('day');
                    break;
            }

            return Promise.resolve(dispatch(ActM.SchedulePageActions.getSessions(start, end)));
        };
    }

    static addCoachAvailableTime(coach: StM.ICoachStoreState, start: moment.Moment, duration: moment.Duration): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Admin/Pages/Schedule/AvailableTimes/AddTime',
                coach: coach,
                start: start,
                duration: duration
            });
        };
    }

    static removeCoachAvailableTime(coach: StM.ICoachStoreState, start: moment.Moment, duration: moment.Duration): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Admin/Pages/Schedule/AvailableTimes/RemoveTime',
                coach: coach,
                start: start,
                duration: duration
            });
        };
    }
}
