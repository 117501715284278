export class NewClubDto implements INewClubDto {
    title: string;
    adminFirstName: string;
    adminLastName: string;
    adminEmail: string;

    constructor(dto?: any) {
        this.title = dto && dto.title ? dto.title : "";
        this.adminFirstName = dto && dto.adminFirstName ? dto.adminFirstName : "";
        this.adminLastName = dto && dto.adminLastName ? dto.adminLastName : "";
        this.adminEmail = dto && dto.adminEmail ? dto.adminEmail : "";
    }
}