import {IRoleDefinitionStoreState, RoleDefinitionStoreState} from '../models/store/clubs/roleDefinitionStoreState';
import * as _ from 'lodash';

export default class PermissionsReducer {
    static handle(state: Array<IRoleDefinitionStoreState> = new Array<IRoleDefinitionStoreState>(), action: any): Array<IRoleDefinitionStoreState> {
        switch (action.type) {
            case 'Permissions/Init': {
                const sessions = <Array<IRoleDefinitionStoreState>>action.payload;
                return <Array<IRoleDefinitionStoreState>>_.assign([], sessions);
            }
            case 'Permissions/Clear': {
                return new Array<IRoleDefinitionStoreState>();
            }
            default: {
                return state;
            }
        }
    }
}
