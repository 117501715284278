import * as _ from 'lodash';
import moment from 'moment';

import { StM, SrvM, Constants } from '../../modules';

export class ValidationService {
    private utils: SrvM.Utils;
    private static _instance: ValidationService;

    constructor() {
        if (typeof ValidationService._instance === 'undefined') {
            ValidationService._instance = this;
            this.utils = new SrvM.Utils();
        }
        return ValidationService._instance;
    }

    public getIsValidEmail(email: string) {
        if (!email) return false;
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    public getIsValidPhone(phone: string): boolean {
        const regExp = /^(.){10,20}$/;
        return regExp.test(phone);
    }

    public getIsBirthdayUnderage(dateOfBirth: moment.Moment, club: StM.IClubStoreState): boolean {
        return this.utils.getCurrentClubDateTime(club).diff(dateOfBirth, 'years') < Constants.MajorityAge;
    }

    public validateEmail(email: string, fieldName: string, displayName: string = 'Email', src?: any) {
        if (!this.getIsValidEmail(email)) {
            let errors = this.validateRequired(email, fieldName, displayName, src);
            if (!this.hasErrors(errors)) errors = errors = this.setError(fieldName, `The entered value is not a correct email address`, src);
            return errors;
        }
        return src || {};
    }

    public validateFieldsMatch(value: string, valueToCompare: string, fieldName: string, displayName: string = 'Fields', src?: any) {
        if (value !== valueToCompare) return this.setError(fieldName, `${displayName} do not match`, src);
        return src || {};
    }

    public validateRequired(value: any, fieldName: string, displayName: string = 'This', src?: any) {
        if (value && value.trim) value = value.trim();
        if (!value) return this.setRequiredError(fieldName, displayName, src);
        return src || {};
    }

    public validatePassword(password: string, fieldName: string, displayName: string = 'Password', src?: any) {
        let errors = this.validateRequired(password, fieldName, displayName, src);
        if (!this.hasErrors(errors)) errors = this.validate(fieldName, () => {
            return password.length < Constants.PasswordMinLength && `${displayName} must be at least ${Constants.PasswordMinLength} characters`;
        }, src);
        return errors;
    }

    public validateBirthDay(birthday: moment.Moment, fieldName: string, club: StM.IClubStoreState, displayName: string = 'Date of Birth', checkUnderage: boolean = false, src?: any) {
        let errors = this.validateRequired(birthday, fieldName, displayName, src);
        if (this.hasErrors(errors)) return errors;
        if (birthday.isAfter(this.utils.getCurrentClubDateTime(club), 'date')) {
            errors = this.setIncorrectError(fieldName, displayName, src);
        } else if (checkUnderage && this.getIsBirthdayUnderage(birthday, club)) {
            errors = this.setError(fieldName, `Your age must be ${Constants.MajorityAge} years or more`, src);
        }
        return errors;
    }

    public validatePhone(phone: string, fieldName: string, displayName: string = 'Phone', src?: any) {
        let errors = this.validateRequired(phone, fieldName, displayName, src);
        if (!this.hasErrors(errors) && !this.getIsValidPhone(phone)) {
            errors = this.setIncorrectError(fieldName, displayName, src);
        }
        return errors;
    }

    public validatePrice(value: number, fieldName: string, displayName: string = 'Price', src?: any, checkForMinTransactionAmount: boolean = false) {
        let errors = src || {};
        if(!value && value!== 0) {
            errors = this.setRequiredError(fieldName, displayName, src);
        }
        if(this.hasErrors(errors)) return errors;
        if(value < 0) {
            errors = this.setIncorrectError(fieldName, displayName, src);
        }
        if(checkForMinTransactionAmount && value > 0 && value < Constants.PaymentSystem.minTransactionAmount) {
            errors = this.setError(fieldName, `${displayName} should be equal to 0 or greater than ${Constants.PaymentSystem.minTransactionAmount}`);
        }
        return errors;
    }

    public validateLength(value: string, fieldName: string, displayName: string, minLength: number = 1, maxLength: number = null, src?: any) {
        let errors = src || {};
        if (!value || value.length < minLength) {
            errors = this.setError(fieldName, `${displayName} is too short`, src);
        } else if (value && maxLength && value.length > maxLength) {
            errors = this.setError(fieldName, `${displayName} is too long`, src);
        }
        return errors;
    }

    public validateIncorrect(condition: boolean,  fieldName: string, displayName: string = 'This field', src?: any) {
        let errors = src || {};
        if (!condition) errors = this.setIncorrectError(fieldName, displayName, src);
        return errors;
    }


    public validate(fieldName: string, func: () => string, src?: any) {
        const errorMessage = func();
        if (!!errorMessage) return this.setError(fieldName, errorMessage, src);
        return src || {};
    }

    public hasErrors(obj: any): boolean {
        return !!obj && !_.isEmpty(obj);
    }

    public setError(field: string, message: string, src: any = {}) {
        return _.set(src, field, message);
    }

    public setServerError(source: any, errors: any) {
        const documentWindow: any = window;
        let serverMessage = source.message;
        if (source.exceptionMessage) {
            if (documentWindow.ISADMIN) serverMessage = source.exceptionMessage;
            else if (source.exceptionMessage.indexOf("EBT.SOF.BusinessLayer.Exceptions.BusinessRuleViolationException:") >= 0) serverMessage = source.exceptionMessage.substring(source.exceptionMessage.indexOf(":") + 2);
        } 
        return { 
            ...errors,
            server: serverMessage
        };
    }

    // private methods

    private setIncorrectError(field: string, displayName: string, src?: any) {
        return this.setError(field, `${displayName} is incorrect`, src);
    }

    private setRequiredError(field: string, displayName: string, src?: any) {
        return this.setError(field, `${displayName} field is required`, src);
    }
}
