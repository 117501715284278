import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';
import * as StM from '../../store'; 

export interface IPasswordRecoveryDialogStoreState extends IBaseDialogStoreState{
}

export class PasswordRecoveryDialogStoreState extends BaseDialogStoreState{
    constructor(){
        super();
    }
}