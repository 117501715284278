import { IBaseDialogStoreState, BaseDialogStoreState } from './baseDialogStoreState';

export interface IAssignCoachFeeTierToCourtsDialogStoreState extends IBaseDialogStoreState {
    id: number;
}

export class AssignCoachFeeTierToCourtsDialogStoreState extends BaseDialogStoreState implements IAssignCoachFeeTierToCourtsDialogStoreState {
    public id: number;

    constructor() {
        super();

        this.id = 0;
    }
}