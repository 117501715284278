import { History } from 'history';
import { combineReducers, Reducer } from 'redux';

import { IGlobalStoreState } from '../models/store';
import HydratedStateReducer from './hydratedStateReducer';
import AppReducer from './appReducer';
import ClubReducer from './clubReducer';
import ClubsReducer from './clubsReducer';
import UserReducer from './userReducer';
import CoachesReducer from './coachesReducer';
import ActiveUsersReducer from './activeUsersReducer';
import CoachFeeTiersReducer from './coachFeeTiersReducer';
import CoachFeeTierPricesReducer from './coachFeeTierPricesReducer';
import PricingTiersReducer from './pricingTiersReducer';
import MembershipLevelsReducer from './membershipLevelsReducer';
import { rootPagesReducer } from './pages';
import { rootDialogsReducer } from './dialogs';
import BasketReducer from './basketReducer';
import ValidatedBasketReducer from './validatedBasketReducer';
import HoverLineReducer from './hoverLineReducer';
import AuthTicketReducer from './authTicketReducer';
import NotificationReducer from './notificationReducer';
import BannerReducer from './bannerReducer';
import PackageReducer from './packagesReducer';
import SubscriptionReducer from './subscriptionsReducer';
import AddonsReducer from './addonsReducer';
import BurgerMenuReducer from './burgerMenuReducer';
import { rootAdminReducer } from './admin';
import CustomSessionsReducer from './customSessionsReducer';
import AdminUsersReducer from './admin/usersReducer';
import PermissionsReducer from './permissionsReducer';
import TemporarySessionReducer from './temporarySessionReducer';
import GroupAccountsReducer from './groupAccountsReducer';
import SubscriptionOffersReducer from './subscriptionOffersReducer';

export const HAND_HYDRATED_STATE: string = 'HAND_HYDRATED_STATE';

export const handleRootReducer = (reducer: any): Reducer<any> => {
    return (state: any, action: any) => {
        switch (action.type) {
            case HAND_HYDRATED_STATE: {
                return HydratedStateReducer.handle(state);
            }
            default:
                return reducer(state, action);
        }
    }
}

const rootReducer = (history: History) =>
    combineReducers<IGlobalStoreState>({
        app: AppReducer.handle,
        club: ClubReducer.handle,
        clubs: ClubsReducer.handle,
        user: UserReducer.handle,
        activeUsers: ActiveUsersReducer.handle,
        coaches: CoachesReducer.handle,
        coachFeeTiers: CoachFeeTiersReducer.handle,
        coachFeeTierPrices: CoachFeeTierPricesReducer.handle,
        pricingTiers: PricingTiersReducer.handle,
        membershipLevels: MembershipLevelsReducer.handle,
        pages: rootPagesReducer,
        dialogs: rootDialogsReducer,
        basket: BasketReducer.handle,
        validatedBasket: ValidatedBasketReducer.handle,
        hoverLine: HoverLineReducer.handle,
        authenticationTicket: AuthTicketReducer.handle,
        notificationBoard: NotificationReducer.handle,
        banner: BannerReducer.handle,
        packages: PackageReducer.handle,
        subscriptions: SubscriptionReducer.handle,
        subscriptionOffers: SubscriptionOffersReducer.handle,
        addons: AddonsReducer.handle,
        burgerMenu: BurgerMenuReducer.handle,
        admin: rootAdminReducer,
        customSessions: CustomSessionsReducer.handle,
        permissions: PermissionsReducer.handle,
        usersListVersion: AdminUsersReducer.handleUsersVersion,
        dateTimeSlotsVersion: AppReducer.handleDateTimeSlotsVersion,
        needProfileCompletionConfirmation: UserReducer.handleProfileCompletionConfirmation,
        temporarySession: TemporarySessionReducer.handle,
        groupAccounts: GroupAccountsReducer.handle,
    });

export const handledRootReducer = (history: History) => handleRootReducer(rootReducer(history));
