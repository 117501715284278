import * as Redux from 'redux';
import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';

export class EditGroupDialogActions {
    static open(userId: string, sectionType?: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Admin/EditGroup/Open',
                userId: userId,
                sectionType: sectionType
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Admin/EditGroup/Close',
            });
        };
    }
}