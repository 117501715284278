export interface IBannerStoreState {
    isShown: boolean;
    mainClubBanner: string;
    rightClubBanner: string;
}
export class BannerStoreState implements IBannerStoreState {
    isShown: boolean = true;
    mainClubBanner: string = '';
    rightClubBanner: string = '';
    
    constructor(dto?: any) {
        this.isShown = dto?.name ?? true;
        this.mainClubBanner = dto?.mainClubBanner ?? '';
        this.rightClubBanner = dto?.rightClubBanner ?? '';
    }
}
