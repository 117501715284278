import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class SubscriptionAddedDialogReducer extends BaseReducer{

    static handle(state: StM.ISubscriptionAddedDialogStoreState = new StM.SubscriptionAddedDialogStoreState(), action: any): StM.ISubscriptionAddedDialogStoreState{
        switch (action.type) {
            case 'Dialogs/SubscriptionAdded/Open': {
                return { ...state, isOpened: true};
            }
            case 'Dialogs/SubscriptionAdded/Close': {
                return {...state, isOpened: false};
            }
            default: {
                return state;
            }
        }
    }
}
