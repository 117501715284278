export interface IPermissionStoreState{
    clubId: number;
    role: string;  
}

export class PermissionStoreState implements IPermissionStoreState{
    clubId: number;
    role: string;

    constructor(dto?: any) {
        this.clubId = dto && dto.clubId ? dto.clubId : 0;
        this.role = dto && dto.role ? dto.role : '';
    }
}
