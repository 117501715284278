import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class PasswordRecoveryDialogReducer extends BaseReducer{

    static handle(state: StM.IPasswordRecoveryDialogStoreState= new StM.PasswordRecoveryDialogStoreState(), action: any): StM.IPasswordRecoveryDialogStoreState{
        switch (action.type) {
            case 'Dialogs/PasswordRecovery/Open': {
                return <StM.IPasswordRecoveryDialogStoreState>_.assign({}, state, { isOpened: true});
            }
            case 'Dialogs/PasswordRecovery/Close': {
                return <StM.IPasswordRecoveryDialogStoreState>_.assign({}, state, { isOpened: false});
            }
            default: {
                return state;
            }
        }
    }
}
