import * as _ from 'lodash';
import * as StM from '../models/store';
import * as ActM from './';
import * as SrvM from '../services';
import moment from 'moment';
import Constants from '../constants';

export class SessionActions {
    public static updateForCurrentPage(params: StM.IBookPageRouteParams, clearCache: boolean = false)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: SrvM.ThunkService) => Promise<any> {
            return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: SrvM.ThunkService) => {
                const { app, club } = getState();
                const isAuth = app.isAuthorized;
                const currentDate = thunkService.routeService.getRouteDate(params ? params.date : '', club);
                const start = currentDate.startOf('day');
                const end = start.clone().add(1, 'day');
                
                const isActiveBookClinicList = thunkService.routeService.isActivePage(StM.Pages.Book, StM.BookPageSessionType.Clinic, ['list']);

                new SrvM.RequestCacheService().resetExcept("AVAILABLE_TIMES");

                if(thunkService.routeService.isActivePage(StM.Pages.Book)){
                    if(isActiveBookClinicList){
                        return Promise.all([dispatch(ActM.ClinicListViewActions.getClinics()), dispatch(ActM.BookPageActions.getSessions(start, end, clearCache))]);
                    }
                    return Promise.resolve(dispatch(ActM.BookPageActions.getSessions(start, end, clearCache)));
                } else if (thunkService.routeService.isActivePage(StM.Pages.MySessions) && isAuth) {
                    const currentDate = moment();
                    const start = currentDate.clone().startOf('day');
                    const end = start.clone().add(1, 'month');
                    return Promise.resolve(dispatch(ActM.MySessionsPageActions.getSessions(start, end)));
                } else if (thunkService.routeService.isActivePage(StM.Pages.OpenSessionsBoard)) {
                    return Promise.resolve(dispatch(ActM.OpenSessionsBoardPageActions.getSessions()));
                }
                return Promise.resolve();
            };
    }

    public static save(session: StM.ISessionStoreState)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: SrvM.ThunkService) => Promise<StM.ISessionStoreState> {
            return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: SrvM.ThunkService) => {
                const club = getState().club;
                return thunkService.api.session.save(session, club);
            };
    }

    public static saveInvitedUsers(users: Array<StM.IPublicUserStoreState>, sessionId: number, token: string)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: SrvM.ThunkService) => Promise<any> {
            return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: SrvM.ThunkService) => {
                return thunkService.api.session.saveInvitedUsers(users, sessionId, token);
            };
    }    

    public static openSessionDialog(from: string, id: number)
        : (dispatch: any, getState: () => StM.IGlobalStoreState) => Promise<void> {
            return (dispatch: any, getState: () => StM.IGlobalStoreState) => {
                if(from && from == StM.SessionInfoFromTypes.basket){
                    const basketSessions = getState().basket.goods;
                    let basketSession = _.find(basketSessions, (i) => i.basketId === id);
                    if(basketSession.id){
                        dispatch(ActM.DialogActions.open(StM.DialogNames.SessionInfo, {from: from, id: id}))
                    } else {
                        dispatch(SessionActions.openBasketSessionDialog(basketSession));
                    }
                }else{
                    dispatch(ActM.DialogActions.open(StM.DialogNames.SessionInfo, {from: from, id: id}))
                }
                return Promise.resolve();
            };
    }

    public static openBasketSessionDialog(basketSession: StM.ISessionStoreState)
        : (dispatch: any) => void {
            return (dispatch: any) => {
                const courtTimeSrv = new SrvM.CourtTimeService();
                const routeService = new SrvM.RouteService();
                const utils = new SrvM.Utils();
                const calendarLink =  routeService.getRelativeCalendarLink(basketSession);
                const start = moment.duration({ hours: basketSession.startDateTime.get('hours'), minutes: basketSession.startDateTime.get('minutes') });
                const timeKey = courtTimeSrv.getKey(basketSession.startDateTime.clone().startOf('day').format(Constants.DateTime.API_FORMAT), basketSession.court.id, start);
                const filter = utils.getFilterTypeBySessionType(basketSession.type);
                const subFilter =  StM.BookPageLessonFilterType.Coach;
                const subFilterId = basketSession.trainerId || StM.BookPageFilterTypeDefault.SubFilterId;

                dispatch(ActM.RouteActions.replace(calendarLink));
                dispatch(ActM.DialogActions.open(StM.DialogNames.NewSession, {
                    courtId: basketSession.court.id, 
                    timeKey: timeKey, 
                    sessionType: filter,
                    sessionSubFilter: subFilter,
                    sessionSubFilterId: subFilterId,
                    sessionId: basketSession.basketId
                }));
            };
    }

    public static saveTemporarySession(session: StM.ISessionStoreState)
        : (dispatch: any, getState: () => StM.IGlobalStoreState) => void {
            return (dispatch: any, getState: () => StM.IGlobalStoreState) => {
                const state = getState();
                if(state.app.isAuthorized && state.temporarySession) {
                    dispatch(SessionActions.clearTemporarySession());
                } else if(!state.app.isAuthorized) {
                    dispatch({type: 'TemporarySession/Save', payload: session })
                }
            }
        }
    
    public static clearTemporarySession() : (dispatch: any) => void {
        return (dispatch: any) => {
            dispatch({ type: 'TemporarySession/Clear'});
        }
    }
}
