import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';

export interface IAlertDialogStoreState extends IBaseDialogStoreState {
    msgKey: string;
    messageType: string;
    message: string;
    isOpenByService?: boolean;
}

export class AlertDialogStoreState extends BaseDialogStoreState implements IAlertDialogStoreState{
    msgKey: string;
    messageType: string;
    message: string;
    isOpenByService?: boolean;
    constructor(){
        super();
        this.msgKey = '';
        this.messageType = '';
        this.message = null;
    }
}