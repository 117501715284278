import * as Redux from 'redux';
import {ThunkService} from '../../services/thunkService';
import * as StM from '../../models/store';

export class AuthDialogActions {

    static open(tab: string, returnUrl?: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Auth/Open',
                payload: { tab: tab, returnUrl: returnUrl }
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Auth/Close',
                payload: {}
            });
        };
    }
}
