import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';

export class PublishConfirmationDialogActions {
    public static open(title: string, msg: string, yesCallback: () => void, noCallback: () => void, yesBtnTxt?: string, noBtnTxt?: string, showExitBtn?: boolean):
        (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/PublishConfirmation/Open',
                title: title,
                msg: msg,
                yesCallback: yesCallback,
                noCallback: noCallback,
                yesBtnTxt: yesBtnTxt,
                noBtnTxt: noBtnTxt,
                showExitBtn: showExitBtn
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/PublishConfirmation/Close',
            });
        };
    }
}
