import { IBaseDialogStoreState, BaseDialogStoreState } from '../baseDialogStoreState';
import * as StM from '../../../store'; 

export interface IGroupInfoDialogStoreState extends IBaseDialogStoreState{
    from: StM.GroupInfoFromType;
    inviteToken: string;
}

export class GroupInfoDialogStoreState extends BaseDialogStoreState implements IGroupInfoDialogStoreState{
    public from: StM.GroupInfoFromType;
    public inviteToken: string;
    
    constructor(){
        super();
        this.from = null;
        this.inviteToken = null;
    }
}