import BaseReducer from './baseReducer';
import * as StM from '../models/store';

export default class SubscriptionsReducer extends BaseReducer {
    static handle(state: StM.ISubscriptionStoreState = new StM.SubscriptionStoreState(), action: any): StM.ISubscriptionStoreState {
        switch (action.type) {
            case 'Subscriptions/Init': {
                var payload = <StM.ISubscriptionStoreState>action.payload;
                return payload;
            }
            default: {
                return state;
            }
        }
    }
}
