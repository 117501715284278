import * as _ from 'lodash';

import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';

export default class SubscriptionHistoryReducer extends BaseReducer{
    static handle(state: StM.ISubscriptionHistoryDialogStoreState = new StM.SubscriptionHistoryDialogStoreState(), action: any): StM. ISubscriptionHistoryDialogStoreState{
        switch (action.type) {
            case 'Dialogs/Admin/SubscriptionHistory/Open': {
                return <StM. ISubscriptionHistoryDialogStoreState>_.assign({}, state, { isOpened: true, ...action });
            }
            case 'Dialogs/Admin/SubscriptionHistory/Close': {
                return <StM.ISubscriptionHistoryDialogStoreState>_.assign({}, state, { isOpened: false});
            }
            default: {
                return state;
            }
        }
    }
}
