import { IAppStoreState, AppStoreState } from '../models/store';

export default class AppReducer {
    public static handle(state: IAppStoreState = new AppStoreState(), action: any): IAppStoreState {
        switch (action.type) {
            case 'App/Init': {
                return {...state, isInitializing: false, isAuthorizing: true };
            }
            case 'App/Authorize': {
                return {...state, isAuthorized: true, isAuthorizing: false };
            }
            case 'App/Finalize': {
                return {...state, isFinalized: true };
            }
            case 'App/Dehydrated': {
                return {...state, isDehydrated: true };
            }
            case 'App/Disauthorize': {
                return {...state, ...{ isAuthorized: false, isAuthorizing: false }};
            }
            case 'App/Spinner/Show': {
                const loadingCount  = state.loadingCount + 1;
                return {...state, ... { isLoading: loadingCount > 0, loadingCount }};
            }
            case 'App/Spinner/Hide': {
                const loadingCount = state.loadingCount > 0 ? state.loadingCount - 1 : 0;
                return {...state, ... { isLoading: loadingCount > 0, loadingCount }};
            }
            case 'App/Spinner/OwnProps': {
                const ownProps = action.payload;
                return {...state, ... { ownProps: ownProps }};
            }
            case 'App/Match/Update': {
                return {...state, match: action.payload};
            }
            default: {
                return state;
            }
        }
    }

    public static handleDateTimeSlotsVersion(state: number = 0, action: any): number {
        switch (action.type) {
            case 'App/Admin/DateTimeSlots/Changed':
                return <number>action.payload;
            default: {
                return state;
            }
        }
    }
}
