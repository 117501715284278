import moment from 'moment';

import Constants from '../../../constants';
import { IAddonDefinitionStoreState } from './addonDefinitionStoreState';
import { IPublicUserStoreState } from '../users';
import { IBookingStoreState } from '../sessions/bookingStoreState';

export interface IAddonStoreState {
    id: number;
    addonDefinition: IAddonDefinitionStoreState;
    createdBy: IPublicUserStoreState;
    updatedBy: IPublicUserStoreState;
    user: IPublicUserStoreState;
    created: moment.Moment;
    updated: moment.Moment;
    price: number;
    salesTax: number;
    userDiscount: number;
    amount: number;
    title: string;
    description: string;
    alias: string;
    booking: IBookingStoreState;
    status: string;
    changeStatusComment: string;
    transactionId: string;
    transactionComments: string;
    transactionDate: moment.Moment;
    refundAmount: number;
    refundTransactionId: string;
    refundDateTime: moment.Moment;
    waiveFee: boolean;
    addonDefinition_Id: number;
}

export class AddonStoreState implements IAddonStoreState {
    id: number;
    addonDefinition: IAddonDefinitionStoreState;
    createdBy: IPublicUserStoreState;
    updatedBy: IPublicUserStoreState;
    user: IPublicUserStoreState;
    created: moment.Moment;
    updated: moment.Moment;
    price: number;
    salesTax: number;
    userDiscount: number;
    amount: number;
    title: string;
    description: string;
    alias: string;
    booking: IBookingStoreState;
    status: string;
    changeStatusComment: string;
    transactionId: string;
    transactionComments: string;
    transactionDate: moment.Moment;
    refundAmount: number;
    refundTransactionId: string;
    refundDateTime: moment.Moment;
    waiveFee: boolean;    
    addonDefinition_Id: number;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.title = dto && dto.title ? dto.title : '';
        this.description = dto && dto.description ? dto.description : '';
        this.alias = dto && dto.alias ? dto.alias : '';
        this.created = dto && dto.created ? moment(dto.created, Constants.DateTime.API_FORMAT) : moment.min();
        this.updated = dto && dto.updated ? moment(dto.updated, Constants.DateTime.API_FORMAT) : moment.min();
        this.createdBy = dto && dto.createdBy ? dto.createdBy : null;
        this.updatedBy = dto && dto.updatedBy ? dto.updatedBy : null;
        this.user = dto && dto.user ? dto.user : null;
        this.price = dto && dto.price ? dto.price : 0;
        this.amount = dto && dto.amount ? dto.amount : 0 ;
        this.userDiscount = dto && dto.userDiscount ? dto.userDiscount : 0 ;
        this.status = dto && dto.status ? dto.status : '' ;
        this.changeStatusComment = dto && dto.changeStatusComment ? dto.changeStatusComment : '';
        this.transactionId = dto && dto.transactionId ? dto.transactionId : '';
        this.transactionComments = dto && dto.transactionComments ? dto.transactionComments : '' ;
        this.transactionDate = dto && dto.transactionDate ? moment(dto.transactionDate, Constants.DateTime.API_FORMAT) : moment.min();
        this.refundAmount = dto && dto.refundAmount ? dto.refundAmount : 0 ;
        this.refundTransactionId = dto && dto.refundTransactionId ? dto.refundTransactionId : '' ;
        this.refundDateTime = dto && dto.refundDateTime ?  moment(dto.refundDateTime, Constants.DateTime.API_FORMAT) : moment.min();
        this.salesTax = dto && dto.salesTax ? dto.salesTax : 0;
        this.waiveFee = dto && dto.waiveFee ? dto.waiveFee : false;
        this.addonDefinition_Id = dto && dto.addonDefinition_Id ? dto.addonDefinition_Id : 0;
    }
}