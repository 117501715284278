export class TransactionDetailsSessionDto implements ITransactionDetailsSessionDto{
    id: string;
    startDateTime: string;
    endDateTime: string;
    courtId: number;
    courtName: string;
    price: string;
    type: string;
    typeAlias: string;
    status: string;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : '';
        this.startDateTime = dto && dto.startDateTime ? dto.startDateTime : '';
        this.endDateTime = dto && dto.endDateTime ? dto.endDateTime : '';
        this.courtId = dto && dto.courtId ? dto.courtId : 0;
        this.courtName = dto && dto.courtName ? dto.courtName : '';
        this.price = dto && dto.price ? dto.price : '';
        this.type = dto && dto.type ? dto.type : '';
        this.typeAlias = dto && dto.typeAlias ? dto.typeAlias : '';
        this.status = dto && dto.status ? dto.status : '';
    }
}
