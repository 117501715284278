
import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';

export class AddUserDialogActions {
    static open(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Admin/AddUser/Open',
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Admin/AddUser/Close',
            });
        };
    }
}
