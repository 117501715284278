import BaseReducer from './baseReducer';
import * as StM from '../models/store';

export default class CoachFeeTierPricesReducer extends BaseReducer {
    static handle(state: Array<StM.ICoachFeeTierPriceStoreState> = new Array<StM.ICoachFeeTierPriceStoreState>(), action: any): Array<StM.ICoachFeeTierPriceStoreState> {
        switch (action.type) {
            case 'CoachFeeTierPrices/Init': {
                var prices = <Array<StM.ICoachFeeTierPriceStoreState>>action.payload;
                return [...prices];
            }
            default: {
                return state;
            }
        }
    }
}
