import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class PublishConfirmationDialogReducer extends BaseReducer {
    public static handle(state: StM.IPublishConfirmationDialogStoreState = new StM.PublishConfirmationDialogStoreState(), action: any): StM.IPublishConfirmationDialogStoreState {
        switch (action.type) {
            case 'Dialogs/PublishConfirmation/Open': {
                return <StM.IPublishConfirmationDialogStoreState>_.assign({}, state, {
                    isOpened: true,
                    title: action.title,
                    msg: action.msg,
                    yesBtnTxt: action.yesBtnTxt,
                    noBtnTxt: action.noBtnTxt,
                    yesCallback: action.yesCallback,
                    noCallback: action.noCallback,
                    showExitBtn: action.showExitBtn
                });
            }
            case 'Dialogs/PublishConfirmation/Close': {
                return <StM.IPublishConfirmationDialogStoreState>_.assign({}, state, { isOpened: false });
            }
            default: {
                return state;
            }
        }
    }
}
