import * as StM from '../../../models/store';
import BaseReducer from '../../baseReducer';

export class GroupInfoDialogReducer extends BaseReducer {
    public static handle(state: StM.IGroupInfoDialogStoreState = new StM.GroupInfoDialogStoreState(), action: any): StM.IGroupInfoDialogStoreState {
        switch (action.type) {
            case 'Dialogs/GroupInfo/Open': {
                return {
                    ...state,
                    isOpened: true,
                    ...action.payload,
                } as StM.IGroupInfoDialogStoreState;
            }
            case 'Dialogs/GroupInfo/Close': {
                return {
                    ...state,
                    isOpened: false,
                    from: null,
                    inviteToken: null
                } as StM.IGroupInfoDialogStoreState
            }
            default: {
                return state;
            }
        }
    }
}
