import * as StM from '../../models/store';
import * as _ from 'lodash';

export default class BookPageReducer {

    static handle(state: StM.IClubDashboardPageStoreState = new StM.ClubDashboardPageStoreState(), action: any): StM.IClubDashboardPageStoreState {
        switch (action.type) {
            case 'Pages/ClubDashboard/Init': {
                return state;
            }
            case 'Pages/ClubDashboard/Sessions': {
                let sessions = <Array<StM.ISessionStoreState>>action.payload;
                return {...state, sessions: sessions };
            }
            case 'Pages/ClubDashboard/CourtTimeSlots': {
                let timeSlots = <Array<StM.CourtTimeSlotStoreState>>action.payload;
                return { ...state, timeSlots: timeSlots };
            }
            default: {
                return state;
            }
        }
    }
}
