import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';

export interface ILeagueRequestToJoinDialogStoreState extends IBaseDialogStoreState{
    sessionId: number;
}

export class LeagueRequestToJoinDialogStoreState extends BaseDialogStoreState implements ILeagueRequestToJoinDialogStoreState{
    sessionId: number;
    constructor(){
        super();
        this.sessionId = 0;
    }
}
