export interface ISalesTaxStoreState {
        id: number;
        club: IClubDto;
        subject: string;
}

export class SalesTaxStoreState implements ISalesTaxStoreState {
        id: number;
        club: IClubDto;
        subject: string;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.club = dto && dto.club ? dto.club : null;
        this.subject = dto && dto.subject ? dto.subject : '';
    }
}
