import * as StM from '../../models/store';
import * as _ from 'lodash';
import moment from 'moment';

export default class SchedulePageReducer {

    static handle(state: StM.ISchedulePageStoreState = new StM.SchedulePageStoreState(), action: any): StM.ISchedulePageStoreState {
        switch (action.type) {
            case 'Admin/Pages/Schedule/Init': {
                return state;
            }
            case 'Admin/Pages/Schedule/Sessions': {
                let sessions = <Array<StM.ISessionStoreState>>action.payload;
                return <StM.ISchedulePageStoreState>_.assign({}, state, { sessions: sessions, refresh: false });
            }
            case 'Admin/Pages/Schedule/AddOrUpdateSession': {
                let session = <StM.ISessionStoreState>action.payload;
                let sessions = [...state.sessions];
                let existedSession = _.find(sessions, (i) => i.id === session.id);
                if (!existedSession) {
                    sessions.push(session);
                } else {
                    existedSession = session;
                }
                return <StM.ISchedulePageStoreState>_.assign({}, state, { sessions: sessions, refresh: false });
            }
            case 'Admin/Pages/Schedule/RemoveSession': {
                let session = <StM.ISessionStoreState>action.payload;
                let sessions = [...state.sessions];
                _.remove(sessions, (i) =>  i.id === session.id);
                return <StM.ISchedulePageStoreState>_.assign({}, state, { sessions: sessions, refresh: false });
            }
            case 'Admin/Pages/Schedule/AvailableTimes': {
                let availableTimes = <Array<StM.IAvailableTimeStoreState>>action.payload;
                return <StM.ISchedulePageStoreState>_.assign({}, state, { availableTimes: availableTimes, refresh: false });
            }
            case 'Admin/Pages/Schedule/AvailableTimes/AddTime': {
                let availableTimes = [...state.availableTimes];
                const start = action.start as moment.Moment;
                const duration = action.duration as moment.Duration;
                const end = start.clone().add(duration.asMinutes(), 'minutes');
                const removed = _.remove(availableTimes, (time) => {
                    if (time.ownerId != action.coach.id) return false;
                    if (!time.begin.isSame(action.start, 'day')) return false;
                    const timeEnd = time.begin.clone().add(time.duration.asMinutes(), 'minutes');
                    return time.begin.isSameOrAfter(start) && timeEnd.isSameOrBefore(end);
                });
                availableTimes.push(new StM.AvailableTimeStoreState({
                    begin: action.start,
                    duration: action.duration,
                    ownerId: action.coach.id
                }));

                return <StM.ISchedulePageStoreState>_.assign({}, state, { availableTimes: availableTimes, refresh: false });
            }
            case 'Admin/Pages/Schedule/AvailableTimes/RemoveTime': {
                let availableTimes = [...state.availableTimes];
                const start = action.start as moment.Moment;
                const startMs = moment.duration({ hours: start.hours(), minutes: start.minutes() }).asMilliseconds();
                const duration = action.duration as moment.Duration;
                const end = start.clone().add(duration.asMinutes(), 'minutes');
                const endMs = moment.duration({ hours: end.hours(), minutes: end.minutes() }).asMilliseconds();
                const timesToRemove = availableTimes.filter((time) => {
                    if (time.ownerId != action.coach.id) return false;
                    if (!time.begin.isSame(action.start, 'day')) return false;
                    const timeEnd = time.begin.clone().add(time.duration.asMinutes(), 'minutes');
                    return time.begin.isBefore(end) && timeEnd.isAfter(start);
                });
                const removed = _.remove(availableTimes, (time) => {
                    if (time.ownerId != action.coach.id) return false;
                    if (!time.begin.isSame(action.start, 'day')) return false;
                    const timeEnd = time.begin.clone().add(time.duration.asMinutes(), 'minutes');
                    return time.begin.isBefore(end) && timeEnd.isAfter(start);
                });
                timesToRemove.forEach(time => {
                    const timeEnd = time.begin.clone().add(time.duration.asMinutes(), 'minutes');
                    const beginMs = moment.duration({ hours: time.begin.hours(), minutes: time.begin.minutes() }).asMilliseconds();
                    const timeEndMs = moment.duration({ hours: timeEnd.hours(), minutes: timeEnd.minutes() }).asMilliseconds();
                    let timeToAdd = new StM.AvailableTimeStoreState(time);
                    if (beginMs < startMs && timeEndMs > startMs && timeEndMs <= endMs) {
                        timeToAdd.duration = moment.duration(startMs - beginMs);
                        availableTimes.push(timeToAdd);
                    } else if (beginMs < endMs && beginMs >= startMs && timeEndMs > endMs) {
                        timeToAdd.begin = end;
                        timeToAdd.duration = moment.duration(timeEndMs - endMs);
                        availableTimes.push(timeToAdd);
                    } else if (beginMs < startMs && beginMs < endMs && timeEndMs > startMs && timeEndMs > endMs) {
                        let splitFirst = new StM.AvailableTimeStoreState(time);
                        let splitLast = new StM.AvailableTimeStoreState(time);
                        splitFirst.duration = moment.duration(startMs - beginMs);
                        splitLast.begin = end;
                        splitLast.duration = moment.duration(timeEndMs - endMs);
                        availableTimes.push(splitFirst, splitLast);
                    }
                });

                return <StM.ISchedulePageStoreState>_.assign({}, state, { availableTimes: availableTimes, refresh: false });
            }
            case 'Admin/Pages/Schedule/CourtTimeSlots': {
                let dateTimeSlots = <Array<StM.DateCourtTimeSlotStoreState>>action.payload;
                return <StM.ISchedulePageStoreState>_.assign({}, state, { dateTimeSlots: dateTimeSlots, refresh: false });
            }
            case 'Pages/Book/AvailableTimesLookup': {
                let availableTimesLookup = <IDictionary>action.payload;
                return <StM.ISchedulePageStoreState>_.assign({}, state, { availableTimesLookup: availableTimesLookup, refresh: false });
            }
            case 'Admin/Pages/Schedule/CourtTimeSlotsRefresh': {
                let dateTimeSlots = <Array<StM.DateCourtTimeSlotStoreState>>action.payload;
                return <StM.ISchedulePageStoreState>_.assign({}, state, { dateTimeSlots: dateTimeSlots, refresh: true });
            }
            case 'Admin/Pages/Schedule/Filter': {
                let filter = <StM.ISchedulePageFilter>action.payload;
                return <StM.ISchedulePageStoreState>_.assign({}, state, { filter: filter });
            }
            default: {
                return <StM.ISchedulePageStoreState>_.assign({}, state, { refresh: false });
            }
        }
    }
}
