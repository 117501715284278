import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class CreditTermsDialogReducer extends BaseReducer{

    static handle(state: StM.ICreditTermsDialogStoreState= new StM.CreditTermsDialogStoreState(), action: any): StM. ICreditTermsDialogStoreState{
        switch (action.type) {
            case 'Dialogs/CreditTerms/Open': {
                return {...state, isOpened: true};
            }
            case 'Dialogs/CreditTerms/Close': {
                return {...state, isOpened: false};
            }
            case 'Dialogs/CreditTerms/Accept': {
                return {...state, isAccepted: true};
            }
            case 'Dialogs/CreditTerms/Decline': {
                return {...state, isAccepted: false};
            }
            default: {
                return state;
            }
        }
    }
}
