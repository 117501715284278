import { ISubscriptionOfferStateModel } from '@models/store/subscriptionOffers/subscriptionOfferStateModel';
import { StM } from '../../../modules';

export interface ISubscriptionOffersState {
    definitions: Array<ISubscriptionOfferStateModel>;
    types: Array<StM.IPackageSessionTypeStoreState>;
    currentSubscriptions: IRecurrentPackageCommitmentDetailsDto[];
}

export class SubscriptionOffersState implements ISubscriptionOffersState  {
    definitions: Array<ISubscriptionOfferStateModel>;
    types: Array<StM.IPackageSessionTypeStoreState>;
    currentSubscriptions: IRecurrentPackageCommitmentDetailsDto[];

    constructor(dto?: any) {
        this.definitions = dto?.definitions ?? [];
        this.types = dto?.types ?? [];
        this.currentSubscriptions = [];
    }
}
