import './sessionService.scss';

import * as React from 'react';
import * as _ from 'lodash';
import ClassNames from 'classnames';

import { StM, SrvM } from '../../modules';
import { Switcher } from '../switcher';
import { Field } from '../UI';
import { InputSearch, IInputSearchItem } from '../inputSearch';
import { InputSearchMobile } from '../inputSearch.mobile';

export enum ServiceAction {
    RemoveVideo,
}

interface IProps {
    club: StM.IClubStoreState;
    alias: StM.ServiceAlias;
    services: StM.IAddonDefinitionStoreState[];
    session?: StM.ISessionStoreState;
    active?: StM.IAddonDefinitionStoreState;
    disabled?: boolean;

    onChange?: (service: StM.IAddonDefinitionStoreState, disabled: boolean) => void;
    onAction?: (service: StM.IAddonDefinitionStoreState, action: ServiceAction) => void;
}

interface IState {
    active?: StM.IAddonDefinitionStoreState;
}

export class SessionService extends React.Component<IProps, IState> {
    private utils = new SrvM.Utils();
    private routeSrv = new SrvM.RouteService();
    private isMobile = this.utils.isMobile();
    private shareVideoLinkDOM = null;

    constructor(props: IProps) {
        super(props);

        this.state = {
            active: props.active,
        };
    }

    public componentDidUpdate(prevProps: IProps) {
        if (!_.isEqual(this.props.active, prevProps.active)) {
            this.setState({ ...this.state, active: this.props.active });
        }
    }

    public render() {
        const { alias, services, disabled, session } = this.props;
        const { active } = this.state;
        return (
            <section className='session-service'>
                <h3 className='session-service-title'>
                    {this.getTitle(alias)} {this.getPrices(services)}
                </h3>
                <Switcher 
                    value={!!active} 
                    disabled={disabled} 
                    onSwitch={this.handleSwitch} 
                    dataTestId={`${alias}ServicesSwitch`}
                />
                {!!active && <p className='session-service-note'>{this.getNote(alias)}</p>}
                {this.renderAdditionalControls(alias, services, session)}
            </section>
        );
    }

    private renderAdditionalControls(alias: StM.ServiceAlias, services: StM.IAddonDefinitionStoreState[], session?: StM.ISessionStoreState) {
        switch (alias) {
            case StM.ServiceAlias.Video:
                return this.renderVideoControls(session);
            case StM.ServiceAlias.VideoAnalysis:
                return this.renderVideoAnalysisControls(services);
            default:
                return null;
        }
    }

    private renderVideoAnalysisControls(services: StM.IAddonDefinitionStoreState[]) {
        if (!this.state.active) return null;
        const activeService = this.props.active || this.props.services[0];
        const coaches = this.getVideoAnalysisCoachInputSearchItems(services);
        const selectedCoach = coaches.find((c) => activeService.title === c.value) || coaches[0];
        return (
            <Field name='videoAnalysisCoach' label={!this.isMobile ? 'Coach' : ''} className='session-service-controls'>
                {this.isMobile ? (
                    <InputSearchMobile array={coaches} readOnly selected={selectedCoach.key} onSelectChange={this.handleCoachChange} />
                ) : (
                    <InputSearch array={coaches} readOnly selected={selectedCoach.key} onSelectChange={this.handleCoachChange} />
                )}
            </Field>
        );
    }

    private renderVideoControls(session: StM.ISessionStoreState) {
        if (!session || !session.videoLinkId) return null;
        const videoLink = this.routeSrv.getSessionVideoLink(session.videoLinkId);
        const copyClass = ClassNames('share-btn icon-btn copy', { 'share-btn-touch': this.utils.getIsTouchDetected() });
        const canRemoveVideo = this.canRemoveVideo();
        return (
            <>
                {canRemoveVideo && (
                    <button type='button' className='btn-link' onClick={this.handleRemoveVideo}>
                        Remove Video
                    </button>
                )}
                {!!this.state.active && (
                    <Field name='videoUrl' label='URL' className='session-service-controls link-wrapper'>
                        <input
                            type='text'
                            ref={(elem) => {
                                this.shareVideoLinkDOM = elem;
                            }}
                            value={videoLink}
                            onChange={(e) => false}
                        />

                        <div className='share-btn-wrapper'>
                            <a href={videoLink} target='_blank' className='share-btn icon-btn video' onClick={null}>
                                View video
                            </a>
                            <a className={copyClass} href='#' onClick={(e) => this.shareVideoClickHandler(e)}>
                                Copy link
                            </a>
                            <a className='share-btn icon-btn mail' href='#' onClick={(e) => this.onSendVideoLinkByEmailClick(e, videoLink)}>
                                Send link by email
                            </a>
                        </div>
                    </Field>
                )}
            </>
        );
    }

    // handlers

    private handleSwitch = (value: boolean) => {
        const oldActive = { ...this.state.active };
        const newActive = value ? this.props.services[0] : null;
        this.setState({ ...this.state, active: newActive }, () => {
            this.props.onChange(value ? newActive : oldActive, !value);
        });
    };

    private handleCoachChange = (item: IInputSearchItem) => {
        const active = this.props.services.find((s) => s.title === item.value) || this.props.services[0];
        this.setState({ ...this.state, active }, () => {
            this.props.onChange(active, false);
        });
    };

    private shareVideoClickHandler(e: any) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (this.shareVideoLinkDOM) {
            this.shareVideoLinkDOM.select();
            document.execCommand('copy');
        }
    }

    private onSendVideoLinkByEmailClick(e: any, sharedLink: string) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        const mailToLink = this.utils.getMailToLink('', '', StM.Strings.VideoSharedEmailSubject, sharedLink);
        window.location.href = mailToLink;
    }

    private handleRemoveVideo = (e: any) => {
        if (e) e.preventDefault();
        if (this.props.onAction) this.props.onAction(this.state.active, ServiceAction.RemoveVideo);
    };

    // helpers

    private getTitle(alias: StM.ServiceAlias) {
        let result = '';
        switch (alias) {
            case StM.ServiceAlias.Video:
                result = 'Record a Video';
                break;
            case StM.ServiceAlias.VideoAnalysis:
                result = StM.Strings.VideoAnalysis;
                break;
        }
        return result;
    }

    private getPrices(services: StM.IAddonDefinitionStoreState[]) {
        const prices = services.map((s) => s.price);
        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);
        return `( $${minPrice}${minPrice !== maxPrice ? ` - $${maxPrice}` : ''} )`;
    }

    private getNote(alias: StM.ServiceAlias) {
        let result = '';
        switch (alias) {
            case StM.ServiceAlias.Video:
                result = StM.Strings.CheckInForVideoReminder;
                break;
            case StM.ServiceAlias.VideoAnalysis:
                result = StM.Strings.VideoAnalysisNote;
                break;
        }
        return result;
    }

    private getVideoAnalysisCoachInputSearchItems(services: StM.IAddonDefinitionStoreState[]) {
        return services.map((s) => {
            return {
                key: s.id,
                value: s.title,
                title: `${s.title} ( $${s.price} )`,
            } as IInputSearchItem;
        });
    }

    private canRemoveVideo(): boolean {
        const { session, club } = this.props;
        if (!session || !this.utils.isAdmin()) return false;
        const clubTime = this.utils.getCurrentClubDateTime(club);
        const isSessionStarted = clubTime.isAfter(session.startDateTime);
        const hasCheckedInBookings = session.bookings.some((b) => b.status === StM.BookingStatus.CheckedIn);
        return (
            !(hasCheckedInBookings && !isSessionStarted) &&
            session.videoWasRecorded &&
            session.status != StM.SessionStatus.Closed &&
            session.status != StM.SessionStatus.Cancel &&
            (isSessionStarted || (session.series && session.series.isCourse && clubTime.isAfter(session.series.startDate)))
        );
    }
}
