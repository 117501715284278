import * as _ from 'lodash';

import * as StM from '../models/store';

export default class BannerReducer {
    static handle(state: StM.IBannerStoreState = new StM.BannerStoreState(), action: any): StM.IBannerStoreState {
        switch (action.type) {
            case 'Banner/Init': {
                return <StM.IBannerStoreState>_.assign({}, { isShown: true, ...action.payload });
            }
            case 'Banner/Open': {
                return <StM.IBannerStoreState>_.assign({}, { isShown: true, ...action.payload });
            }
            case 'Banner/Close': {
                return <StM.IBannerStoreState>_.assign({}, { isShown: false, ...action.payload });
            }
            default: { 
                return state;
            }
        }
    }
}
