export class YoutubeIdDto implements IYoutubeIdDto {
        encoderId: number;
        youtubeId: string;
        courtId: number;
        cameraPosition: string;
        encoderName: string;

    constructor(dto?: any) {
        this.encoderId = dto && dto.encoderId ? dto.encoderId : 0;
        this.youtubeId = dto && dto.youtubeId ? dto.youtubeId : '';
        this.courtId = dto && dto.courtId ? dto.courtId : 0;
        this.cameraPosition = dto && dto.cameraPosition ? dto.cameraPosition : '';
        this.encoderName = dto && dto.encoderName ? dto.encoderName : '';
    }
}