import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';
import * as StM from '../../store'; 

// tslint:disable-next-line:no-empty-interface
export interface ISubscriptionAddedDialogStoreState extends IBaseDialogStoreState{}

export class SubscriptionAddedDialogStoreState extends BaseDialogStoreState implements ISubscriptionAddedDialogStoreState{
    isExist: boolean;
    constructor(){
        super()
    }
}
