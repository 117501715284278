import * as Redux from 'redux';

import { StM } from '../modules';

export class SubscriptionOffersActions {
    static init(
        subscriptionDefinitions: StM.ISubscriptionOfferStateModel[],
        subscriptionSessionTypes: StM.ISubscriptionOfferSessionTypeState[]
    ): (dispatch: any) => void {
        return (dispatch: any) => {
            const types = Object.keys(StM.PackageSessionTypeGroup).reduce((result, group) => {
                const sessionTypes = subscriptionSessionTypes.filter((t) => t.group === group);
                if (sessionTypes.length) {
                    result.push(...sessionTypes);
                }
                return result;
            }, [] as StM.IPackageSessionTypeStoreState[]);
            dispatch({
                type: 'SubscriptionOffers/Init',
                payload: { definitions: subscriptionDefinitions, types },
            });
        };
    }
}
