/// <reference path="../../../typings/index.d.ts" />
import { BaseApiService } from '../baseApiService';
import * as StM from '../../models/store';

export class PackageApiService extends BaseApiService {
    private static _instance: PackageApiService;

    constructor(config?: any) {
        if (typeof PackageApiService._instance == "undefined") {
            super(config);
            PackageApiService._instance = this;
        }
        return PackageApiService._instance;
    }

    public getAll(): Promise<Array<IPackageDefinitionDto>> {
        return this.get('')
        .then((response: any) => {
            return <Array<IPackageDefinitionDto>>response.data
        });
    }

    public checkout(definition: StM.IPackageDefinitionStoreState): Promise<IPackageDto>{
       return this.put('/', definition)
        .then((response: any) => {
            return <IPackageDto>response.data;
        });
    }  
    
    public checkoutMultiple(definitions: Array<StM.IPackageDefinitionStoreState>): Promise<Array<StM.IPackageDefinitionStoreState>>{
        return this.post('/', this.mapper.getPackageDefinitionDtosFromModels(definitions))
         .then((response: any) => {
            let models = this.mapper.getPackageDefinitionModelsFromDtos(<Array<IPackageDefinitionDto>>response.data);
            return models;
         });
     } 
}
