export class SubscriptionOfferDto {
    billingPeriod: string;
    coachFeeTierId: number;
    creditsDefinitions: IRecurrentPackageCreditsDefinitionDto[];
    currentVersion: number;
    description: string;
    id: number;
    mandatoryIntroPeriod: number;
    price: number;
    status: string;
    statusHistory: any[];
    title: string;

    constructor(dto?: any) {
        this.billingPeriod = dto?.billingPeriod ? dto.billingPeriod : '';
        this.coachFeeTierId = dto?.coachFeeTierId ? dto.coachFeeTierId : 0;
        this.creditsDefinitions = dto?.creditsDefinitions ? dto.creditsDefinitions : [];
        this.currentVersion = dto?.currentVersion ? dto.currentVersion : 0;
        this.description = dto?.description ? dto.description : '';
        this.id = dto?.id ? dto.id : 0;
        this.mandatoryIntroPeriod = dto?.mandatoryIntroPeriod ? dto.mandatoryIntroPeriod : 0;
        this.price = dto?.price ? dto.price : 0;
        this.status = dto?.status ? dto.status : '';
        this.title = dto?.title ? dto.title : '';
        this.statusHistory = dto?.statusHistory ?? [];
    }
}
