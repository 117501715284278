import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';

export class ConfirmDeleteBasketItemDialogActions {
    static open(basketIndex:number, isPackage: boolean = false): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/ConfirmDeleteBasketItem/Open',
                basketIndex: basketIndex,
                isPackage: isPackage
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/ConfirmDeleteBasketItem/Close',
            });
        };
    }
}
