import * as Redux from 'redux';
import * as _ from 'lodash';

import {ThunkService} from '../../services/thunkService';
import { StM, ActM, PolM } from '../../modules';

export class ClinicListViewActions {

    public static init()
    : (d: any, gs: () => StM.IGlobalStoreState, ts: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(ActM.AppActions.showSpinner());
            
            const final = () => {
                dispatch(ActM.AppActions.hideSpinner());
                dispatch(ActM.BaseActions.hideSplash());
            }

            return Promise.resolve(dispatch(ClinicListViewActions.getClinics()))
                .then(() => {
                    final();
                }).catch(() => {
                    final();
                });
        };
    }

    public static getClinics()
    : (d: any, gs: () => StM.IGlobalStoreState, ts: ThunkService) => Promise<Array<StM.ISessionStoreState>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers, basket } =  getState();
            const basketSessions = basket.goods;

            return thunkService.api.session.getClinics(club, pricingTiers)
            .then((response: any) => {
                const clinics = <Array<StM.ISessionStoreState>>response;
                const availableClinics = _.filter(clinics, (session: StM.ISessionStoreState) => {
                    const sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(session, basketSessions);
                    return sessionAvailabilityPolicy.handle() && sessionAvailabilityPolicy.getIsHaveFreePlaces();
                })
                .sort((session: StM.ISessionStoreState) => { 
                    return  -session.startDateTime.valueOf(); 
                });
                dispatch({
                    type: 'Pages/Book/Clinics',
                    payload: availableClinics
                });
                
                return availableClinics;
            }).catch((error) => {
                thunkService.logger.error(error);
                return [];
            });
        };
    }
}
