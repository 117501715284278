import * as _ from 'lodash';

import { BaseApiService } from '../baseApiService';
import * as StM from '../../models/store';

export class PricingApiService extends BaseApiService {
    private static _instance: PricingApiService;

    constructor(config?: any) {
        if (typeof PricingApiService._instance == "undefined") {
            super(config);
            PricingApiService._instance = this;
        }
        return PricingApiService._instance;
    }

    public getPricingTiers(): Promise<Array<StM.IPricingTierStoreState>> {
        return this.get('/tiers')
            .then((response: any) => {
                let models = this.mapper.getPricingTierModelsFromDtos(<Array<IPricingTierDto>>response.data);
                return models;
            });
    }

    public savePricingTiers(tiers: StM.IPricingTierStoreState[]): Promise<any> {
        return this.post('/tiers', this.mapper.getPricingTierDtosFromModels(tiers))
            .then((response: any) => {
                let models = this.mapper.getPricingTierModelsFromDtos(<Array<IPricingTierDto>>response.data);
                return models;
            }).catch((error) => {
                throw error.response.data;
            });
    }

    public deletePricingTier(id: number): Promise<any> {
        return this.delete('/tiers/{0}'.format(id))
            .then((response: any) => {
                return response.data;
            }).catch((error) => {
                throw error.response.data;
            });
    }
    
    public getCoachFeeTierPrices(): Promise<Array<StM.ICoachFeeTierPriceStoreState>> {
        return this.get('/price/coachTiers')
            .then((response: any) => {
                let models = this.mapper.getCoachFeeTierPriceModelsFromDtos(<Array<ICoachFeeTierPriceDto>>response.data);
                return models;
            });
    }

    public getCoachFeeTierPrice(coachTierId: number): Promise<StM.ICoachFeeTierPriceStoreState> {
        return this.get('/price/coachTiers/{0}'.format(coachTierId))
            .then((response: any) => {
                let models = this.mapper.getCoachFeeTierPriceModelFromDto(<ICoachFeeTierPriceDto>response.data);
                return models;
            });
    }

    public saveCoachFeeTierPrices(tiers: StM.ICoachFeeTierPriceStoreState[]): Promise<any> {
        return this.post('/price/coachTiers', this.mapper.getCoachFeeTierPriceDtosFromModels(tiers))
            .then((response: any) => {
                let models = this.mapper.getCoachFeeTierPriceModelsFromDtos(<Array<ICoachFeeTierPriceDto>>response.data);
                return models;
            }).catch((error) => {
                throw error.response.data;
            });
    }

}
