import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';
import * as StM from '../../store'; 

export class SessionInfoFromTypes {
    static basket = 'basket';
    static calendar = 'calendar';
    static notification = 'notification';
    static inviteLink = 'invite-link';
    static inviteAcceptLink = 'accept-invite-link';
    static inviteRejectLink = 'reject-invite-link';
}

export interface ISessionInfoDialogStoreState extends IBaseDialogStoreState{
    id: number;
    from: string;
    session: StM.ISessionStoreState;
    inviteToken: string;
}

export class SessionInfoDialogStoreState extends BaseDialogStoreState implements ISessionInfoDialogStoreState{
    id: number;
    from: string;
    session: StM.ISessionStoreState;
    inviteToken: string;
    
    constructor(){
        super();
        this.id = 0;
        this.from = '';
        this.session = null;
        this.inviteToken = '';
    }
}