import { BaseDialogStoreState, IBaseDialogStoreState } from './baseDialogStoreState';

export interface ISubscriptionNotificationDialogStoreState extends IBaseDialogStoreState {
    id: number;
    sendNotificationCallback: () => void;
}

export class SubscriptionNotificationDialogStoreState extends BaseDialogStoreState implements ISubscriptionNotificationDialogStoreState {
    id: number;
    sendNotificationCallback: () => void;
    
    constructor() {
        super();
        this.id = 0;
    }
}
