import React, { useRef, useState } from 'react';

const VirtualList = ({ height, itemHeight, itemCount, renderItem, noItemsRenderer }) => {
  const [scrollTop, setScrollTop] = useState(0);
  const containerRef = useRef(null);

  const onScroll = () => {
    if (containerRef.current) {
      setScrollTop(containerRef.current.scrollTop);
    }
  };

  const getVisibleRange = () => {
    const startIndex = Math.floor(scrollTop / itemHeight);
    const endIndex = Math.min(itemCount - 1, Math.floor((scrollTop + height) / itemHeight));
    return [startIndex, endIndex];
  };

  const [startIndex, endIndex] = getVisibleRange();

  const items = [];
  for (let i = startIndex; i <= endIndex; i++) {
    items.push(
      <div key={i} style={{ position: 'absolute', top: `${i * itemHeight}px`, height: `${itemHeight}px`, width: '100%' }}>
        {renderItem(i)}
      </div>
    );
  }

  const containerHeight = itemCount * itemHeight;

  return (
    <div
      ref={containerRef}
      style={{ position: 'relative', height: `${height}px`, overflowY: 'auto', overflowX: 'hidden' }}
      onScroll={onScroll}
    >
      <div style={{ position: 'relative', height: `${containerHeight}px` }}>
        {items.length > 0 ? items : noItemsRenderer()}
      </div>
    </div>
  );
};

export default VirtualList;
