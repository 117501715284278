import moment from 'moment';
import { IAuthenticationTicketStoreState } from '../models/store';

export default class AuthTicketReducer {
    static handle(state: IAuthenticationTicketStoreState = null, action: any): IAuthenticationTicketStoreState {
        switch (action.type) {
            case 'AuthTicket/Init': {
                const authTicket = <IAuthenticationTicketStoreState>action.payload;
                return {...authTicket};
            }
            case 'AuthTicket/LastAction': {
                const lastAction = <moment.Moment>action.payload;
                return {...state, lastAction: lastAction };
            }
            case 'AuthTicket/Clear': {
                return null;
            }
            default: {
                return state;
            }
        }
    }
}
