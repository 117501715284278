export class CourtDto implements ICourtDto {
        id: number;
        title: string;
        clubId: number;
        startTimeOffset: number;
        order: number;
        createdDate: string;


    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.title = dto && dto.title ? dto.title : '';
        this.clubId = dto && dto.clubId ? dto.clubId : 0;
        this.startTimeOffset = dto && dto.startTimeOffset ? dto.startTimeOffset : 0;
        this.order = dto && dto.order ? dto.order : 0;
        this.createdDate = dto && dto.createdDate ? dto.createdDate : '';
    }
}
