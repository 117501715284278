import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';
import * as StM from '../../store'; 

export interface IDemographicInformationDialogStoreState extends IBaseDialogStoreState {
    returnUrl: string;
}

export class DemographicInformationDialogStoreState extends BaseDialogStoreState implements IDemographicInformationDialogStoreState{
    returnUrl: string;
    constructor(){
        super();
        this.returnUrl = '';
    }
}