import * as Redux from 'redux';

import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';

export class AlertDialogActions {
    static open(msgKey: string, messageType: string = StM.MessageTypes.Success, message: string = null, isOpenByService?: boolean): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Alert/Open',
                msgKey: msgKey,
                messageType: messageType,
                message: message,
                isOpenByService
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Alert/Close',
            });
        };
    }
}
