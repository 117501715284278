import * as Redux from 'redux';
import * as _ from 'lodash';

import {ThunkService} from '../services/thunkService';
import * as DtoModule from '../models/dto';
import * as StoreModule from '../models/store';

export class CoachesActions {

    static init(coaches: Array<StoreModule.ICoachStoreState>): (dispatch: any, getState: any, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Coaches/Init',
                payload: coaches
            });
        };
    }
}
