import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';
import * as StM from '../../store'; 

export interface IConfirmDeleteBasketItemStoreState extends IBaseDialogStoreState{
    basketIndex:number;
    isPackage: boolean;
}

export class ConfirmDeleteBasketItemStoreState extends BaseDialogStoreState{
    basketIndex:number;
    isPackage: boolean;
    constructor(){
        super();
    }
}