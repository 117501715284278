import '../dialogs.scss';
import './passwordChangeDialog.scss';

import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import ClassNames from 'classnames';

import * as StM from '../../../models/store';
import * as ActM from '../../../actions';
import * as SrvM from '../../../services';
import { Modal } from '../../../components';

interface IPasswordChangingDialogProps {
    isShown: boolean;
    isAuth: boolean;
    hash: string;
    email: string;
    user: StM.UserStoreState;
    
    closeDialog: () => void;
    showSpinner: () => any;
    hideSpinner: () => any;
    changePassword: (oldPassword: string, newPassword: string) => Promise<any>;
    recoveryPassword: (email: string, password: string, hash: string) => Promise<any>;
    showAlert: (msgKey: string) => Promise<any>;
    closeAlert: () => void;
}

interface IPasswordChangingDialogState {
    isRecovery: boolean;
    password: string;
    newPassword: string;
    repeatNewPassword: string;
    
    isPasswordError: boolean;
    isNewPasswordError: boolean;
    isNewPasswordRepeatError: boolean;

    error: string;
    errorTextPassword: string;
    errorTextNewPassword: string;
    errorTextRepeatNewPassword: string;
}

class PasswordChangingDialog extends React.Component<IPasswordChangingDialogProps, IPasswordChangingDialogState> {
    private authSrv = new SrvM.AuthenticationService();
    constructor(props: IPasswordChangingDialogProps) {
        super(props);
        this.state = {password: ''
            , newPassword: ''
            , repeatNewPassword: ''
            , isRecovery: false   
            , isPasswordError: false
            , isNewPasswordError: false
            , isNewPasswordRepeatError: false
            , error: ''
            , errorTextPassword: ''
            , errorTextNewPassword: ''
            , errorTextRepeatNewPassword: ''
        };
    }

    componentDidMount() {
        this.init(this.props);
    }

    componentDidUpdate(prevProps: IPasswordChangingDialogProps) {
        if (this.props.isShown !== prevProps.isShown || this.props.hash !== prevProps.hash) {
            this.setState({
                password: '',
                newPassword: '',
                repeatNewPassword: '',
                isRecovery: !!this.props.isShown && !!this.props.hash,
                isPasswordError: false,
                isNewPasswordError: false,
                isNewPasswordRepeatError: false,
                error: '',
                errorTextPassword: '',
                errorTextNewPassword: '',
                errorTextRepeatNewPassword: '',
            });
        }

        if (this.props.isAuth && !prevProps.isAuth && this.props.isShown) {
            this.props.closeDialog();
        }

        this.init(this.props);
    }

    public render() {
        const title = this.state.isRecovery ? 'Recovery Password' : 'Change Password';
        const passwordClasses = ClassNames("input-item", {error: this.state.isPasswordError});
        const newPasswordClasses = ClassNames("input-item", {error: this.state.isNewPasswordError});
        const repeatNewPasswordClasses = ClassNames("input-item", {error: this.state.isNewPasswordRepeatError});
        if (this.props.isShown) {
            return (
                <Modal classes="change-pass-modal" closeHandler={this.props.closeDialog} dialogName={StM.DialogNames.PasswordChange}>
                    <div className="modal-dialog dialog">
                            <div className="modal-dialog-header">
                                <span className="close-dialog" onClick={(e) => this.onCloseClick(e)}></span>
                                <div className="title">{title}</div>
                            </div>
                            <form onSubmit={e => this.onChangeClick(e)}>
                                <div className="modal-dialog-body profile-edit">
                                    <div className="modal-dialog-body-content ">
                                        <div className="form-wrapper">
                                            <div className="inputs-wrapper">
                                                {this.state.isRecovery ? null :
                                                    (<div className={passwordClasses} >
                                                        <label>Old Password</label>
                                                        <div>
                                                            <input type="password" maxLength={128} onChange={(e) => {this.onPasswordChangingHandler(e)}}/>                                                        
                                                        </div>
                                                        {this.state.isPasswordError && <div className="validation-error">{this.state.errorTextPassword}</div>}
                                                    </div>)
                                                }
                                                <div className={newPasswordClasses}>
                                                    <label>New Password</label>
                                                    <div>
                                                        <input type="password" maxLength={128} onChange={(e) => {this.onNewPasswordChangingHandler(e)}}/>
                                                    </div>
                                                    {this.state.isNewPasswordError && <div className="validation-error">{this.state.errorTextNewPassword}</div>}
                                                </div>
                                                <div className={repeatNewPasswordClasses}>
                                                    <label>Repeat New Password</label>
                                                    <div>
                                                        <input type="password" maxLength={128} onChange={(e) => {this.onRepeatNewPasswordChangingHandler(e)}}/>
                                                    </div>
                                                    {this.state.isNewPasswordRepeatError && <div className="validation-error">{this.state.errorTextRepeatNewPassword}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.error && (
                                        <div className="error-message-wrapper">
                                            <span className="error-message-title">{this.state.error}</span>
                                        </div>
                                    )}
                                    <div className="divider-line"></div>
                                    <div className="btns-wrapper modal-dialog-body-content">
                                        <button className="btn-next btn-red" onClick={(e) => this.onChangeClick(e)}>Change</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                </Modal>
            );
        } else {
            return null;
        }
    }

    private init(props: IPasswordChangingDialogProps) {
        if(this.authSrv.isInRole(StM.Roles.GroupAccount, props.user)){
            props.closeDialog();
        }
    }

    private onCloseClick(e:any) {
        if(e) {e.preventDefault(); e.stopPropagation();}
        this.props.closeDialog();
    }

    private onPasswordChangingHandler(e:any) {
        if(e) {e.preventDefault(); e.stopPropagation();}
        this.setState({...this.state, ...{error: '', isPasswordError: false, errorTextPassword: '', password: e.target.value}});
    }

    private onNewPasswordChangingHandler(e:any) {
        if(e) {e.preventDefault(); e.stopPropagation();}
        this.setState({...this.state, ...{error: '', isNewPasswordError: false, errorTextNewPassword: '', newPassword: e.target.value}});
    }

    private onRepeatNewPasswordChangingHandler(e:any) {
        if(e) {e.preventDefault(); e.stopPropagation();}
        this.setState({...this.state, ...{error: '', isNewPasswordRepeatError: false, errorTextRepeatNewPassword: '', repeatNewPassword: e.target.value}});
    }

    private onChangeClick(e:any) {
        if(e) {e.preventDefault(); e.stopPropagation();}

        if (!this.getIsValidForm()){
            return ;
        }

        let prom = this.state.isRecovery 
            ? this.props.recoveryPassword(this.props.email, this.state.newPassword, this.props.hash)
            : this.props.changePassword(this.state.password, this.state.newPassword)
        ;

        this.props.showSpinner();
        prom.then(() => {
            this.props.hideSpinner();
            this.props.closeDialog();
            this.props.showAlert(StM.MessagesKey.PasswordResetSuccess);
        }).catch((error: { exceptionMessage: string, response: {data:{ exceptionMessage: string} }}) => {
            this.props.hideSpinner();
            this.props.closeAlert();
            if(error) {
                let message = error.exceptionMessage || error.response.data.exceptionMessage;
                if(message === 'Invalid token.'){
                    message = 'Sorry, this password recovery link expired or was already used earlier.';
                }

                const isPasswordError = message == 'Incorrect password.';
                if(isPasswordError) {
                    this.setState({...this.state, errorTextPassword: message, isPasswordError: isPasswordError});
               
                }else{
                    message && this.setState({...this.state, error: message, isPasswordError: isPasswordError});
                }
            }else{
                this.setState({...this.state, ...{error: 'Error has occured'}});
            }
        });
    }

    private getIsValidForm(){
        this.setState({...this.state, ...{
            isPasswordError: false
            , isNewPasswordError: false
            , isNewPasswordRepeatError: false
        }});
        
        if(this.state.newPassword !== this.state.repeatNewPassword){
            const errorTextNewPassword = 'Passwords don\'t match';
            this.setState({...this.state,
                errorTextNewPassword: errorTextNewPassword
                , errorTextRepeatNewPassword: errorTextNewPassword
                , isNewPasswordError: true
                , isNewPasswordRepeatError: true
            });
            return false;
        }

        if(!this.state.isRecovery && !this.getIsValidPass()){
            return false;
        }

        if(!this.getIsValidNewPass()){
            return false;
        }

        if(!this.getIsValidRepeatNewPass()){
            return false;
        }
        return true;
    }

    private getIsValidPass(){
        if (!this.state.password) {
            this.setState({...this.state, ...{
                error: ''
                , isPasswordError: true
                , errorTextPassword: 'Please provide your old password'
            }});
            return false;
        }

        if(this.state.password.length < 6 && this.state.password.length > 0){
            this.setState({...this.state, ...{
                error: ''
                , isPasswordError: true
                , errorTextPassword: 'Password must be at least 6 characters long'
            }});
            return false;
        }
        return true;
    }

    private getIsValidNewPass(){
        if (!this.state.newPassword) {
            this.setState({...this.state, ...{
                error: ''
                , isNewPasswordError: true
                , errorTextNewPassword: 'Please provide your new password'
            }});
            return false;
        }

        if(this.state.newPassword.length < 6 && this.state.newPassword.length > 0){
            this.setState({...this.state, ...{
                error: ''
                , isNewPasswordError: true
                , errorTextNewPassword: 'Password must be at least 6 characters long'
            }});
            return false;
        }
        return true;
    }

    private getIsValidRepeatNewPass(){
        if (!this.state.repeatNewPassword) {
            this.setState({...this.state, ...{
                error: ''
                , isNewPasswordRepeatError: true
                , errorTextRepeatNewPassword: 'Please provide your new password again'
            }});
            return false;
        }        

        if(this.state.repeatNewPassword.length < 6 && this.state.repeatNewPassword.length > 0){
            this.setState({...this.state, ...{
                error: ''
                , isNewPasswordRepeatError: true
                , errorTextRepeatNewPassword: 'Password must be at least 6 characters long'
            }});
            return false;
        }
        return true;
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    return {
        isShown: state.dialogs.passwordChanging.isOpened
        , hash: state.dialogs.passwordChanging.hash
        , email: state.dialogs.passwordChanging.email
        , user: state.user
        , isAuth: state.app.isAuthorized
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: () => dispatch(ActM.DialogActions.close(StM.DialogNames.PasswordChange))
        , showSpinner: () => dispatch(ActM.AppActions.showSpinner())
        , hideSpinner: () => dispatch(ActM.AppActions.hideSpinner())
        , changePassword: (oldPassword: string, newPassword: string) => dispatch(ActM.PasswordChangingDialogActions.change(oldPassword, newPassword))
        , recoveryPassword: (email: string, password: string, hash: string) => dispatch(ActM.PasswordChangingDialogActions.recovery(email, password, hash))
        , showAlert: (msgKey: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, {msgKey: msgKey, messageType: StM.MessageTypes.Success}))
        , closeAlert: () => dispatch(ActM.DialogActions.close(StM.DialogNames.Alert, null, true))
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChangingDialog);
