import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class HistoryQueryDialogReducer extends BaseReducer{

    static handle(state: StM.IHistoryQueryDialogStoreState = new StM.HistoryQueryDialogStoreState(), action: any): StM.IHistoryQueryDialogStoreState{
        switch (action.type) {
            case 'Dialogs/HistoryQuery/Open': {
                return <StM.IHistoryQueryDialogStoreState>_.assign({}, state, { isOpened: true, paymentId: action.paymentId});
            }
            case 'Dialogs/HistoryQuery/Close': {
                return <StM.IHistoryQueryDialogStoreState>_.assign({}, state, { isOpened: false});
            }
            default: {
                return state;
            }
        }
    }
}
