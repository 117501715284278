import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class PackageAddedDialogReducer extends BaseReducer{

    static handle(state: StM.IPackageAddedDialogStoreState = new StM.PackageAddedDialogStoreState(), action: any): StM.IPackageAddedDialogStoreState{
        switch (action.type) {
            case 'Dialogs/PackageAdded/Open': {
                return { ...state, isOpened: true};
            }
            case 'Dialogs/PackageAdded/Close': {
                return {...state, isOpened: false};
            }
            default: {
                return state;
            }
        }
    }
}
