import * as Redux from 'redux';

import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';

export class CancelConfirmationActions {
    static open(
        msgKey: string, 
        messageType: string = StM.MessageTypes.Success, 
        message: string = null, 
        isOpenByService?: boolean,
        acceptButtonText?: string,
        rejectButtonText?: string,
        yesCallback?: () => void 
    ): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/CancelConfirmation/Open',
                msgKey: msgKey,
                messageType: messageType,
                message: message,
                isOpenByService,
                acceptButtonText,
                rejectButtonText,
                yesCallback
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/CancelConfirmation/Close',
            });
        };
    }
}
