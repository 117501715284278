import * as StM from '../../models/store';
import * as DlgActM from './';
import * as SrvM from '../../services';
import { SubscriptionPurchaseDialogActions } from './subscriptionPurchaseDialogActions';

export interface IOpenedDialogPromise {
    promise: Promise<any>;
    resolve: (value?: any) => void;
    reject: (error?: any) => void;
    resolveData: any;
    rejectData: any;
}

export class DialogActions {

    private static openedDialogPromises = {} as IDictionary;

    static getScrollbarWidth() {
        const outer: any = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.width = '100px';
        outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps

        document.body.appendChild(outer);

        const widthNoScroll = outer.offsetWidth;
        // force scrollbars
        outer.style.overflow = 'scroll';

        // add innerdiv
        const inner = document.createElement('div');
        inner.style.width = '100%';
        outer.appendChild(inner);

        const widthWithScroll = inner.offsetWidth;

        // remove divs
        outer.parentNode.removeChild(outer);

        return widthNoScroll - widthWithScroll;
    }

    static setModalScroll() {
        const scrollbar = this.getScrollbarWidth();
        const root = document.getElementById('root');
        if (!!document.body.classList.contains && !document.body.classList.contains('modal-open')) {
            document.body.classList.add('modal-open');
            root.style.paddingRight = scrollbar + 'px';
        }

    }

    static removeModalScroll() {
        const root = document.getElementById('root')
        document.body.classList.remove('modal-open');
        root.style.paddingRight = '0px';
    }

    static open(dialogName: string, options?: any, isOpenByService?: boolean): (dispatch: any, getState: any, thunkService: SrvM.ThunkService) => Promise<any> {
        return (dispatch: any, getState: any, thunkService: SrvM.ThunkService) => {

            const prom = DialogActions.getPromise(dialogName);
            if (thunkService.routeDialogService.getIsRouteDialog(dialogName) && !isOpenByService) {
                thunkService.routeDialogService.changeHashByOpeningDialog(dialogName, options);
            }

            this.setModalScroll();
            const returnUrl = options && options.returnUrl ? options.returnUrl : '';
            switch (dialogName) {
                case StM.DialogNames.Auth: {
                    dispatch(DlgActM.AuthDialogActions.open(options.tab, returnUrl));
                    break;
                }
                case StM.DialogNames.NewSession: {
                    dispatch(DlgActM.CreateSessionDialogActions.open(
                            options.courtId,
                            options.timeKey,
                            options.sessionType,
                            options.sessionSubFilter,
                            options.sessionSubFilterId,
                            options.sessionId
                        )
                    );
                    break;
                }
                case StM.DialogNames.Book: {
                    dispatch(DlgActM.BookDialogActions.open());
                    break;
                }
                case StM.DialogNames.PackagePurchase: {
                    dispatch(DlgActM.PackagePurchaseDialogActions.open(options.packageDefinition, options.packageDefinitionId));
                    break;
                }
                case StM.DialogNames.SubscriptionPurchase: {
                    dispatch(DlgActM.SubscriptionPurchaseDialogActions.open(options.subscription, options.id));
                    break;
                }
                case StM.DialogNames.BillingInfo: {
                    dispatch(DlgActM.BillingInfoDialogActions.open(returnUrl));
                    break;
                }
                case StM.DialogNames.SessionInfo: {
                    dispatch(DlgActM.SessionInfoDialogActions.open(options.from, options.id));
                    break;
                }
                case StM.DialogNames.UserEdit: {
                    dispatch(DlgActM.UserEditDialogActions.open());
                    break;
                }
                case StM.DialogNames.PasswordRecovery: {
                    dispatch(DlgActM.PasswordRecoveryDialogActions.open());
                    break;
                }
                case StM.DialogNames.PasswordChange: {
                    dispatch(DlgActM.PasswordChangingDialogActions.open(options.hash, options.email));
                    break;
                }
                case StM.DialogNames.HistoryQuery: {
                    dispatch(DlgActM.HistoryQueryDialogActions.open(options.paymentId));
                    break;
                }
                case StM.DialogNames.LeagueRequestToJoin: {
                    dispatch(DlgActM.LeagueRequestToJoinDialogActions.open(options.sessionId));
                    break;
                }

                case StM.DialogNames.ContactUs: {
                    dispatch(DlgActM.ContactUsDialogActions.open());
                    break;
                }

                case StM.DialogNames.ConfirmDeleteBasketItem: {
                    dispatch(DlgActM.ConfirmDeleteBasketItemDialogActions.open(options.basketIndex, options.isPackage));
                    break;
                }
                case StM.DialogNames.Alert: {
                    dispatch(DlgActM.AlertDialogActions.open(options.msgKey, options.messageType, options.message ? decodeURI(options.message) : null, isOpenByService));
                    break;
                }
                case StM.DialogNames.CancelConfirmation: {
                    dispatch(DlgActM.CancelConfirmationActions.open(
                        options.msgKey, options.messageType,
                        options.message ? decodeURI(options.message) : null,
                        isOpenByService,
                        options.acceptButtonText ? decodeURI(options.acceptButtonText) : 'Ok',
                        options.rejectButtonText ? decodeURI(options.rejectButtonText) : 'Cancel',
                        options.yesCallback
                    ));
                    break;
                }
                case StM.DialogNames.SessionCreateSuccess: {
                    dispatch(DlgActM.SessionCreateSuccessDialogActions.open(options.isExist));
                    break;
                }
                case StM.DialogNames.PackageAdded: {
                    dispatch(DlgActM.PackageAddedDialogActions.open());
                    break;
                }
                case StM.DialogNames.Waiver: {
                    dispatch(DlgActM.WaiverDialogActions.open());
                    break;
                }
                case StM.DialogNames.TermsAndConditions: {
                    dispatch(DlgActM.TermsAndConditionsDialogActions.open(options ? options.noConfirmation : false));
                    break;
                }
                case StM.DialogNames.CreditTerms: {
                    dispatch(DlgActM.CreditTermsDialogActions.open());
                    break;
                }
                case StM.DialogNames.PrivacyPolicy: {
                    dispatch(DlgActM.PrivacyPolicyDialogActions.open());
                    break;
                }
                case StM.DialogNames.DemographicInformation: {
                    dispatch(DlgActM.DemographicInformationDialogActions.open(returnUrl));
                    break;
                }
                case StM.DialogNames.Confirm: {
                    dispatch(DlgActM.ConfirmDialogActions.open(
                        options.msg
                        , options.yesCallback
                        , options.noCallback
                        , options.yesBtnTxt
                        , options.noBtnTxt
                        , options.showExitBtn
                    ));
                    break;
                }
                case StM.DialogNames.CreateGroupAccount: {
                    dispatch(DlgActM.CreateGroupAccountDialogActions.open());
                    break;
                }
                case StM.DialogNames.InviteGroupMember: {
                    dispatch(DlgActM.InviteGroupMemberDialogActions.open(options.groupId));
                    break;
                }
                case StM.DialogNames.EditGroupAccount: {
                    dispatch(DlgActM.EditGroupAccountDialogActions.open());
                    break;
                }
                case StM.DialogNames.GroupInfo: {
                    dispatch(DlgActM.GroupInfoDialogActions.open(options.from, options.inviteToken));
                    break;
                }

                // admin dialogs
                case StM.DialogNames.NewClub: {
                    dispatch(DlgActM.NewClubDialogActions.open());
                    break;
                }
                case StM.DialogNames.TransactionRefund: {
                    dispatch(DlgActM.TransactionRefundDialogActions.open(options.transaction, options.closeCallback));
                    break;
                }
                case StM.AdminDialogNames.NewSession: {
                    dispatch(DlgActM.CreateSessionAdminDialogActions.open(
                            options.courtId,
                            options.timeKey,
                            options.time,
                            options.sessionId
                        )
                    );
                    break;
                }
                case StM.AdminDialogNames.SessionInfo: {
                    dispatch(DlgActM.SessionInfoAdminDialogActions.open(options.sessionId));
                    break;
                }
                case StM.AdminDialogNames.AddUser: {
                    dispatch(DlgActM.AddUserDialogActions.open());
                    break;
                }
                case StM.AdminDialogNames.EditUser: {
                    dispatch(DlgActM.EditUserAdminDialogActions.open(options.userId, options.sectionType));
                    break;
                }
                case StM.AdminDialogNames.EditGroup: {
                    dispatch(DlgActM.EditGroupDialogActions.open(options.userId, options.sectionType));
                    break;
                }
                case StM.AdminDialogNames.EditCoachFeeTier: {
                    dispatch(DlgActM.EditCoachFeeTierDialogActions.open(options.id));
                    break;
                }
                case StM.AdminDialogNames.EditPackage: {
                    dispatch(DlgActM.EditPackageDialogActions.open(options.id));
                    break;
                }
                case StM.AdminDialogNames.EditSubscription: {
                    dispatch(DlgActM.EditSubscriptionDialogActions.open(options.id));
                    break;
                }
                case StM.AdminDialogNames.SubscriptionHistory: {
                    dispatch(DlgActM.SubscriptionHistoryDialogActions.open(options.id));
                    break;
                }
                case StM.AdminDialogNames.SubscriptionNotification: {
                    dispatch(DlgActM.SubscriptionNotificationDialogActions.open(
                        options.id, 
                        options.sendNotificationCallback
                    ));
                    break;
                }
                case StM.AdminDialogNames.SubscribersList: {
                    dispatch(DlgActM.SubscribersListDialogActions.open(options.id));
                    break;
                }
                case StM.DialogNames.PublishConfirmation: {
                    dispatch(DlgActM.PublishConfirmationDialogActions.open(
                        options.title,
                        options.msg,
                        options.yesCallback,
                        options.noCallback,
                        options.yesBtnTxt,
                        options.noBtnTxt,
                        options.showExitBtn,
                    ));
                    break;
                }
                case StM.AdminDialogNames.EditService: {
                    dispatch(DlgActM.EditAddonDialogActions.open(options.id));
                    break;
                }
                case StM.AdminDialogNames.CreateNotification: {
                    dispatch(DlgActM.CreateNotificationDialogActions.open());
                    break;
                }
                case StM.AdminDialogNames.AssignCoachFeeTierToCourts: {
                    dispatch(DlgActM.AssignCoachFeeTierToCourtsDialogActions.open(options.id));
                    break;
                }
            }
            return prom;
        };
    }

    static close(dialogName: string, options?: any, isOpenByService?: boolean): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: SrvM.ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: SrvM.ThunkService) => {
            const openedDialogPromise = DialogActions.openedDialogPromises[dialogName];

            if (thunkService.routeDialogService.getIsRouteDialog(dialogName)) {
                thunkService.routeDialogService.changeHashByClosingDialog(dialogName);

                if (openedDialogPromise) {
                    openedDialogPromise.resolveData = options;
                }
            }

            const dialogs = thunkService.routeDialogService.getOpenDialogs();

            if (!dialogs.length) {
                this.removeModalScroll();
            }

            switch (dialogName) {
                case StM.DialogNames.Auth: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.AuthDialogActions.close());
                }
                case StM.DialogNames.NewSession: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.CreateSessionDialogActions.close());
                }
                case StM.DialogNames.Book: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.BookDialogActions.close());
                }
                case StM.DialogNames.PackagePurchase: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.PackagePurchaseDialogActions.close());
                }
                case StM.DialogNames.BillingInfo: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.BillingInfoDialogActions.close());
                }
                case StM.DialogNames.SessionInfo: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.SessionInfoDialogActions.close());
                }
                case StM.DialogNames.UserEdit: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.UserEditDialogActions.close());
                }
                case StM.DialogNames.PasswordRecovery: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.PasswordRecoveryDialogActions.close());
                }
                case StM.DialogNames.PasswordChange: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.PasswordChangingDialogActions.close());
                }
                case StM.DialogNames.HistoryQuery: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.HistoryQueryDialogActions.close());
                }
                case StM.DialogNames.LeagueRequestToJoin: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.LeagueRequestToJoinDialogActions.close());
                }
                case StM.DialogNames.ContactUs: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.ContactUsDialogActions.close());
                }
                case StM.DialogNames.ConfirmDeleteBasketItem: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.ConfirmDeleteBasketItemDialogActions.close());
                }
                case StM.DialogNames.Alert: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.AlertDialogActions.close());
                }
                case StM.DialogNames.CancelConfirmation: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.CancelConfirmationActions.close());
                }
                case StM.DialogNames.SessionCreateSuccess: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.SessionCreateSuccessDialogActions.close());
                }
                case StM.DialogNames.PackageAdded: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.PackageAddedDialogActions.close());
                }
                case StM.DialogNames.Waiver: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.WaiverDialogActions.close());
                }
                case StM.DialogNames.TermsAndConditions: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.TermsAndConditionsDialogActions.close());
                }
                case StM.DialogNames.CreditTerms: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.CreditTermsDialogActions.close());
                }
                case StM.DialogNames.PrivacyPolicy: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.PrivacyPolicyDialogActions.close());
                }
                case StM.DialogNames.DemographicInformation: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.DemographicInformationDialogActions.close());
                }
                case StM.DialogNames.Confirm: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.ConfirmDialogActions.close());
                }
                case StM.DialogNames.CreateGroupAccount: {
                    dispatch(DlgActM.CreateGroupAccountDialogActions.close());
                    break;
                }
                case StM.DialogNames.InviteGroupMember: {
                    dispatch(DlgActM.InviteGroupMemberDialogActions.close());
                    break;
                }
                case StM.DialogNames.EditGroupAccount: {
                    dispatch(DlgActM.EditGroupAccountDialogActions.close());
                    break;
                }
                case StM.DialogNames.GroupInfo: {
                    dispatch(DlgActM.GroupInfoDialogActions.close());
                    break;
                }
                case StM.DialogNames.PublishConfirmation: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.PublishConfirmationDialogActions.close());
                }

                // admin dialogs
                case StM.DialogNames.NewClub: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.NewClubDialogActions.close());
                }
                case StM.DialogNames.TransactionRefund: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.TransactionRefundDialogActions.close());
                }
                case StM.AdminDialogNames.NewSession: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.CreateSessionAdminDialogActions.close());
                }
                case StM.AdminDialogNames.SessionInfo: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.SessionInfoAdminDialogActions.close());
                }
                case StM.AdminDialogNames.AddUser: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.AddUserDialogActions.close());
                }
                case StM.AdminDialogNames.EditUser: {
                    if (openedDialogPromise) {
                        openedDialogPromise.resolve(openedDialogPromise.resolveData);
                    }
                    return dispatch(DlgActM.EditUserAdminDialogActions.close());
                }
                case StM.AdminDialogNames.EditGroup: {
                    dispatch(DlgActM.EditGroupDialogActions.close());
                    break;
                }
                case StM.AdminDialogNames.EditCoachFeeTier: {
                    dispatch(DlgActM.EditCoachFeeTierDialogActions.close());
                    break;
                }
                case StM.AdminDialogNames.EditPackage: {
                    dispatch(DlgActM.EditPackageDialogActions.close());
                    break;
                }
                case StM.DialogNames.SubscriptionPurchase: {
                    dispatch(DlgActM.SubscriptionPurchaseDialogActions.close());
                    break;
                }
                case StM.AdminDialogNames.EditSubscription: {
                    dispatch(DlgActM.EditSubscriptionDialogActions.close());
                    break;
                }
                case StM.AdminDialogNames.SubscriptionHistory: {
                    dispatch(DlgActM.SubscriptionHistoryDialogActions.close());
                    break;
                }
                case StM.AdminDialogNames.SubscriptionNotification: {
                    dispatch(DlgActM.SubscriptionNotificationDialogActions.close());
                    break;
                }
                case StM.AdminDialogNames.SubscribersList: {
                    dispatch(DlgActM.SubscribersListDialogActions.close());
                    break;
                }
                case StM.AdminDialogNames.EditService: {
                    dispatch(DlgActM.EditAddonDialogActions.close());
                    break;
                }
                case StM.AdminDialogNames.CreateNotification: {
                    dispatch(DlgActM.CreateNotificationDialogActions.close());
                    break;
                }
                case StM.AdminDialogNames.AssignCoachFeeTierToCourts: {
                    dispatch(DlgActM.AssignCoachFeeTierToCourtsDialogActions.close());
                    break;
                }
            }
        };
    }

    public static openByReturnUrl(returnUrl: string) {
        if (returnUrl) {
            const hash = window.location.hash ? '{0}&{1}'.format(window.location.hash, decodeURIComponent(returnUrl)) : decodeURIComponent(returnUrl);
            window.location.hash = hash;
        }
    }

    private static getPromise(dialogName: string): Promise<any> {
        const existProm = DialogActions.openedDialogPromises && DialogActions.openedDialogPromises[dialogName]
            ? DialogActions.openedDialogPromises[dialogName]
            : null
        ;

        if (!existProm) {
            const callback = (resolve: (value?: any) => void, reject: (error?: any) => void) => {
                DialogActions.openedDialogPromises[dialogName] = ({
                    promise: null
                    , resolve
                    , reject
                } as IOpenedDialogPromise)
            };
            const dialogPromise = new Promise(callback);
            DialogActions.openedDialogPromises[dialogName].promise = dialogPromise;
        }
        return DialogActions.openedDialogPromises[dialogName].promise;
    }
}
