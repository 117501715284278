import { IBaseDialogStoreState, BaseDialogStoreState } from './baseDialogStoreState';

export interface IEditGroupDialogStoreState extends IBaseDialogStoreState { 
    userId: string;
    sectionType: string;
}

export class EditGroupDialogStoreState extends BaseDialogStoreState implements IEditGroupDialogStoreState {
    userId: string;
    sectionType: string;
    constructor() {
        super();
    }
}