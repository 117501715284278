import '../dialogs.scss';
import './passwordRecoveryDialog.scss';

import * as React from 'react';

import * as StM from '../../../models/store';
import { Modal, FormError, Field } from '../../../components';
import * as BD from './basePasswordRecoveryDialog';

interface IPasswordRecoveryDialogProps extends BD.IPasswordRecoveryDialogProps {}


class PasswordRecoveryDialog extends React.Component<IPasswordRecoveryDialogProps> {
    private customWindow: any = window;

    constructor(props: IPasswordRecoveryDialogProps) {
        super(props);
    }

    public render() {
        if(!this.props.isShown) return null;
        return (
            <Modal classes="pass-recovery-modal" closeHandler={this.props.closeDialog} dialogName={StM.DialogNames.PasswordRecovery}>
                <form onSubmit={(e) => this.props.onResetPassClick(e)}>
                    <div className="modal-dialog dialog">
                            <div className="modal-dialog-header">
                                <span className="close-dialog" onClick={(e) => this.props.closeDialog()}></span>
                                <div className="title">Password Recovery</div>
                            </div>
                                
                            <div className="modal-dialog-body">
                                <div className="modal-dialog-body-content">
                                    <div className="form-wrapper email-reset-pass">
                                        <Field type="text" 
                                            name="email"
                                            error={this.props.errors && this.props.errors.email}
                                            placeholder="Email"
                                            onFieldChange={(name, value) => this.props.onFieldChange(name, value)}
                                        />
                                        <div className="btns-wrapper">
                                            <button className="btn btn-block btn-red" type="submit" onClick={(e) => this.props.onResetPassClick(e)}>Reset Password</button>
                                        </div>
                                    </div>
                                </div>
                                <FormError errors={this.props.errors}/>
                                <div className="divider-line"></div>
                                <div className="btn-back-wrapper">
                                    <span className="btn-back" onClick={(e) => this.props.onBackClick(e)}>Back</span>
                                </div>
                            </div>
                        </div>
                </form>
            </Modal>
        );
    }
}

export default BD.withPasswordRecoveryDialog(PasswordRecoveryDialog);
