import BaseReducer from './baseReducer';
import * as StM from '../models/store';

export default class PackagesReducer extends BaseReducer {
    static handle(state: StM.IPackagesStoreState = new StM.PackagesStoreState(), action: any): StM.IPackagesStoreState {
        switch (action.type) {
            case 'Packages/Init': {
                var payload = <StM.IPackagesStoreState>action.payload;
                return payload;
            }
            default: {
                return state;
            }
        }
    }
}
