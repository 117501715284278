export class CommonDictionariesDto implements ICommonDictionariesDto {
    club: IClubDto;
    coaches: ICoachDto[];
    coachFeeTiers: ICoachFeeTierDto[];
    membershipLevels: IMembershipLevelDto[];
    packageDefinitions: IPackageDefinitionDto[];
    packageSessionTypes: IPackageSessionTypeDto[];
    recurrentPackageOffers: IRecurrentPackageOfferListDto[];
    customSessions: string;
    permissions: string;
    addonDefinitions: IAddonDefinitionDto[];

    constructor(dto?: any) {
        this.club = dto?.club ?? null;
        this.coaches = dto?.coaches ?? [];
        this.coachFeeTiers = dto?.coachFeeTiers ?? [];
        this.membershipLevels = dto?.membershipLevels ?? [];
        this.packageDefinitions = dto?.packageDefinitions ?? [];
        this.recurrentPackageOffers = dto?.recurrentPackageOffers ?? [];
        this.addonDefinitions = dto?.addonDefinitions ?? [];
        this.packageSessionTypes = dto?.packageSessionTypes ?? [];
        this.customSessions = dto?.customSessions ?? '';
        this.permissions = dto?.permissions ?? '';
    }
}
