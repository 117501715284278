import { IClubStoreState } from '../clubs';
import moment from 'moment';
import { IPublicUserStoreState } from './userStoreState';
import Constants from '../../../constants';

export interface IAvailableTimeStoreState{
    id: number;
    owner: IPublicUserStoreState;
    ownerId: string;
    club: IClubStoreState;
    clubId: number;
    begin: moment.Moment;
    end: moment.Moment;
    duration: moment.Duration;
}

export class AvailableTimeStoreState implements IAvailableTimeStoreState{
    id: number;
    owner: IPublicUserStoreState;
    ownerId: string;
    club: IClubStoreState;
    clubId: number;
    begin: moment.Moment;
    end: moment.Moment;
    duration: moment.Duration;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.owner = dto && dto.owner ? dto.owner : null;
        this.ownerId = dto && dto.ownerId ? dto.ownerId : null;
        this.club = dto && dto.club ? dto.club : null ;
        this.clubId = dto && dto.clubId ? dto.clubId : 0 ;
        this.begin = dto && dto.begin ? moment(dto.begin, Constants.DateTime.API_FORMAT) : moment.min() ;
        this.duration = dto && dto.duration ? moment.duration(dto.duration) : moment.duration();
    }
}
