import * as _ from 'lodash';
import moment from 'moment';

import { Constants, PolM, StM, SrvM } from '../modules';

export class CourtTimeService {
    private static _instance: CourtTimeService;
    private utils: SrvM.Utils;

    constructor() {
        if (typeof CourtTimeService._instance == "undefined") {
            CourtTimeService._instance = this;
            this.utils = new SrvM.Utils();
        }
        return CourtTimeService._instance;
    }

    public findCourtSlot(date: moment.Moment, court: StM.ICourtStoreState, courtTimeSlots: Array<StM.ICourtTimeSlotStoreState>): StM.ICourtTimeSlotStoreState {
        let timeSlot = _.find(courtTimeSlots, (courtTimeSlot: StM.ICourtTimeSlotStoreState) => {
            let isTheSameDate = date.startOf('day').diff(courtTimeSlot.date.startOf('day')) === 0;
            let isTheSameCourt = courtTimeSlot.court.id === court.id;
            return isTheSameDate && isTheSameCourt;
        });
        return timeSlot;
    }

    public getCourtDaySlots(
        date: moment.Moment
        , club: StM.IClubStoreState
        , sessions: Array<any>
        , basketSessions: Array<StM.ISessionStoreState>
        , availableTimes: Array<StM.IAvailableTimeStoreState>
        , user: StM.IUserStoreState
        , coachFeeTiers: Array<StM.ICoachFeeTierStoreState>
        , filter: StM.IBookPageRouteParams
        , pricingTiers: StM.IPricingTierStoreState[]
        , shouldHandlePolicies: boolean = true
        , coaches?: StM.ICoachStoreState[]
    ): Array<StM.CourtTimeSlotStoreState> {
        if (!club.clubTimes.length) return new Array<StM.CourtTimeSlotStoreState>();
        sessions = this.mapBasketSessions(sessions, basketSessions);
        const timeSlots = new Array<StM.ICourtTimeSlotStoreState>();
        const currentClubTime = this.utils.getCurrentClubTime(club);
        const currentDate = date.startOf('day');
        const clubStart = moment.duration(club.clubTimes[currentDate.day()].startTime);
        const clubEnd = moment.duration(club.clubTimes[currentDate.day()].endTime);
        const utcDayDiff = currentDate.diff(this.utils.getUtcInClubTimeZone(moment().utc(), club).startOf('day'), 'days');
        const dateFormatted = date.clone().startOf('day').format(Constants.DateTime.API_FORMAT);

        for (const court of club.courts) {
            const timeSlot = new StM.CourtTimeSlotStoreState();
            let courtTimeBlocks = new Array<StM.ICourtTimeBlockStoreState>();
            const courtStart = moment.duration(clubStart).add(court.startTimeOffset, 'minutes');
            const courtSessions = this.getCourtSessions(date, court, sessions)
                .sort((a, b) => {
                    return a.startDateTime.valueOf() - b.startDateTime.valueOf();
                }); // sort the sessions by the start time. It is important because regular sessions have been merged with basket sessions.
            // create a closure to set all global parameters of the getTimeBlock method
            const that = this;
            const getEmptyTimeBlock = function (
                timeBlockStart: moment.Duration
                , timeBlockEnd: moment.Duration
                , duration: number
                , isUnavailable: boolean
                , pricingTier: StM.IPricingTierStoreState
            ): StM.ICourtTimeBlockStoreState {
                const timeBlock = that.getTimeBlock(timeBlockStart, timeBlockEnd, duration, court, null, sessions
                    , basketSessions, availableTimes, currentClubTime, currentDate, user, coachFeeTiers, filter
                    , courtStart, dateFormatted, utcDayDiff, pricingTier, shouldHandlePolicies, isUnavailable, coaches);
                return timeBlock;
            }
            let time = clubStart;
            // add court offset block
            if (court.startTimeOffset > 0) {
                const offsetBlock = this.getTimeBlock(clubStart, courtStart, court.startTimeOffset, court, null, []
                    , [], [], currentClubTime, currentDate, user, [], filter, courtStart, dateFormatted, utcDayDiff, null, null, null, coaches);
                courtTimeBlocks.push(offsetBlock);
                time = courtStart;
            }
            // it is assumed that sessions don't overlap and session.endDateTime is always greater than session.startDateTime
            for (const session of courtSessions) {
                const sessionEnd = this.utils.getDurationTime(session.endDateTime);
                const sessionEndHours = sessionEnd.asHours();
                // skip all potential sessions that end before the iteration time
                if (sessionEndHours <= time.asHours()) continue;
                let sessionStart = this.utils.getDurationTime(session.startDateTime);
                if (sessionStart.asHours() < courtStart.asHours()) {
                    // chop the session's head. It is a possible case if the club opening time had been changed to a later time after the session creation.
                    sessionStart = moment.duration(courtStart);
                }
                const sessionDuration = sessionEnd.asMinutes() - sessionStart.asMinutes();
                const pricingTier = this.getPricingTier(currentDate, sessionStart, pricingTiers);
                // fill in empty time slots
                courtTimeBlocks.push(...this.getEmptyBlocks(currentDate, club, time, sessionStart, courtStart, pricingTiers, getEmptyTimeBlock));
                const timeBlock = this.getTimeBlock(sessionStart, sessionEnd, sessionDuration, court, session, sessions
                    , basketSessions, availableTimes, currentClubTime, currentDate, user, coachFeeTiers, filter
                    , courtStart, dateFormatted, utcDayDiff, pricingTier, shouldHandlePolicies, null, coaches);
                courtTimeBlocks.push(timeBlock);
                time = timeBlock.end;
                if (time.valueOf() >= clubEnd.valueOf()) break;
            }
            if (time.valueOf() < clubEnd.valueOf()) {
                // fill in remaining empty time slots
                courtTimeBlocks.push(...this.getEmptyBlocks(currentDate, club, time, clubEnd, courtStart, pricingTiers, getEmptyTimeBlock));
            }
            courtTimeBlocks = this.getCourtTimeBlocksProcessedIsAvailableDoubleCreation(courtTimeBlocks);

            timeSlot.court = court;
            timeSlot.date = currentDate.clone().startOf('day');
            timeSlot.start = clubStart;
            timeSlot.end = clubEnd;
            timeSlot.timeBlocks = courtTimeBlocks;

            timeSlots.push(timeSlot);
        }

        return timeSlots;
    }

    private getEmptyBlocks(
        currentDate: moment.Moment
        , club: StM.IClubStoreState
        , start: moment.Duration
        , end: moment.Duration
        , courtStart: moment.Duration
        , pricingTiers: StM.IPricingTierStoreState[]
        , getEmptyTimeBlock: (
            timeBlockStart: moment.Duration
            , timeBlockEnd: moment.Duration
            , duration: number
            , isUnavailable: boolean
            , pricingTier: StM.IPricingTierStoreState
        ) => StM.ICourtTimeBlockStoreState
    ): Array<StM.ICourtTimeBlockStoreState> {
        let timeBlocks = new Array<StM.ICourtTimeBlockStoreState>();
        if (start.asHours() >= end.asHours()) return timeBlocks;
        for (let timeBlockStart = moment.duration(courtStart)
            , timeBlockEnd = timeBlockStart
            ; timeBlockEnd.valueOf() < end.valueOf()
            ; timeBlockStart = timeBlockEnd
        ) {
            const pricingTier = this.getPricingTier(currentDate, timeBlockStart, pricingTiers)
            let duration = pricingTier ? pricingTier.blockSize : 0;
            timeBlockEnd = moment.duration(timeBlockStart).add(duration, 'minutes');
            if (timeBlockEnd.asHours() <= start.asHours()) continue;
            let isBlockReduced = false;
            let blockStart = moment.duration(timeBlockStart);
            let blockEnd = moment.duration(timeBlockEnd);
            if (timeBlockStart.asHours() < start.asHours()) {
                // chop head
                blockStart = moment.duration(start);
                isBlockReduced = true;
            }
            if (end.asHours() < timeBlockEnd.asHours()) {
                // chop tail
                blockEnd = moment.duration(end);
                isBlockReduced = true;
            }
            if (isBlockReduced) {
                duration = blockEnd.asMinutes() - blockStart.asMinutes();
            }
            const timeBlock = getEmptyTimeBlock(blockStart, blockEnd, duration, isBlockReduced, pricingTier);
            timeBlocks.push(timeBlock);
        }
        return timeBlocks;
    }

    public getPricingTier(date: moment.Moment, startTime: moment.Duration, pricingTiers: StM.IPricingTierStoreState[]) {
        return pricingTiers
            .sort((a, b) => b.priority - a.priority)
            .find((tier) => tier.schedule.some(schedule => this.getIsPricingTierPeriodEnabled(date, startTime, schedule)));
    }

    public getKey(
        dateFormatted: string
        , courtId: number
        , start: moment.Duration
    ): string {
        let timeFormat = start.asHours().toString();
        let key = '{0}_{1}_{2}'.format(dateFormatted, courtId.toString(), timeFormat);
        return key;
    }

    // private methods

    private getTimeBlock(
        timeBlockStart: moment.Duration
        , timeBlockEnd: moment.Duration
        , duration: number
        , court: StM.ICourtStoreState
        , session: StM.ISessionStoreState
        , sessions: Array<StM.ISessionStoreState>
        , basketSessions: Array<StM.ISessionStoreState>
        , availableTimes: Array<StM.IAvailableTimeStoreState>
        , currentClubTime: moment.Duration
        , currentDate: moment.Moment
        , user: StM.IUserStoreState
        , coachFeeTiers: Array<StM.ICoachFeeTierStoreState>
        , filter: StM.IBookPageRouteParams
        , courtStart: moment.Duration
        , dateFormatted: string
        , utcDayDiff
        , pricingTier?: StM.IPricingTierStoreState
        , shouldHandlePolicies: boolean = true
        , isUnavailable?: boolean
        , coaches?: StM.ICoachStoreState[]
    ): StM.ICourtTimeBlockStoreState {
        let timeBlock = new StM.CourtTimeBlockStoreState();
        timeBlock.date = currentDate.clone();
        timeBlock.start = moment.duration(timeBlockStart);
        timeBlock.end = moment.duration(timeBlockEnd);
        timeBlock.duration = duration;
        timeBlock.courtId = court.id;
        timeBlock.session = session;
        timeBlock.pricingTier = pricingTier;

        if (!session && court.startTimeOffset && courtStart.asHours() > timeBlockStart.asHours()) {
            timeBlock.isOffset = true;
            timeBlock.end = moment.duration(courtStart);
            timeBlock.duration = moment.duration(timeBlock.end.asMilliseconds() - timeBlock.start.asMilliseconds()).asMinutes();
        }

        timeBlock.key = this.getKey(dateFormatted, court.id, timeBlock.start);
        if (shouldHandlePolicies) {
            timeBlock = this.handlePolicies(sessions, basketSessions, availableTimes, timeBlock, currentClubTime, currentDate, court
                , user, coachFeeTiers, filter, utcDayDiff, coaches);
        }

        if (isUnavailable) {
            timeBlock.isAvailableTime = false;
            timeBlock.isNotAvailable = true;
        }

        return timeBlock;
    }

    private handlePolicies(
        sessions: Array<StM.ISessionStoreState>
        , basketSessions: Array<StM.ISessionStoreState>
        , availableTimes: Array<StM.IAvailableTimeStoreState>
        , time: StM.CourtTimeBlockStoreState
        , currentClubTime: moment.Duration
        , currentDate: moment.Moment
        , court: StM.ICourtStoreState
        , user: StM.IUserStoreState
        , coachFeeTiers: Array<StM.ICoachFeeTierStoreState>
        , filter: StM.IBookPageRouteParams
        , utcDayDiff: number
        , coaches: StM.ICoachStoreState[]
    ): StM.CourtTimeBlockStoreState {

        const timeAvailabilityPolicy = new PolM.TimeAvailabilityPolicy(time, currentClubTime, currentDate, sessions, basketSessions, utcDayDiff, court, user);
        const pricePolicy = new PolM.PricesPolicy(false, time.session, time.pricingTier);
        const sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(time.session, basketSessions);
        const userAvailabilityPolicy = new PolM.UserAvailabilityPolicy(sessions, basketSessions, time, court, user);
        const filterAvailabilityPolicy = new PolM.BookPageFilterPolicy(sessions, basketSessions, availableTimes, currentDate, time, time.session, filter, court, coaches, coachFeeTiers);
        const prices = pricePolicy.handle();

        time.isAvailableTime = timeAvailabilityPolicy.handle();
        time.isFutureTime = timeAvailabilityPolicy.getIsFuture();
        time.isFilter = filterAvailabilityPolicy.handle();
        time.price = prices.price;
        time.credits = prices.credits;

        if (time.session) {
            time.isAvailableSession = sessionAvailabilityPolicy.handle();
            time.isInvited = sessionAvailabilityPolicy.getIsInvited();
            time.isOpenBoard = sessionAvailabilityPolicy.getIsOpenBoard();
            time.isSessionFromBasket = sessionAvailabilityPolicy.getIsBasket();
            time.isBought = userAvailabilityPolicy.getIsBoughtUser(time.session);
            time.isOwner = sessionAvailabilityPolicy.getIsOwner();
        }
        return time;
    }

    private getCourtSessions(
        date: moment.Moment
        , court: StM.ICourtStoreState
        , sessions: Array<StM.ISessionStoreState>
    ): Array<StM.ISessionStoreState> {
        return sessions.filter((session) => {
            // return earlier to improve performance
            const isSameDate = session.startDateTime.isSame(date, 'day');
            if (!isSameDate) return false;
            const isCurrentCourt = session.court && session.court.id === court.id;
            const isCurrentCourts = session.courts && _.some(session.courts, { id: court.id });
            return isCurrentCourt || isCurrentCourts
        });
    }

    private mapBasketSessions(sessions: Array<StM.ISessionStoreState>, basketSessions: Array<StM.ISessionStoreState>) {
        const results: Array<StM.ISessionStoreState> = [];
        for (const session of basketSessions.concat(sessions)) {
            let inResult = false;
            for (const resultSession of results) {
                const isCourse = resultSession.series && resultSession.series.isCourse;
                if (resultSession.id && resultSession.id === session.id && !isCourse) {
                    inResult = true;
                    resultSession.basketId = resultSession.basketId || session.basketId;
                }
            }
            if (!inResult) {
                results.push(session);
            }
        }
        return results;
    }

    private getCourtTimeBlocksProcessedIsAvailableDoubleCreation(courtTimeBlocks: Array<StM.ICourtTimeBlockStoreState>) {
        const courtTimeBlocksWithProcessedIsAvailableDoubleCreation = [...courtTimeBlocks];

        for (let i = 0; i < courtTimeBlocksWithProcessedIsAvailableDoubleCreation.length; i++) {
            const time = courtTimeBlocksWithProcessedIsAvailableDoubleCreation[i];
            if (time) {
                const nextTime = courtTimeBlocksWithProcessedIsAvailableDoubleCreation[i + 1];
                const isDoubleSession = !!time.session && time.session.isDoubledSession;
                let isNextTimeChecked = false;
                if (time.pricingTier && nextTime) {
                    const isPeakTime = time.pricingTier.type === StM.PricingTierType.Peak;
                    const isSamePricingTier = !!nextTime.pricingTier && nextTime.pricingTier.type === time.pricingTier.type;
                    isNextTimeChecked = !isPeakTime && !nextTime.session && (nextTime.isAvailableTime || !nextTime.isFutureTime) && isSamePricingTier;
                }
                time.isAvailableDoubleCreation = isDoubleSession || isNextTimeChecked;
            }
        }

        return courtTimeBlocksWithProcessedIsAvailableDoubleCreation;
    }

    private getIsPricingTierPeriodEnabled(date: moment.Moment, startTime: moment.Duration, schedule: StM.IPricingTierPeriodStoreState) {
        const weekday = date.weekday();
        const startAsMinutes = startTime.asMinutes();
        let isDate = false;
        switch(weekday) {
            case 0: 
                isDate = schedule.isSunday;
                break;
            case 1: 
                isDate = schedule.isMonday;
                break;
            case 2: 
                isDate = schedule.isTuesday;
                break;
            case 3: 
                isDate = schedule.isWednesday;
                break;
            case 4: 
                isDate = schedule.isThursday;
                break;
            case 5: 
                isDate = schedule.isFriday;
                break;
            case 6: 
                isDate = schedule.isSaturday;
                break;
        }

        const isTime = schedule.start.asMinutes() <= startAsMinutes && startAsMinutes < schedule.end.asMinutes()

        return isDate && isTime;
    }
}
