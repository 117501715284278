import * as Redux from 'redux';

export class CreateGroupAccountDialogActions {
    public static open(): (dispatch: any) => void {
        return (dispatch: any) => {
            dispatch({ type: 'Dialogs/CreateGroupAccount/Open' });
        };
    }

    public static close(): (dispatch: any) => void {
        return (dispatch: any) => {
            dispatch({ type: 'Dialogs/CreateGroupAccount/Close' });
        };
    }
}
