import moment from 'moment';

export interface IPricingTierPeriodStoreState {
    title: string;
    type: string;
    isReadOnly: boolean;
    start: moment.Duration;
    end: moment.Duration;
    isMonday: boolean;
    isTuesday: boolean;
    isWednesday: boolean;
    isThursday: boolean;
    isFriday: boolean;
    isSaturday: boolean;
    isSunday: boolean;
}

export class PricingTierPeriodStoreState implements IPricingTierPeriodStoreState{
    title: string;
    type: string;
    isReadOnly: boolean;
    start: moment.Duration;
    end: moment.Duration;
    isMonday: boolean;
    isTuesday: boolean;
    isWednesday: boolean;
    isThursday: boolean;
    isFriday: boolean;
    isSaturday: boolean;
    isSunday: boolean;

    constructor(dto?: any) {
        this.title = dto && dto.title ? dto.title : '';
        this.type = dto && dto.type ? dto.type : '';
        this.isReadOnly = dto && !!dto.isReadOnly;
        this.start = dto && dto.start ? moment.duration(dto.start) : moment.duration();
        this.end = dto && dto.end ? moment.duration(dto.end) : moment.duration();
        this.isMonday = dto && !!dto.isMonday;
        this.isTuesday = dto && !!dto.isTuesday;
        this.isWednesday = dto && !!dto.isWednesday;
        this.isThursday = dto && !!dto.isThursday;
        this.isFriday = dto && !!dto.isFriday;
        this.isSaturday = dto && !!dto.isSaturday;
        this.isSunday = dto && !!dto.isSunday;
    }
}
