import { BaseApiService } from '../baseApiService';
import * as StoreModule from '../../models/store';
import { StM } from '../../modules';

export class ClubApiService extends BaseApiService {
    private static _instance: ClubApiService;

    constructor(config?: any) {
        if (typeof ClubApiService._instance == 'undefined') {
            super(config);
            ClubApiService._instance = this;
        }

        return ClubApiService._instance;
    }

    getCurrent(): Promise<StoreModule.IClubStoreState> {
        return this.get('/current')
            .then((response: any) => {
                let model = this.mapper.getClubModelFromDto(<IClubDto>response.data);
                return model;
            });
    }

    public getForCurrentUser(): Promise<Array<StM.IClubStoreState>> {
        return this.get('/')
            .then((response: any) => {
                const models = this.mapper.getClubModelsFromDto(<Array<IClubDto>>response.data);
                return models;
            })
            .catch((error: any) => {
                throw (error.response);
            });
    }
}
