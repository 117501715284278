import BaseReducer from '../baseReducer';
import * as StM from '../../models/store';

export default class AdminUsersReducer extends BaseReducer {
    static handle(state: StM.IUserEditableStoreState[] = [], action: any): StM.IUserEditableStoreState[] {
        switch (action.type) {
            case 'Admin/Users': {
                const users = action.payload as StM.IUserEditableStoreState[];
                return [...users];
            }
            case 'Admin/Users/Add': {
                const users = state.slice();
                users.push(action.payload as StM.IUserEditableStoreState);
                return [...users];
            }
            case 'Admin/Users/Update': {
                const users = state.map((u) => u.id === action.payload.id ? new StM.UserEditableStoreState(action.payload) : u); 
                return [...users];
            }
            case 'Admin/Users/EnableUser': {
                const users = state.map((u) => u.id === action.payload ? { ...u, disabled: false } : u);
                return [...users];
            }
            default: {
                return state;
            }
        }
    }

    static handleArchived(state: StM.IUserEditableStoreState[] = [], action: any): StM.IUserEditableStoreState[] {
        switch (action.type) {
            case 'Admin/ArchivedUsers': {
                const users = action.payload as StM.IUserEditableStoreState[];
                return [...users];
            }
            default: {
                return state;
            }
        }
    }

    static handleUsersVersion(state: number = 0, action: any): number {
        switch (action.type) {
            case 'Admin/Users/Changed':
                return <number>action.payload;
            default: {
                return state;
            }
        }
    }
}
