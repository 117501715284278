import * as Redux from 'redux';

import { ThunkService } from '../services/thunkService';
import * as StM from '../models/store';
import { AppActions } from './appActions';
import { DialogActions } from './dialogs/dialogActions';
import { UserActions } from './userActions';
import { NotificationActions } from './notificationActions';

export class GroupAccountActions {
    public static getForCurrentUser(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => Promise<void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => {
            const { club, pricingTiers } =  getState();
            dispatch(AppActions.showSpinner());
            return thunk.api.group.getGroups(club, pricingTiers)
                .then((groups) => {
                    dispatch({ type: 'GroupAccounts/Init', payload: groups});
                    dispatch(AppActions.hideSpinner());
                }).catch(() => {
                    dispatch(AppActions.hideSpinner());
                });
        };
    }

    public static create(group: StM.IGroupStoreState, image?: any): (dispatch: any,getState: () => StM.IGlobalStoreState, thunk: ThunkService) => Promise<StM.IGroupStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => {
            const state = getState();
            return thunk.api.group.create(group, state.club)
                .then((response) => {
                    return image 
                        ? thunk.api.group.updateImage(image, response.id, state.club) 
                        : Promise.resolve(response);
                }).then((response) => {
                    dispatch(this.getForCurrentUser());
                    return response;
                });
        };
    }

    public static remove(groupId: number): (dispatch: any,getState: () => StM.IGlobalStoreState, thunk: ThunkService) => Promise<void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => {
            const state = getState();
            dispatch(AppActions.showSpinner());
            return thunk.api.group.deleteGroup(groupId, state.club)
                .then(() => {
                    dispatch(this.getForCurrentUser());
                    dispatch(AppActions.hideSpinner());
                }).catch(() => {
                    dispatch(AppActions.hideSpinner());
                });
        };
    }

    public static searchMemberToInvite(model: IGroupMemberSearchDto): (dispatch: any,getState: () => StM.IGlobalStoreState, thunk: ThunkService) => Promise<IGroupMemberSearchDto> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => {
            dispatch(AppActions.showSpinner());
            return thunk.api.group.searchUsers(model)
                .then((response) => {
                    dispatch(AppActions.hideSpinner());
                    return response;
                }).catch((error) => {
                    dispatch(AppActions.hideSpinner());
                    throw error;
                });
        };
    }

    public static update(group: StM.IGroupStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => Promise<StM.IGroupStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => {
            const state = getState();
            dispatch(AppActions.showSpinner());
            return thunk.api.group.update(group, state.club)
                .then((response) => {
                    if(state.user && state.user.group) {
                        dispatch(UserActions.getCurrent());
                    } else if(state.user) {
                        dispatch(this.getForCurrentUser());
                    }
                    dispatch(AppActions.hideSpinner());
                    return response;
                }).catch((error) => {
                    dispatch(AppActions.hideSpinner());
                    throw error;
                });
        };
    }

    public static leaveGroup(groupId: number): (dispatch: any,getState: () => StM.IGlobalStoreState, thunk: ThunkService) => Promise<void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => {
            dispatch(AppActions.showSpinner());
            return thunk.api.group.leaveGroup(groupId)
                .then(() => {
                    dispatch(this.getForCurrentUser());
                    dispatch(AppActions.hideSpinner());
                }).catch(() => {
                    dispatch(AppActions.hideSpinner());
                });
        };
    }

    public static acceptInvitation(token: string): (dispatch: any,getState: () => StM.IGlobalStoreState, thunk: ThunkService) => Promise<void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => {
            const state = getState();
            const groups = state.groupAccounts;
            dispatch(AppActions.showSpinner());
            return thunk.api.group.acceptInviteByInviteToken(token, state.club)
                .then((response) => {
                    if(groups.some(group => group.id === response.id)) {
                        dispatch(DialogActions.open(StM.DialogNames.Alert, { msgKey: StM.MessagesKey.InviteLinkAlreadyUsed, messageType: StM.MessageTypes.Warning }))
                    } else {
                        dispatch(this.getForCurrentUser());
                        dispatch(this.dismissGroupInvitationNotification(response.id));
                        dispatch(DialogActions.open(StM.DialogNames.Alert, { msgKey: StM.MessagesKey.AcceptGroupInvitation, messageType: StM.MessageTypes.Success }));
                    }
                    dispatch(AppActions.hideSpinner());
                }).catch((response) => {
                    var error = response.data;
                    var message = error.exceptionMessage || error.message || 'This group is unavailable';
                    dispatch(DialogActions.open(StM.DialogNames.Alert, {msgKey: StM.MessagesKey.GenericRequestError,  message, messageType: StM.MessageTypes.Error }));
                    dispatch(AppActions.hideSpinner());
                });
        };
    }

    public static rejectInvitation(token: string): (dispatch: any,getState: () => StM.IGlobalStoreState, thunk: ThunkService) => Promise<void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => {
            const state = getState();
            dispatch(AppActions.showSpinner());
            return thunk.api.group.rejectInviteByInviteToken(token, state.club)
                .then((response) => {
                    dispatch(this.dismissGroupInvitationNotification(response.id));
                    dispatch(DialogActions.open(StM.DialogNames.Alert, { msgKey: StM.MessagesKey.RejectGroupInvitation, messageType: StM.MessageTypes.Error }));
                    dispatch(AppActions.hideSpinner());
                }).catch((response) => {
                    var error = response.data;
                    var message = error.exceptionMessage || error.message || 'This group is unavailable';
                    dispatch(DialogActions.open(StM.DialogNames.Alert, {msgKey: StM.MessagesKey.GenericRequestError,  message, messageType: StM.MessageTypes.Error }));
                    dispatch(AppActions.hideSpinner());
                });
        };
    }

    public static resendInvitation(memberId: number, isAdmin: boolean = false): (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => Promise<void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => {
            dispatch(AppActions.showSpinner());
            return thunk.api.group.resendInvite(memberId)
                .then(() => {
                    if(!isAdmin) {
                        dispatch(UserActions.getCurrent());
                    }
                    dispatch(DialogActions.open(StM.DialogNames.Alert, { msgKey: StM.MessagesKey.ResendGroupMemberInvitation, messageType: StM.MessageTypes.Success }));
                    dispatch(AppActions.hideSpinner());
                }).catch(() => {
                    dispatch(AppActions.hideSpinner());
                });
        };
    }

    public static getGroupByInviteToken(token: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => Promise<StM.IGroupStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => {
            const state = getState();
            dispatch(AppActions.showSpinner());
            return thunk.api.group.getByInviteToken(token, state.club)
                .then((response) => {
                    dispatch(AppActions.hideSpinner());
                    return response;
                }).catch((response) => {
                    var error = response.data;
                    var message = error.exceptionMessage || error.message || 'This group is unavailable';
                    dispatch(DialogActions.open(StM.DialogNames.Alert, {msgKey: StM.MessagesKey.GenericRequestError,  message, messageType: StM.MessageTypes.Error }));
                    dispatch(AppActions.hideSpinner());
                    throw error;
                });
        };
    }

    public static acceptInvitationByGroupId(groupId: number): (dispatch: any,getState: () => StM.IGlobalStoreState, thunk: ThunkService) => Promise<void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => {
            const state = getState();
            dispatch(AppActions.showSpinner());
            return thunk.api.group.acceptInvitation(groupId, state.club)
                .then((response) => {
                    dispatch(this.getForCurrentUser());
                    dispatch(this.dismissGroupInvitationNotification(groupId));
                    dispatch(DialogActions.open(StM.DialogNames.Alert, { msgKey: StM.MessagesKey.AcceptGroupInvitation, messageType: StM.MessageTypes.Success }));
                    dispatch(AppActions.hideSpinner());
                }).catch((response) => {
                    var error = response.data;
                    var message = error.exceptionMessage || error.message || 'This group is unavailable';
                    dispatch(DialogActions.open(StM.DialogNames.Alert, {msgKey: StM.MessagesKey.GenericRequestError,  message, messageType: StM.MessageTypes.Error }));
                    dispatch(NotificationActions.getForCurrentUser());
                    dispatch(AppActions.hideSpinner());
                });
        };
    }

    public static rejectInvitationByGroupId(groupId: number): (dispatch: any,getState: () => StM.IGlobalStoreState, thunk: ThunkService) => Promise<void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => {
            const state = getState();
            dispatch(AppActions.showSpinner());
            return thunk.api.group.rejectInvitation(groupId, state.club)
                .then((response) => {
                    dispatch(this.dismissGroupInvitationNotification(groupId));
                    dispatch(DialogActions.open(StM.DialogNames.Alert, { msgKey: StM.MessagesKey.RejectGroupInvitation, messageType: StM.MessageTypes.Error }));
                    dispatch(AppActions.hideSpinner());
                }).catch((response) => {
                    var error = response.data;
                    var message = error.exceptionMessage || error.message || 'This group is unavailable';
                    dispatch(DialogActions.open(StM.DialogNames.Alert, {msgKey: StM.MessagesKey.GenericRequestError,  message, messageType: StM.MessageTypes.Error }));
                    dispatch(NotificationActions.getForCurrentUser());
                    dispatch(AppActions.hideSpinner());
                });
        };
    }

    private static dismissGroupInvitationNotification(groupId: number): (dispatch: any,getState: () => StM.IGlobalStoreState, thunk: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunk: ThunkService) => {
            const state = getState();
            const notification = state.notificationBoard.notifications.find(n => n.targetId === groupId);
            if(!notification) return;

            dispatch(NotificationActions.dismiss(notification));
        };
    }
}
