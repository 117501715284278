import * as React from 'react';
import { Component} from 'react';
import { Route, Redirect} from 'react-router-dom';
import * as StoreModule from '../../models/store';
import * as SrvM from '../../services';
import { store, history } from '../../store/store';
import { IGlobalStoreState } from '../../models/store';

class RouteWithAuth extends React.Component<any, any> {
    private component: React.Component;
    private authService: SrvM.AuthenticationService;
    
    constructor(props) {
        super(props);
        this.authService = new SrvM.AuthenticationService();
    }
    
    render () {
        const state = store.getState() as IGlobalStoreState;
        return (!this.props.authorize && !this.props.permissions) || 
         (state.app.isAuthorized && this.authService.isInRoles(this.props.authorize, state.user) && this.authService.hasSomePermissions(this.props.permissions, state.user)) 
         ? (<Route {...this.props}/> ) : (<Redirect to={{ pathname: '/admin'}}/>)
    }
}

export default RouteWithAuth;
