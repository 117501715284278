import * as _ from 'lodash';

export default class BaseReducer {
    costructor() {
    }

    static update<T>(prevState: T, nextState: any): T {
        return <T>_.assign({}, prevState, nextState);
    }

    static updateArray<T>(prevState: Array<T>, nextState: Array<T>): Array<T> {
        let newState = _.map(nextState, (next: any) => {
            var empty = <T>{};
            return this.update<T>(empty, next);
        });
        return newState;
    }
}
