import './inputSearch.scss';
import * as React from 'react';
import ClassNames from 'classnames';
import * as _ from 'lodash';
import { store } from '../store/store';

import * as StM from '../models/store';
import * as SrvM from '../services';
import * as PolM from '../policies';
import { BaseInputSearchList, IBaseInputSearchProps, IBaseInputSearchState } from './baseInputSearch';

export interface IAddedUserSearchItem extends StM.IAddedUserStoreState {
    disableRemove?: boolean;
}

interface IUserSearchProps extends IBaseInputSearchProps {
    users: StM.IUserStoreState[];
    added?: IAddedUserSearchItem[];
    invited?: StM.IUserStoreState[];
    unavailable?: StM.IPublicUserStoreState[];
    unjoinBtnText?: string;
    uninviteBtnText?: string;
    showWarning?: boolean;
    user?: StM.IUserStoreState;
    maxInvited?: number;
    minAdded?: number;
    searchWarning?: string;
    session?: StM.ISessionStoreState;
    children?: React.ReactNode;
    dataTestId?: string;

    onAddChange?: (addedUsers: IAddedUserSearchItem[]) => void;
    onInviteChange?: (invitedUsers: StM.IUserStoreState[]) => void;
    onInputBlur?: (value?: any, filter?: string) => void;
    onWarningIconClick?: (item: StM.IUserStoreState) => void;
    showWarningIcon?: (item: StM.IUserStoreState) => boolean;
}

interface IUserSearchState extends IBaseInputSearchState {
    added?: IAddedUserSearchItem[];
    invited?: StM.IUserStoreState[];
    unavailable?: StM.IPublicUserStoreState[];
    sourceArray: StM.IUserStoreState[];
    filteredArray: StM.IUserStoreState[];
    isGroup: boolean;
}

export class UserSearch extends React.Component<IUserSearchProps, IUserSearchState> {
    added: IAddedUserSearchItem[] = [];
    invited: StM.IUserStoreState[] = [];
    addedItem: IAddedUserSearchItem;
    invitedItem: StM.IUserStoreState;
    multiple: boolean = false;
    isAdmin: boolean = false;
    __DOM: any = null;
    private authService = new SrvM.AuthenticationService();
    private utils = new SrvM.Utils();
    private groupInfoPolicy = new PolM.GroupInfoPolicy();
    private MAX_PLAYER_NAME_LENGTH = 25;

    constructor(props: IUserSearchProps) {
        super(props);

        let added = props.added ? [...props.added] : [];
        let invited = props.invited ? [...props.invited] : [];
        const unavailable = props.unavailable ? [...props.unavailable] : []

        added = added ? this.setAdded(added) : added;
        invited = invited ? this.setInvited(invited) : invited;
        
        this.multiple = props.multiple || false;
        this.state = {
            filter: '',
            isShowList: false,
            added,
            invited,
            unavailable,
            filteredArray: [],
            sourceArray: [],
            isGroup: false,
        };

        const customWindow: any = window;
        this.isAdmin = customWindow.ISADMIN
    }

    componentDidMount() {
        const arrays = this.initArrays(this.props);
        this.setState({ ...arrays, isGroup: this.getIsGroup() });
    }

    componentDidUpdate(prevProps) {
        const isPropsChanged =
            !_.isEqual(this.props.users, prevProps.users) ||
            !_.isEqual(this.props.added, prevProps.added) ||
            !_.isEqual(this.props.invited, prevProps.invited) ||
            !_.isEqual(this.props.unavailable, prevProps.unavailable) ||
            !_.isEqual(this.props.max, prevProps.max) ||
            !_.isEqual(this.props.placeholder, prevProps.placeholder) ||
            !_.isEqual(this.props.classes, prevProps.classes) ||
            !_.isEqual(this.props.multiple, prevProps.multiple) ||
            !_.isEqual(this.props.notFoundText, prevProps.notFoundText) ||
            !_.isEqual(this.props.addBtnText, prevProps.addBtnText) ||
            !_.isEqual(this.props.readOnly, prevProps.readOnly) ||
            !_.isEqual(this.props.disabled, prevProps.disabled) ||
            !_.isEqual(this.props.isNeedSort, prevProps.isNeedSort) ||
            !_.isEqual(this.props.user, prevProps.user) ||
            !_.isEqual(this.props.maxInvited, prevProps.maxInvited);

        if (isPropsChanged) {
            let added = this.props.added ? [...this.props.added] : [];
            let invited = this.props.invited ? [...this.props.invited] : [];
            if (this.props.multiple && !isNaN(this.props.max) && this.props.max < this.state.added.length) {
                added = added.slice(0, this.props.max);
            }
            if(this.props.multiple && !isNaN(this.props.maxInvited) && this.props.maxInvited < this.state.invited.length) {
                invited = invited.slice(0, this.props.maxInvited);
            }
            added = this.setAdded(added, this.props);
            invited = this.setInvited(invited, this.props);
            const arrays = this.initArrays(this.props);
            this.setState({ 
                added, 
                invited, 
                unavailable:  this.props.unavailable ? [...this.props.unavailable] : [],
                isShowList: !(this.props.autoClose == null || this.props.autoClose), 
                ...arrays,
                isGroup: this.getIsGroup(),
            });
        }
    }

    componentWillUnmount() {
        if (this.state.isShowList) this.removeDocumentClickHandler();
    }

    render() {
        const filteredArray = this.state.filteredArray;
        let text = this.props.placeholder;
        if (!this.props.multiple) {
            text = this.state.added && this.state.added.length ? this.state.added[0].displayName : text;
        }
        const renderItem = this.props.multiple ? this.renderMultipleItem.bind(this) : this.renderItem.bind(this);
        const classes = ClassNames(this.props.classes, 'custom-select', {
            disabled: this.props.disabled,
            'readonly-input-wrapper': this.props.readOnly,
            'not-default-value': !!this.props.added && !!this.state.added.length,
            active: this.state.isShowList,
        });
        const zIndexStyle = this.state.isShowList ? { zIndex: 100 } : { zIndex: 5 };
        return (
            <div
                className={classes}
                style={zIndexStyle}
                ref={(element) => {
                    this.__DOM = element;
                }}
                onClick={(e) => this.props.readOnly && this.onInputClick(e)}
            >
                <input
                    type='text'
                    placeholder={text}
                    className='custom-input'
                    readOnly={this.props.readOnly}
                    value={this.state.filter}
                    onFocus={() => this.onFocusHandler()}
                    onClick={(e) => this.onInputClick(e)}
                    onBlur={() => this.onBlurHandler()}
                    onChange={(e) => this.changeFilter(e)}
                    onKeyPress={(e) => this.onInputKeyDown(e)}
                    data-testid={this.props.dataTestId}
                />
                {this.state.isShowList && !!filteredArray.length && !this.props.disabled && (
                    <BaseInputSearchList
                        itemsCount={filteredArray.length}
                        rowHeight={this.props.rowHeight}
                        notFoundText={this.props.notFoundText}
                        renderItem={renderItem}
                    />
                )}
                {this.props.searchWarning && (<div className='search-warning'>{this.props.searchWarning}</div>)}
                {this.props.children}
                <span className='caret' onClick={(e) => this.onInputClick(e)} />
            </div>
        );
    }

    private renderItem(index) {
        const item = this.state.filteredArray[index];
        if (!item) return null;

        return (
            <div className='search-list-item' key={item.id} onClick={(e) => this.addItem(e, item)}>
                <div className='name'>
                    <span className='player-name'>{item.displayName}</span>
                    {this.renderWarning(item)}
                </div>
            </div>
        );
    }

    private renderMultipleItem(index) {
        const item = this.state.filteredArray[index];
        if (!item) return null; 

        const addedItem = this.state.added.find(u => u.id === item.id);
        const user = this.props.user;
        const isGroup = this.getIsGroup();
        let button: any;
        let added = false;
        let invited = false;
        let isCanSelect = true;
        let inviteButton = this.renderInviteButton(item, isCanSelect);
        let selectedItemText = '';
        let unavailableButton: any;

        let displayName = this.utils.shortenString(item.displayName, this.MAX_PLAYER_NAME_LENGTH);
        let isGroupMember = false;
        if(isGroup) {
            const member = this.groupInfoPolicy.getGroupMember(item.id);
            if(member) {
                isGroupMember = true;
                const memberMark = ` ${this.groupInfoPolicy.getMemberTitleMark(member.user.id)}`;
                displayName = this.utils.shortenString(item.displayName, this.MAX_PLAYER_NAME_LENGTH - memberMark.length);
                displayName += memberMark; 
                inviteButton = null;
            }
        }

        if (!!addedItem) {
            const isAvailableToRemove = !addedItem.disableRemove && ((isGroup && isGroupMember) || this.authService.hasPermission('DropoutPlayer', user)) 
                && (isNaN(this.props.minAdded) || this.state.added.length > this.props.minAdded);
            button = isAvailableToRemove ? this.renderRemoveButton(item, true) : null;
            inviteButton = null;
            added = true;
            selectedItemText = 'Added';
        } else if (this.invited.some((user) => user.id == item.id)) {
            button = isGroup || this.authService.hasPermission('InvitePlayer', user) || !this.isAdmin ? this.renderRemoveButton(item, false) : null;
            inviteButton = null;
            invited = true;
            selectedItemText = 'Invited';
        } else if (this.state.unavailable.some((user) => user.id == item.id)) {
            button = null;
            inviteButton = null;
            invited = true;
            selectedItemText = 'Unavailable';
        } else if (!isNaN(this.props.max) && this.state.added.length >= this.props.max) {
            button = null;
            isCanSelect = false;
        } else {
            button = (isGroup && isGroupMember) || this.authService.hasPermission('AddPlayer', user) ? this.renderAddButton(item, isCanSelect) : null;
        }

        if (!isNaN(this.props.max) && this.state.invited.length >= this.props.maxInvited) {
            inviteButton = null;
        }
        
        const classes = ClassNames('search-list-item', { added, invited });
    
        return (
            <div className={classes} key={item.id}>
                <div className='name'>
                    <span>{displayName}</span>
                    {this.renderWarning(item)}
                </div>
                <div className='invite'>{selectedItemText}</div>
                <div className='btns-wrapper'>
                    {inviteButton}
                    {button}
                    {unavailableButton}
                </div>
            </div>
        );
    }

    private renderWarning(item: StM.IUserStoreState) {
        const showWarning = (this.props.showWarning && !item.hasPaymentInfo) || (this.props.showWarningIcon && this.props.showWarningIcon(item));
        const showWarning2 = (this.props.showWarning
            && this.props.session?.playerQualification?.membershipLevels?.length > 0
            && (!item.level?.id || !this.props.session.playerQualification.membershipLevels.find(value => value === item.level.id)));
        return (
            <div>
                {showWarning && <span onClick={() => this.props.onWarningIconClick(item)} className='warning' />}
                {showWarning2 &&
                    <span className="tooltip-warning" data-tooltip="User's member tier doesn't meet session requirements!">
                        <span onClick={() => this.props.onWarningIconClick(item)} className='warning-not-critical' />
                    </span>
                }
            </div>
        );
    }

    private renderAddButton(item: StM.IUserStoreState, isCanSelect: boolean) {
        const text = this.props.addBtnText ? this.props.addBtnText : 'Add';
        return (
            <div className='btn-container' onClick={(e) => isCanSelect && this.addItem(e, item)}>
                {text}
            </div>
        );
    }

    private renderInviteButton(item: StM.IUserStoreState, isCanSelect: boolean) {
        const text = 'Invite';
        return (
            <div className='btn-container' onClick={(e) => isCanSelect && this.inviteItem(e, item)}>
                {text}
            </div>
        );
    }

    private renderRemoveButton(item: StM.IUserStoreState, isUnjoin: boolean = true) {
        const text = (isUnjoin ? this.props.unjoinBtnText : this.props.uninviteBtnText) || 'Remove';

        if(!isUnjoin && !!this.props.session && !!this.props.user) {
            const user = _.find(this.props.session.invitedUsers, u => u.id == item.id) || item;
            const currentUserId = this.props.user.id;
            
            if(!!user.invitedBy && user.id !== currentUserId && user.invitedBy !== currentUserId && this.props.session.ownerId !== currentUserId)
            {
                return null;
            }
        }

        return (
            <div className='btn-container' onClick={(e) => this.unselectItem(e, item)}>
                {text}
            </div>
        );
    }

    private initArrays(props: IUserSearchProps)  {
        let sourceArray = props.users.slice();
        if(props.isNeedSort){
            sourceArray = this.sortArray(sourceArray, props.user);
        }
        const filteredArray = this.filterArray(sourceArray, this.state.filter, props.user);
        return { sourceArray, filteredArray }
    }

    private setAdded(added: IAddedUserSearchItem[], props?: IUserSearchProps): IAddedUserSearchItem[] {
        this.added = [];
        this.addedItem = null;
        props = props ? props : this.props;
        if (!props.multiple) {
            const firstAdded = added && added.length > 0 ? added[0] : null;
            for (let i = 0; i < props.users.length; i++) {
                const user = props.users[i];
                if (firstAdded && user.id == firstAdded.id) {
                    this.addedItem = firstAdded;
                    return added;
                }
            }
        } else {
            for (let i = 0; i < added.length; i++) {
                const item = added[i];
                this.added.push(item);
            }
            return added;
        }
    }

    private setInvited(invited: StM.IUserStoreState[], props?: IUserSearchProps): StM.IUserStoreState[] {
        this.invited = [];
        this.invitedItem = null;
        props = props ? props : this.props;
        if (!props.multiple) {
            const firstInvited = invited && invited.length > 0 ? invited[0] : null;
            for (let i = 0; i < props.users.length; i++) {
                const user = props.users[i];
                if (firstInvited && user.id == firstInvited.id) {
                    this.invitedItem = firstInvited;
                    return invited;
                }
            }
        } else {
            for (let i = 0; i < invited.length; i++) {
                const item = invited[i];
                this.invited.push(item);
            }
            return invited;
        }
    }

    private changeFilter(e: any) {
        const searchList: any = document.getElementsByClassName('search-list-control')[0];
        searchList && (searchList.scrollTop = 0);
        const filter = e.target.value;
        const filteredArray = this.filterArray(this.state.sourceArray, filter);
        let isShowList = this.state.isShowList;

        if (!isShowList) {
            this.setDocumentClickHandler();
            isShowList = true;
        }

        this.setState({ ...this.state, filter, filteredArray, isShowList });
    }

    private filterArray(array: StM.IUserStoreState[], value?: string, user: StM.IUserStoreState = this.props.user) {
        let results = array.slice();
        if (value) {
            const upperCasedValue = value.toUpperCase();
            const arrayStartFromFilter: StM.IUserStoreState[] = [];
            const arrayNoStartFromFilter: StM.IUserStoreState[] = [];
            
            for(const item of results) {
                const valueIndex = item.displayName.toUpperCase().indexOf(upperCasedValue);
                if(valueIndex == 0) arrayStartFromFilter.push(item);
                else if(valueIndex > 0) arrayNoStartFromFilter.push(item);
            }
            results = arrayStartFromFilter.concat(arrayNoStartFromFilter);
        } else if(this.props.isNeedSort){
            results = this.sortArray(results, user);
        }

        return results;
    }

    private alphabeticComparison = (a: StM.IUserStoreState) => _.startCase(_.toLower(a.displayName));
   
    private sortArray(array: StM.IUserStoreState[], user: StM.IUserStoreState): StM.IUserStoreState[] {
        if(!this.getIsGroup()) return _.sortBy(array, this.alphabeticComparison);
        
        const currentUser: StM.IUserStoreState = store.getState().user;
        const activeMembers = _.filter(currentUser.group.members, (member: StM.IGroupMemberStoreState) => member.invitationStatus === StM.InvitationStatus.Accepted && member.user);

        return _.sortBy(array, [
            (a: StM.IUserStoreState) => !activeMembers.find((m: StM.IGroupMemberStoreState) => m.user.id === a.id),
            this.alphabeticComparison ]);
    }

    // handlers functions
    private onInputKeyDown(e: any) {
        if (e) {
            e.stopPropagation();
        }
    }

    private onFocusHandler() {
        this.setVisibilityList();
    }

    private onBlurHandler() {
        if (this.props.onInputBlur) {
            this.props.onInputBlur(this.state.added, this.state.filter);
        }
        // this.blur();
    }

    private blur() {
        this.removeDocumentClickHandler();
    }

    private setDocumentClickHandler() {
        document.addEventListener('click', this.handleDocumentClick, true);
        document.addEventListener('keydown', this.handleDocumentKeyPress);
    }

    private removeDocumentClickHandler() {
        document.removeEventListener('click', this.handleDocumentClick, true);
        document.removeEventListener('keydown', this.handleDocumentKeyPress);
    }

    private addItem(e: any, item: StM.IUserStoreState) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        const foundItem = _.find(this.added, (user) => {
            return item.id == user.id;
        });
        if (!!foundItem || (this.props.max && this.state.added.length >= this.props.max)) {
            return false;
        }

        const block: any = document.getElementsByClassName('invite-wrapper')[0];
        if (block) {
            block.style.height = block.clientHeight + 60 + 'px';
        }

        let selected: IAddedUserSearchItem[];

        const addedUser = new StM.AddedUserStoreState(item);
        addedUser.paymentType = StM.PaymentTypes.Charge;

        if (this.multiple) {
            selected = this.state.added.slice();

            selected.push(addedUser);
            this.added.push(addedUser);
        } else {
            selected = [addedUser];
            this.addedItem = addedUser;
        }

        const filter = '';
        const filteredArray = this.filterArray(this.state.sourceArray, filter);
        this.setState({ ...this.state, filter, added: selected, isShowList: !(this.props.autoClose == null || this.props.autoClose), filteredArray }, () =>{
            if (this.props.onAddChange) {
                this.props.onAddChange(selected);
            }
        });
    }

    private inviteItem(e: any, item: StM.IUserStoreState) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        const foundItem = _.find(this.invited, (user) => {
            return item.id == user.id;
        });
        if (!!foundItem || (this.props.maxInvited && this.state.invited.length >= this.props.maxInvited)) {
            return false;
        }

        const block: any = document.getElementsByClassName('invite-wrapper')[0];
        if (block) {
            block.style.height = block.clientHeight + 60 + 'px';
        }

        let invited: StM.IUserStoreState[];

        if (this.multiple) {
            invited = this.state.invited.slice();
            invited.push(item);
            this.invited.push(item);
        } else {
            invited = [item];
            this.invitedItem = item;
        }

        const filter = '';
        const filteredArray = this.filterArray(this.state.sourceArray, filter);
        this.setState({ ...this.state, filter, invited, isShowList: !(this.props.autoClose == null || this.props.autoClose), filteredArray }, () => {
            if (this.props.onInviteChange) {
                this.props.onInviteChange(invited);
            }
        });
    }

    private unselectItem(e: any, item: StM.IUserStoreState) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        _.remove(this.added, (user) => {
            return user.id == item.id;
        });
        _.remove(this.invited, (user) => {
            return user.id == item.id;
        });
        const selected = this.state.added.slice();
        const invited = this.state.invited.slice();
        let indexSelected = -1;
        let indexInvited = -1;
        for (let i = 0; i < selected.length; i++) {
            const _selectedItem = selected[i];
            if (_selectedItem && item.id == _selectedItem.id) {
                indexSelected = i;
                break;
            }
        }
        for (let j = 0; j < invited.length; j++) {
            const _invitedItem = invited[j];
            if (_invitedItem && item.id == _invitedItem.id) {
                indexInvited = j;
                break;
            }
        }

        let newState = { ...this.state, isShowList: !(this.props.autoClose == null || this.props.autoClose) };
        let shouldResetFilter = false;
        if (indexSelected + 1) {
            selected.splice(indexSelected, 1);

            if (this.props.onAddChange) {
                this.props.onAddChange(selected);
            }

            shouldResetFilter = true;
            newState.added = selected;
        }
        if (indexInvited + 1) {
            invited.splice(indexInvited, 1);
            if (this.props.onInviteChange) {
                this.props.onInviteChange(invited);
            }
            shouldResetFilter = true;
            newState.invited = invited;
        }

        if(shouldResetFilter) {
            const filter = '';
            const filteredArray = this.filterArray(this.state.sourceArray, filter);
            newState = { ...newState, filter, filteredArray };
        } 

        const block: any = document.getElementsByClassName('invite-wrapper')[0];
        if (block) {
            block.style.height = block.clientHeight - 60 + 'px';
        }

        this.setState({...newState });
    }

    private handleDocumentClick = (evt: any) => {
        const playersListsDOM = this.__DOM.getElementsByClassName('players-lists-wrapper');
        const playersLists = this && this.__DOM && playersListsDOM.length > 0 ? playersListsDOM[0] : null;
        if ((!!playersLists && !!playersLists.contains && playersLists.contains(evt.target)) || (this.__DOM.contains && !this.__DOM.contains(evt.target))) {
            this.blur();
            this.setState({ ...this.state, isShowList: false });
        }
    };

    private handleDocumentKeyPress = (evt: any) => {
        if (this.props.multiple) return false;
        if (evt.keyCode != 38 && evt.keyCode != 40) return false;

        let itemIndex = 0;

        if (this.state.added && this.state.added.length > 0) {
            for (let i = 0; i < this.props.users.length; i++) {
                const item = this.props.users[i];
                if (_.some(this.state.added, (user) => item.id == user.id)) {
                    itemIndex = i;
                }
            }
        }
        const item = evt.keyCode == 38 ? this.props.users[itemIndex - 1] : this.props.users[itemIndex + 1];
        if (!item) return false;

        const addedUser = new StM.AddedUserStoreState(item);
        addedUser.paymentType = StM.PaymentTypes.Charge;

        this.addedItem = addedUser;
        if (this.props.onAddChange) {
            this.props.onAddChange([addedUser]);
        }
        const filter = '';
        const filteredArray = this.filterArray(this.state.sourceArray, filter);
        this.setState({ ...this.state, filter, added: [addedUser], filteredArray });
    };

    private onInputClick(e: any) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        this.setVisibilityList();
    }

    private setVisibilityList() {
        if (!this.props.disabled) {
            if (!this.state.isShowList) {
                this.setState({ ...this.state, isShowList: true });
                this.setDocumentClickHandler();
            } else if (this.props.readOnly) {
                this.setState({ ...this.state, isShowList: false });
            }
        }
    }

    private getIsGroup() {
        return this.groupInfoPolicy.getIsAuthenticated();
    }
}
