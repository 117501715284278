import './inputSearch.scss';
import * as React from 'react';
import ClassNames from 'classnames';
import * as StM from '../models/store';
import * as SrvM from "../services";
import * as _ from 'lodash';

const utils = new SrvM.Utils();

interface IUserGroupSearchProps {
    userGroups: Array<StM.IMembershipLevelStoreState>;
    users: Array<StM.IUserStoreState>;
    added?: Array<StM.IAddedUserStoreState>;
    invited?: Array<StM.IUserStoreState>;
    max?: number;
    placeholder?: string;
    classes?: string;
    multiple?: boolean;
    notFoundText?: string;
    addBtnText?: string;
    readOnly?: boolean;
    disabled?: boolean;
    isNeedSort?: boolean;
    autoClose?: boolean;
    user?: StM.IUserStoreState;

    onAddChange?: (item: StM.IMembershipLevelStoreState, addedUsers: Array<StM.IAddedUserStoreState>) => void;
    onInviteChange?: (item: StM.IMembershipLevelStoreState, invitedUsers: Array<StM.IUserStoreState>) => void;
    onInputBlur?: (value?: any, filter?: string) => void;
}

interface IUserGroupSearchState {
    filter?: string;
    isShowList: boolean;
    added?: Array<StM.IAddedUserStoreState>;
    invited?: Array<StM.IUserStoreState>;
}

export class UserGroupSearch extends React.Component<IUserGroupSearchProps, IUserGroupSearchState> {
    filterString = '';
    added: Array<StM.IAddedUserStoreState> = [];
    invited: Array<StM.IUserStoreState> = [];
    addedItem: StM.IAddedUserStoreState;
    invitedItem: StM.IUserStoreState;
    multiple: boolean = false;
    __DOM: any = null;
    private authService = new SrvM.AuthenticationService();

    constructor(props: IUserGroupSearchProps) {
        super(props);

        let added = props.added ? [...props.added] : [];
        let invited = props.invited ? [...props.invited] : [];

        added = added ? this.setAdded(added) : added;
        invited = invited ? this.setInvited(invited) : invited;

        this.multiple = props.multiple || false;
        this.state = {
            filter: '',
            isShowList: false,
            added: added,
            invited: invited
        }
    }

    componentDidUpdate(prevProps: IUserGroupSearchProps) {
        const isPropsChanged = !_.isEqual(this.props.userGroups, prevProps.userGroups)
            || !_.isEqual(this.props.added, prevProps.added)
            || !_.isEqual(this.props.invited, prevProps.invited)
            || !_.isEqual(this.props.max, prevProps.max)
            || !_.isEqual(this.props.placeholder, prevProps.placeholder)
            || !_.isEqual(this.props.classes, prevProps.classes)
            || !_.isEqual(this.props.multiple, prevProps.multiple)
            || !_.isEqual(this.props.notFoundText, prevProps.notFoundText)
            || !_.isEqual(this.props.addBtnText, prevProps.addBtnText)
            || !_.isEqual(this.props.readOnly, prevProps.readOnly)
            || !_.isEqual(this.props.disabled, prevProps.disabled)
            || !_.isEqual(this.props.isNeedSort, prevProps.isNeedSort);

        if (isPropsChanged) {
            let added = [...this.props.added];
            let invited = [...this.props.invited];
            if (this.props.multiple && this.props.max < this.state.added.length) {
                added = added.slice(0, this.props.max);
            }
            added = this.setAdded(added, this.props);
            invited = this.setInvited(invited, this.props);
            this.setState({ added: added, invited: invited, isShowList: !(this.props.autoClose == null || this.props.autoClose) });
        }
    }

    componentWillUnmount() {
        if (this.state.isShowList) this.removeDocumentClickHandler();
    }

    render() {
        let filteredArray = this.filterArray(this.state.filter);
        if (this.props.isNeedSort) {
            filteredArray = _.sortBy(filteredArray, 'name');
        }

        if (this.state.filter.length > 0) {
            filteredArray = _.sortBy(filteredArray, (item: any) => {
                return (item.name.toUpperCase().indexOf(this.state.filter.toUpperCase()) == 0);
            });
        }

        let text = this.props.placeholder;
        const classes = ClassNames(this.props.classes, 'custom-select',
            {
                'disabled': this.props.disabled
                , 'readonly-input-wrapper': this.props.readOnly
                , 'not-default-value': !!this.state.added && this.state.added.length > 0
                , 'active': this.state.isShowList
            });
        var zIndexStyle = this.state.isShowList ? { zIndex: 100 } : { zIndex: 5 };
        return (
            <div className={classes} style={zIndexStyle} ref={(element) => { this.__DOM = element; /*this.fixListHeight(element, !filteredArray.length)*/ }} onClick={(e) => this.props.readOnly && this.onInputClick(e)}>
                <input type="text" placeholder={text} className="custom-input"
                    readOnly={this.props.readOnly}
                    value={this.state.filter}
                    onFocus={() => this.onFocusHandler()}
                    onClick={(e) => this.onInputClick(e)}
                    onBlur={() => this.onBlurHandler()}
                    onChange={(e) => this.changeFilter(e)} onKeyPress={(e) => this.onInputKeyDown(e)} />
                {this.state.isShowList && !!filteredArray.length && !this.props.disabled &&
                    <ul className="search-list search-list-control" ref={this.setListPosition}>
                        {filteredArray.map((item) => {
                            return this.renderMultipleItem(item);
                        })}
                        {!filteredArray.length && this.props.notFoundText && this.renderNotFound()}
                    </ul>
                }
                {this.props.children}
                <span className="caret" onClick={(e) => this.onInputClick(e)}></span>
            </div>
        );
    }

    private renderMultipleItem(item: StM.IMembershipLevelStoreState) {
        let button = this.renderAddButton(item, true);
        let inviteButton = this.renderInviteButton(item, true);

        const classes = ClassNames('search-list-item');

        return (
            <li className={classes} key={item.id}>
                <div className="name">
                    <span>{item.name}</span>
                </div>
                <div className="invite"></div>
                <div className="btns-wrapper">
                    {inviteButton}
                    {button}
                </div>
            </li>
        );
    }

    private renderAddButton(item: StM.IMembershipLevelStoreState, isCanSelect: boolean) {
        const text = this.props.addBtnText || 'Add';
        return (
            <div className="btn-container" onClick={(e) => isCanSelect && this.addUsers(e, item)}>{text}</div>
        )
    }

    private renderInviteButton(item: StM.IMembershipLevelStoreState, isCanSelect: boolean) {
        const text = 'Invite';
        return (
            <div className="btn-container" onClick={(e) => isCanSelect && this.inviteUsers(e, item)}>{text}</div>
        )
    }

    private renderNotFound() {
        return (
            <li className="search-list-item" key={0}>
                <div className="name">{this.props.notFoundText}</div>
            </li>
        );
    }

    private setAdded(added: Array<StM.IAddedUserStoreState>, props?: IUserGroupSearchProps): Array<StM.IAddedUserStoreState> {
        this.added = [];
        this.addedItem = null;
        props = props ? props : this.props;
        if (!props.multiple) {
            const firstAdded = added && added.length > 0 ? added[0] : null;
            for (let i = 0; i < props.userGroups.length; i++) {
                if (firstAdded) {
                    this.addedItem = firstAdded
                    return added;
                }
            }
        } else {
            for (let i = 0; i < added.length; i++) {
                let item = added[i];
                this.added.push(item);
            }
            return added;
        }
    }

    private setInvited(invited: Array<StM.IUserStoreState>, props?: IUserGroupSearchProps): Array<StM.IUserStoreState> {
        this.invited = [];
        this.invitedItem = null;
        props = props ? props : this.props;
        if (!props.multiple) {
            const firstInvited = invited && invited.length > 0 ? invited[0] : null;
            for (let i = 0; i < props.userGroups.length; i++) {
                let group = props.userGroups[i];
                if (firstInvited) {
                    this.invitedItem = firstInvited
                    return invited;
                }
            }
        } else {
            for (let i = 0; i < invited.length; i++) {
                let item = invited[i];
                this.invited.push(item);
            }
            return invited;
        }
    }

    private changeFilter(e: any) {
        var searchList: any = document.getElementsByClassName('search-list-control')[0];
        searchList && (searchList.scrollTop = 0);

        let isShowList = this.state.isShowList;

        if (!isShowList) {
            this.setDocumentClickHandler();
            isShowList = true;
        }

        this.setState({ ...this.state, filter: e.target.value, isShowList });
    }

    private filterArray(string?: string) {
        if (!string) return this.props.userGroups;
        let reg = new RegExp(utils.escapeRegExp(string), 'i');

        let filteredArray = this.props.userGroups.filter(group => {
            return reg.test(group.name);
        });

        return filteredArray;
    }

    // handlers functions
    private onInputKeyDown(e: any) {
        if (e) { e.stopPropagation(); }
    }

    private onFocusHandler() {
        this.setVisibilityList();
    }

    private onBlurHandler() {
        if (this.props.onInputBlur) {
            this.props.onInputBlur(this.state.added, this.state.filter);

        }
        //this.blur();
    }

    private blur() {
        this.removeDocumentClickHandler();
    }

    private setDocumentClickHandler() {
        document.addEventListener('click', this.handleDocumentClick, true);
    }

    private removeDocumentClickHandler() {
        document.removeEventListener('click', this.handleDocumentClick, true);
    }

    private addUsers(e: any, group: StM.IMembershipLevelStoreState) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        const foundUsers = this.props.users.filter(user => !_.some(this.added, { id: user.id }) && _.some(user.membershipLevels, { id: group.id }))
            .map(user => {
                let addedUser = new StM.AddedUserStoreState(user)
                addedUser.paymentType = StM.PaymentTypes.Charge;
                return addedUser;
            });

        const usersToAdd = _.take(foundUsers, this.props.max - this.added.length);
        let selected: Array<StM.IAddedUserStoreState>;

        if (this.multiple) {
            selected = this.state.added.slice();

            selected.push(...usersToAdd);
            this.added.push(...usersToAdd);
        } else {
            selected = [usersToAdd[0]];
            this.addedItem = usersToAdd[0];
            //this.blur();
        }

        if (this.props.onAddChange) {
            this.props.onAddChange(group, selected);
        }
        this.setState({ filter: '', added: selected, isShowList: !(this.props.autoClose == null || this.props.autoClose) });
    }

    private inviteUsers(e: any, group: StM.IMembershipLevelStoreState) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        const foundUsers = this.props.users.filter(user => {
            return !_.some(this.invited, (i) => i.id === user.id)
                && !_.some(this.added, (i) => i.id === user.id)
                && _.some(user.membershipLevels, (i) => i.id === group.id);
        });

        let invited: Array<StM.IUserStoreState>;

        if (this.multiple) {
            invited = this.state.invited.slice();
            invited.push(...foundUsers);
            this.invited.push(...foundUsers);
        } else {
            invited = [foundUsers[0]];
            this.invitedItem = foundUsers[0];
            //this.blur();
        }

        if (this.props.onInviteChange) {
            this.props.onInviteChange(group, invited);
        }

        this.setState({ filter: '', invited: invited, isShowList: !(this.props.autoClose == null || this.props.autoClose) });
    }

    private handleDocumentClick = (evt: any) => {
        const playersListsDOM = this.__DOM.getElementsByClassName('players-lists-wrapper');
        const playersLists = this && this.__DOM && playersListsDOM.length > 0 ? playersListsDOM[0] : null;
        if ((!!playersLists && !!playersLists.contains && playersLists.contains(evt.target)) || (!!this.__DOM.contains && !this.__DOM.contains(evt.target))) {
            this.blur();
            this.setState({ ...this.state, isShowList: false });
        }
    }

    // render
    private setListPosition(element: any) {
        if (!element) {
            return false;
        }
        const $input = element.parentElement.getElementsByTagName('input')[0];
        let listHeight = element.offsetHeight;
        let inputHeigth = $input.offsetHeight;
        let coords = $input.getBoundingClientRect();

        let bottomOffset = window.innerHeight - (inputHeigth + coords.top);

        if (bottomOffset < listHeight) {
            element.style.top = 'auto';
            element.style.bottom = '100%';
        }
    }

    private onInputClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        this.setVisibilityList();
    }

    private setVisibilityList() {
        if (!this.props.disabled) {
            if (!this.state.isShowList) {
                this.setState({ ...this.state, isShowList: true });
                this.setDocumentClickHandler();
            } else if (this.props.readOnly) {
                this.setState({ ...this.state, isShowList: false });
            }
        }
    }

    private fixListHeight(element: any, isFixHeight) {
        if (!element) {
            return false;
        }
        const ul = element.getElementsByTagName('ul')[0];
        if (isFixHeight && IS_IE) {
            ul && (ul.style.height = '0');
        } else {
            ul && (ul.style.height = '');
        }
    }
}