import './bookingUserList.scss';

import * as React from 'react';

import * as StM from '../../models/store';
import * as SrvM from '../../services';
import Constants from '../../constants';

interface IBookingUserListProps {
    list: Array<StM.IPublicUserStoreState>;
    firstItemText?: string;
    caption?: string;
    
    removeClick?: (index: number) => void;
    renderItemTex?: (item: StM.IPublicUserStoreState) => string;
    renderItem?: (item: StM.IPublicUserStoreState, index: number) => JSX.Element;
    showRemoveButton?: (item: StM.IPublicUserStoreState) => boolean;
}

class BookingUserList extends React.Component<IBookingUserListProps> {
    private utils = new SrvM.Utils();
    constructor(props: IBookingUserListProps) {
        super(props);
    }

    public render() {
        return (
            <ul className="add-list">
                {this.props.caption && !!this.props.list.length && <span className="list-caption">{this.props.caption}</span>}
                {this.props.list.map((item, number) => {
                    return this.props.renderItem
                        ? this.props.renderItem(item, number) 
                        : this.renderListItem(item, number);
                })}
            </ul>
        );
    }

    private renderListItem(item: StM.IPublicUserStoreState, index: number) {
        const image = item.imageId ? item.imageId : '';
        const url = image ? `/api/v2/blobs/${image}/content` : '/content/img/nophoto-2.png';
        return (
            <li className="add-list-item" key={index} onClick={(e) => this.onClick(e)}>
                <div className="photo">
                    <img src={url} />
                </div>
                <div className="name">{this.renderItemName(item, index)}</div>
                {(!this.props.showRemoveButton || this.props.showRemoveButton(item)) && (<div className="btn-container" onClick={(e) => this.onRemoveClick(e, index)}>Remove</div>)}
            </li>);
    }

    private renderItemName(item: StM.IPublicUserStoreState, index: number) {
        let result = '';
        if(this.props.renderItemTex) result = this.props.renderItemTex(item);
        if(!result) {
            result = this.utils.shortenString(item.displayName);
            if (this.props.firstItemText && this.props.list.length > 1 && index == 0) {
                result = `${result} (${this.props.firstItemText})`;
            }
        }

        return result;
    }

    private onClick(e: any) {
        if (e) { e.stopPropagation(); e.preventDefault(); }
    }

    private onRemoveClick(e: any, index: number) {
        if (e) { e.stopPropagation(); e.preventDefault(); }
        if (this.props.removeClick) {
            this.props.removeClick(index);
            var block: any = document.getElementsByClassName('invite-wrapper')[0];
            if (block) {
                block.style.height = block.clientHeight - 40 + 'px';
            }
        }
    }
}

export default BookingUserList;