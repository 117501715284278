export interface ISessionPrices {
    price: number;
    credits: number;
    totalPrice: number, 
    additionalPrice: number;
    additionalCredits: number;
    availableCredits: number;
    servicesPrice: number;
}

export class SessionPrices implements ISessionPrices {
    constructor(
        public price: number = 0, 
        public totalPrice: number = 0, 
        public credits: number = 0, 
        public additionalPrice: number = 0, 
        public additionalCredits: number = 0,
        public availableCredits: number = 0,
        public servicesPrice: number = 0,
    ) {}
}
