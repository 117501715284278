import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class DemographicInformationDialogReducer extends BaseReducer{

    static handle(state: StM.IDemographicInformationDialogStoreState= new StM.DemographicInformationDialogStoreState(), action: any): StM.IDemographicInformationDialogStoreState{
        switch (action.type) {
            case 'Dialogs/DemographicInformation/Open': {
                const returnUrl = action.payload.returnUrl;
                return {...state, ...{ isOpened: true, returnUrl: returnUrl}};
            }
            case 'Dialogs/DemographicInformation/Close': {
                return {...state, ...{ isOpened: false, returnUrl: ''}};
            }
            default: {
                return state;
            }
        }
    }
}
