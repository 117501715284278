import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';

export class AuthDialogTabs{
    static SignIn = 'signin';
    static SignUp = 'signup';
}

export interface IAuthDialogStoreState extends IBaseDialogStoreState{
    tab: string;
    returnUrl: string;
}

export class AuthDialogStoreState extends BaseDialogStoreState implements IAuthDialogStoreState{
    tab: string;
    returnUrl: string;

    constructor(){
        super();
        this.tab = AuthDialogTabs.SignIn;
    }
}
