import './credits.scss';
import * as React from 'react';
import ClassNames from 'classnames';

import * as SrvM from '../../services';
import * as StM from '../../models/store';


interface ICreditsProps {
    credits: number;
    type?: StM.SessionType;
    alias?: string;
    small?: boolean;
    bgColor?: string;
    txtColor?: string;
    disabled?: boolean;
}

export default class Credits extends React.PureComponent<ICreditsProps>{
    private utils = new SrvM.Utils();

    render() {
        let creditsStyle: any = {};
        if (this.props.type === StM.SessionType.Custom && !this.props.disabled) {
            creditsStyle.color = this.props.txtColor;
        }
        const wrapperClasses = ClassNames("credits-amount-wrapper", { "credits-sm": this.props.small, "disabled": this.props.disabled });

        return (
            <div className={wrapperClasses}>
                {this.renderIcon()}
                <span className="credits-amount" style={creditsStyle}>{this.props.credits}</span>
            </div>
        );
    }

    private renderIcon() {
        const creditsIconClasses = ClassNames("credits-icon", this.utils.getSessionClassByType(this.props.type));

        return (
            <div className={creditsIconClasses}>
                {this.props.small
                    ? (<svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" fill={this.props.bgColor || '#b3b3b3'}>
                        <path fillRule="evenodd"
                            d="M10,19.5c-5.2,0-9.5-4.3-9.5-9.5S4.8,0.5,10,0.5s9.5,4.3,9.5,9.5S15.2,19.5,10,19.5z M10,1.5c-4.7,0-8.6,3.8-8.6,8.6 s3.8,8.5,8.6,8.5s8.5-3.8,8.5-8.5S14.7,1.5,10,1.5z M10,17.6c-4.2,0-7.6-3.4-7.6-7.6S5.8,2.4,10,2.4s7.6,3.4,7.6,7.6 S14.2,17.6,10,17.6z M6.4,8.8c0-0.1,0.1-0.3,0.2-0.4C6.7,8.3,6.8,8.2,7,8.2c0.1-0.1,0.3-0.1,0.5-0.1c0.3,0,0.5,0.1,0.7,0.2 c0.2,0.1,0.3,0.3,0.4,0.5l1-0.4C9.4,8.1,9.2,7.8,8.8,7.5C8.4,7.3,8,7.2,7.5,7.2c-0.4,0-0.7,0.1-1,0.2C6.2,7.5,6,7.6,5.8,7.8 S5.5,8.2,5.4,8.5C5.3,8.7,5.3,9,5.3,9.3v1.4c0,0.3,0,0.6,0.1,0.8c0.1,0.3,0.2,0.5,0.4,0.7c0.2,0.2,0.4,0.3,0.7,0.5 c0.3,0.1,0.6,0.2,1,0.2c0.5,0,1-0.1,1.3-0.3c0.4-0.2,0.6-0.5,0.8-1l-1-0.4c-0.1,0.2-0.2,0.4-0.4,0.6c-0.2,0.1-0.4,0.2-0.7,0.2 c-0.2,0-0.4,0-0.5-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.1-0.1-0.3-0.1-0.4V9.2C6.3,9.1,6.3,9,6.4,8.8z M14.6,8.1c-0.1-0.2-0.2-0.4-0.4-0.5c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.5-0.1-0.7-0.1h-2.5v5.6h1v-2.3h1.1l1,2.3h1.2l-1.2-2.5 c0.4-0.1,0.7-0.3,0.9-0.6c0.2-0.3,0.3-0.6,0.3-0.9C14.7,8.6,14.7,8.3,14.6,8.1z M12.7,9.6h-1.3V8.1h1.3c0.3,0,0.5,0.1,0.7,0.2 c0.2,0.1,0.3,0.3,0.3,0.6c0,0.3-0.1,0.5-0.3,0.6C13.2,9.6,13,9.6,12.7,9.6z"
                        />
                    </svg>)
                    : (<svg xmlns="http://www.w3.org/2000/svg" width="31px" height="31px" fill={this.props.bgColor || '#b3b3b3'}>
                        <path fillRule="evenodd"
                            d="M15.5,30.5c-8.3,0-15-6.7-15-15s6.7-15,15-15s15,6.7,15,15S23.8,30.5,15.5,30.5z M15.5,2.4C8.3,2.4,2.4,8.3,2.4,15.5 s5.8,13.1,13.1,13.1s13.1-5.8,13.1-13.1S22.7,2.4,15.5,2.4z M15.5,27.6c-6.7,0-12.1-5.4-12.1-12.1c0-6.7,5.4-12.1,12.1-12.1 c6.7,0,12.1,5.4,12.1,12.1C27.6,22.2,22.2,27.6,15.5,27.6z"
                        />
                    </svg>)
                }
            </div>
        );
    }
}
