import './sessionPrice.scss';

import * as React from 'react';
import ClassNames from 'classnames';

import { StM, SrvM } from '../../modules';
import Credits from '../credits/credits';

interface IPriceProps {
    amount: number;
    sessionType: StM.SessionType;
    sessionBgColor?: string;
    sessionTxtColor?: string;
    isCredits?: boolean;
    className?: string;
}

const utils = new SrvM.Utils();

const Price = (props: IPriceProps) => {
    const classes = ClassNames('session-price-amount-item', props.className);
    return (
        <span className={classes}>
            {props.isCredits ? (
                <Credits credits={props.amount} type={props.sessionType.toString()} bgColor={props.sessionBgColor} txtColor={props.sessionTxtColor} />
            ) : (
                `$${utils.formatStringPrice(props.amount.toString())}`
            )}
        </span>
    );
};

export interface ISessionPriceProps extends IPriceProps {
    additional?: number;
    note?: string;
    title?: string;
}

export const SessionPrice = React.memo((props: ISessionPriceProps) => {
    return (
        <div className='session-price'>
            {props.title && <h3 className='session-price-title'>{props.title}</h3>}
            <div className='session-price-content'>
                <strong className='session-price-amount'>
                    <Price {...props} />
                    {!!props.additional && <Price {...props} amount={props.additional} className='session-price-amount-additional' />}
                </strong>
                {props.note && <p className='session-price-note'>{props.note}</p>}
            </div>
        </div>
    );
});
