import * as StM  from '../../../store';

export interface ILeagueListViewPageStoreState {
    sessions: Array<StM.ISessionStoreState>;
}

export class LeagueListViewPageStoreState implements ILeagueListViewPageStoreState {
    sessions: Array<StM.ISessionStoreState>;
    constructor() {
        this.sessions = new Array();
    }
}
