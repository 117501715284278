import * as Redux from 'redux';
import * as _ from 'lodash';

import {ThunkService} from '../../services/thunkService';
import * as StM from '../../models/store';
import * as ActM from '../';

export class BillingInfoDialogActions {
    static open(returnUrl?: string): (dispatch: any, getState: () => StM.IGlobalStoreState) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState) => {
            const state = getState();
            dispatch({
                type: 'Dialogs/BillingInfo/Open',
                returnUrl,
                paymentSystem: state.club.paymentSystem.type,
            });
        };
    }

    static close() {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/BillingInfo/Close',
            });
        };
    }
    static getCountries() {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.countries.getList();
        }
    }

    static save(profile: StM.IPaymentProfileInformationStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.user.savePaymentProfile(profile)
            .then((response: any) => {
                return dispatch(ActM.DialogActions.close(StM.DialogNames.Alert));
            }).catch((error: any) => {
                dispatch(ActM.DialogActions.close(StM.DialogNames.Alert));
                
                if(error.response && error.response.data && error.response.data.exceptionType.indexOf("OutstandingBalanceException") >= 0) 
                    dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, {msgKey: StM.MessagesKey.OutstandingBalanceError, messageType: StM.MessageTypes.Error}));
                
                dispatch(ActM.UserActions.getCurrent());

                throw error.response;
            });
        }
    }
}
