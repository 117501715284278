import './sessionServices.scss';

import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import moment from 'moment';

import { StM, SrvM, PolM } from '../../../modules';
import { SessionService, ServiceAction } from '../../../components/session';

const clubServices = [StM.ServiceAlias.Video, StM.ServiceAlias.VideoAnalysis];

interface IOwnProps {
    session?: StM.ISessionStoreState;
    activeServices?: StM.IAddonDefinitionStoreState[];
    checkOwner?: boolean;

    onChange?: (services: StM.IAddonDefinitionStoreState[]) => void;
    onAction?: (service: StM.IAddonDefinitionStoreState, acton: ServiceAction.RemoveVideo) => void;
}

interface IProps extends IOwnProps {
    services: StM.IAddonDefinitionStoreState[];
    club: StM.IClubStoreState;
    user: StM.IUserStoreState;
    basketSessions: StM.ISessionStoreState[];
}

interface IState {
    activeServices: Array<StM.IAddonDefinitionStoreState>;
}

class BaseSessionAdditionalServices extends React.Component<IProps, IState> {
    private utils = new SrvM.Utils();
    private authSrv = new SrvM.AuthenticationService();

    constructor(props: IProps) {
        super(props);

        this.state = {
            activeServices: this.convertServicesToMap(this.props.activeServices),
        };
    }

    public componentDidUpdate(prevProps: IProps) {
        const isActiveServicesChanged = !_.isEqual(prevProps.activeServices, this.props.activeServices);
        if (isActiveServicesChanged) {
            this.setState({ ...this.state, activeServices: this.convertServicesToMap(this.props.activeServices) });
        }
    }

    public render() {
        const { services, session, club } = this.props;
        const { activeServices } = this.state;
        if (!session || !this.getServicesAvailable()) return null;
        const isAdmin = this.utils.isAdmin();
        const isVideoEnabled = session.recordVideo && (!session.id || !isAdmin || !!session.videoLinkId) && activeServices.some((s) => s.alias === StM.ServiceAlias.Video);
        const isDisabledForSession = this.checkIsDisabledForSession(session, isAdmin);

        return (
            <section className='session-services'>
                <h2 className='session-services-title'>Additional Services</h2>
                <ul className='session-services-list'>
                    {clubServices.filter(this.filterServices).map((alias) => {
                        const isOther = alias === StM.ServiceAlias.Other;
                        const isVideoAnalysis = alias === StM.ServiceAlias.VideoAnalysis;
                        const isVideo = alias === StM.ServiceAlias.Video;
                        const disabled = isDisabledForSession || ((isVideoAnalysis || (isVideo && session.id && isAdmin && !session.videoLinkId)) && !isVideoEnabled);
                        const activeService = (isOther || isVideoEnabled) && activeServices.find((s) => s.alias === alias);

                        if (
                            isAdmin &&
                            isVideoAnalysis &&
                            !_.some(session.bookings, (b) => b.userId == session.ownerId) &&
                            !_.some(session.addedUsers, (b) => b.id == session.ownerId)
                        ) {
                            return null;
                        }

                        return (
                            <li key={alias} className='session-services-list-item'>
                                <SessionService
                                    club={club}
                                    alias={alias}
                                    active={activeService}
                                    session={session}
                                    disabled={disabled}
                                    services={services.filter((s) => s.alias === alias)}
                                    onChange={this.handleServiceChange}
                                    onAction={this.handleServiceAction}
                                />
                            </li>
                        );
                    })}
                </ul>
            </section>
        );
    }

    // handlers

    private handleServiceChange = (service: StM.IAddonDefinitionStoreState, disabled: boolean) => {
        const isVideoService = service.alias === StM.ServiceAlias.Video;
        const activeServices = [...this.state.activeServices];

        _.remove(activeServices, (s) => s.alias === service.alias);

        if (disabled) {
            if (isVideoService) _.remove(activeServices, (s) => s.alias === StM.ServiceAlias.VideoAnalysis);
        } else {
            activeServices.push(service);
        }
        this.setState({ ...this.state, activeServices }, () => {
            if (this.props.onChange) this.props.onChange(activeServices);
        });
    };

    private handleServiceAction = (service: StM.IAddonDefinitionStoreState, action: ServiceAction) => {
        if (this.props.onAction) this.props.onAction(service, action);
    };

    // helpers

    private convertServicesToMap(services: StM.IAddonDefinitionStoreState[] = []) {
        return [...services];
    }

    private getServicesAvailable() {
        const { session, user, basketSessions, services, checkOwner } = this.props;
        const isVideoEnabled = this.utils.videoIsAvailableForClub(services);
        if ((!session || !session.id) && isVideoEnabled) return true;
        const sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(session, basketSessions, user);
        const groupInfoPolicy = new PolM.GroupInfoPolicy(user);
        const isOwner = sessionAvailabilityPolicy.getIsOwner();
        const isOwnerGroupMember = !!session && groupInfoPolicy.getIsMember(session.ownerId);
        const isGroupMemberSession = sessionAvailabilityPolicy.getIsGroupMemberSession();
        const isOwnerChecked = !checkOwner || isOwner || (isGroupMemberSession && isOwnerGroupMember);
        return isVideoEnabled && isOwnerChecked;
    }

    private checkIsDisabledForSession(session: StM.ISessionStoreState, isAdmin: boolean = false) {
        if (!session) return false;
        const { status, endDateTime, series, startDateTime, bookings } = session;
        const currentTime = moment(this.utils.getCurrentClubDateTime(this.props.club));
        const isSessionStarted = currentTime.isAfter(startDateTime);
        const isFinishedStatus = status === StM.SessionStatus.Closed || status === StM.SessionStatus.Cancel;
        const isTimePassed = currentTime.isAfter(endDateTime);
        const isCourse = !!series && series.isCourse;
        const hasCheckedInBookings = bookings.some((b) => b.user && b.status === StM.BookingStatus.CheckedIn);
        const isDisabledForUser = isAdmin
            ? (isFinishedStatus || isTimePassed) &&
              (!isCourse || currentTime.isAfter(series.startDate.clone().add(endDateTime.diff(startDateTime, 'minutes'), 'minutes')))
            : isSessionStarted;

        return (hasCheckedInBookings && !isSessionStarted) || isDisabledForUser;
    }

    private filterServices = (alias: StM.ServiceAlias) => {
        const { session, services } = this.props;
        const isVideoAnalysis = alias === StM.ServiceAlias.VideoAnalysis;
        const isCustom = !!session && session.type === StM.SessionType.Custom;
        const isAdminOwner =
            !!session &&
            !!session.owner &&
            this.authSrv.isInRoles(
                [StM.Roles.ApplicationAdmin, StM.Roles.CoachAdmin, StM.Roles.ClubAdmin, StM.Roles.ManagerOnDuty],
                session.owner as StM.IUserStoreState
            );
        const hasHost = !!session && !!session.owner && !isAdminOwner;
        return services.some((s) => s.alias === alias) && (!isVideoAnalysis || !isCustom || hasHost);
    };
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: IOwnProps) => ({
    services: state.addons.filter((a) => a.isActive && clubServices.some((alias) => alias === a.alias)),
    activeServices: ownProps.activeServices,
    club: state.club,
    user: state.user,
    basketSessions: state.basket.goods,
    checkOwner: ownProps.checkOwner === undefined ? true : ownProps.checkOwner,
    onChange: ownProps.onChange,
    onAction: ownProps.onAction,
});

export const SessionAdditionalServices = connect(mapStateToProps)(BaseSessionAdditionalServices);
