import './footer.mobile.scss';
import * as React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import * as StoreModule from '../models/store';
import * as StM from '../models/store';

interface IFooterMobileProps {
    club: StM.IClubStoreState;
}

interface IFooterMobileState {
}

class FooterMobile extends React.Component<IFooterMobileProps, IFooterMobileState> {
    constructor(props: IFooterMobileProps) {
        super(props);
    }

    render() {
        const club = this.props.club;
        const year = moment().year();

        return (
        <div className="footer-mobile">
            <div className="mobile-row">
                <div className="mobile-col-12">
                    <div>&copy; {club.title} {year}</div>
                </div>
            </div>
        </div>
        )
    };
};


const mapStateToProps = (state: StoreModule.IGlobalStoreState, ownProps: any) => {
    return {
        club: state.club
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterMobile);