import { BasketActions } from './../../actions/basketActions';
import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class PackagePurchaseDialogReducer extends BaseReducer{

    static handle(state: StM.IPackageCheckoutDialogStoreState = new StM.PackageCheckoutDialogStoreState(), action: any): StM.IPackageCheckoutDialogStoreState {
        switch (action.type) {
            case 'Dialogs/Packages/Purchase/Open': {
                return { ...state, ...{ isOpened: true, packageDefinition:  action.payload.packageDefinition, packageDefinitionId: action.payload.packageDefinitionId }};
            }
            case 'Dialogs/Packages/Purchase/Close': {
                return { ...state, ...{ isOpened: false}};
            }
            default: {
                return state;
            }
        }
    }
}
