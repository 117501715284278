import * as StM from '../models/store';
import BaseReducer from './baseReducer';

export default class BurgerMenuReducer {
    static handle(state: StM.IBurgerMenuStoreState = new StM.BurgerMenuStoreState(), action: any): StM.IBurgerMenuStoreState {
        switch (action.type) {
            case 'BurgerMenu/Toggle': {
                let isOpen = state.isOpen;
                return {... state, ...{ isOpen: !isOpen }};
            }
            case 'BurgerMenu/Open': {
                return {... state, ...{ isOpen: true }};
            }
            case 'BurgerMenu/Close': {
                return {... state, ...{ isOpen: false }};
            }
            default: { 
                return state;
            }
        }
    }
}
