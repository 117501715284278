export class PricingTierDto implements IPricingTierDto {
    id: number;
    clubId: number;
    type: string;
    title: string;
    blockSize: number;
    priority: number;
    isEnabled: boolean;
    schedule: IPricingTierPeriodDto[];

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.clubId = dto && dto.clubId ? dto.clubId : 0;
        this.type = dto && dto.type ? dto.type : '';
        this.title = dto && dto.title ? dto.title : '';
        this.blockSize = dto && dto.blockSize ? dto.blockSize : 0;
        this.priority = dto && dto.priority ? dto.priority : 0;
        this.isEnabled = dto && dto.isEnabled ? dto.isEnabled : false;
        this.schedule = dto && dto.schedule ? dto.schedule : [];
    }
}
