export class AvailableTimeDto implements IAvailableTimeDto{
    id: number;
    owner: IPublicUserDto;
    ownerId: string;
    club: IClubDto;
    clubId: number;
    begin: string;
    duration: any;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.owner = dto && dto.owner ? dto.owner : null;
        this.ownerId = dto && dto.ownerId ? dto.ownerId : null;
        this.club = dto && dto.club ? dto.club : null ;
        this.clubId = dto && dto.clubId ? dto.clubId : 0 ;
        this.begin = dto && dto.begin ? dto.begin : '' ;
        this.duration = dto && dto.duration ? dto.duration : '';
    }
}
