import moment from 'moment';
import * as StM from '../../models/store';
import { IBasePolicy, BasePolicy } from '../basePolicy';
import { UserAvailabilityPolicy } from '../users';
import * as _ from 'lodash';

export class TimeAvailabilityPolicy extends BasePolicy implements IBasePolicy{
    private userAvailabilityPolicy: UserAvailabilityPolicy;
    private nowDate: moment.Moment;
    private dayDiff: number;
    private club: StM.IClubStoreState;
    
    constructor(
        private time: StM.ICourtTimeBlockStoreState
        , private currentTime: moment.Duration
        , private currentDate: moment.Moment
        , private sessions: Array<StM.ISessionStoreState>
        , private basketSessions: Array<StM.ISessionStoreState>
        , private utcDayDiff: number
        , private court: StM.ICourtStoreState 
        , private user?: StM.IUserStoreState     
    ){
        super();
        if(!this.user){ this.user = this.getCurrentUser(); }
        this.userAvailabilityPolicy = new UserAvailabilityPolicy(sessions, basketSessions,  time, court, user);
        this.club = this.getCurrentClub();
        this.nowDate = this.utils.getUtcInClubTimeZone(moment.utc(), this.club);
        this.dayDiff = this.currentDate.diff(this.nowDate.clone().startOf('day'), 'days');
    }

    handle(): boolean {
        const isFuture = this.getIsFuture();
        const isAvailableFuture = this.getIsAvailableFuture();
        const isUserFree = this.userAvailabilityPolicy.getIsUserFree();
        return isFuture && isAvailableFuture && isUserFree;
    }
    
    public getIsFuture(): boolean{
        let isFuture = false;
        if(this.currentDate.year() != this.nowDate.year()) return this.currentDate.year() > this.nowDate.year();
        if(this.dayDiff == 0 && this.currentDate.date() == this.nowDate.date() && this.currentTime.asHours() < this.time.end.asHours()){
            isFuture = true;
        }else if(this.dayDiff == 0 
            && ((this.currentDate.month() === this.nowDate.month() &&  this.currentDate.date() > this.nowDate.date())
                || this.currentDate.month() > this.nowDate.month() || this.currentDate.year() > this.nowDate.year())
            ){
            isFuture = true;
        }      
        else if(this.dayDiff > 0){
            isFuture = true;
        } 
        return isFuture;
    }

    private getIsAvailableFuture(): boolean{
        let isAvailable = true;
        
        const advanceBookingDayCount = this.getAdvanceBookingDayCount();
        if(this.utcDayDiff >= advanceBookingDayCount){
            isAvailable = false;
        } 
        return isAvailable;
    }

    private getAdvanceBookingDayCount(): number{
        let days = 1;
        if(this.user && this.user.id && this.user.level && this.user.level.id > 0){
            days = this.user.actualDetails.daysInAdvance ? this.user.actualDetails.daysInAdvance : Number.MAX_VALUE;
        }else{
            const levels = this.getState().membershipLevels;
            const defaultLevel = _.find(levels, { isDefault: true});
            if(defaultLevel){
                days = defaultLevel.daysInAdvanceABooking ? defaultLevel.daysInAdvanceABooking : Number.MAX_VALUE;
            }
        }

        return days;
    }
}
