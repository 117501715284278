import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';

export interface IBookDialogStoreState extends IBaseDialogStoreState{
}

export class BookDialogStoreState extends BaseDialogStoreState{
    constructor(){
        super();
    }
}
