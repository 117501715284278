import * as StM from '../../models/store';

export default class HistoryPageReducer {

    static handle(state: StM.IHistoryPageStoreState = new StM.HistoryPageStoreState(), action: any): StM.IHistoryPageStoreState {
        switch (action.type) {
            case 'Pages/History/Init': {
                return state;
            }
            case 'Pages/History/Payments': {
                let payments = <Array<StM.IUserPaymentStoreState>>action.payload;
                return {...state,  payments: payments};
            }
            default: {
                return state;
            }
        }
    }
}
