import * as StM from './models/store';

export interface IDialogRoute {
    name: string;
    params: Array<string>;
}

export class ApiSettings {
    public BASE_API_URL: string = '';
    public config: any = {
        withCredentials: true
    };
}

export const DialogHashParam: string = 'dialogs';
export const DialogRouteSettings = [
    { name: StM.DialogNames.Auth, params: ['tab:string', 'returnUrl:string'] },
    { name: StM.DialogNames.NewSession, params: ['courtId:number', 'timeKey:string', 'sessionType:string', 'sessionSubFilter:string', 'sessionSubFilterId:string', 'sessionId:string'] },
    { name: StM.DialogNames.Book },
    { name: StM.DialogNames.PackagePurchase, params: ['packageDefinitionId:number'] },
    { name: StM.DialogNames.SubscriptionPurchase, params: ['id:number'] },
    { name: StM.DialogNames.SessionInfo, params: ['from:string', 'id:any'] },
    { name: StM.DialogNames.UserEdit },
    { name: StM.DialogNames.BillingInfo, params: ['returnUrl:string'] },
    { name: StM.DialogNames.PasswordRecovery },
    { name: StM.DialogNames.PasswordChange, params: ['hash:string', 'email:string'] },
    { name: StM.DialogNames.HistoryQuery, params: ['paymentId:number'] },
    { name: StM.DialogNames.LeagueRequestToJoin, params: ['sessionId:number'] },
    { name: StM.DialogNames.ConfirmDeleteBasketItem, params: ['basketIndex:number', 'isPackage:boolean'] },
    { name: StM.DialogNames.ContactUs },
    { name: StM.DialogNames.Alert, params: ['messageType:string', 'msgKey:string', 'message:string'] },
    { name: StM.DialogNames.SessionCreateSuccess, params: ['isExist:boolean'] },
    { name: StM.DialogNames.PackageAdded },
    { name: StM.DialogNames.NewClub },
    { name: StM.DialogNames.CreateGroupAccount },
    { name: StM.DialogNames.InviteGroupMember, params: ['groupId: number'] },
    { name: StM.DialogNames.EditGroupAccount },
    { name: StM.DialogNames.GroupInfo, params: ['from:string', 'inviteToken:string'] },
    
    // Admin dialogs
    { name: StM.AdminDialogNames.SessionInfo, params: ['sessionId:number'] },
    { name: StM.AdminDialogNames.AddUser },
    { name: StM.AdminDialogNames.EditUser, params: ['userId:string', 'sectionType:string'] },
    { name: StM.AdminDialogNames.EditGroup, params: ['userId:string', 'sectionType:string'] },
    { name: StM.AdminDialogNames.EditCoachFeeTier, params: ['id:number'] },
    { name: StM.AdminDialogNames.EditPackage, params: ['id:number'] },
    { name: StM.AdminDialogNames.EditService, params: ['id:number'] },
    { name: StM.AdminDialogNames.EditSubscription, params: ['id:number'] },
    { name: StM.AdminDialogNames.SubscriptionHistory, params: ['id:number'] },
    { name: StM.AdminDialogNames.CreateNotification },
    { name: StM.AdminDialogNames.AssignCoachFeeTierToCourts, params: ['id:number'] },
] as Array<IDialogRoute>;

export class AppSettings {
    public Api: ApiSettings = new ApiSettings();
}

const appSettings = new AppSettings();
export default appSettings;
