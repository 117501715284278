import moment from 'moment';
import { ICourtTimeSlotStoreState } from './courtTimeSlotStoreState';

export interface IDateCourtTimeSlotStoreState {
    date: moment.Moment;
    courtTimeSlots: Array<ICourtTimeSlotStoreState>;
}

export class DateCourtTimeSlotStoreState implements IDateCourtTimeSlotStoreState {
    date: moment.Moment;
    courtTimeSlots: Array<ICourtTimeSlotStoreState>;

    constructor() {
        this.date = moment.min();
        this.courtTimeSlots = new Array<ICourtTimeSlotStoreState>();
    }
};
