import './dialogs.scss';
import './alertDialog.scss';

import ClassNames from 'classnames';
import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import * as StM from '../../models/store';
import * as ActM from '../../actions';
import {Modal} from '../../components';


interface IAlertDialogProps {
    isShown: boolean;
    messageType: string;
    title: string;
    note: string;
    isOpenByService?: boolean;

    closeDialog: (isOpenByService?: boolean) => void;
}

interface IAlertDialogState {}

class AlertDialog extends React.Component<IAlertDialogProps, IAlertDialogState> {
    constructor(props: IAlertDialogProps) {
        super(props);
    }

    render() {
        if (this.props.isShown) {
            const messageType = this.props.messageType || StM.MessageTypes.Success || StM.MessageTypes.Warning || StM.MessageTypes.Error;
            const rootClasses = ClassNames('alert-dialog', messageType)
            return (
                <Modal customZIndex={999999} classes={rootClasses} ref={(elem) => this.setPositionAlertDialog(elem)} closeHandler={() => this.props.closeDialog(this.props.isOpenByService)} dialogName={StM.DialogNames.Alert}>
                    <div className="modal-dialog dialog">
                        <div className="modal-dialog-header">
                            <div className="title success"><span className="ico-alert-dialog"></span><span>{this.props.title}</span></div>
                        </div>
                        <div className="modal-dialog-body">
                            {this.renderNote(this.props.note)}
                            <div className="btns-wrapper">
                                <button onClick={(e) => this.closeClick(e)}>Close</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            );
        } else {
            return null;
        }
    }

    setPositionAlertDialog = (elem:any) => {
        var windowHeight: any = document.body.clientHeight;
        var dialog: any = document.getElementsByClassName('alert-dialog')[0];
        var dialogHeight: any = dialog.offsetHeight;
        var topPosition = (windowHeight - dialogHeight)/2;
        dialog.style.top = topPosition + "px";
    }

    renderNote(string:string) {
        if(string) {
            return <div className="note" dangerouslySetInnerHTML={{__html: string}}></div>
        } 
        return null;
    }

    closeClick(e:any) {
        if(e) {e.stopPropagation();}
        this.props.closeDialog(this.props.isOpenByService);
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => {
    let dialog = state.dialogs.alert;
    let title = StM.Messages[dialog.msgKey] || 'Success!';
    let note = dialog.message ? `<p>${dialog.message}</p>` : StM.Notes.getNote(dialog.msgKey, state.club);

    return {
        isShown: dialog.isOpened,
        messageType: dialog.messageType,
        title: title,
        note: note,
        isOpenByService: dialog.isOpenByService,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        closeDialog: (isOpenByService?: boolean) => dispatch(ActM.DialogActions.close(StM.DialogNames.Alert, null, isOpenByService)),
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(AlertDialog);
