import * as React from 'react';
import ClassNames from 'classnames';

import FormError from '../forms/formError';
import * as SrvM from '../../../services';

interface IModalComponentProps {
    className?: string;
    children?: React.ReactNode;
}

interface IModalHeaderProps {
    title?: string;

    onCloseDialog?: (e: any) => void;
}

const utils = new SrvM.Utils();
const isMobile = utils.isMobile();

export const ModalHeader = (props: IModalHeaderProps) => {
    const handleCloseDialog = (e: any) => props.onCloseDialog && props.onCloseDialog(e);
    return (
        <header className="modal-dialog-header">
            {(props.onCloseDialog && !isMobile) && <span className="close-dialog" onClick={(e) => handleCloseDialog(e)} />}
            {props.title && <h1 className="title">{props.title}</h1>}
        </header>
    );
}

export const ModalBody = (props: IModalComponentProps) => {
    const classes = ClassNames('modal-dialog-body', props.className);
    return (
        <section className={classes}>
            <div className='modal-dialog-body-content'>
                {props.children}
            </div>
        </section>
    );
}

interface IModalSectionProps extends IModalComponentProps {
    title?: string;
}

export const ModalSection = (props: IModalSectionProps) => {
    return (
        <section className={props.className}>
            {props.title && <h2>{props.title}</h2>}
            {props.children}
        </section>
    );
}

interface IModalFooterProps extends IModalComponentProps {
    errors?: any;
    errorMessage?: string;
}

export const ModalFooter = (props: IModalFooterProps) => {
    const validator = new SrvM.ValidationService();
    const footerClasses = ClassNames('modal-dialog-footer', { error: validator.hasErrors(props.errors) });
    const contentClasses = ClassNames('modal-dialog-footer-content', props.className);
    return (
        <footer className={footerClasses}>
            <FormError errors={props.errors} message={props.errorMessage} />
            <div className={contentClasses}>
                {props.children}
            </div>
        </footer>
    );
}