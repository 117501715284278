import BaseReducer from '../baseReducer';
import * as StM from '../../models/store';

export default class AdminTransactionsReducer extends BaseReducer {
    static handle(state: Array<StM.ITransactionStoreState> = new Array<StM.TransactionStoreState>(), action: any): Array<StM.ITransactionStoreState> {
        switch (action.type) {
            case 'Admin/Transactions/Init': {
                var transactions = <Array<StM.ITransactionStoreState>>action.payload;
                return [...transactions];
            }
            default: {
                return state;
            }
        }
    }
}
