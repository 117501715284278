import moment from 'moment';

export interface IAppStoreState {
    isInitializing: boolean; 
    isFinalized: boolean;
    isAuthorizing: boolean;
    isAuthorized: boolean;
    isLoading: boolean;
    utcOffset: number;
    ownProps: any;
    match: any;
    isDehydrated: boolean;
    loadingCount: number;
}

export class AppStoreState implements IAppStoreState {
    isInitializing: boolean;
    isFinalized: boolean;
    isAuthorizing: boolean;
    isAuthorized: boolean;
    isLoading: boolean;
    utcOffset: number;
    ownProps: any;
    match: any;
    isDehydrated: boolean;
    loadingCount: number;

    constructor() {
        this.isInitializing = true;
        this.isFinalized = false;
        this.isAuthorizing = false;
        this.isAuthorized = false;
        this.isLoading = false;
        this.ownProps = null;
        this.utcOffset = moment().utcOffset();
        this.match = {};
        this.isDehydrated = false;
        this.loadingCount = 0;
    }
}
