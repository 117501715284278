import * as Redux from 'redux';
import * as _ from 'lodash';

import {ThunkService} from '../services/thunkService';
import * as DtoModule from '../models/dto';
import * as StoreModule from '../models/store';
import {ICustomSessionTypeStoreState, CustomSessionTypeStoreState} from '../models/store/clubs/customSessionTypeStoreState';

export class CustomSessionsActions {

    static init(customSessions: Array<ICustomSessionTypeStoreState>): (dispatch: any, getState: any, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'CustomSessions/Init',
                payload: customSessions
            });
        };
    }
}
