export class PaymentSystemEditableDto implements IPaymentSystemEditableDto {
    title: string;
    type: any;
    apiLogin: string;
    apiKey: string;
    apiAuthenticationParams: string;
    publicApiKey: string;
    supportsSecurePayments: boolean;
    updated: string;

    constructor(dto?: any) {
        this.title = dto && dto.title ? dto.title : '';
        this.type = dto && dto.type ? dto.type : '';
        this.apiLogin = dto && dto.apiLogin ? dto.apiLogin : '';
        this.apiKey = dto && dto.apiKey ? dto.apiKey : '';
        this.publicApiKey = dto && dto.publicApiKey ? dto.publicApiKey : '';
        this.supportsSecurePayments = dto && dto.supportsSecurePayments ? dto.supportsSecurePayments : false;
        this.updated = dto && dto.updated ? dto.updated : '';
    }
}