import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';
import { ISubscriptionOfferStateModel } from '@models/store/subscriptionOffers';

export interface ISubscriptionCheckoutDialogStoreState extends IBaseDialogStoreState{
    subscriptionDefinition: ISubscriptionOfferStateModel;
    subscriptionDefinitionId: number;
}

export class SubscriptionCheckoutDialogStoreState extends BaseDialogStoreState implements ISubscriptionCheckoutDialogStoreState {
    subscriptionDefinition: ISubscriptionOfferStateModel;
    subscriptionDefinitionId: number;
    constructor(){
        super();
        this.subscriptionDefinition = null;
        this.subscriptionDefinitionId = 0;
    }
}
