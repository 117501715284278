import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class ContacUsDialogReducer extends BaseReducer{

    static handle(state: StM.IContactUsDialogStoreState= new StM.ContactUsDialogStoreState(), action: any): StM.IContactUsDialogStoreState{
        switch (action.type) {
            case 'Dialogs/ContactUs/Open': {
                return <StM.IContactUsDialogStoreState>_.assign({}, state, { isOpened: true});
            }
            case 'Dialogs/ContactUs/Close': {
                return <StM.IContactUsDialogStoreState>_.assign({}, state, { isOpened: false});
            }
            default: {
                return state;
            }
        }
    }
}
