import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';
import { IPackageDefinitionStoreState } from '../packages';

export interface IPackageCheckoutDialogStoreState extends IBaseDialogStoreState{
    packageDefinition: IPackageDefinitionStoreState;
    packageDefinitionId: number;
}

export class PackageCheckoutDialogStoreState extends BaseDialogStoreState{
    packageDefinition: IPackageDefinitionStoreState;
    packageDefinitionId: number;
    constructor(){
        super();
        this.packageDefinition = null;
        this.packageDefinitionId = 0;
    }
}
