import * as _ from 'lodash';

import * as StoreModule from '../../models/store';
import BaseReducer from '../baseReducer';

export default class OpenedDialogsReducer extends BaseReducer {
    static handle(state: Array<string> = new Array<string>(), action: any): Array<string> {
        switch (action.type) {
            case 'Dialogs/Opened/Add': {
                const dialogName: string = action.payload.dialogName;

                if(!_.find(state, dialogName)){
                    state.push(dialogName);
                    return OpenedDialogsReducer.updateArray([], state);
                }
                return state;
            }
            case 'Dialogs/Opened/Remove': {
                const dialogName: string = action.payload.dialogName;

                if(_.find(state, dialogName)){
                    state = _.filter(state, (openedDialogName: string) => {
                        return openedDialogName !== dialogName;
                    });
                    return this.updateArray([], state);
                }
                return state;
            }
            default: {
                return state;
            }
        }
    }
}
