export class PublicUserDto implements IPublicUserDto {
    id: string;
    displayName: string;
    skill: string;
    imageId: number;
    isAvailableForInvitation: boolean;
    hasPaymentInfo: boolean;
    paymentProfileStatus: string;
    invitedBy: string;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : '';
        this.displayName = dto && dto.displayName ? dto.displayName : '';
        this.skill = dto && dto.skill ? dto.skill : '';
        this.imageId = dto && dto.imageId ? dto.imageId : null;
        this.skill = dto && dto.skill ? dto.skill : '';
        this.isAvailableForInvitation = dto && dto.isAvailableForInvitation ? dto.isAvailableForInvitation : false;
        this.hasPaymentInfo = !!dto && !!dto.hasPaymentInfo;
        this.paymentProfileStatus = dto && dto.paymentProfileStatus ? dto.paymentProfileStatus : '';
        this.invitedBy = dto && dto.invitedBy ? dto.invitedBy : '';
    }
}

export class UserDto extends PublicUserDto implements IUserDto {
    userName: string;
    email: string;
    firstName: string;
    lastName: string;
    description: string;
    dateOfBirth: string;
    disabled: boolean;
    gender: string;
    phone: string;
    discount: number;
    level: IMembershipLevelDto;
    membershipLevels: IMembershipLevelDto[];
    creditsWallets: ICreditsWalletDto[];
    isNewsletterSubscribe: boolean;
    newsletterSubscribe: boolean;
    isSendTrainNotifications: boolean;
    isSendSpecialOffers: boolean;
    sendNotificationsPeriod: string;
    isPublic: boolean;
    isOneClickPayments: boolean;
    primaryCreditCardType: string;
    paymentProfileId: string;
    creditCardShort: string;
    lastAction: string;
    registrationDate: string;
    isWatchedTutorial: boolean;
    isAcceptedWaiver: boolean;
    permissions: IPermissionDto[];
    outstandingBalance: number;
    lastRecurrentAvailabilityApplicationDate: string;
    emergencyContactPhoneNumber: string;
    emergencyContactFullName: string;
    emergencyContactRelationship: string;
    group: IGroupDto;
    state: string;
    isEmailConfirmed: boolean;
    actualDetails: {
        discount: number,
        isSubscriptionDiscount: boolean,
        daysInAdvance: number,
        isSubscriptionDaysInAdvance: boolean
    }

    constructor(dto?: any) {
        super(dto);
        this.userName = dto && dto.userName ? dto.userName : '';
        this.email = dto && dto.email ? dto.email : '';
        this.firstName = dto && dto.firstName ? dto.firstName : '';
        this.lastName = dto && dto.lastName ? dto.lastName : '';
        this.description = dto && dto.description ? dto.description : '';
        this.dateOfBirth = dto && dto.dateOfBirth;
        this.disabled = dto && dto.disabled;
        this.gender = dto && dto.gender ? dto.gender : '';
        this.phone = dto && dto.phone ? dto.phone : '';
        this.discount = dto && dto.discount ? dto.discount : 0;
        this.level = dto && dto.level ? dto.level : null;
        this.membershipLevels = dto && dto.membershipLevels ? dto.membershipLevels : new Array<IMembershipLevelDto>();
        this.newsletterSubscribe = !!(dto && dto.newsletterSubscribe);
        this.isSendTrainNotifications = dto && dto.isSendTrainNotifications;
        this.isSendSpecialOffers = dto && dto.isSendSpecialOffers;
        this.sendNotificationsPeriod = dto && dto.sendNotificationsPeriod ? dto.sendNotificationsPeriod : '';
        this.isPublic = dto && dto.isPublic;
        this.isOneClickPayments = dto && dto.isOneClickPayments;
        this.primaryCreditCardType = dto && dto.primaryCreditCardType ? dto.primaryCreditCardType : '';
        this.paymentProfileId = dto && dto.paymentProfileId ? dto.paymentProfileId : '';
        this.creditCardShort = dto && dto.creditCardShort ? dto.creditCardShort : '';
        this.lastAction = dto && dto.lastAction ? dto.lastAction : '';
        this.registrationDate = dto && dto.registrationDate ? dto.registrationDate : '';
        this.creditsWallets = dto && dto.creditsWallets ? dto.creditsWallets : new Array<ICreditsWalletDto>();
        this.isWatchedTutorial = dto && dto.isWatchedTutorial;
        this.isAcceptedWaiver = dto && dto.isAcceptedWaiver;
        this.permissions = dto && dto.permissions ? dto.permissions : new Array<IPermissionDto>();
        this.outstandingBalance = dto && dto.outstandingBalance ? dto.outstandingBalance : 0;
        this.emergencyContactPhoneNumber = dto && dto.emergencyContactPhoneNumber ? dto.emergencyContactPhoneNumber : '';
        this.emergencyContactFullName = dto && dto.emergencyContactFullName ? dto.emergencyContactFullName : '';
        this.emergencyContactRelationship = dto && dto.emergencyContactRelationship ? dto.emergencyContactRelationship : '';
        this.group = dto && dto.group ? dto.group : null;
        this.state = dto && dto.state ? dto.state : '';
        this.isEmailConfirmed = dto && dto.isEmailConfirmed;
        this.actualDetails = dto?.actualDetails ?? null;
    }
}

export class CoachDto extends PublicUserDto implements ICoachDto {
    description: string;
    coachFeeTierId: number;
    coachFeeTier: ICoachFeeTierDto;
    lastRecurrentAvailabilityApplicationDate: string;
    constructor(dto?: any) {
        super(dto);
        this.coachFeeTier = dto && dto.coachFeeTier ? dto.coachFeeTier : null;
        this.coachFeeTierId = dto && dto.coachFeeTierId ? dto.coachFeeTierId : 0;
        this.description = dto && dto.description ? dto.description : '';
        this.lastRecurrentAvailabilityApplicationDate = dto && dto.lastRecurrentAvailabilityApplicationDate ? dto.lastRecurrentAvailabilityApplicationDate : null;
    }
}

export class SessionCoachDto extends CoachDto implements ISessionCoachDto {
}

export class AddedUserDto extends PublicUserDto implements IAddedUserDto {
    paymentType: string;

    constructor(dto?: any) {
        super(dto);
        this.paymentType = dto && dto.paymentType ? dto.paymentType : '';

    }
}

export class UserEditiableDto extends UserDto implements IUserEditableDto {
    email: string;
    age: number;
    coachFeeTierId: number;
    coachFeeTier: ICoachFeeTierDto;
    lastRecurrentAvailabilityApplicationDate: string;
    archived: boolean;
    constructor(dto?: any) {
        super(dto);
        this.coachFeeTier = dto && dto.coachFeeTier ? dto.coachFeeTier : null;
        this.coachFeeTierId = dto && dto.coachFeeTierId ? dto.coachFeeTierId : null;
        this.email = dto && dto.email ? dto.email : '';
        this.age = dto && dto.age ? dto.age : 0;
        this.description = dto && dto.description ? dto.description : '';
        this.lastRecurrentAvailabilityApplicationDate = dto && dto.lastRecurrentAvailabilityApplicationDate ? dto.lastRecurrentAvailabilityApplicationDate : null;
        this.archived = dto && dto.archived ? dto.archived : false;
    }
}

export class UserManagableDto implements IUserManagableDto {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    newsletterSubscribe: boolean;
    doSendTrainNotifications: boolean;
    doSendSpecialOffers: boolean;
    sendNotificationsPeriod: string;
    aliasedTimeZone: IAliasedTimeZoneDto;
    timeZoneId: number;
    skill: string;
    age: number;
    gender: string;
    phone: string;
    isAvailableForInvitation: boolean;
    hasPaymentInfo: boolean;
    dateOfBirth: string;
    emergencyContactPhoneNumber: string;
    emergencyContactFullName: string;
    emergencyContactRelationship: string;
    
    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : '';
        this.email = dto && dto.email ? dto.email : '';
        this.firstName = dto && dto.firstName ? dto.firstName : '';
        this.lastName = dto && dto.lastName ? dto.lastName : '';
        this.newsletterSubscribe = !!(dto && dto.newsletterSubscribe);
        this.doSendTrainNotifications = !!dto && !!dto.doSendTrainNotifications;
        this.doSendSpecialOffers = !!dto && !!dto.doSendSpecialOffers;
        this.sendNotificationsPeriod = dto && dto.sendNotificationsPeriod ? dto.sendNotificationsPeriod : '';
        this.aliasedTimeZone = dto && dto.aliasedTimeZone ? dto.aliasedTimeZone : null;
        this.timeZoneId = dto && dto.timeZoneId ? dto.timeZoneId : 0;
        this.skill = dto && dto.skill ? dto.skill : '';
        this.age = dto && dto.age ? dto.age : 0;
        this.gender = dto && dto.gender ? dto.gender : '';
        this.phone = dto && dto.phone ? dto.phone : '';
        this.isAvailableForInvitation = !!dto && !!dto.isAvailableForInvitation ? dto.isAvailableForInvitation : false;
        this.hasPaymentInfo = !!dto && !!dto.hasPaymentInfo;
        this.dateOfBirth = dto && dto.dateOfBirth ? dto.dateOfBirth : '';
        this.emergencyContactPhoneNumber = dto && dto.emergencyContactPhoneNumber ? dto.emergencyContactPhoneNumber : '';
        this.emergencyContactFullName = dto && dto.emergencyContactFullName ? dto.emergencyContactFullName : '';
        this.emergencyContactRelationship = dto && dto.emergencyContactRelationship ? dto.emergencyContactRelationship : '';
    }
}

