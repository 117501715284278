import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class PasswordChangeDialogReducer extends BaseReducer{

    static handle(state: StM.IPasswordChangingDialogStoreState= new StM.PasswordChangingDialogStoreState(), action: any): StM.IPasswordChangingDialogStoreState{
        switch (action.type) {
            case 'Dialogs/PasswordChanging/Open': {
                const hash = action.payload.hash;
                const email = action.payload.email;
                return { ...state, ...{ isOpened: true, hash: hash, email: email}};
            }
            case 'Dialogs/PasswordChanging/Close': {
                return { ...state, ...{ isOpened: false, hash: null, email: null}};
            }
            default: {
                return state;
            }
        }
    }
}
