import * as DlgM from './';
import { SubscriptionCheckoutDialogStoreState } from '@models/store/dialogs/subscriptionCheckoutDialogStoreState';

export class DialogNames {
    static AddonEdit: string = 'edit-addon';
    static Alert: string = 'alert';
    static AssignCoachFeeTierToCourts: string = 'assign-coach-fee-tier-to-courts';
    static Auth: string = 'auth';
    static BillingInfo: string = 'billinginfo';
    static Book: string = 'book';
    static CancelConfirmation: string = 'cancel-confirmation';
    static CoachFeeTierEdit: string = 'coach-fee-tier-edit';
    static Confirm: string = 'confirm';
    static ConfirmDeleteBasketItem: string = 'confirm-delete-session';
    static ContactUs: string = 'contact-us';
    static CreateGroupAccount: string = 'create-group-account';
    static CreditTerms: string = 'credit-terms';
    static DemographicInformation: string = 'demographic-information';
    static EditGroup: string = 'edit-group';
    static EditGroupAccount: string = 'edit-group-account';
    static GroupInfo: string = 'group-info';
    static HistoryQuery: string = 'history-query';
    static InviteGroupMember: string = 'invite-group-member';
    static LeagueRequestToJoin: string = 'league-request-to-join';
    static NewClub: string = 'new-club';
    static NewSession: string = 'new-session';
    static NotificationCreate: string = 'create-notification';
    static PackageAdded: string = 'package-added';
    static PackageEdit: string = 'edit-package';
    static PackagePurchase: string = 'package-purchase';
    static PasswordChange: string = 'password-changing';
    static PasswordRecovery: string = 'password-recovery';
    static PrivacyPolicy: string = 'privacy-policy';
    static PublishConfirmation: string = 'publish-confirmation';
    static SessionCreateSuccess: string = 'session-create-success';
    static SessionInfo: string = 'sessioninfo';
    static SubscriptionEdit: string = 'edit-subscription';
    static SubscriptionAdded: string = 'subscription-added';
    static SubscriptionHistory: string = 'subscription-history';
    static SubscriptionNotification: string = 'subscription-notification';
    static SubscriptionPurchase = 'subscription-purchase';
    static TermsAndConditions: string = 'terms-and-conditions';
    static TransactionRefund: string = 'refund-transaction';
    static UserEdit: string = 'useredit';
    static Waiver: string = 'waiver';
    static SubscribersList: string = 'subscribers-list';
}

export interface IDialogsStoreState {
    openedDialogs: Array<any>;

    addUser: DlgM.IAddUserDialogStoreState;
    alert: DlgM.IAlertDialogStoreState;
    assignCoachFeeTierToCourts: DlgM.IAssignCoachFeeTierToCourtsDialogStoreState,
    auth: DlgM.IAuthDialogStoreState;
    billingInfo: DlgM.IBillingInfoDialogStoreState;
    book: DlgM.IBookDialogStoreState;
    cancelConfirmation: DlgM.ICancelConfirmationStoreState;
    confirm: DlgM.IConfirmDeleteBasketItemStoreState;
    confirmDlg: DlgM.IConfirmDialogStoreState;
    contactUs: DlgM.IContactUsDialogStoreState;
    createGroupAccount: DlgM.ICreateGroupAccountDialogStoreState,
    createNotification: DlgM.ICreateNotificationDialogStoreState;
    createSessionAdmin: DlgM.ICreateSessionAdminDialogStoreState;
    creditTerms: DlgM.ICreditTermsDialogStoreState;
    demographicInformation: DlgM.IDemographicInformationDialogStoreState;
    editAddon: DlgM.IEditAddonDialogStoreState;
    editCoachFeeTier: DlgM.IEditCoachFeeTierDialogStoreState;
    editGroup: DlgM.IEditGroupDialogStoreState;
    editGroupAccount: DlgM.IEditGroupAccountDialogStoreState;
    editPackage: DlgM.IEditPackageDialogStoreState;
    editSubscription: DlgM.IEditSubscriptionDialogStoreState;
    editUserAdmin: DlgM.IEditUserAdminDialogStoreState;
    groupInfo: DlgM.IGroupInfoDialogStoreState;
    historyQuery: DlgM.IHistoryQueryDialogStoreState;
    inviteGroupMember: DlgM.IInviteGroupMemberDialogStoreState,
    leagueRequestToJoin: DlgM.ILeagueRequestToJoinDialogStoreState;
    newClub: DlgM.INewClubDialogStoreState,
    packageAdded: DlgM.IPackageAddedDialogStoreState;
    packagePurchase: DlgM.IPackageCheckoutDialogStoreState;
    passwordChanging: DlgM.IPasswordChangingDialogStoreState;
    passwordRecovery: DlgM.IPasswordRecoveryDialogStoreState;
    privacyPolicy: DlgM.IPrivacyPolicyDialogStoreState;
    publishConfirmation: DlgM.IPublishConfirmationDialogStoreState;
    sessionCreateSuccess: DlgM.ISessionCreateSuccessDialogStoreState;
    sessionInfo: DlgM.ISessionInfoDialogStoreState;
    sessionInfoAdmin: DlgM.ISessionInfoAdminDialogStoreState;
    subscriptionHistory: DlgM.ISubscriptionHistoryDialogStoreState;
    subscriptionPurchase: DlgM.ISubscriptionCheckoutDialogStoreState;
    subscriptionAdded: DlgM.ISubscriptionAddedDialogStoreState;
    termsAndConditions: DlgM.ITermsAndConditionsDialogStoreState;
    transactionRefund: DlgM.ITransactionRefundDialogStoreState;
    userEdit: DlgM.IUserEditDialogStoreState;
    waiver: DlgM.IWaiverDialogStoreState;
    сreateSession: DlgM.ICreateSessionDialogStoreState;
    subscriptionNotification: DlgM.ISubscriptionNotificationDialogStoreState;
    subscribersList: DlgM.ISubscribersListDialogStoreState;
}

// tslint:disable-next-line:max-classes-per-file
export class DialogsStoreState implements IDialogsStoreState {
    openedDialogs: Array<any>;

    addUser: DlgM.IAddUserDialogStoreState;
    alert: DlgM.IAlertDialogStoreState;
    assignCoachFeeTierToCourts: DlgM.IAssignCoachFeeTierToCourtsDialogStoreState;
    auth: DlgM.AuthDialogStoreState;
    billingInfo: DlgM.IBillingInfoDialogStoreState;
    book: DlgM.IBookDialogStoreState;
    cancelConfirmation: DlgM.ICancelConfirmationStoreState;
    confirm: DlgM.IConfirmDeleteBasketItemStoreState;
    confirmDlg: DlgM.ConfirmDialogStoreState;
    contactUs: DlgM.IContactUsDialogStoreState;
    createGroupAccount: DlgM.ICreateGroupAccountDialogStoreState;
    createNotification: DlgM.CreateNotificationDialogStoreState;
    createSessionAdmin: DlgM.ICreateSessionAdminDialogStoreState;
    creditTerms: DlgM.ICreditTermsDialogStoreState;
    demographicInformation: DlgM.IDemographicInformationDialogStoreState;
    editAddon: DlgM.IEditAddonDialogStoreState;
    editCoachFeeTier: DlgM.EditCoachFeeTierDialogStoreState;
    editGroup: DlgM.IEditGroupDialogStoreState;
    editGroupAccount: DlgM.IEditGroupAccountDialogStoreState;
    editPackage: DlgM.EditPackageDialogStoreState;
    editSubscription: DlgM.EditSubscriptionDialogStoreState;
    editUserAdmin: DlgM.IEditUserAdminDialogStoreState;
    groupInfo: DlgM.IGroupInfoDialogStoreState;
    historyQuery: DlgM.IHistoryQueryDialogStoreState;
    inviteGroupMember: DlgM.IInviteGroupMemberDialogStoreState;
    leagueRequestToJoin: DlgM.ILeagueRequestToJoinDialogStoreState;
    newClub: DlgM.INewClubDialogStoreState;
    packageAdded: DlgM.PackageAddedDialogStoreState;
    packagePurchase: DlgM.IPackageCheckoutDialogStoreState;
    passwordChanging: DlgM.IPasswordChangingDialogStoreState;
    passwordRecovery: DlgM.IPasswordRecoveryDialogStoreState;
    privacyPolicy: DlgM.IPrivacyPolicyDialogStoreState;
    publishConfirmation: DlgM.IPublishConfirmationDialogStoreState;
    subscriptionNotification: DlgM.ISubscriptionNotificationDialogStoreState;
    sessionCreateSuccess: DlgM.ISessionCreateSuccessDialogStoreState;
    sessionInfo: DlgM.ISessionInfoDialogStoreState;
    sessionInfoAdmin: DlgM.ISessionInfoAdminDialogStoreState;
    subscriptionHistory: DlgM.SubscriptionHistoryDialogStoreState;
    subscribersList: DlgM.SubscribersListDialogStoreState;
    subscriptionPurchase: DlgM.SubscriptionCheckoutDialogStoreState;
    subscriptionAdded: DlgM.SubscriptionAddedDialogStoreState;
    termsAndConditions: DlgM.ITermsAndConditionsDialogStoreState;
    transactionRefund: DlgM.ITransactionRefundDialogStoreState;
    userEdit: DlgM.IUserEditDialogStoreState;
    waiver: DlgM.IWaiverDialogStoreState;
    сreateSession: DlgM.ICreateSessionDialogStoreState;

    costructor() {
        this.addUser = new DlgM.AddUserDialogStoreState();
        this.alert = new DlgM.AlertDialogStoreState();
        this.assignCoachFeeTierToCourts = new DlgM.AssignCoachFeeTierToCourtsDialogStoreState();
        this.auth = new DlgM.AuthDialogStoreState();
        this.billingInfo = new DlgM.BillingInfoDialogStoreState();
        this.book = new DlgM.BookDialogStoreState();
        this.cancelConfirmation = new DlgM.CancelConfirmationStoreState();
        this.confirm = new DlgM.ConfirmDeleteBasketItemStoreState();
        this.confirmDlg = new DlgM.ConfirmDialogStoreState();
        this.contactUs = new DlgM.ContactUsDialogStoreState();
        this.createGroupAccount = new DlgM.CreateGroupAccountDialogStoreState();
        this.createNotification = new DlgM.CreateNotificationDialogStoreState();
        this.createSessionAdmin = new DlgM.CreateSessionAdminDialogStoreState();
        this.creditTerms = new DlgM.CreditTermsDialogStoreState();
        this.demographicInformation = new DlgM.DemographicInformationDialogStoreState();
        this.editAddon = new DlgM.EditAddonDialogStoreState();
        this.editCoachFeeTier = new DlgM.EditCoachFeeTierDialogStoreState();
        this.editGroup = new DlgM.EditGroupDialogStoreState();
        this.editGroupAccount = new DlgM.EditGroupAccountDialogStoreState();
        this.editPackage = new DlgM.EditPackageDialogStoreState();
        this.editSubscription = new DlgM.EditSubscriptionDialogStoreState();
        this.editUserAdmin = new DlgM.EditUserAdminDialogStoreState();
        this.groupInfo = new DlgM.GroupInfoDialogStoreState();
        this.historyQuery = new DlgM.HistoryQueryDialogStoreState();
        this.inviteGroupMember = new DlgM.InviteGroupMemberDialogStoreState();
        this.leagueRequestToJoin = new DlgM.LeagueRequestToJoinDialogStoreState();
        this.newClub = new DlgM.NewClubDialogStoreState();
        this.openedDialogs = new Array<any>();
        this.packageAdded = new DlgM.PackageAddedDialogStoreState();
        this.packagePurchase = new DlgM.PackageCheckoutDialogStoreState;
        this.passwordChanging = new DlgM.PasswordChangingDialogStoreState();
        this.passwordRecovery = new DlgM.PasswordRecoveryDialogStoreState();
        this.privacyPolicy = new DlgM.PrivacyPolicyDialogStoreState();
        this.publishConfirmation = new DlgM.PublishConfirmationDialogStoreState;
        this.subscriptionNotification = new DlgM.SubscriptionNotificationDialogStoreState;
        this.subscribersList = new DlgM.SubscribersListDialogStoreState();
        this.sessionCreateSuccess = new DlgM.SessionCreateSuccessDialogStoreState();
        this.sessionInfo = new DlgM.SessionInfoDialogStoreState();
        this.sessionInfoAdmin = new DlgM.SessionInfoAdminDialogStoreState();
        this.subscriptionHistory = new DlgM.SubscriptionHistoryDialogStoreState();
        this.subscriptionPurchase = new DlgM.SubscriptionCheckoutDialogStoreState();
        this.subscriptionAdded = new DlgM.SubscriptionAddedDialogStoreState();
        this.termsAndConditions = new DlgM.TermsAndConditionsDialogStoreState();
        this.transactionRefund = new DlgM.TransactionRefundDialogStoreState();
        this.userEdit = new DlgM.UserEditDialogStoreState();
        this.waiver = new DlgM.WaiverDialogStoreState();
        this.сreateSession = new DlgM.CreateSessionDialogStoreState();
    }
}
