import { BaseApiService } from '../baseApiService';
import { ISubscriptionOfferStateModel } from '@models/store/subscriptionOffers';

export class SubscriptionApiService extends BaseApiService {
    private static _instance: SubscriptionApiService;

    constructor(config?: any) {
        if (typeof SubscriptionApiService._instance == 'undefined') {
            super(config);
            SubscriptionApiService._instance = this;
        }
        return SubscriptionApiService._instance;
    }

    public getAll(): Promise<Array<IRecurrentPackageOfferListDto>> {
        return this.get('')
            .then((response: any) => {
                return <Array<IRecurrentPackageOfferListDto>>response.data
            });
    }
    
    public checkout(subscriptionOfferStateModel: ISubscriptionOfferStateModel): Promise<any> {
        const { id: offerId, currentVersion: offerVersion } = subscriptionOfferStateModel;
        return this.post('/buy', {offerId, offerVersion})
            .then((response: any) => {
                return <any>response.data;
            });
    }

    public getCurrentSubscriptions(): Promise<IRecurrentPackageCommitmentDetailsDto> {
        return this.get('/commitments')
            .then((response: any) => {
                const commitmentDetails = {
                    ...response.data,
                    subcriptionAlerts: response.data.subcriptionAlerts || [],
                    billingAlerts: response.data.billingAlerts || []
                } as IRecurrentPackageCommitmentDetailsDto;
                return <any>response.data;
            });
    }

    public onUserCommitmentCancellation(id: number): Promise<any> {
        return this.put(`/commitments/${id}/cancel`)
            .then((response: any) => {
                return <any>response.status;
            });
    }
}
