import * as React from "react";
import ClassNames from 'classnames';

import { Checkbox } from '../checkbox';

interface IFieldProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    name: string;
    label?: React.ReactNode;
    error?: string;
    warning?: string;
    id?: string;

    onFieldChange?: (name: string, value: any) => void;
}

export class Field extends React.PureComponent<IFieldProps> {
    constructor(props: IFieldProps) {
        super(props);
    }

    public render() {
        const fieldClasses = ClassNames('input-item', this.props.className, { 
            error: !!this.props.error, 
            disable: this.props.disabled,
            warning: !!this.props.warning
        });
        return (
            <div className={fieldClasses}>
                {this.props.label && <label title={this.props.warning}>{this.props.label}</label>}
                <div data-testid={this.props.id}>
                    {this.renderInput()}
                    {!this.props.type && this.props.children}
                </div>
                {this.props.error && <div className="validation-error">{this.props.error}</div>}
                {!!this.props.type && this.props.children}
            </div>
        );
    }

    private renderInput() {
        if (!this.props.type) return null;
        const inputProps = { ...this.props };
        delete inputProps.onFieldChange;
        delete inputProps.error;
        delete inputProps.children;

        switch(this.props.type) {
            case 'checkbox':
                return <Checkbox {...inputProps} onChange={(e) => this.handleOnChange(e)} />;
            default:
                return <input {...inputProps} onChange={(e) => this.handleOnChange(e)} />;
        }
    }


    private handleOnChange(e: any) {
        if (e) { e.stopPropagation(); }
        if (this.props.onFieldChange) {
            const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
            this.props.onFieldChange(this.props.name, value);
        }
    }
}

export default Field;
