import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class TransactionRefundDialogReducer extends BaseReducer{

    static handle(state: StM.ITransactionRefundDialogStoreState= new StM.TransactionRefundDialogStoreState(), action: any): StM. ITransactionRefundDialogStoreState{
        switch (action.type) {
            case 'Dialogs/Transaction/Refund/Open': {
                return {...state, ...{ isOpened: true, transaction: action.transaction, closeCallback: action.closeCallback }};
            }
            case 'Dialogs/Transaction/Refund/Close': {
                return {...state, ...{ isOpened: false}};
            }
            default: {
                return state;
            }
        }
    }
}
