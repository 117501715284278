import * as Redux from 'redux';
import * as _ from 'lodash';

import {ThunkService} from '../services/thunkService';
import * as DtoM from '../models/dto';
import * as StM from '../models/store';

export class BaseActions {
    public static reloadPage(): () => void {
        return () => {
            document.getElementById('page-splash').style.display = 'unset';
            document.body.classList.add('splash-show');

            window.location.reload();
        }
    }

    static hideSplash(): (dispatch: any, getState: any, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            document.getElementById('page-splash').style.display = "none";
            document.body.classList.remove('splash-show');
        };
    }

    static setContentHeight(): (dispatch: any, getState: any, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            let setHeight = (array:Array<any>, height:any) => {
                for (let i = 0; i < array.length; i++) {
                    let $list = array[i];
                    for (let j = 0; j < $list.length; j++) {
                        let $item = $list[j];
                        $item.style.height = height;
                    }
                }
            }

            let $notif = document.querySelectorAll('.bell-hiden-content');
            let $basket = document.querySelectorAll('.basket-content-block-wrapper');

            let elemToSetHeight:Array<any> = [];
            elemToSetHeight = elemToSetHeight.concat($notif, $basket);
            setHeight(elemToSetHeight, 0);

            let $header = document.querySelector('.header');
            let $footer = document.querySelector('.footer');

            if($header && $footer){
                let $headerCoord = document.querySelector('.header').getBoundingClientRect();
                let $footerCoord = document.querySelector('.footer').getBoundingClientRect();

                let height = $footerCoord.top - $headerCoord.bottom;
                
                setHeight(elemToSetHeight, height + 'px');
            }
        };
    }
}
