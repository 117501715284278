import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';

export interface ISubscriptionHistoryDialogStoreState extends IBaseDialogStoreState {
    id: number;
}

export class SubscriptionHistoryDialogStoreState extends BaseDialogStoreState implements ISubscriptionHistoryDialogStoreState {
    id: number;

    constructor(){
        super();
    }
}