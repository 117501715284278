import * as _ from 'lodash';

import { ThunkService } from '../../services/thunkService';
import { StM } from '../../modules';
import { AppActions } from '../appActions';

export class PackagesActions {
    static getAll(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<IPackageDefinitionDto>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(AppActions.showSpinner())
            return thunkService.api.admin.getPackages()
                .then((packages) => {
                    dispatch({
                        type: 'Admin/Packages',
                        payload: packages
                    });
                    dispatch(AppActions.hideSpinner());
                    return packages;
                }).catch((error) => {
                    dispatch(AppActions.hideSpinner());
                    throw error;
                });
        };
    }

    static getPackageSessionTypes(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<IPackageSessionTypeDto>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.getPackageSessionTypes();
        };
    }

    static savePackage(definition: IPackageDefinitionDto): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<IPackageDefinitionDto> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(AppActions.showSpinner())
            return thunkService.api.admin.savePackage(definition)
            .then((packageDefinition) => {
                dispatch({
                    type: 'Admin/Package/Update',
                    payload: packageDefinition
                })
                dispatch(AppActions.hideSpinner());
                return packageDefinition;
            }).catch((error) => {
                dispatch(AppActions.hideSpinner());
                throw error;
            });
        };
    }

    static updatePackageOrder(packageId: number, order: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(AppActions.showSpinner());
            return thunkService.api.admin.updatePackageOrder(packageId, order)
                .then(() => {
                    dispatch(this.getAll());
                    dispatch(AppActions.hideSpinner());
                }).catch((error) => {
                    dispatch(AppActions.hideSpinner());
                    throw error;
                });
        };
    }

    static assignPackage(packageId: number, userId: string, note: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<IPackageDto> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.assignPackage(packageId, userId, note);
        };
    }

    static archivePackage(packageId: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(AppActions.showSpinner())
            return thunkService.api.admin.archivePackage(packageId)
            .then(() => {
                dispatch(AppActions.hideSpinner())
            }).catch(() => {
                dispatch(AppActions.hideSpinner())
            });
        };
    }

    static getUserPackages(userId: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<StM.ICreditsTransactionStoreState>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.getUserPackages(userId);
        };
    }

    static getUserBalances(userId: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<ICreditsWalletBalanceDto>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.getUserBalances(userId);
        };
    }

    static updateUserWalletBalances(userId: string, balances: Array<ICreditsWalletBalanceDto>, note: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<ICreditsWalletDto> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.updateUserWalletBalances(userId, balances, note);
        };
    }

    static updatePackageExpirationDate(packageId: number, date: moment.Moment): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(AppActions.showSpinner());
            return thunkService.api.admin.updatePackageExpirationDate(packageId, date)
                .then(() => {
                    dispatch(AppActions.hideSpinner());
                }).catch(() => {
                    dispatch(AppActions.hideSpinner());
                });
        };
    }}