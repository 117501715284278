import { IAvailableTimeStoreState } from '../users/availableTimeStoreState';
import { ICourtTimeSlotStoreState } from '../courtTimes/courtTimeSlotStoreState';
import { IDateCourtTimeSlotStoreState } from '../courtTimes/dateCourtTimeSlotStoreState';

export class ISchedulePageRouteParams {
    date: string;
    viewType: string;
}

export class SchedulePageRouteParams implements ISchedulePageRouteParams {
    date: string;
    viewType: string;
}

export interface ISchedulePageStoreState {
    sessions: Array<any>;
    availableTimes: Array<IAvailableTimeStoreState>;
    timeSlots: Array<ICourtTimeSlotStoreState>;
    dateTimeSlots: Array<IDateCourtTimeSlotStoreState>;
    refresh: boolean;
    filter: SchedulePageFilter;
}

export class ISchedulePageRouteParamsMatch {
    isExact: boolean;
    path: string;
    url: string;
    params: ISchedulePageRouteParams;
}

export class SchedulePageStoreState implements ISchedulePageStoreState {
    sessions: Array<any>;
    availableTimes: Array<IAvailableTimeStoreState>;
    timeSlots: Array<ICourtTimeSlotStoreState>;
    dateTimeSlots: Array<IDateCourtTimeSlotStoreState>;
    refresh: boolean;
    filter: SchedulePageFilter;
    constructor() {
        this.sessions = new Array();
        this.availableTimes = new Array();
        this.timeSlots = new Array();
        this.dateTimeSlots = new Array();
        this.refresh = false;
        this.filter = new SchedulePageFilter();
    }
}

export interface ISchedulePageFilter {
    ignoredCoaches: Set<string>;
    ignoredSessionTypes: Set<string>;
    ignoredSkills: Set<string>;
    ignoredAttendence: Set<string>;
}

export class SchedulePageFilter implements ISchedulePageFilter {
    ignoredCoaches: Set<string>;
    ignoredSessionTypes: Set<string>;
    ignoredSkills: Set<string>;
    ignoredAttendence: Set<string>;
    constructor() {
        this.ignoredCoaches = new Set<string>();
        this.ignoredSessionTypes = new Set<string>();
        this.ignoredSkills = new Set<string>();
        this.ignoredAttendence = new Set<string>();
    }
}

export class SchedulePageSessionTypes {
    static Play: string = 'play';
    static Clinic: string = 'clinic';
    static Lesson: string = 'lesson';
    static Custom: string = 'custom';
}

export class ScheduleFilterTypes {
    static Coach: string = 'Coach';
    static SessionType: string = 'SessionType';
    static Skill: string = 'Skill';
    static Attendence: string = 'Attendence';
}

export class ScheduleAttendenceTypes {
    static HaveParticipants: string = 'participants';
    static NoParticipants: string = 'no-participants';
}

export class CalendarViewTypes{
    static Day: string = 'day';
    static Week: string = 'week';
    static List: string = 'list';
}