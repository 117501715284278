import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class WaiverDialogReducer extends BaseReducer{

    static handle(state: StM.IWaiverDialogStoreState= new StM.WaiverDialogStoreState(), action: any): StM. IWaiverDialogStoreState{
        switch (action.type) {
            case 'Dialogs/Waiver/Open': {
                return {...state, ...{ isOpened: true}};
            }
            case 'Dialogs/Waiver/Close': {
                return {...state, ...{ isOpened: false}};
            }
            case 'Dialogs/Waiver/Accept': {
                return {...state, ...{ isAccepted: true}};
            }
            case 'Dialogs/Waiver/Decline': {
                return {...state, ...{ isAccepted: false}};
            }
            default: {
                return state;
            }
        }
    }
}
