import * as redux from 'redux';
import * as StM from '../../models/store';

import SchedulePageReducer from './schedulePageReducer';
import BadgeTooltipReducer from '../badgeTooltipReducer';
import CoachAvailabilityReducer from '../coachAvailabilityReducer';
import AdminUsersReducer from './usersReducer';
import AdminGroupsReducer from './groupsReducer';
import AdminPackagesReducer from './packagesReducer';
import AdminSubscriptionsReducer from './subscriptionsReducer';
import AdminAddonsReducer from './addonsReducer';
import AdminNotificationsReducer from './notificationsReducer';
import AdminTransactionsReducer from './transactionsReducer';
import MembershipLevelsReducer from '../membershipLevelsReducer';

export const rootAdminReducer = redux.combineReducers<StM.IAdminStoreState>({
    schedule: SchedulePageReducer.handle,
    badgeTooltip: BadgeTooltipReducer.handle,
    coachAvailability: CoachAvailabilityReducer.handle,
    users: AdminUsersReducer.handle,
    archivedUsers: AdminUsersReducer.handleArchived,
    groups: AdminGroupsReducer.handle,
    packages: AdminPackagesReducer.handle,
    subscriptions: AdminSubscriptionsReducer.handle,
    addons: AdminAddonsReducer.handle,
    notifications: AdminNotificationsReducer.handle,
    transactions: AdminTransactionsReducer.handle,
    membershipLevels: MembershipLevelsReducer.handle,
});