import { IBaseDialogStoreState, BaseDialogStoreState } from './baseDialogStoreState';

export interface IEditSubscriptionDialogStoreState extends IBaseDialogStoreState {
    id?: number;
}

export class EditSubscriptionDialogStoreState extends BaseDialogStoreState {
    id?: number;
    constructor() {
        super();
    }
}