export class Constants {
    public static DateTime = {
        API_FORMAT: 'YYYY-MM-DDTHH:mm:ss'
        , DATE_FORMAT: 'MM-DD-YYYY'
        , XLSX_FORMAT: 'DDMMMYYYY_hh.mmA'
        , TIMESTAMP: 'MM/DD/YYYY, hh:mm A'
        , DATEPICKER_FORMAT: 'MM/dd/yyyy'
        , TIME_FORMAT: 'HH:mm:ss'
        , LOCALE: 'en-US'
    };
    public static TimeLine = {
        hourHeight: 79,
        topOffset: 40
    }
    public static NameLength = 30;
    public static MaxDate = new Date(2999, 12, 31);
    public static VideoPrice = 3;
    public static MajorityAge = 18;
    public static PasswordMinLength = 6;
    public static ImagesPath = "/content/img";
    public static ApiImagesPattern = "/api/v2/blobs/{0}/content";
    public static PhonePlaceholder = "+1 (111) 111 1111";
    public static CardNumberPlaceholder = "\u2022\u2022\u2022\u2022 \u2022\u2022\u2022\u2022 \u2022\u2022\u2022\u2022";
    public static MaxGroupAccounts = 6;
    public static MaxStartTimeOffsetHours = 2;
    public static StartTimeOffsetStepMins = 15;
    public static MinsInHour = 60;
    public static TodayRouteParam = 'today';
    public static PaymentSystem = {
        expiresInDays: 60,
        minTransactionAmount: 1,
    }
}

export class Images {
    public static VideoIcon = `${Constants.ImagesPath}/camera.svg`;
    public static NoPhoto2 = `${Constants.ImagesPath}/nophoto-2.png`;
    public static AuthorizeNetLogo = `${Constants.ImagesPath}/authorize.png`;

    public static getCardIcon(cardType: string): string {
        if (!cardType) return null;
        return `${Constants.ImagesPath}/card_${cardType}.svg`;
    }
}

export class MobileImages {
    public static RedArrow: string = `${Constants.ImagesPath}/red_arrow_mobile.svg`;
}

export default Constants;
