import './inputSearch.scss';

import * as React from 'react';
import ClassNames from 'classnames';
import  _ from 'lodash';
import $ from 'jquery';

import { BaseInputSearchList, IBaseInputSearchProps, IBaseInputSearchState } from './baseInputSearch';

export interface IInputSearchItem {
    sort?: any;
    key: any;
    value: any;
    title?: string;
    className?: string;
}

export class InputSearchItem implements IInputSearchItem {
    sort?: any;
    key: any;
    value: any;
    title?: string;
    className?: string;

    constructor(cfg: IInputSearchItem) {
        this.key = cfg.key;
        this.value = cfg.value;
        this.title = cfg.title;
        this.sort = cfg.sort;
        this.className = cfg.className;
    }
}

interface IInputSearchProps extends IBaseInputSearchProps {
    array: IInputSearchItem[];
    selected?: any;
    selectedLabelText?: string;
    children?: React.ReactNode;
    dataTestId?: string;

    onSelectChange?: (item: any, e: any) => void;
    onInputBlur?: (value?: any, filter?: string) => void;
    onInputClick?: (e: any) => void;
    renderItem?: (item: IInputSearchItem) => React.ReactNode;
}

interface IInputSearchState extends IBaseInputSearchState {
    selected?: any;
    sourceArray: IInputSearchItem[];
    filteredArray: IInputSearchItem[];
}

export class InputSearch extends React.PureComponent<IInputSearchProps, IInputSearchState> {
    selectedMap: any = {};
    selectedItem: any;
    multiple: boolean = false;
    __DOM: any = null;

    constructor(props: IInputSearchProps) {
        super(props);

        let selected: any;
        if (props.selected) {
            selected = props.selected;
        }

        if (selected) {
            selected = this.setSelected(selected);
        }

        this.multiple = props.multiple || false;
        this.state = {
            filter: '',
            selected: selected,
            isShowList: false,
            filteredArray: [],
            sourceArray: [],
        };
    }

    componentDidMount() {
        const arrays = this.initArrays(this.props);
        this.setState({ ...this.state, ...arrays });
    }

    public componentDidUpdate(prevProps: IInputSearchProps) {
        const havePropsChanged = this.havePropsChanged(prevProps, this.props);

        if (havePropsChanged) {
            let selected = this.props.selected;
            if (this.props.multiple && this.state.selected && this.props.max < this.state.selected.length) {
                selected = selected.slice(0, this.props.max);
            }
            selected = this.setSelected(selected, this.props);
            const arrays = this.initArrays(this.props);
            this.setState({ selected, isShowList: false, ...arrays });
        }
    }
    
    private havePropsChanged(prevProps: IInputSearchProps, nextProps: IInputSearchProps) {
        return (
            !_.isEqual(prevProps.array, nextProps.array) ||
            !_.isEqual(prevProps.selected, nextProps.selected) ||
            !_.isEqual(prevProps.max, nextProps.max) ||
            !_.isEqual(prevProps.placeholder, nextProps.placeholder) ||
            !_.isEqual(prevProps.classes, nextProps.classes) ||
            !_.isEqual(prevProps.multiple, nextProps.multiple) ||
            !_.isEqual(prevProps.notFoundText, nextProps.notFoundText) ||
            !_.isEqual(prevProps.addBtnText, nextProps.addBtnText) ||
            !_.isEqual(prevProps.readOnly, nextProps.readOnly) ||
            !_.isEqual(prevProps.disabled, nextProps.disabled) ||
            !_.isEqual(prevProps.isNeedSort, nextProps.isNeedSort) ||
            !_.isEqual(prevProps.canSelectItem, nextProps.canSelectItem)
        );
    }

    componentWillUnmount() {
        if (this.state.isShowList) this.removeDocumentClickHandler();
    }

    render() {
        const filteredArray = this.state.filteredArray;
        let text = this.props.placeholder;
        if (!this.props.multiple) {
            text = this.state.selected ? this.state.selected.title || this.state.selected.value : text;
        }
        const renderItem = this.props.multiple ? this.renderMultipleItem.bind(this) : this.renderItem.bind(this);
        const classes = ClassNames(this.props.classes, 'custom-select', {
            disabled: this.props.disabled,
            'readonly-input-wrapper': this.props.readOnly,
            'not-default-value': this.props.selected,
            active: this.state.isShowList,
        });
        const zIndexStyle = this.state.isShowList ? { zIndex: 100 } : { zIndex: 5 };

        return (
            <div
                className={classes}
                style={zIndexStyle}
                ref={(element) => {
                    this.__DOM = element;
                }}
                onClick={(e) => this.props.readOnly && this.onInputClick(e)}
            >
                <input
                    type='text'
                    placeholder={text}
                    className='custom-input'
                    autoComplete='off'
                    readOnly={this.props.readOnly}
                    value={this.state.filter}
                    onFocus={() => this.onFocusHandler()}
                    onClick={(e) => this.onInputClick(e)}
                    onBlur={() => this.onBlurHandler()}
                    onChange={(e) => this.changeFilter(e)}
                    onKeyPress={(e) => this.onInputKeyDown(e)}
                    data-testid={this.props.dataTestId}
                />
                {this.state.isShowList && !!filteredArray.length && !this.props.disabled && (
                    <BaseInputSearchList
                        itemsCount={filteredArray.length}
                        rowHeight={this.props.rowHeight}
                        notFoundText={this.props.notFoundText}
                        renderItem={renderItem}
                    />
                )}
                {this.props.children}
                <span className='caret' onClick={(e) => this.onInputClick(e)} />
            </div>
        );
    }

    private renderItem(index) {
        const item = this.state.filteredArray[index];
        if (!item) return null; 

        const itemClasses = ClassNames('search-list-item', item.className);
        return (
            <div className={itemClasses} key={item.key} onClick={(e) => this.props.canSelectItem ?? this.selectItem(e, item)}>
                {this.props.renderItem && this.props.renderItem(item)}
                {!this.props.renderItem && <div className='name'>{item.title || item.value}</div>}
            </div>
        );
    }

    private renderMultipleItem(index) {
        const item = this.state.filteredArray[index];
        if (!item) return null; 

        let button: any;
        let invited = false;
        let isCanSelect = true;

        if (this.selectedMap[item.key]) {
            button = this.renderRemoveButton(item);
            invited = true;
        } else if (!isNaN(this.props.max) && this.state.selected && this.state.selected.length >= this.props.max) {
            button = null;
            isCanSelect = false;
        } else {
            button = this.renderAddButton(item);
        }

        const itemClasses = ClassNames('search-list-item', item.className, { invited: invited });

        return (
            <div className={itemClasses} key={item.key} onClick={(e) => isCanSelect && this.selectItem(e, item)}>
                {this.props.renderItem && this.props.renderItem(item)}
                {!this.props.renderItem && (
                    <div className="search-list-item-wrapper">
                        <div className='name'>{item.title || item.value}</div>
                        <div className='invite'>{this.props.selectedLabelText || "Invited"}</div>
                        <div className='btns-wrapper'>{button}</div>
                    </div>
                )}
            </div>
        );
    }

    private renderAddButton(item: any) {
        const text = this.props.addBtnText ? this.props.addBtnText : 'Add player';
        return <div className='btn-container'>{text}</div>;
    }

    private renderRemoveButton(item: any) {
        return (
            <div className='btn-container' onClick={(e) => this.unselectItem(e, item)}>
                Remove
            </div>
        );
    }

    private initArrays(props: IInputSearchProps)  {
        let sourceArray = props.array.slice();
        if(props.isNeedSort){
            sourceArray = this.sortArray(sourceArray);
        }
        const filteredArray = this.filterArray(sourceArray, this.state.filter);
        return { sourceArray, filteredArray }
    }

    private setSelected(value: any[], props?: IInputSearchProps) {
        this.selectedMap = {};
        this.selectedItem = null;
        props = props ? props : this.props;
        if (!props.multiple) {
            for (const item of props.array) {
                if (item.key == value) {
                    this.selectedItem = value;
                    return item;
                }
            }
        } else {
            for (const item of value) {
                this.selectedMap[item.key] = item;
            }
            return value;
        }
    }

    private changeFilter(e: any) {
        const searchList: any = document.getElementsByClassName('search-list-control')[0];
        searchList && (searchList.scrollTop = 0);
        const filter = e.target.value;
        const filteredArray = this.filterArray(this.state.sourceArray, filter);
        const foundItem = filteredArray.find(item => item.title === filter);
        let isShowList = this.state.isShowList;
        let selected = this.state.selected;

        if (!isShowList && !foundItem) {
            this.setDocumentClickHandler();
            isShowList = true;
        }

        if (foundItem && !this.props.multiple && this.props.onSelectChange) {
            selected = foundItem;
            this.props.onSelectChange(foundItem, e);
        }

        this.setState({ 
            ...this.state, 
            filter: (this.props.multiple || !foundItem) ? filter : '', 
            filteredArray, 
            isShowList, 
            selected 
        });
    }

    private filterArray(array: IInputSearchItem[], value?: string) {
        let results = array.slice();
        if (value) {
            const upperCasedValue = value.toUpperCase();
            const arrayStartFromFilter: IInputSearchItem[] = [];
            const arrayNoStartFromFilter: IInputSearchItem[] = [];

            for(const item of results) {
                const valueIndex = item.value ? item.value.toUpperCase().indexOf(upperCasedValue) : -1; 
                const titleIndex = item.title ? item.title.toUpperCase().indexOf(upperCasedValue) : -1;
                if(valueIndex === 0 || titleIndex === 0) arrayStartFromFilter.push(item);
                else if(valueIndex > 0 || titleIndex > 0) arrayNoStartFromFilter.push(item);
            }
            results = arrayStartFromFilter.concat(arrayNoStartFromFilter);
        }

        return results;
    }

    private sortArray(array: IInputSearchItem[]): IInputSearchItem[] {
        return array.sort((a, b) =>{
            const aCompareValue: string = (a.title ? a.title : a.value.toString()).toUpperCase();
            const bCompareValue: string = (b.title ? b.title : b.value.toString()).toUpperCase();
            return aCompareValue >= bCompareValue ? 1 : -1;
        });    
    }

    // handlers functions
    private onInputKeyDown(e: any) {
        if (e) {
            e.stopPropagation();
        }
    }

    private onFocusHandler() {
        // this.setVisibilityList();
    }

    private onBlurHandler() {
        if (this.props.onInputBlur) {
            this.props.onInputBlur(this.state.selected, this.state.filter);
        }
        // this.blur();
    }

    private blur() {
        this.removeDocumentClickHandler();
    }

    private setDocumentClickHandler() {
        document.addEventListener('click', this.handleDocumentClick);
        document.addEventListener('keydown', this.handleDocumentKeyPress);
    }

    private removeDocumentClickHandler() {
        document.removeEventListener('click', this.handleDocumentClick);
        document.removeEventListener('keydown', this.handleDocumentKeyPress);
    }

    private selectItem(e: any, item: any) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (this.selectedMap[item.key]
            || (this.props.max && this.state.selected && this.state.selected.length >= this.props.max)
            || this.selectItem === item.key) {
            return false;
        }

        const block: any = this.__DOM ? $(this.__DOM).closest('.invite-wrapper') : null;
        if (block.lenght) {
            block.style.height = block.clientHeight + 60 + 'px';
        }

        let selected: any;

        if (this.multiple) {
            selected = this.state.selected ? this.state.selected.slice() : [];
            selected.push(item);
            this.selectedMap[item.key] = item;
        } else {
            selected = item;
            this.selectedItem = item.key;
            // this.blur();
        }

        const filter = '';
        const filteredArray = this.filterArray(this.state.sourceArray, filter);
        this.setState({ filter, selected, isShowList: !(this.props.autoClose == null || this.props.autoClose), filteredArray }, () => {
            if (typeof this.props.onSelectChange === 'function') {
                this.props.onSelectChange(selected, e);
            }
        });
    }

    private unselectItem(e: any, item: any) {
        if (e) e.stopPropagation();
        delete this.selectedMap[item.key];
        const selected = this.state.selected ? this.state.selected.slice() : [];
        let index = 0;
        for (let i = 0; i < selected.length; i++) {
            const _selectedItem = selected[i];
            if (item.key === _selectedItem.key) {
                index = i;
                break;
            }
        }

        let newState = { ...this.state, isShowList: this.props.autoClose == null || this.props.autoClose };
        if (index + 1) {
            selected.splice(index, 1);
            if (typeof this.props.onSelectChange === 'function') {
                this.props.onSelectChange(selected, e);
            }
            const filter = '';
            const filteredArray = this.filterArray(this.state.sourceArray, filter);
            newState = { ...newState, filter, selected, filteredArray };
        }

        const block: any = this.__DOM ? $(this.__DOM).closest('.invite-wrapper') : null;
        if (block.lenght) {
            block.style.height = block.clientHeight - 60 + 'px';
        }
        this.setState({...newState});
    }

    private handleDocumentClick = (evt: any) => {
        const addList =
            !!this && this.__DOM && this.__DOM.getElementsByClassName('add-list').length > 0 ? this.__DOM.getElementsByClassName('add-list')[0] : null;
        if ((!!addList && !!addList.contains && addList.contains(evt.target)) || (!!this.__DOM && !!this.__DOM.contains && !this.__DOM.contains(evt.target))) {
            this.blur();
            this.setState({ ...this.state, isShowList: false });
        }
    };

    private handleDocumentKeyPress = (evt: any) => {
        if (this.props.multiple) return false;
        if (evt.keyCode !== 38 && evt.keyCode !== 40) return false;

        let itemIndex = 0;

        if (this.state.selected) {
            for (let i = 0; i < this.props.array.length; i++) {
                const item = this.props.array[i];
                if (item.key === this.state.selected.key) {
                    itemIndex = i;
                }
            }
        }

        const item = evt.keyCode === 38 ? this.props.array[itemIndex - 1] : this.props.array[itemIndex + 1];
        if (!item) return false;

        this.selectedItem = item.key;
        if (typeof this.props.onSelectChange === 'function') {
            this.props.onSelectChange(item, evt);
        }
        const filter = '';
        const filteredArray = this.filterArray(this.state.sourceArray, filter);
        this.setState({ ...this.state, filter, selected: item, filteredArray });
    };

    private onInputClick(e: any) {
        this.setVisibilityList();

        if (this.props.onInputClick) {
            this.props.onInputClick(e);
        }
    }

    private setVisibilityList() {
        if (!this.props.disabled) {
            this.setDocumentClickHandler();
            if (!this.state.isShowList) {
                this.setState({ ...this.state, isShowList: true });
            } else if (this.props.readOnly) {
                this.setState({ ...this.state, isShowList: false });
            }
        }
    }
}
