import * as _ from 'lodash';
import moment from 'moment';
import * as StM from '../models/store';

import { IBasePolicy, BasePolicy } from './basePolicy';
import { SessionAvailabilityPolicy } from './sessions';
import { CoachAvailabilityPolicy } from './users';
import { AuthenticationService } from '../services';

export class BookPageFilterPolicy extends BasePolicy implements IBasePolicy {

    private sessionAvailabilityPolicy: SessionAvailabilityPolicy;
    private coachAvailabilityPolicy: CoachAvailabilityPolicy;
    private authSrv = new AuthenticationService();

    constructor(
        private sessions: Array<StM.ISessionStoreState>
        , private basketSessions: Array<StM.ISessionStoreState>
        , private availableTimes: Array<StM.IAvailableTimeStoreState>
        , private currentDate: moment.Moment
        , private time: StM.ICourtTimeBlockStoreState
        , private session: StM.ISessionStoreState
        , private filter: StM.IBookPageRouteParams
        , private court: StM.ICourtStoreState
        , private coaches: StM.ICoachStoreState[]
        , private coachFeeTiers: StM.ICoachFeeTierStoreState[]
    ) {
        super();
        this.sessionAvailabilityPolicy = new SessionAvailabilityPolicy(session, basketSessions);
        this.coachAvailabilityPolicy = new CoachAvailabilityPolicy(sessions, availableTimes, currentDate, time, filter, false, coachFeeTiers);
    }

    handle(): boolean {
        const result = this.getIsFiltered();

        return result;
    }

    private getIsFiltered(): boolean {
        let isFiltered = false;
        if (!this.filter) return isFiltered;
        switch (this.filter.sessionType) {
            case StM.BookPageSessionType.Play:
                isFiltered = this.getIsFilteredPlay();
                break;
            case StM.BookPageSessionType.Lesson:
                isFiltered = this.getIsFilteredLesson();
                break;
            case StM.BookPageSessionType.Clinic:
                isFiltered = !!this.time.session && this.getIsFilteredClinic();
                break;
            case StM.BookPageSessionType.Custom:
                isFiltered = !!this.time.session && this.getIsFilteredCustom();
                break;
            case StM.BookPageSessionType.Group:
                isFiltered = this.getIsFilteredGroupMemberSession();
                break;
            default:
                isFiltered = false;
                break;
        }

        return isFiltered;
    }


    private getIsFilteredPlay() {
        let isSubFilter = true;

        if (!!this.session) {
            const isGroupMemberSession = this.sessionAvailabilityPolicy.getIsGroupMemberSession(false);
            const isAvailablePlay = this.session.type === StM.SessionType.Play 
                && (this.sessionAvailabilityPolicy.handle() || isGroupMemberSession);
            if (this.filter.playSubfilter === StM.BookPagePlaySessionVisibility.OnlyMySessions) {
                isSubFilter = isAvailablePlay && !this.sessionAvailabilityPolicy.getIsOpenBoard();
            } else if (this.filter.playSubfilter === StM.BookPagePlaySessionVisibility.AllSessions) {
                isSubFilter = isAvailablePlay;
            }
        }
        return isSubFilter;
    }

    private getIsFilteredLesson() {
        const coach = _.find(this.coaches, (coach) => coach.id === this.filter.lessonSubfilterId);
        const isCoachAvailable = this.coachAvailabilityPolicy.handle();
        const isCoachAssignedToCourts = this.coachAvailabilityPolicy.getIsCoachAssignedToCourts(coach, [this.court]);
        const isLesson = !!this.session && this.session.type === StM.SessionType.Private;

        return (isCoachAvailable && isCoachAssignedToCourts && !this.session)
            || (isLesson || (!!this.session && this.session.trainerId === this.filter.lessonSubfilterId));
    }

    private getIsFilteredClinic() {
        if (!this.session) return false;
        const isClinic = this.session.type === StM.SessionType.Clinic;
        const isCustomClinic = this.session.type === StM.SessionType.Custom && this.session.typeAlias === 'clinic';
        return isClinic || isCustomClinic;
    }

    private getIsFilteredCustom() {
        const isFilter = !!this.session && this.session.type === StM.SessionType.Custom;
        return !!isFilter;
    }

    private getIsFilteredGroupMemberSession() {
        const user = this.getCurrentUser();
        const isGroupAccount = this.authSrv.isInRole(StM.Roles.GroupAccount, user);
        if(!isGroupAccount) return false;

        const member = user.group.members.find(m => m.id === +this.filter.memberId);
        return !this.session || (!!member && member.user && this.session.bookings.some(b => b.userId === member.user.id && this.utils.isActiveBooking(this.session, b)));
    }
}