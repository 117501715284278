import * as Redux from 'redux';

import { ThunkService } from '../../services/thunkService';
import { StM, ActM } from '../../modules';

export class SettingsActions {
    constructor() {
    }

    static createClub(newClub: StM.INewClubStoreState): (
            dispatch: any,
            getState: () => StM.IGlobalStoreState,
            thunkService: ThunkService) => Promise<any> {
        return (
            dispatch: any,
            getState: () => StM.IGlobalStoreState,
            thunkService: ThunkService) => thunkService.api.admin.createClub(newClub).then((response: any) => response.data);
    }

    static getClub(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.IClubStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.getClub()
                .then((club: StM.IClubStoreState) => {
                    dispatch({
                        type: 'Club/Init',
                        payload: club
                    });
                    return club;
                });
        };
    }

    static saveClub(club: StM.IClubStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.IClubStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(ActM.AppActions.showSpinner());
            return thunkService.api.admin.saveClub(club)
                .then((club: StM.IClubStoreState) => {
                    dispatch(ActM.AppActions.hideSpinner());
                    dispatch({ type: 'Club/Init', payload: club });
                    return club;
                }).catch((error) => {
                    dispatch(ActM.AppActions.hideSpinner());
                    throw error;
                });
        };
    }

    static uploadImage(image: string, file: any):
        (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkSrv: ThunkService) => {
            return thunkSrv.api.admin.uploadImage(image, file);
        };
    }
    
    static saveClubPricing(club: StM.IClubStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.IClubStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.saveClubPricing(club)
                .then((club: StM.IClubStoreState) => {
                    return club;
                });
        };
    }

    static saveClubTimes(clubTimes: Array<StM.IClubTimeStoreState>, club: StM.IClubStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.IClubStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.saveClubTimes(clubTimes, club)
                .then((club: StM.IClubStoreState) => {
                    return club;
                });
        };
    }

    static getTimeZones(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<StM.IAliasedTimeZoneStoreState>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.getTimeZones()
                .then((timezones: Array<StM.IAliasedTimeZoneStoreState>) => {
                    return timezones;
                });
        };
    }

    static saveCourt(court: StM.ICourtStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.ICourtStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const club = getState().club;
            return thunkService.api.admin.saveCourt(court)
                .then((court: StM.ICourtStoreState) => {
                    return court;
                });
        };
    }

    static deleteCourt(court: StM.ICourtStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.ICourtStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const club = getState().club;
            return thunkService.api.admin.deleteCourt(court)
                .then((court: StM.ICourtStoreState) => {
                    return court;
                });
        };
    }

    static saveCourtsOrder(courts: Array<StM.ICourtStoreState>): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const club = getState().club;
            return thunkService.api.admin.saveCourtsOrder(courts)
                .then((response: any) => {
                    return response;
                });
        };
    }

    static getCoachFeeTiers(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<StM.ICoachFeeTierStoreState>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.getCoachFeeTiers()
                .then((coachFeeTiers: Array<StM.ICoachFeeTierStoreState>) => {
                    const filteredTiers = coachFeeTiers.filter((item) => { return !!item.subtitle });
                    dispatch(ActM.CoachFeeTiersActions.init(filteredTiers));
                    return coachFeeTiers;
                });
        };
    }

    static deleteCoachFeeTier(coachFeeTier: StM.ICoachFeeTierStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<boolean> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.deleteCoachFeeTier(coachFeeTier);
        };
    }

    static saveCoachFeeTier(coachFeeTier: StM.ICoachFeeTierStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.saveCoachFeeTier(coachFeeTier, true);
        };
    }

    static setCoachFeeTierOrder(id: number, orderNumber: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<boolean> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.setCoachFeeTierOrder(id, orderNumber);
        };
    }

    static getMembershiplevels(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<StM.IMembershipLevelStoreState>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.getMembershipLievels()
                .then((levels: Array<StM.IMembershipLevelStoreState>) => {
                    return levels;
                });
        };
    }

    static saveMembershipLevel(level: StM.IMembershipLevelStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<StM.IMembershipLevelStoreState>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.saveMembershipLievel(level)
                .then((level: Array<StM.IMembershipLevelStoreState>) => {
                    return level;
                });
        };
    }

    static removeMembershiplevel(id: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(ActM.AppActions.showSpinner());
            return thunkService.api.admin.removeMembershipLevel(id)
                .then(() => {
                    dispatch(ActM.AppActions.hideSpinner());
                }).catch((error) => {
                    dispatch(ActM.AppActions.hideSpinner());
                    throw error.data;
                });
        };
    }

    static saveStaticPage(page: StM.IStaticPageStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.IStaticPageStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.saveStaticPage(page)
                .then((page: StM.IStaticPageStoreState) => {
                    return page;
                });
        };
    }

    static getStaticPages(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<StM.IStaticPageStoreState>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.getStaticPages()
                .then((levels: Array<StM.IStaticPageStoreState>) => {
                    return levels;
                });
        };
    }

    public static getPaymentSystems(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.IPaymentSystemEditableStoreState[]> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const club = getState().club;
            dispatch(ActM.AppActions.showSpinner());
            return thunkService.api.admin.getPaymentSystems(club)
                .then((results) => {
                    dispatch(ActM.AppActions.hideSpinner());
                    return results;
                }).catch((error) => {
                    dispatch(ActM.AppActions.hideSpinner());
                    throw error;
                });
        }
    }

    public static updatePaymentSystem(paymentSystem: StM.IPaymentSystemEditableStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.IPaymentSystemEditableStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const club = getState().club;
            dispatch(ActM.AppActions.showSpinner());
            if (paymentSystem.type === StM.PaymentSystem.Stripe) paymentSystem.supportsSecurePayments = true;
            return thunkService.api.admin.updatePaymentSystem(club, paymentSystem)
                .then((result) => {
                    dispatch(ActM.AppActions.hideSpinner());
                    return result;
                }).catch((error) => {
                    dispatch(ActM.AppActions.hideSpinner());
                    throw error.data;
                });
        }
    }
}