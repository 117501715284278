export class SeekingDto implements ISeekingDto {
    id: number;
    type: string;
    description: string;
    userId: string;
    owner: IPublicUserDto;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.type = dto && dto.type ? dto.type : '';
        this.description = dto && dto.description ? dto.description : '';
        this.userId = dto && dto.userId ? dto.userId : '';
        this.owner = dto && dto.owner ? dto.owner : null;
    }
}