import * as StM from '../../store';
import moment from 'moment';
import Constants from '../../../constants';

export interface IGroupStoreState {
    id: number;
    type: StM.GroupType;
    groupAccount: StM.IPublicUserStoreState;
    groupAccountId: string;
    members: Array<StM.IGroupMemberStoreState>;
    club: StM.IClubStoreState;
    title: string;
    description: string;
    notes: string;
    disabled: boolean;
    created: moment.Moment;
    createdBy: StM.IPublicUserStoreState;
    lastUpdated: moment.Moment;
    lastUpdatedBy: StM.IPublicUserStoreState;
    events: Array<StM.IGroupEventStoreState>;
    archived: boolean;
    outstandingBalance: number;
}

export class GroupStoreState implements IGroupStoreState  {
    id: number;
    type: StM.GroupType;
    groupAccount: StM.IPublicUserStoreState;
    groupAccountId: string;
    members: Array<StM.IGroupMemberStoreState>;
    club: StM.IClubStoreState;
    title: string;
    description: string;
    notes: string;
    disabled: boolean;
    created: moment.Moment;
    createdBy: StM.IPublicUserStoreState;
    lastUpdated: moment.Moment;
    lastUpdatedBy: StM.IPublicUserStoreState;
    events: Array<StM.IGroupEventStoreState>;
    archived: boolean;
    outstandingBalance: number;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.type = dto && dto.type ? dto.type : StM.GroupType.Team;
        this.groupAccount = dto && dto.groupAccount ? dto.groupAccount : null;
        this.groupAccountId = dto && dto.groupAccountId ? dto.groupAccountId : 0;
        this.members = dto && dto.members ? dto.members : [];
        this.club = dto && dto.club ? dto.club : null;
        this.title = dto && dto.title ? dto.title : '';
        this.description = dto && dto.description ? dto.description : '';
        this.description = dto && dto.description ? dto.description : '';
        this.notes = dto && dto.notes ? dto.notes : '';
        this.disabled = dto && dto.disabled ? dto.disabled : false;
        this.created = dto && dto.created ? moment(dto.created, Constants.DateTime.API_FORMAT) : moment.min();
        this.lastUpdated = dto && dto.lastUpdated ? moment(dto.lastUpdated, Constants.DateTime.API_FORMAT) : moment.min();
        this.createdBy = dto && dto.createdBy ? dto.createdBy : null;
        this.lastUpdatedBy = dto && dto.lastUpdatedBy ? dto.lastUpdatedBy : null;
        this.events = dto && dto.events ? dto.events : [];
        this.archived = dto && dto.archived ? dto.archived : false;
        this.outstandingBalance = dto && dto.outstandingBalance ? dto.outstandingBalance : 0;
    }
}