import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';
import * as StM from '../../store'; 

export interface IPasswordChangingDialogStoreState extends IBaseDialogStoreState{
    hash: string;
    email: string;
}

export class PasswordChangingDialogStoreState extends BaseDialogStoreState{
    hash: string;
    email: string;
    constructor(){
        super();
        this.hash = null;
        this.email = null;
    }
}