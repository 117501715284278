import Constants from '../../../constants';
import * as StM from '../../store';

export interface IPackagesStoreState {
    definitions: Array<StM.IPackageDefinitionStoreState>;
    types: Array<StM.IPackageSessionTypeStoreState>;
    
}

export class PackagesStoreState implements IPackagesStoreState  {
    definitions: Array<StM.IPackageDefinitionStoreState>;
    types: Array<StM.IPackageSessionTypeStoreState>;

    constructor(dto?: any) {
        this.definitions = dto && dto.definitions ? dto.definitions : new Array<StM.IPackageDefinitionStoreState>();
        this.types = dto && dto.types ? dto.types : new Array<StM.IPackageSessionTypeStoreState>();
    }
}
