export class MembershipLevelDto implements IMembershipLevelDto{
    id: number;
    name: string;
    primeTimeSessionsPerMonth: number;
    nonPrimeTimeSessionsPerMonth: number;
    daysInAdvanceABooking: number;
    disabled: boolean;
    isDefault: boolean;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.name = dto && dto.name ? dto.name : '';
        this.primeTimeSessionsPerMonth = dto && dto.primeTimeSessionsPerMonth ? dto.primeTimeSessionsPerMonth : 0;
        this.nonPrimeTimeSessionsPerMonth = dto && dto.nonPrimeTimeSessionsPerMonth ? dto.nonPrimeTimeSessionsPerMonth : 0;
        this.daysInAdvanceABooking = dto && dto.daysInAdvanceABooking ? dto.daysInAdvanceABooking : 0;
        this.disabled = dto && dto.disabled;
        this.isDefault = dto && dto.isDefault;
    }
}
