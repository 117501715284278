import * as Redux from 'redux';

import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';
import * as SrvM from '../../services';
import * as ActM from '../';
import * as PolM from '../../policies';

const utils = new SrvM.Utils();

export class OpenSessionsBoardPageActions {
    public static init() : (dispatch: any) => Promise<void> {
        return (dispatch: any) => {
            const final = () => {
                dispatch(ActM.AppActions.hideSpinner());
                dispatch(ActM.BaseActions.hideSplash());
            }
            return Promise.resolve(dispatch(OpenSessionsBoardPageActions.getSessions()))
                .then(() => {
                    dispatch({ type: 'Pages/SeekingPlayerBoard/Init', payload: null });
                    final();
                })
                .catch(() => {
                    final();
                });        
        };
    }

    public static getSessions(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers, user, basket } =  getState();
            const basketSessions = basket ? basket.goods : [];

            const today = utils.getCurrentClubDateTime(club);
            const weekForward = today.clone().add(1, 'weeks');

            return thunkService.api.session.getByPeriod(today, weekForward, club, pricingTiers)
                .then((sessions: Array<StM.ISessionStoreState>) => {
                    const sharedSessions = sessions.filter((session) => {
                        const sessionAvailabilityPolicy = new PolM.SessionAvailabilityPolicy(session, basketSessions);
                        const sessionInfo = new PolM.SessionInfoPolicy(session, user);
                        return sessionAvailabilityPolicy.getIsOpenBoard()
                            && (session.type == StM.SessionType.Play || session.type == StM.SessionType.Private)
                            && !session.isHidden
                            && (session.maxUserCount - sessionInfo.getPlayers().length) > 0
                            ;
                    })
                        .sort((session: StM.ISessionStoreState) => {
                            return session.startDateTime.valueOf();
                        });

                    dispatch({
                        type: 'Pages/SeekingPlayerBoard/Sessions',
                        payload: sharedSessions
                    });
                })
        };
    }
}
