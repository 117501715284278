import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';

export class LeagueRequestToJoinDialogActions {
    static open(sessionId: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/LeagueRequestToJoin/Open',
                payload: {sessionId: sessionId}
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/LeagueRequestToJoin/Close',
            });
        };
    }

    static sendRequest(name: string, email: string, phone: string, skill: string, leagueName: string, comment: string, session: StM.ISessionStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<void>{
        
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const store = <StM.IGlobalStoreState>getState();
            const club = store && store.club ? store.club : null;
            return thunkService.api.session.sendLeagueRequest(name, email, phone, skill, leagueName, comment, session, club)
                .then(() => {
                    return Promise.resolve();
                });
        };
    }
}
