import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';

export class SessionCreateSuccessDialogActions {
    static open(isExist: boolean): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/SessionCreateSuccess/Open'
                , payload: {isExist: isExist}
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/SessionCreateSuccess/Close'
            });
        };
    }
}
