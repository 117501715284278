export class NotificationDto implements INotificationDto{
    id: number;
    message: string;
    subject: string;
    club: IClubDto;
    clubId: number;
    dateCreated: string;
    expirationDate: string;
    createdBy: IPublicUserDto;
    createdById: string;
    addressedTo: IPublicUserDto;
    addressedToId: string;
    sendDate: string;
    messageStatus: string;
    notificationType: string;
    targetId: number;
    targetType: string;
    event: string;
    target: any;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.message = dto && dto.message ? dto.message : '';
        this.subject = dto && dto.subject ? dto.subject : '';
        this.club = dto && dto.club ? dto.club : null;
        this.clubId = dto && dto.clubId ? dto.clubId : 0;
        this.dateCreated = dto && dto.dateCreated ? dto.dateCreated : '';
        this.expirationDate = dto && dto.expirationDate ? dto.expirationDate : '';
        this.createdBy = dto && dto.createdBy ? dto.createdBy : null;
        this.createdById = dto && dto.createdById ? dto.createdById : '';
        this.addressedTo = dto && dto.addressedTo ? dto.addressedTo : null;
        this.addressedToId = dto && dto.addressedToId ? dto.addressedToId : '';
        this.sendDate = dto && dto.sendDate ? dto.sendDate : '';
        this.messageStatus = dto && dto.messageStatus ? dto.messageStatus : '';
        this.notificationType = dto && dto.notificationType ? dto.notificationType : '';
        this.targetId = dto && dto.targetId ? dto.targetId : '';
        this.targetType = dto && dto.targetType ? dto.targetType : '';
        this.event = dto && dto.event ? dto.event : '';
        this.target = dto && dto.target ? dto.target : null;
    }
}
