import * as _ from 'lodash';
import moment from 'moment';

import { StM, SrvM } from '../modules';

export class HydratedStateService {
    private static _instance: HydratedStateService;
    constructor() {
        if (typeof HydratedStateService._instance == "undefined") {
            HydratedStateService._instance = this;
        }
        return HydratedStateService._instance;
    }

    getRestoredState(state: any): StM.IGlobalStoreState {
        let handledState = <StM.IGlobalStoreState>{ ...state };
        handledState.authenticationTicket = !!state.authenticationTicket ? this.getRestoredAuthTicket(state.authenticationTicket) : null;

        if(handledState.authenticationTicket 
            && handledState.authenticationTicket[".expires"] 
            && moment(handledState.authenticationTicket[".expires"]).isSameOrBefore(moment().utcOffset()))
        {
                handledState.authenticationTicket = null;
        }

        handledState.user = !!state.user ? this.getRestoredUser(state.user) : null;
        handledState.basket.goods = _.map(state.basket.goods, (session: StM.ISessionStoreState) => {
            return this.getRestoredSession(session);
        });
        handledState.basket.packages = !!state.basket.packages ? _.map(state.basket.packages, (packageDef: StM.IPackageDefinitionStoreState) => {
            return this.getRestoredPackageDefinition(packageDef);
        }) : [];

        handledState.validatedBasket = {...handledState.basket};
        handledState.app.utcOffset = moment().utcOffset();
        return handledState;
    }

    getRestoredAuthTicket(ticket: any): StM.IAuthenticationTicketStoreState {
        let handledAuthTicket = <StM.IAuthenticationTicketStoreState>{ ...ticket };
        handledAuthTicket.lastAction = moment(handledAuthTicket.lastAction);
        return handledAuthTicket;
    }

    getRestoredSession(session: any): StM.ISessionStoreState {
        let handledSession = <StM.ISessionStoreState>_.assign({}, session);
        handledSession.startDateTime = moment(session.startDateTime);
        handledSession.endDateTime = moment(session.endDateTime);
        handledSession.created = moment(session.created);

        handledSession.owner = !!session.owner ? this.getRestoredUser(session.owner) : null;
        handledSession.court = !!session.court ? this.getRestoredCourt(session.court) : null;
        handledSession.series = !!session.series ? this.getRestoredSeries(session.series) : null;
        handledSession.courts = _.map(session.courts, (court) => {
            return this.getRestoredCourt(court);
        });

        if(handledSession.club) {
            handledSession.club.clubTimes.forEach((c) => { c.startTime = moment.duration(c.startTime); c.endTime = moment.duration(c.endTime)});
        }

        return handledSession;
    }

    getRestoredPackageDefinition(packageDef: any): StM.IPackageDefinitionStoreState {
        let handledPackage = <StM.IPackageDefinitionStoreState>_.assign({}, packageDef);
        handledPackage.changeDate = moment(packageDef.changeDate);
        handledPackage.createDate = moment(packageDef.createDate);

        handledPackage.changedBy = !!packageDef.changedBy ? this.getRestoredUser(packageDef.changedBy) : null;
        handledPackage.createdBy = !!packageDef.createdBy ? this.getRestoredUser(packageDef.createdBy) : null;
        handledPackage.expirationTimeSpan = !!packageDef.expirationTimeSpan ? moment.duration(packageDef.expirationTimeSpan) : null;

        return handledPackage;
    }

    getRestoredUser(user: any): StM.IUserStoreState {
        let handledUser = <StM.IUserStoreState>_.assign({}, user);
        handledUser.dateOfBirth = moment(user.dateOfBirth);
        handledUser.lastAction = moment(user.lastAction);
        handledUser.registrationDate = moment(user.registrationDate);
        return handledUser;
    }

    getRestoredCourt(court: any): StM.ICourtStoreState {
        let handledCourt = <StM.ICourtStoreState>_.assign({}, court);
        handledCourt.createdDate = moment(court.createdDate);
        return handledCourt;
    }

    getRestoredSeries(series: any): StM.ISeriesStoreState {
        let handledSeries = <StM.ISeriesStoreState>_.assign({}, series);
        handledSeries.created = moment(series.created);
        handledSeries.startDate = moment(series.startDate);
        return handledSeries;
    }
}
