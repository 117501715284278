import BaseReducer from './baseReducer';
import * as StM from '../models/store';

export default class CoachFeeTiersReducer extends BaseReducer {
    static handle(state: Array<StM.ICoachFeeTierStoreState> = new Array<StM.ICoachFeeTierStoreState>(), action: any): Array<StM.ICoachFeeTierStoreState> {
        switch (action.type) {
            case 'CoachFeeTiers/Init': {
                var coachFeeTiers = <Array<StM.ICoachFeeTierStoreState>>action.payload;
                return [...coachFeeTiers];
            }
            default: {
                return state;
            }
        }
    }
}
