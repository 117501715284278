import BaseReducer from '../baseReducer';
import { AddonDefinitionDto } from '../../models/dto';

export default class AdminAddonsReducer extends BaseReducer {
    static handle(state: Array<IAddonDefinitionDto> = new Array<AddonDefinitionDto>(), action: any): Array<AddonDefinitionDto> {
        switch (action.type) {
            case 'Admin/Addons': {
                var addons = <Array<IAddonDefinitionDto>>action.payload;
                return [...addons];
            }
            default: {
                return state;
            }
        }
    }
}
