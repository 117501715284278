import { IBaseDialogStoreState, BaseDialogStoreState } from './baseDialogStoreState';

export interface INewClubDialogStoreState extends IBaseDialogStoreState {
    
}

export class NewClubDialogStoreState extends BaseDialogStoreState {
    constructor() {
        super();
    }
}
