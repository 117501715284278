import * as StM  from '../../../store';

export interface IHistoryPageStoreState {
    payments: Array<StM.IUserPaymentStoreState>;
}

export class HistoryPageStoreState implements IHistoryPageStoreState {
    payments: Array<StM.IUserPaymentStoreState>;
    constructor() {
        this.payments = new Array<StM.IUserPaymentStoreState>();
    }
}