import * as StM from '../../store';
import moment from 'moment';
import Constants from '../../../constants';

export interface IGroupEventStoreState {
    id: number;
    group: StM.IGroupStoreState;
    user: StM.IPublicUserStoreState;
    eventType: string;
    created:  moment.Moment;
    createdBy: StM.IPublicUserStoreState;
    session: StM.ISessionStoreState;
    booking: StM.IBookingStoreState;
    package: StM.IPackagesStoreState;
    error: boolean;
    details: string;
}

export class GroupEventStoreState implements IGroupEventStoreState  {
    id: number;
    group: StM.IGroupStoreState;
    user: StM.IPublicUserStoreState;
    eventType: string;
    created:  moment.Moment;
    createdBy: StM.IPublicUserStoreState;
    session: StM.ISessionStoreState;
    booking: StM.IBookingStoreState;
    package: StM.IPackagesStoreState;
    error: boolean;
    details: string;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.group = dto && dto.group ? dto.group : null;
        this.user = dto && dto.user ? dto.user : null;
        this.eventType = dto && dto.eventType ? dto.eventType : '';
        this.createdBy = dto && dto.createdBy ? dto.createdBy : null;
        this.created = dto && dto.created ? moment(dto.created, Constants.DateTime.API_FORMAT) : moment.min();
        this.error = dto && dto.error ? dto.error : false;
        this.session = dto && dto.session ? dto.session : null;
        this.booking = dto && dto.booking ? dto.booking : null;
        this.package = dto && dto.package ? dto.package : null;
        this.details = dto && dto.details ? dto.eventdetailsType : '';
    }
}