import * as Redux from 'redux';
import * as _ from 'lodash';

import {ThunkService} from '../services/thunkService';
import * as StoreModule from '../models/store';

import * as StM from '../models/store';

export class ClubActions {
    constructor() {
    }

    static getCurrent(): (dispatch: any, getState: any, thunkService: ThunkService) => Promise<void> {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            return thunkService.api.club.getCurrent()
                .then((response: any) => {
                    let club = <StoreModule.IClubStoreState>response;
                    dispatch(this.init(club));
                });
        };
    }

    static getForCurrentUser(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.club.getForCurrentUser()
                .then((clubs: Array<StM.IClubStoreState>) => {
                    dispatch({ type: 'Clubs/Init', payload: clubs});
                }).catch(() => {

                });
        };
    }

    static init(club: StoreModule.IClubStoreState): (dispatch: any, getState: any, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            document.getElementsByTagName('title')[0].textContent = "AB: " + club.title; // set title
            dispatch({
                type: 'Club/Init',
                payload: club
            });
        };
    }
}
