import { BaseApiService } from '../baseApiService';
import { StM } from '../../modules';

export class NotificationApiService extends BaseApiService {
    private static _instance: NotificationApiService;

    constructor(config?: any) {
        if (typeof NotificationApiService._instance == "undefined") {
            super(config);
            NotificationApiService._instance = this;
        }

        return NotificationApiService._instance;
    }

    public getForCurrentUser(club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<Array<StM.INotificationStoreState>> {
        return this.get('/')
            .then((response: any) => {
                const models = this.mapper.getNotificationModelsFromDtos(<Array<INotificationDto>>response.data, club, tiers);
                return models;
            })
            .catch((error: any) => {
                console.log(error);
                return Promise.resolve(new Array<StM.NotificationStoreState>());
            });
    }

    public getForCurrentUserBySession(sessionId: number, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.INotificationStoreState> {
        return this.get(`/${sessionId}`)
            .then((response: { data: INotificationDto}) => {
                const model = this.mapper.getNotificationModelFromDto(response.data, club, tiers);
                return model;
            });
    }

    public create(model: StM.INotificationStoreState, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.INotificationStoreState> {
        const dto = this.mapper.getNotificationDtoFromModel(model);
        return this.post('/', dto)
            .then( (response: { data: INotificationDto }) => {
                const model = this.mapper.getNotificationModelFromDto(response.data, club, tiers);
                return model;
            });
    }

    public dismiss(model: StM.INotificationStoreState, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.INotificationStoreState> {
        const dto = this.mapper.getNotificationDtoFromModel(model);
        return this.put('/', dto)
            .then( (response: { data: INotificationDto }) => {
                const model = this.mapper.getNotificationModelFromDto(response.data, club, tiers);
                return model;
            });
    }

    public ignore(model: StM.INotificationStoreState, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.INotificationStoreState> {
        const dto = this.mapper.getNotificationDtoFromModel(model);
        return this.put('/ignore', dto)
            .then( (response: { data: INotificationDto }) => {
                const model = this.mapper.getNotificationModelFromDto(response.data, club, tiers);
                return model;
            });
    }

    public markAsRead(model: StM.INotificationStoreState, club: StM.IClubStoreState, tiers: StM.IPricingTierStoreState[]): Promise<StM.INotificationStoreState> {
        const dto = this.mapper.getNotificationDtoFromModel(model);
        return this.put('/markAsRead', dto)
            .then( (response: { data: INotificationDto }) => {
                const model = this.mapper.getNotificationModelFromDto(response.data, club, tiers);
                return model;
            });
    }
}
