import moment from 'moment';
import Constants from '../../../constants';
import * as StM from '../../store';

export interface INotificationStoreState {
    id: number;
    message: string;
    subject: string;
    club: StM.IClubStoreState;
    clubId: number;
    dateCreated: moment.Moment;
    expirationDate: moment.Moment;
    createdBy: StM.IPublicUserStoreState;
    createdById: string;
    addressedTo: StM.IPublicUserStoreState;
    addressedToId: string;
    sendDate: moment.Moment;
    messageStatus: string;
    notificationType: string;
    targetId: number;
    targetType: string;
    event: string;
    target: any;
}

export class NotificationStoreState implements INotificationStoreState{
    id: number;
    message: string;
    subject: string;
    club: StM.IClubStoreState;
    clubId: number;
    dateCreated: moment.Moment;
    expirationDate: moment.Moment;
    createdBy: StM.IPublicUserStoreState;
    createdById: string;
    addressedTo: StM.IPublicUserStoreState;
    addressedToId: string;
    sendDate: moment.Moment;
    messageStatus: string;
    notificationType: string;
    targetId: number;
    targetType: string;
    event: string;
    target: any;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.message = dto && dto.message ? dto.message : '';
        this.subject = dto && dto.subject ? dto.subject : '';
        this.club = dto && dto.club ? dto.club : null;
        this.clubId = dto && dto.clubId ? dto.clubId : 0;
        this.dateCreated = dto && dto.dateCreated ?  moment(dto.dateCreated, Constants.DateTime.API_FORMAT) : moment.min();
        this.expirationDate = dto && dto.expirationDate ?  moment(dto.expirationDate, Constants.DateTime.API_FORMAT) : moment.min();
        this.createdBy = dto && dto.createdBy ? dto.createdBy : null;
        this.createdById = dto && dto.createdById ? dto.createdById : '';
        this.addressedTo = dto && dto.addressedTo ? dto.addressedTo : null;
        this.addressedToId = dto && dto.addressedToId ? dto.addressedToId : '';
        this.sendDate = dto && dto.sendDate ?  moment(dto.sendDate, Constants.DateTime.API_FORMAT) : moment.min();
        this.messageStatus = dto && dto.messageStatus ? dto.messageStatus : '';
        this.notificationType = dto && dto.notificationType ? dto.notificationType : '';
        this.targetId = dto && dto.targetId ? dto.targetId : '';
        this.targetType = dto && dto.targetType ? dto.targetType : '';
        this.event = dto && dto.event ? dto.event : '';
        this.target = dto && dto.target? dto.target : null;
    }
}
