import moment from 'moment';
import Constants from '../../../constants';
import { ISeriesStoreState } from './seriesStoreState';
import { IAddedUserStoreState, ICoachStoreState, IPublicUserStoreState } from '../users';
import { IClubStoreState, ICourtStoreState } from '../clubs';
import { IBookingStoreState } from './bookingStoreState';
import { ISeekingStoreState } from './seekingStoreState';
import { IPlayerQualificationStoreState } from './playerQualificationStoreState';
import { IYoutubeIdStoreState } from './youtubeIdStoreState';

export interface ISessionStoreState{
    id: number;
    type: string;
    owner: IPublicUserStoreState;
    ownerId: string;
    startDateTime: moment.Moment;
    endDateTime: moment.Moment;
    court: ICourtStoreState;
    courtId: number;
    courts: ICourtStoreState[];
    bookings: IBookingStoreState[];
    club: IClubStoreState;
    clubId: number;
    trainer: ICoachStoreState;
    trainerId: string;
    seekingId: number;
    seeking: ISeekingStoreState;
    status: string;
    minUserCount: number;
    maxUserCount: number;
    pricingTierId: number;
    pricingTierType: string;
    price: number;
    isPaidByOwner: boolean;
    isDoubledSession: boolean;
    cancellationReasonText: string;
    notes: string;
    playerQualification: IPlayerQualificationStoreState;
    addedUsers: IAddedUserStoreState[];
    removedUsers: IAddedUserStoreState[];
    invitedUsers: IPublicUserStoreState[];
    declinedUsers: IPublicUserStoreState[];
    isHidden: boolean;
    created: moment.Moment;
    createdById: string;
    basketId: number;
    validationResult: IValidationResultDto;
    title: string;
    credits: number;
    salesTax: number;
    customTextColor: string;
    customBackgroundColor: string;
    recurrencyDates: moment.Moment[];
    assistants: IPublicUserStoreState[];
    typeAlias: string;
    series: ISeriesStoreState;
    youtubeIds: IYoutubeIdStoreState[];
    isCourse: boolean;
    seriesOrder: number;
    recordVideo: boolean;
    videoLinkId: string;
    videoWasRecorded: boolean;
    splitPrice: boolean;
    maxActivePlayers: number;
    checkoutPrice: number;
    checkoutCredits: number;
    noServiceCheckoutPrice: number;
}

export class SessionStoreState implements ISessionStoreState {
    id: number;                                      
    type: string;
    owner: IPublicUserStoreState;
    ownerId: string;
    startDateTime: moment.Moment;
    endDateTime: moment.Moment;
    court: ICourtStoreState;
    courtId: number;
    courts: ICourtStoreState[];
    bookings: IBookingStoreState[];
    club: IClubStoreState;
    clubId: number;
    trainer: ICoachStoreState;
    trainerId: string;
    seekingId: number;
    seeking: ISeekingStoreState;
    status: string;
    minUserCount: number;
    maxUserCount: number;
    pricingTierId: number;
    pricingTierType: string;
    price: number;
    isPaidByOwner: boolean;
    isDoubledSession: boolean;
    cancellationReasonText: string;
    notes: string;
    playerQualification: IPlayerQualificationDto;
    invitedUsers: IPublicUserStoreState[];
    addedUsers: IAddedUserStoreState[];
    removedUsers: IAddedUserStoreState[];
    declinedUsers: IPublicUserStoreState[];
    isHidden: boolean;
    created: moment.Moment;
    createdById: string;
    basketId: number;
    validationResult: IValidationResultDto;
    title: string;
    credits: number;
    salesTax: number;
    customTextColor: string;
    customBackgroundColor: string;
    recurrencyDates: moment.Moment[];
    assistants: IPublicUserStoreState[];
    typeAlias: string;
    series: ISeriesStoreState;
    youtubeIds: IYoutubeIdStoreState[];
    isCourse: boolean;
    seriesOrder: number;
    recordVideo: boolean;
    videoLinkId: string;
    videoWasRecorded: boolean;
    splitPrice: boolean;
    maxActivePlayers: number;
    checkoutPrice: number;
    checkoutCredits: number;
    noServiceCheckoutPrice: number;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.type = dto && dto.type ? dto.type : '';
        this.owner = dto && dto.owner ? dto.owner : null;
        this.ownerId = dto && dto.ownerId ? dto.ownerId : null;
        this.startDateTime = dto && dto.startDateTime ?  moment(dto.startDateTime, Constants.DateTime.API_FORMAT) : moment.min();
        this.endDateTime = dto && dto.endDateTime ?  moment(dto.endDateTime, Constants.DateTime.API_FORMAT) : moment.min();
        this.court = dto && dto.court ? dto.court : null;
        this.courtId = dto && dto.courtId ? dto.courtId : null;
        this.courts = dto && dto.courts ? dto.courts : [];
        this.bookings = dto && dto.bookings ? dto.bookings : [];
        this.club = dto && dto.club ? dto.club : null;
        this.clubId = dto && dto.clubId ? dto.clubId : null;
        this.trainer = dto && dto.trainer ? dto.trainer : null;
        this.trainerId = dto && dto.trainerId ? dto.trainerId : null;
        this.seekingId = dto && dto.seekingId ? dto.seekingId : null;
        this.seeking = dto && dto.seeking ? dto.seeking : null;
        this.status = dto && dto.status ? dto.status : '';
        this.minUserCount = dto && dto.minUserCount ? dto.minUserCount : 0;
        this.maxUserCount = dto && dto.maxUserCount ? dto.maxUserCount : 0;
        this.pricingTierId = dto && dto.pricingTierId ? dto.pricingTierId : 0;
        this.pricingTierType = dto && dto.pricingTierType ? dto.pricingTierType : '';
        this.checkoutPrice = dto.checkoutPrice ? dto.checkoutPrice : 0;
        this.price = dto && dto.price ? dto.price : 0;
        this.isPaidByOwner = dto && dto.isPaidByOwner ? dto.isPaidByOwner : false;
        this.isDoubledSession = !!dto && dto.isDoubledSession;
        this.cancellationReasonText = dto && dto.cancellationReasonText ? dto.cancellationReasonText : '';
        this.notes = dto && dto.notes ? dto.notes : '';
        this.playerQualification = dto && dto.playerQualification ? dto.playerQualification : null;
        this.invitedUsers = dto && dto.invitedUsers ? dto.invitedUsers : [];
        this.addedUsers = dto && dto.addedUsers ? dto.addedUsers : [];
        this.removedUsers = dto && dto.removedUsers ? dto.removedUsers : [];
        this.declinedUsers = dto && dto.declinedUsers ? dto.declinedUsers : [];
        this.isHidden = dto && dto.isHidden ? dto.isHidden : false;
        this.created = dto && dto.created ? moment(dto.created, Constants.DateTime.API_FORMAT) : moment.min();
        this.createdById = dto && dto.createdById ? dto.createdById : '';
        this.basketId = dto && dto.basketId ? dto.basketId : null;
        this.validationResult = dto && dto.validationResult ? dto.validationResult : null;
        this.title = dto && dto.title ? dto.title : '';
        this.checkoutCredits = dto.checkoutCredits ? dto.checkoutCredits : 0;
        this.credits = dto && dto.credits ? dto.credits : 0;
        this.salesTax = dto && dto.salesTax ? dto.salesTax : 0;
        this.customTextColor = dto && dto.customTextColor ? dto.customTextColor : '';
        this.customBackgroundColor = dto && dto.customBackgroundColor ? dto.customBackgroundColor : '';
        this.recurrencyDates = dto && dto.recurrencyDates ? dto.recurrencyDates : [];
        this.assistants = dto && dto.assistants ? dto.assistants : [];
        this.typeAlias = dto && dto.typeAlias ? dto.typeAlias : '';
        this.series = dto && dto.series ? dto.series : null;
        this.youtubeIds = dto && dto.youtubeId ? dto.youtubeId : [];
        this.isCourse = dto && dto.isCourse ? dto.isCourse : false;
        this.seriesOrder = dto && dto.seriesOrder ? dto.seriesOrder : 0;
        this.recordVideo = dto && dto.recordVideo ? dto.recordVideo : false;
        this.videoLinkId = dto && dto.videoLinkId ? dto.videoLinkId : 0;
        this.videoWasRecorded = dto && dto.videoWasRecorded ? dto.videoWasRecorded : false;
        this.splitPrice = dto && dto.splitPrice ? dto.splitPrice : false;
        this.maxActivePlayers = dto && dto.maxActivePlayers ? dto.maxActivePlayers : 0;
        this.pricingTierId = dto && dto.pricingTierId ? dto.pricingTierId : 0;
        this.pricingTierType = dto && dto.pricingTierType ? dto.pricingTierType : '';
        this.noServiceCheckoutPrice = dto && dto.noServiceCheckoutPrice ? dto.noServiceCheckoutPrice : 0;
    }
}
