import BaseReducer from '../baseReducer';

export default class AdminSubscriptionsReducer extends BaseReducer {
    static handle(state: IRecurrentPackageOfferListDto[] = [], action: any): IRecurrentPackageOfferListDto[] {
        switch (action.type) {
            case 'Admin/Subscriptions': {
                return [...action.payload as IRecurrentPackageOfferListDto[]];
            }
            default: {
                return state;
            }
        }
    }
}
