import { ThunkService } from '../../services/thunkService';
import { StM } from '../../modules';
import { AppActions } from '../appActions';

export class SubscriptionsActions {
    static getAll(onlyArchived?: boolean): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<IRecurrentPackageOfferListDto>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(AppActions.showSpinner())
            return thunkService.api.admin.getSubscriptions(onlyArchived)
                .then((subscriptions) => {
                    dispatch({
                        type: 'Admin/Subscriptions',
                        payload: subscriptions
                    });
                    dispatch(AppActions.hideSpinner());
                    return subscriptions;
                }).catch((error) => {
                    dispatch(AppActions.hideSpinner());
                    throw error;
                });
        };
    }

    static getHistory(subscriptionId: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<IRecurrentPackageOfferVersionDto>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.getSubscriptionHistoryById(subscriptionId);
        };
    }

    static getSubscriptionById(subscriptionId: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<IRecurrentPackageOfferViewDto> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.getSubscriptionById(subscriptionId);
        };
    }

    static create(subscriptionModel: IRecurrentPackageOfferEditDto): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<IRecurrentPackageOfferEditDto> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.createSubscription(subscriptionModel);
        };
    }

    static update(subscriptionModel: IRecurrentPackageOfferEditDto, subscriptionId: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<IRecurrentPackageOfferEditDto> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.updateSubscription(subscriptionModel, subscriptionId);
        };
    }

    static publish(subscriptionId: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.publishSubscription(subscriptionId);
        };
    }

    static unpublish(subscriptionId: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.unPublishSubscription(subscriptionId);
        };
    }

    static cancel(subscriptionId: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.cancelSubscription(subscriptionId);
        };
    }

    static archive(subscriptionId: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.archiveSubscription(subscriptionId);
        };
    }
    static sendNotification(subscriptionId: number, title: string, text: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<ISubscribersNotificationDto> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.sendSubscriptionNotification(subscriptionId, title, text);
        };
    }

    static getActiveUsers(subscriptionId: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<IRecurrentPackageSubscriberDto>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.getSubscriptionActiveUsers(subscriptionId);
        };
    }

    static getNotificationTemplates(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<INotificationTemplateDto>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.getSubscriptionNotificationTemplates();
        };
    }

    static assign(subscriptionId: number, offerVersion: number, userId: string, note: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.assignSubscription(subscriptionId, offerVersion, userId, note);
        };
    }

    static updateSubscriptionOrder(subscriptionId: number, order: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(AppActions.showSpinner());
            return thunkService.api.admin.updateSubscriptionOrder(subscriptionId, order)
                .then(() => {
                    dispatch(this.getAll());
                    dispatch(AppActions.hideSpinner());
                }).catch((error) => {
                    dispatch(AppActions.hideSpinner());
                    throw error;
                });
        };
    }
}