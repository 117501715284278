import * as StM from '../models/store';
import BaseReducer from './baseReducer';
import * as _ from 'lodash';

export default class BadgeTooltipReducer {
    static handle(state: StM.IBadgeTooltipStoreState = new StM.BadgeTooltipStoreState(), action: any): StM.IBadgeTooltipStoreState {
        switch (action.type) {
            case 'BadgeTooltip/Show': {
                return <StM.IBadgeTooltipStoreState>_.assign({}, state, { 
                    isShown: true, 
                    sessions: action.sessions,  
                    targetRect: action.targetRect,
                    key: action.key
                });
            }
            case 'BadgeTooltip/Hide': {
                return <StM.IBadgeTooltipStoreState>_.assign({}, state, { 
                    isShown: false,
                    sessions: new Array<any>(),
                    targetRect: null
                 });
            }
            default: { 
                return state;
            }
        }
    }
}