import moment from 'moment';
import * as _ from 'lodash';

import { BaseApiService } from '../baseApiService';
import * as StM from '../../models/store';
import Constants from '../../constants';
import * as SerM from '../../services';

export class UserApiService extends BaseApiService {
    private static _instance: UserApiService;
    private cache: SerM.RequestCacheService;

    constructor(config?: any) {
        if (typeof UserApiService._instance == "undefined") {
            super(config);
            UserApiService._instance = this;
            this.cache = new SerM.RequestCacheService();
        }
        return UserApiService._instance;
    }

    getCurrent(): Promise<StM.IUserStoreState> {
        return this.get('/current')
            .then((response: any) => {
                const model = this.mapper.getUserModelFromDto(<IUserDto>response.data);
                return model;
            });
    }

    getAvailableTimes(start: moment.Moment, end: moment.Moment, club: StM.IClubStoreState, force: boolean = false): Promise<Array<StM.IAvailableTimeStoreState>> {

        if (!force) {
            let cachedRequest = this.cache.get("AVAILABLE_TIMES", start);

            if (cachedRequest)
                return new Promise((resolve) => {
                    let requiredCachedItems = _.filter(cachedRequest as Array<StM.IAvailableTimeStoreState>,
                        (item) => item.begin.isSameOrAfter(start) && item.begin.clone().add(item.duration).isSameOrBefore(end));
                    resolve(requiredCachedItems);
                });
        }

        let startDateCache = start.clone().add(-1, "week");
        let endDateCache = end.clone().add(1, "week");

        let startFormat = startDateCache.format(Constants.DateTime.API_FORMAT);
        let endFormat = endDateCache.format(Constants.DateTime.API_FORMAT);

        return this.get('/availableTimes/{0}/{1}'.format(startFormat, endFormat))
            .then((response: any) => {
                const models = this.mapper.getAvailableTimeModelsFromDto(<Array<IAvailableTimeDto>>response.data, club);

                this.cache.set("AVAILABLE_TIMES", models, startDateCache, endDateCache);

                let requiredCachedItems = _.filter(models,
                    (item) => item.begin.isSameOrAfter(start) && item.begin.clone().add(item.duration).isSameOrBefore(end));
                return requiredCachedItems;
            });
    }

    updateProfile(model: StM.IUserManagableStoreState): Promise<StM.IUserManagableStoreState> {
        const handledDto = this.mapper.getUserManagableDtoFromModel(model);

        return this.put('/profile', handledDto)
            .then((response: any) => {
                let dto = <IUserManagableDto>response.data;
                let model = this.mapper.getUserManagableModelFromDto(dto);
                return model;
            })
            ;
    }

    savePaymentProfile(profile: StM.IPaymentProfileInformationStoreState): Promise<string> {
        var dto = this.mapper.getPaymentProfileInformationDtoFromModel(profile);
        return this.put('/billing', dto)
            .then((response: any) => {
                return response.data;
            });
    }

    completePaymentMethod(success: boolean = true): Promise<void> {
        return this.post(`/paymentProfile/${success ? 'commit' : 'rollback'}`)
            .catch((error) => {
                throw error;
            });
    }

    saveDemographicInformation(model: StM.IUserManagableStoreState): Promise<StM.IUserManagableStoreState> {
        const handledDto = this.mapper.getUserManagableDtoFromModel(model);
        return this.put('demographic-information/', handledDto)
            .then((response: any) => {
                let dto = <IUserManagableDto>response.data;
                let model = this.mapper.getUserManagableModelFromDto(dto);
                return model;
            });
    }

    getUserPayments(start: moment.Moment, end: moment.Moment, club: StM.IClubStoreState): Promise<Array<StM.IUserPaymentStoreState>> {
        const startFormat = start.format(Constants.DateTime.API_FORMAT);
        const endFormat = end.format(Constants.DateTime.API_FORMAT);
        return this.get('paymentHistory/{0}/{1}'.format(startFormat, endFormat))
            .then((response: any) => {
                const dtos = <Array<IUserPaymentDto>>response.data;
                const models = this.mapper.getUserPaymentModelsFromDtos(dtos, club);
                return models;
            });
    }

    uploadProfileImage(file: any) {
        return this.post('uploadImage', file, { headers: { 'Content-Type': file.type } })
            .then((response: any) => {
                return null;
            });
    }

    getActives(skipCache: boolean = false): Promise<Array<StM.IPublicUserStoreState>> {
        if(!skipCache) {
            let cachedRequest = this.cache.getItem("ACTIVE_USERS");

            if (cachedRequest) {
                return new Promise((resolve) => {
                    resolve(cachedRequest as Array<StM.IPublicUserStoreState>);
                });
            }
        }

        return this.get('/actives')
            .then((response: { data: Array<IPublicUserDto> }) => {
                const models = this.mapper.getPublicUserModelsFromDtos(response.data);
                if(!skipCache){
                    this.cache.setItem("ACTIVE_USERS", models);
                }
                return models;
            });
    }

    getGroups(): Promise<Array<IGroupDto>> {
        return this.get('/groups')
            .then((response: { data: Array<IGroupDto> }) => {
                return response.data;
            });
    }

    acceptWaiver(): Promise<any> {
        return this.put('waiver-acceptation')
            .then((response: any) => {
                return null;
            });
    }
}
