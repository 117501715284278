import * as _ from 'lodash';

import { StM } from '../modules';
import { IBasePolicy, BasePolicy } from './basePolicy';
import { GroupInfoPolicy } from './groupInfoPolicy';
import { SessionAvailabilityPolicy } from './sessions/sessionAvailabilityPolicy';

export class PricesPolicy extends BasePolicy implements IBasePolicy {
    private readonly groupInfo = new GroupInfoPolicy();
    private session: StM.ISessionStoreState;
    private isAdmin: boolean;
 
    private readonly matches = {
        [StM.SessionType.Play]: {
            [StM.PackageSessionTypeGroup.PlayAdditional]: (session: StM.ISessionStoreState, user: StM.IUserStoreState, balances: ICreditsWalletBalanceDto[]) => this.playCreditsMatcher(session, user, balances),
            [StM.PackageSessionTypeGroup.PlayNonPeakTime]: (session: StM.ISessionStoreState, user: StM.IUserStoreState, balances: ICreditsWalletBalanceDto[]) => this.playCreditsMatcher(session, user, balances),
            [StM.PackageSessionTypeGroup.PlayAllTime]: (session: StM.ISessionStoreState, user: StM.IUserStoreState, balances: ICreditsWalletBalanceDto[]) => this.playCreditsMatcher(session, user, balances)
        },
        [StM.SessionType.Private]: {
            [StM.PackageSessionTypeGroup.PrivateAdditional]: (session: StM.ISessionStoreState, user: StM.IUserStoreState, balances: ICreditsWalletBalanceDto[]) => this.privateCreditsMatcher(session, user, balances),
            [StM.PackageSessionTypeGroup.PrivateNonPeakTime]: (session: StM.ISessionStoreState, user: StM.IUserStoreState, balances: ICreditsWalletBalanceDto[]) => this.privateCreditsMatcher(session, user, balances),
            [StM.PackageSessionTypeGroup.PrivateAllTime]: (session: StM.ISessionStoreState, user: StM.IUserStoreState, balances: ICreditsWalletBalanceDto[]) => this.privateCreditsMatcher(session, user, balances)
        },
        [StM.SessionType.Clinic]: {
            [StM.PackageSessionTypeGroup.ClinicAdditional]: (session: StM.ISessionStoreState, user: StM.IUserStoreState, balances: ICreditsWalletBalanceDto[]) => this.clinicCreditsMatcher(session, user, balances),
            [StM.PackageSessionTypeGroup.ClinicNonPeakTime]: (session: StM.ISessionStoreState, user: StM.IUserStoreState, balances: ICreditsWalletBalanceDto[]) => this.clinicCreditsMatcher(session, user, balances),
            [StM.PackageSessionTypeGroup.ClinicAllTime]: (session: StM.ISessionStoreState, user: StM.IUserStoreState, balances: ICreditsWalletBalanceDto[]) => this.clinicCreditsMatcher(session, user, balances)
        },
        [StM.SessionType.Custom]: {
            [StM.PackageSessionTypeGroup.Custom]: (session: StM.ISessionStoreState, user: StM.IUserStoreState, balances: ICreditsWalletBalanceDto[]) => this.customCreditsMatcher(session, user, balances),
        }
    };

    private readonly packageGroupTypes = {
        [StM.SessionType.Play]: {
            [StM.PricingTierType.Additional]: StM.PackageSessionTypeGroup.PlayAdditional,
            [StM.PricingTierType.OffPeak]: StM.PackageSessionTypeGroup.PlayNonPeakTime,
            [StM.PricingTierType.Peak]: StM.PackageSessionTypeGroup.PlayAllTime,
        },
        [StM.SessionType.Private]: {
            [StM.PricingTierType.Additional]: StM.PackageSessionTypeGroup.PrivateAdditional,
            [StM.PricingTierType.OffPeak]: StM.PackageSessionTypeGroup.PrivateNonPeakTime,
            [StM.PricingTierType.Peak]: StM.PackageSessionTypeGroup.PrivateAllTime,
        },
        [StM.SessionType.Clinic]: {
            [StM.PricingTierType.Additional]: StM.PackageSessionTypeGroup.ClinicAdditional,
            [StM.PricingTierType.OffPeak]: StM.PackageSessionTypeGroup.ClinicNonPeakTime,
            [StM.PricingTierType.Peak]: StM.PackageSessionTypeGroup.ClinicAllTime,
        }
    };

    constructor(
        private isDouble?: boolean,
        session?: StM.ISessionStoreState,
        private pricingTier?: StM.IPricingTierStoreState,
    ) {
        super();
        const documentWindow : any = window;
        this.isAdmin = documentWindow.ISADMIN;
        this.handleSession(session);
    }

    public handle(): StM.ISessionPrices {
        return this.getSessionPrices();
    }

    private getIsUserInBookings(session: StM.ISessionStoreState, user: StM.IPublicUserStoreState): boolean {
        return !!session && !!user && session.bookings && session.bookings
            .some(b => (b.userId === user.id || (!!b.user && b.user.id === user.id)) && this.utils.isActiveBooking(session, b));
    }

    private handleSession(session: StM.ISessionStoreState) {
        const user = this.getCurrentUser();
        const handledSession = !!session ? _.cloneDeep(session) : this.getTempSession();
        if(!handledSession) return;
        if(!handledSession.bookings) handledSession.bookings = [];
        if(!this.isAdmin && !!user && !this.getIsUserInBookings(handledSession, user) && !user.group) {
            const booking = new StM.BookingStoreState({
                user,
                userId: user.id,
                status: StM.BookingStatus.New,
            });
            handledSession.bookings.push(booking);
        }

        if(this.isAdmin && handledSession.addedUsers.length) {
            const addedUserBookings = handledSession.addedUsers
                .filter(item => !this.getIsUserInBookings(handledSession, item))
                .map((item) => new StM.BookingStoreState({
                    user: item,
                    userId: item.id,
                    status: StM.BookingStatus.New
                }));
            handledSession.bookings.push(...addedUserBookings)
        }

        if(handledSession.bookings.length) handledSession.bookings.forEach(b => b.session = handledSession);
        this.session = handledSession;
    }

    private getTempSession(): StM.ISessionStoreState {
        const filter = this.getBookPageFilter();
        if(!filter) return null;
        const result = new StM.SessionStoreState({
            type: this.utils.getSessionTypeByFilterType(filter.sessionType),
            club: this.getCurrentClub(),
            isDoubledSession: this.isDouble,
            trainerId: filter.lessonSubfilterId
        });

        return result;
    }

    private getTierByCoachId(coachId: string): StM.ICoachFeeTierStoreState {
        const coaches = this.getCoaches();
        const coach = coaches.find(c => c.id === coachId);
        return coach ? coach.coachFeeTier : null;
    }

    private getTierPriceByCoachId(coachId: string, pricingTierId: number): StM.ICoachFeeTierPriceStoreState {
        const coaches = this.getCoaches();
        const coach = coaches.find(c => c.id === coachId);
        const coachFeeTierPrices = this.getCoachFeeTierPrices();
        return !!coach && coachFeeTierPrices.find(c => (pricingTierId === c.pricingTierId) && c.coachFeeTierId === coach.coachFeeTierId);
    }

    public getSessionPrices(user?: StM.IUserStoreState): StM.ISessionPrices {
        const results = new StM.SessionPrices();
        if(!this.session) return results;
        if(!user) user = this.getCurrentUser();

        results.price = this.handleValue(this.getPriceInCash(user));
        results.credits = this.handleValue(this.getPriceInCredits(user));
        results.additionalPrice = this.handleValue(this.getAdditionalPriceInCash(user, results.price));
        results.additionalCredits = this.handleValue(this.getPriceInCredits(user, true));
        results.availableCredits = this.handleValue(this.getPriceInCredits(user, true, true));
        results.servicesPrice = this.handleValue(this.getSessionServicesPrice(user));
        
        if (this.session.removedUsers.length) {
            if(!results.price && results.additionalPrice) {
                results.price = results.additionalPrice;
                results.additionalPrice = 0;
            }

            if(!results.credits && results.additionalCredits) {
                results.credits = results.additionalCredits;
                results.additionalCredits = 0;
            }
        }

        return results;
    }

    private getPriceInCash(user: StM.IUserStoreState): number {
        
        let result = 0;
        
        const session = this.session;
        
        if(!session) {
            return result;
        }

        const sessionAvailability = new SessionAvailabilityPolicy(session, this.getBasketSessions(), user);        
        const isOwner = sessionAvailability.getIsOwner();
        const isGroup = this.groupInfo.getIsAuthenticated();
        const isPaidByOwner = session.isPaidByOwner;
        const isGroupMemberSession = sessionAvailability.getIsGroupMemberSession(true, true);
        const isGroupOwnedSession = sessionAvailability.getIsCreatedByGroupSession();
        const isOpenShared = !session.isHidden && !isPaidByOwner;

        if (isPaidByOwner && !isOwner && (!isGroup || !isGroupOwnedSession) && (!!session.id || this.isAdmin)) {
            return result;
        }

        result = this.calculateInCash(session, user);

        if (isGroup && !isGroupMemberSession && session.id && session.addedUsers && session.addedUsers.length){
            result *= session.addedUsers.length;
        } else if (isGroup && isGroupMemberSession && session.id) {
            const isLesson = session.type === StM.SessionType.Private;
            const membersCount = sessionAvailability.getSessionGroupMemberBookings(false, true).length || 1;
            const membersCountPrice = result * membersCount;
            let groupPrice = membersCountPrice;
            if (session.splitPrice) { 
                return session.removedUsers.length 
                    ? Math.min(membersCountPrice, session.noServiceCheckoutPrice)
                    : session.noServiceCheckoutPrice;
            }
            if (!isLesson || isGroupOwnedSession) {
                const removedUsersPrice = !isGroupOwnedSession ? session.removedUsers.length * result : 0;
                groupPrice = session.noServiceCheckoutPrice - this.handleDoubleSessionPrice(removedUsersPrice, user);
            }
            if (groupPrice > 0 || session.addedUsers.length) return groupPrice;
        }

        result = this.handleDoubleSessionPrice(result, user);

        return result;
    }

    private getPriceInCredits(user: StM.IUserStoreState, asAdditional: boolean = false, asAvailable: boolean = false): number {
        let result = 0;
        const session = this.session;
        const wallet = user.creditsWallet;
        const hasWallet = !!session && !!user && !!wallet;
        const booking = session.bookings.find(b => b.userId === user.id && this.utils.isActiveBooking(session, b));

        if(session.splitPrice) return result;

        if(!!booking  && (!hasWallet || booking.paymentType === StM.PaymentTypes.Charge)) return result;
       
        const sessionAvailability = new SessionAvailabilityPolicy(session, this.getBasketSessions(), user);
        const isGroupMemberSession = sessionAvailability.getIsGroupMemberSession();
        const isGroupOwnedSession = sessionAvailability.getIsCreatedByGroupSession();
        const isGroupSession = sessionAvailability.getIsGroupSession();
        const isGroup = this.groupInfo.getIsAuthenticated();
        if (asAvailable && !isGroupSession) asAdditional = false;
        const isDoubleNotCustom = hasWallet && session.type !== StM.SessionType.Custom && session.isDoubledSession;
        let canUserUseCredits = !this.session.splitPrice;
        if(isGroup) {
            const groupBookings = sessionAvailability.getSessionGroupMemberBookings();
            const isCreditBookings = !groupBookings.length 
                || groupBookings.filter(b => this.utils.isActiveBooking(session, b)).some(b => b.paymentType === StM.PaymentTypes.Credits);
            canUserUseCredits = !session.id || !isGroupMemberSession || isCreditBookings;
        }
        const isSessionAvailableForCredits = !session.isPaidByOwner || session.ownerId === user.id || isGroupMemberSession || (!this.isAdmin && !session.id);
        const canUseCredits = hasWallet && isSessionAvailableForCredits && canUserUseCredits;
        if(!canUseCredits || isDoubleNotCustom) return result;

        const balances = this.getMatchingBalances(session, wallet, user);
        const basePrice = this.calculateInCredits(session, user);
        const removedUserCredits = session.removedUsers.length * basePrice;
        const availableCredits = this.getAvailableCredits(balances) + removedUserCredits;
        const basketCredits = this.getBasketSessions()
            .reduce((result, bs) => result += (bs.type === session.type && bs.id !== session.id && bs.credits) ? bs.credits : 0, 0);

        if (!availableCredits && ((isGroup && !isGroupSession) || asAdditional || this.isAdmin)) {
            return this.handleCredits(result, availableCredits, basketCredits, asAvailable);
        } else if (!availableCredits) return session.checkoutCredits - removedUserCredits;

        const isOwnerGroupMember = sessionAvailability.getIsOwnerGroupMember();

        if(!!session.id && (isGroupSession || isGroupMemberSession) && !asAdditional) {
            const groupPrice = session.checkoutCredits - (!isGroupOwnedSession ? removedUserCredits : 0);
            return groupPrice <= 0 && !session.addedUsers.length ? basePrice : groupPrice;
        }
      
        result = asAdditional 
            ? this.getPriceForAddedPlayersInCredits(basePrice, session) 
            : basePrice;

        if(!asAdditional && !!session.id && isGroup && !isGroupSession && !isOwnerGroupMember && session.addedUsers.length) {
            result *= session.addedUsers.length;
        }

        return this.handleCredits(result, availableCredits, basketCredits, asAvailable);
    }

    private handleCredits(credits: number, availableCredits: number, basketCredits: number, asAvailable: boolean = false) {
        const restCredits = availableCredits - basketCredits - credits;
        return restCredits >= 0 ? (asAvailable ? restCredits : credits) : 0;
    }

    private getAdditionalPriceInCash(user: StM.IUserStoreState, mainPrice: number): number {
        let result = 0;
        const session = this.session;
        if(!session || !user) return result;

        const basePrice = this.calculateInCash(session, user, true);
        const isGroup = this.groupInfo.getIsAuthenticated();
        const sessionAvailability = new SessionAvailabilityPolicy(session, this.getBasketSessions(), user);

        if (session.splitPrice && isGroup) {
            const membersCount = sessionAvailability.getSessionGroupMemberBookings(false, true).length + session.addedUsers.length;
            result = basePrice * membersCount - mainPrice;
        } else {
            result += this.getPriceForAddedPlayersInCash(basePrice, session);
        }

        result = this.handleDoubleSessionPrice(result, user);

        return result;
    }

    private getPriceForAddedPlayersInCash(basePrice: number, session: StM.ISessionStoreState) {
        let result = 0;
        const sessionAvailability = new SessionAvailabilityPolicy(session, this.getBasketSessions(), this.getCurrentUser());
        const isOwnerGroupMember = sessionAvailability.getIsOwnerGroupMember();
        const isGroupMemberSession = sessionAvailability.getIsGroupMemberSession();
        const isGroupOnlySplitSession = isGroupMemberSession && session.splitPrice 
            && !session.bookings.filter(b => b.groupId != this.getCurrentUser().group.id && b.status != StM.BookingStatus.LateCancel).length

        if(isGroupMemberSession && !isOwnerGroupMember && session.addedUsers.length && !isGroupOnlySplitSession && !session.credits) {
            result = basePrice * session.addedUsers.length;
        }
        return result;
    }

    private getPriceForAddedPlayersInCredits(basePrice: number, session: StM.ISessionStoreState) {
        let result = 0;
        const sessionAvailability = new SessionAvailabilityPolicy(session, this.getBasketSessions(), this.getCurrentUser());
        const isOwnerGroupMember = sessionAvailability.getIsOwnerGroupMember();
        const isGroupMemberSession = sessionAvailability.getIsGroupMemberSession();

        if(isGroupMemberSession && !isOwnerGroupMember && session.addedUsers.length) {
            var addedGroupBookingsCount = session.addedUsers.filter(b => b.paymentType === StM.PaymentTypes.Credits).length;
            result = basePrice * addedGroupBookingsCount;
        }
        return result;
    }

    private getSessionServicesPrice(user: StM.IUserStoreState) {
        let result = 0;
        const session = this.session;
        if (!session || !user) return result;

        const sessionAvailability = new SessionAvailabilityPolicy(session, this.getBasketSessions(), user);
        const isGroupSession = sessionAvailability.getIsGroupSession();
        const isCourse = session.isCourse || (session.series !== null && session.series.isCourse);
        const isOwner = session.ownerId === user.id;
        const booking = session.bookings.find(b => (b.user && b.user.id === user.id) || b.userId === user.id || (isGroupSession && b.userId === session.ownerId && this.groupInfo.getIsMember(b.userId)));
        const services = booking ? booking.addons : [];
        const isCustom = session.type === StM.SessionType.Custom;
        const isPaidByOwner = session.isPaidByOwner;

        if(!isCourse && (isOwner || isGroupSession) && (!isCustom || isPaidByOwner) && services.length) result += services.reduce((sum, s) => sum += s.price, 0);
        return result;
    }

    private getMatchingBalances(session: StM.ISessionStoreState, wallet: StM.ICreditsWalletStoreState, user: StM.IUserStoreState): ICreditsWalletBalanceDto[] {
        let results: ICreditsWalletBalanceDto[] = [];
        if (!session || !this.matches[session.type]) return results;

        const matchedSessionTypeSet = this.matches[session.type];
        const matchedBalances = wallet.creditsWalletBalances.filter(b => b.packageSessionType && matchedSessionTypeSet[b.packageSessionType.group]);
        if (matchedBalances === null || !matchedBalances.length) return results;

        for (var set in matchedSessionTypeSet) {
            var matches = matchedSessionTypeSet[set](session, user, matchedBalances);
            if (matches !== null && matches.length) results = _.unionBy(results, matches, 'id');
        }

        return results;
    }

    private getPlayersCount(session: StM.ISessionStoreState, user: StM.IUserStoreState): number {
        if(!session || !session.bookings) return 0;
        const isNewAdminSession = this.isAdmin && !session.id;
        if(isNewAdminSession && session.type === StM.SessionType.Private) return session.maxUserCount - 1;
        return session.bookings.filter(b => b.userId !== user.id && this.utils.isActiveBooking(session, b, true) 
                && !session.removedUsers.some(u => u.id === b.userId)).length 
            + session.addedUsers.filter(b => b.id !== user.id).length;
    }

    private getAvailableCredits(balances: ICreditsWalletBalanceDto[]): number {
        return balances.reduce((sum, balance) => sum + balance.credits, 0);
    }

    private filterBalances(balances: ICreditsWalletBalanceDto[], session: StM.ISessionStoreState) {
        const pricingTierType = this.pricingTier ? this.pricingTier.type : session.pricingTierType;
        const packageSessionType = this.packageGroupTypes[session.type];
        if (!packageSessionType) return [];
        return balances.filter((b) => {
            if (!b.packageSessionType || b.credits <= 0) return false;
            let isValidGroup = false;
            switch (pricingTierType) {
                case StM.PricingTierType.Additional:
                    isValidGroup = b.packageSessionType.group === packageSessionType[StM.PricingTierType.Additional];
                case StM.PricingTierType.OffPeak:
                    isValidGroup = isValidGroup || b.packageSessionType.group === packageSessionType[StM.PricingTierType.OffPeak];
                default:
                    isValidGroup = isValidGroup || b.packageSessionType.group === packageSessionType[StM.PricingTierType.Peak];
                    break;
            }
            return isValidGroup;
        });
    }

    private playCreditsMatcher(session: StM.ISessionStoreState, user: StM.IUserStoreState, balances: ICreditsWalletBalanceDto[]): ICreditsWalletBalanceDto[] {
        const availableBalances = this.filterBalances(balances, session);
        const credits = this.getIsMaxCreditsAvailable(session, user) ? 2 : 1;
        const isEnoughCredits = this.getAvailableCredits(availableBalances) >= credits;
        return isEnoughCredits ? balances : [];
    }

    private privateCreditsMatcher(session: StM.ISessionStoreState, user: StM.IUserStoreState, balances: ICreditsWalletBalanceDto[]): ICreditsWalletBalanceDto[] {
        if (!this.getIsMaxCreditsAvailable(session, user)) return [];
        const tier = this.getTierByCoachId(session.trainerId);
        const tierId = tier ? tier.id : null;
        return !!tierId 
            ? this.filterBalances(balances, session)
                .filter(b => !!b.package && (!b.package.coachFeeTierId || !!b.package.coachFeeTierId && b.package.coachFeeTierId === tierId))
            : [];
    }

    private clinicCreditsMatcher(session: StM.ISessionStoreState, user: StM.IUserStoreState, balances: ICreditsWalletBalanceDto[]): ICreditsWalletBalanceDto[] {
        return this.filterBalances(balances, session);
    }

    private customCreditsMatcher(session: StM.ISessionStoreState, user: StM.IUserStoreState, balances: ICreditsWalletBalanceDto[]): ICreditsWalletBalanceDto[] {
        return balances.filter(b => b.credits > 0 && (!!b.packageSessionTypeCredits.typeAlias 
            ? b.packageSessionTypeCredits.typeAlias === session.typeAlias 
            : !session.typeAlias)
        );
    }

    private calculateInCredits(session: StM.ISessionStoreState, user: StM.IUserStoreState): number {
        let result = 0;
        const playersCount = this.getPlayersCount(session, user);
        const isMaxCreditsAvailable = this.getIsMaxCreditsAvailable(session, user);
        switch(session.type) {
            case StM.SessionType.Play:
                const isFullCredits = isMaxCreditsAvailable || session.isPaidByOwner;
                if (playersCount <= 2) result = isFullCredits ? 2 : 1;
                break;
            case StM.SessionType.Private:
                result = isMaxCreditsAvailable ? 1 : 0;
                break;
            case StM.SessionType.Clinic:
                result = 1;
                break;
            case StM.SessionType.Custom:
                result = 1;
                break;
        }
        return result;
    }

    private calculateInCash(session: StM.ISessionStoreState, user: StM.IUserStoreState, asAdditional: boolean = false): number {
        let result = 0;
        let tier: StM.ICoachFeeTierPriceStoreState;

        if(session.splitPrice) {
            return this.getPriceForSplitPriceSession(session, user, asAdditional);
        }

        const sessionAvailability = new SessionAvailabilityPolicy(session, this.getBasketSessions(), this.getCurrentUser());
        const pricingTierId = this.pricingTier ? this.pricingTier.id : session.pricingTierId;
        const isPaidByOwner = session.isPaidByOwner;
        const isGroupSession = sessionAvailability.getIsGroupSession();
        const isOwnerGroupMember = sessionAvailability.getIsOwnerGroupMember();
        const isCustomOrClinic = session.type === StM.SessionType.Custom || session.type === StM.SessionType.Clinic;
        const isPurchasedByCurrentUser = session.bookings.some(b => !!b.id && b.userId === user.id && this.utils.isActiveBooking(session, b, true));
        const bookingsCount = session.bookings.filter(b => {
            const isNotOwner = (!isOwnerGroupMember || (b.userId !== session.ownerId));
            const isActive = this.utils.isActiveBooking(session, b, true, true)
            return isNotOwner && isActive;
        }).length;
        const hasGroupBookings = !!sessionAvailability.getSessionGroupMemberBookings(false, true).length;
        const addedUsersCount = session.addedUsers.length || ((isPurchasedByCurrentUser || (isGroupSession && hasGroupBookings)) ? 0 : 1);
        const removedUsersCount = session.removedUsers.length;

        let playersCount = isPaidByOwner && !isCustomOrClinic 
            ? session.maxUserCount 
            : bookingsCount + addedUsersCount - removedUsersCount;
        playersCount = Math.min(playersCount, session.maxUserCount);

        if (!!session.id && session.type === StM.SessionType.Play && !this.isAdmin) {
            return session.noServiceCheckoutPrice;
        }

        if (session.type === StM.SessionType.Private || session.type === StM.SessionType.Clinic) {
            tier = this.getTierPriceByCoachId(session.trainerId, pricingTierId);
        } else if(session.type === StM.SessionType.Play) {
            const coachFeeTiers = this.getCoachFeeTiers();
            const coachFeeTierPrices = this.getCoachFeeTierPrices();
            const coachFeeTier = coachFeeTiers.find(t => t.isNoCoach);
            tier = !!coachFeeTier && coachFeeTierPrices.find(c => (pricingTierId === c.pricingTierId) && c.coachFeeTierId === coachFeeTier.id);
        }

        if (!tier && session.type !== StM.SessionType.Custom) {
            return session.noServiceCheckoutPrice;
        }

        switch(session.type) {
            case StM.SessionType.Play:
                result = tier.playSessionPrice;
                if(!isPaidByOwner && playersCount) result /= playersCount;
                break;
            case StM.SessionType.Private:
                switch(playersCount) {
                    case 0:
                    case 1:
                        result = tier.privateLessonPrice;
                        break;
                    case 2: 
                        result = tier.semiPrivateLessonPrice;
                        break;
                    default:
                        result = tier.groupLessonPrice;
                        break;
                }
                if(isPaidByOwner || isOwnerGroupMember) result *= playersCount;
                else if(!isGroupSession && (session.ownerId == user.id || !session.id) && 
                    (session.maxUserCount > playersCount || session.maxUserCount == 1) &&
                    !_.some(session.bookings, (b) => b.status === StM.BookingStatus.LateCancel || b.status === StM.BookingStatus.New)
                    )
                {
                    result = tier.privateLessonPrice;
                }
                break;
            case StM.SessionType.Clinic:
                result = tier.clinicSessionPrice;
                if(isPaidByOwner) result *= playersCount;
                break;
            case StM.SessionType.Custom:
                result = session.price;
                break;
            default:
                result = session.noServiceCheckoutPrice;
                break;
        }

        return result;
    }

    private getPriceForSplitPriceSession(session: StM.ISessionStoreState, user: StM.IUserStoreState, asAdditional: boolean = false): number {
        let result = session.price;
        const userBooking = session.bookings.find(b => this.utils.isCountableSplitBooking(b) && b.userId == user.id && !!b.id);
        const sessionAvailability = new SessionAvailabilityPolicy(session, this.getBasketSessions(), user);
        const isGroupSession = sessionAvailability.getIsGroupSession();
        const isLateCancel = this.getIsLateCancel(session);
        const activeBookings = session.bookings.filter(b => this.utils.isActiveBooking(session, b, true));
        
        if (session.isPaidByOwner) {
            return isGroupSession || session.ownerId == user.id ? result : 0;
        } else if (!this.isAdmin && (!this.groupInfo.getIsAuthenticated() || (!isGroupSession && session.addedUsers.length <= 1))) {
            return session.noServiceCheckoutPrice;
        }
        
        let totalPlayers = 1;
       
        if (activeBookings.length) {
            const activeBooking = activeBookings[0];
            const bookingPrice = activeBooking.amount - activeBooking.salesTax;
            const addedPlayersCount = isGroupSession || session.addedUsers.length > 1 ? session.addedUsers.length : 1;
            let removedPlayersCount = session.removedUsers.length;
            const isMaxActivePlayersExceed = activeBookings.length + addedPlayersCount - removedPlayersCount > session.maxActivePlayers;
            const hasLateCancelBookings = session.bookings.some(b => _.includes([StM.BookingStatus.LateCancel, StM.BookingStatus.LateCancelPayFail], b.status));
            let playersCount = activeBookings.length;
            if (isLateCancel && !isMaxActivePlayersExceed) {
                removedPlayersCount = 0;
                playersCount = bookingPrice ? (result / bookingPrice) : 0;
            }
            totalPlayers = playersCount + addedPlayersCount - removedPlayersCount;
            if ((hasLateCancelBookings || (isLateCancel && session.removedUsers.length)) && !isMaxActivePlayersExceed) {
                totalPlayers = Math.min(playersCount, totalPlayers);
            }
        } else {
            totalPlayers = session.addedUsers.length;
        }

        totalPlayers = Math.min(totalPlayers || 1, session.maxUserCount);

        if (userBooking) {
            totalPlayers = session.maxActivePlayers;
        }

        return this.utils.roundPrice(result / totalPlayers);
    }

    private getIsMaxCreditsAvailable(session: StM.ISessionStoreState, user: StM.IUserStoreState) {
        const sessionAvailability = new SessionAvailabilityPolicy(session, this.getBasketSessions(), user);
        const isGroupSession = sessionAvailability.getIsGroupSession();
        const isGroupOwner = sessionAvailability.getIsOwnerGroupMember();
        return isGroupSession ? isGroupOwner && session.addedUsers.length <= 1 : this.getPlayersCount(session, user) === 0
    }

    private handleValue(value: number) {
        return value > 0 ? value : 0;
    }

    private handleDoubleSessionPrice(price: number, user: StM.IUserStoreState) {
        let result = price;
        const session = this.session;
        if (!session) return result;

        const sessionAvailability = new SessionAvailabilityPolicy(session, this.getBasketSessions(), user);  
        const isOwner = sessionAvailability.getIsOwner();
        const isGroupOwnedSession = sessionAvailability.getIsCreatedByGroupSession();
        const isOpenShared = !session.isHidden && !session.isPaidByOwner;
        const isCustom = session.type === StM.SessionType.Custom;
        const isLesson = session.type === StM.SessionType.Private;
        const isPlay = session.type === StM.SessionType.Play;

        const isDouble = session.isDoubledSession && !isCustom 
            && (this.isAdmin || ((!isPlay || !session.id) 
            && (isOwner || isGroupOwnedSession || (isLesson && isOpenShared))));

        if (isDouble) {
            const doublePrice = result * 2;
            const club = this.getCurrentClub();
            const doubleDiscount = doublePrice * club.doubleSessionsDiscount / 100;
            result = doublePrice - doubleDiscount;
        }

        return result;
    }

    private getIsLateCancel = (session: StM.ISessionStoreState) => this.utils.isWithinLateCancellationFeePeriod(session.startDateTime, this.getCurrentClub());
}