import { ISessionStoreState } from './../models/store/sessions/sessionStoreState';
import * as Redux from 'redux';
import * as _ from 'lodash';

import {ThunkService} from '../services/thunkService';
import * as StoreModule from '../models/store';

export class ValidatedBasketActions {

    static updateSessions(sessions: Array<ISessionStoreState>): (dispatch: any, getState: () => StoreModule.IGlobalStoreState, thunkService: ThunkService) => void  {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
           dispatch({
               type: 'ValidatedBasket/UpdateSessions',
               payload: sessions
           });
       };   
   }
}
