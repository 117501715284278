import { UserActions } from '../userActions';
import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';

export class TermsAndConditionsDialogActions {
    static open(hideConfirmation: boolean = false): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: hideConfirmation ? 'Dialogs/TermsAndConditions/OpenAndHideConfirmation' : 'Dialogs/TermsAndConditions/Open'
            });
            dispatch(TermsAndConditionsDialogActions.decline());
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/TermsAndConditions/Close',
            });
        };
    }

    static accept(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const user = getState().user;

            if(user && user.id && !user.isAcceptedWaiver){
                dispatch(UserActions.acceptWaiver());
                dispatch({
                    type: 'Dialogs/TermsAndConditions/Accept',
                });
            }
            
            dispatch({
                type: 'Dialogs/TermsAndConditions/Accept',
            });
            return Promise.resolve();
        };
    }

    static decline(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/TermsAndConditions/Decline',
            });
        };
    }
}
