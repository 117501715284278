import { IBaseDialogStoreState, BaseDialogStoreState } from '../baseDialogStoreState';

export interface IInviteGroupMemberDialogStoreState extends IBaseDialogStoreState { 
    groupId: number;
}

export class InviteGroupMemberDialogStoreState extends BaseDialogStoreState implements IInviteGroupMemberDialogStoreState {
    public groupId: number;

    constructor() {
        super();

        this.groupId = 0;
    }
}
