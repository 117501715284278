import moment from 'moment';
import Constants from '../../../constants';
import * as StM from '../../store';

export interface ITransactionStoreState{
    id: number;
    transactionId: string;
    refundTransactionId: string;
    transactionTimestamp: moment.Moment;
    transactionStatus: string;
    transactionComments: string;
    cardNumber: string;
    bookingStatus: string;
    playerName: string;
    sessionType: string;
    sessionTimestamp: moment.Moment;
    amount: number;
    refund: number;
    refundReason: string;
    userDiscount: number;
    session: StM.ITransactionDetailsSessionStoreState;
    email: string;
    paymentType: string;
    credits: number;
    gatewayStatus: string;
    paymentSystemType: string;
    refundMethod: string;
}

export class TransactionStoreState implements ITransactionStoreState{
    id: number;
    transactionId: string;
    refundTransactionId: string;
    transactionTimestamp: moment.Moment;
    transactionStatus: string;
    transactionComments: string;
    cardNumber: string;
    bookingStatus: string;
    playerName: string;
    sessionType: string;
    sessionTimestamp: moment.Moment;
    amount: number;
    refund: number;
    refundReason: string;
    userDiscount: number;
    session: StM.ITransactionDetailsSessionStoreState;
    email: string;
    paymentType: string;
    credits: number;
    gatewayStatus: string;
    paymentSystemType: string;
    refundMethod: string;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.transactionId = dto && dto.transactionId ? dto.transactionId : '';
        this.refundTransactionId = dto && dto.refundTransactionId ? dto.refundTransactionId : '';
        this.transactionTimestamp = dto && dto.transactionTimestamp ?  moment(dto.transactionTimestamp, Constants.DateTime.API_FORMAT) : moment.min();
        this.transactionStatus = dto && dto.transactionStatus ? dto.transactionStatus : 0;
        this.transactionComments = dto && dto.transactionComments ? dto.transactionComments : '';
        this.cardNumber = dto && dto.cardNumber ? dto.cardNumber : '';
        this.bookingStatus = dto && dto.bookingStatus ? dto.bookingStatus : '';
        this.playerName = dto && dto.playerName ? dto.playerName : '';
        this.sessionTimestamp = dto && dto.sessionTimestamp ?  moment(dto.sessionTimestamp, Constants.DateTime.API_FORMAT) : moment.min();
        this.sessionType = dto && dto.sessionType ? dto.sessionType : '';
        this.amount = dto && dto.amount ? dto.amount : 0;
        this.refund = dto && dto.refund ? dto.refund : 0;
        this.refundReason = dto && dto.refundReason ? dto.refundReason : '';
        this.session = dto && dto.session ? new StM.TransactionDetailsSessionDto(dto.session) : null;
        this.email = dto && dto.email ? dto.email : '';
        this.paymentType = dto && dto.paymentType ? dto.paymentType : '';
        this.credits = dto && dto.credits ? dto.credits : '';
        this.gatewayStatus = dto && dto.gatewayStatus ? dto.gatewayStatus : '';
        this.paymentSystemType = dto && dto.paymentSystemType ? dto.paymentSystemType : '';
        this.refundMethod = dto && dto.refundMethod ? dto.refundMethod : '';
    }
}
