import {ICustomSessionTypeStoreState, CustomSessionTypeStoreState} from '../models/store/clubs/customSessionTypeStoreState';
import * as _ from 'lodash';

export default class CustomSessionsReducer {
    static handle(state: Array<ICustomSessionTypeStoreState> = new Array<ICustomSessionTypeStoreState>(), action: any): Array<ICustomSessionTypeStoreState> {
        switch (action.type) {
            case 'CustomSessions/Init': {
                const sessions = <Array<ICustomSessionTypeStoreState>>action.payload;
                return <Array<ICustomSessionTypeStoreState>>_.assign([], sessions);
            }
            case 'CustomSession/Clear': {
                return new Array<ICustomSessionTypeStoreState>();
            }
            default: {
                return state;
            }
        }
    }
}
