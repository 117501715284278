import moment from 'moment';
import Constants from '../../../constants';

export class BookingDto implements IBookingDto{
    id: number;
    user: IPublicUserDto;
    userId: string;
    session: ISessionDto;
    sessionId: number;
    amount: number;
    credits: number;
    excessiveFee: number;
    userDiscount: number;
    status: string;
    paymentType: string;
    changeStatusComment: string;
    transactionId: string;
    transactionComments: string;
    transactionDate: string;
    creditsTransaction: ICreditsTransactionDto;
    creditsTransactionId: number;
    refund: number;
    refundTransactionId: string;
    refundedTransactionId: string;
    refundDateTime: string;
    cancellationDateTime: string;
    salesTax: number;
    videoLinkId: string;
    addons: IAddonDto[];
    group: IGroupDto;
    groupId: number;
    paymentSystemId: number;
    payoffByUserName: string;
    payoffDateTime: moment.Moment;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.user = dto && dto.user ? dto.user : null ;
        this.userId = dto && dto.userId ? dto.userId : 0;
        this.session = dto && dto.session ? dto.session : null;
        this.sessionId = dto && dto.sessionId ? dto.sessionId : 0;
        this.amount = dto && dto.amount ? dto.amount : 0 ;
        this.credits = dto && dto.credits ? dto.credits : 0 ;
        this.excessiveFee = dto && dto.excessiveFee ? dto.excessiveFee : 0 ;
        this.userDiscount = dto && dto.userDiscount ? dto.userDiscount : 0 ;
        this.status = dto && dto.status ? dto.status : '' ;
        this.paymentType = dto && dto.paymentType ? dto.paymentType : '' ;
        this.changeStatusComment = dto && dto.changeStatusComment ? dto.changeStatusComment : '';
        this.transactionId = dto && dto.transactionId ? dto.transactionId : '';
        this.creditsTransactionId = dto && dto.creditsTransactionId ? dto.creditsTransactionId : 0;
        this.transactionComments = dto && dto.transactionComments ? dto.transactionComments : '' ;
        this.transactionDate = dto && dto.transactionDate ? dto.transactionDate : '';
        this.refund = dto && dto.refund ? dto.refund : 0 ;
        this.refundTransactionId = dto && dto.refundTransactionId ? dto.refundTransactionId : '' ;
        this.refundDateTime = dto && dto.refundDateTime ? dto.refundDateTime : '';
        this.cancellationDateTime = dto && dto.cancellationDateTime ? dto.cancellationDateTime : '' ;
        this.salesTax = dto && dto.salesTax ? dto.salesTax : 0 ;
        this.videoLinkId = dto && dto.videoLinkId ? dto.videoLinkId : '';
        this.addons = dto && dto.addons ? dto.addons : [];
        this.group = dto && dto.group ? dto.group : null ;
        this.groupId = dto && dto.groupId ? dto.groupId : 0;
        this.paymentSystemId = dto && dto.paymentSystemId ? dto.paymentSystemId : 0;
        this.payoffByUserName = dto?.payoffByUserName ?? '';
        this.payoffDateTime = dto?.payoffDateTime ? moment(dto.payoffDateTime, Constants.DateTime.API_FORMAT) : moment.min();
    }
}