import * as Redux from 'redux';
import * as _ from 'lodash';    

import {ThunkService} from '../../services/thunkService';

export class CreateSessionDialogActions {
    constructor() {

    }

    static open(courtId: number, timeKey: string, sessionType?: string, sessionSubFilter?: string, sessionSubFilterId?: string, sessionId?: number) {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/CreateSession/Open',
                courtId: courtId,
                timeKey: timeKey,
                sessionType: sessionType,
                sessionSubFilter: sessionSubFilter,
                sessionSubFilterId: sessionSubFilterId,
                sessionId: sessionId
            });
        };
    }

    static close() {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/CreateSession/Close',
            });
        };
    }
}
