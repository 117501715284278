import * as ActM from '../';
import * as StM from '../../models/store';
import * as SrvM from '../../services';
import { ThunkService } from '../../services/thunkService';

export class SessionInfoDialogActions {
    static open(from: string, id: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/SessionInfo/Open',
                from: from,
                id: id,
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/SessionInfo/Close',
            });
        };
    }

    static dropOut(session: StM.ISessionStoreState, agreeWithCancelationFee: boolean)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.session.dropOut(session, club, pricingTiers, agreeWithCancelationFee)
                .then(() => {
                    return Promise.resolve(dispatch(this.verifyCancellation(session)));
                }).then(() => { 
                    return Promise.resolve(dispatch(ActM.UserActions.getCurrent()));
                })
        };
    }

    static dropOutUsers(session: StM.ISessionStoreState, users: StM.IPublicUserStoreState[])
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.ISessionStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
			const promises = users
				.reduce((promiseChain, user) => { return promiseChain.then(() => dispatch(this.dropOutUser(session, user))); }, Promise.resolve([]));
            return Promise.resolve(promises)
                .then(() => {
                    dispatch(ActM.UserActions.getCurrent(false));
                    return dispatch(this.getSessionById(session.id, true))
                });
        };
    }

    static dropOutUser(session: StM.ISessionStoreState, user: StM.IPublicUserStoreState)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.ISessionStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.session.dropOutUser(session, user, club, pricingTiers)
                .then((session) => session);
        };
    }

    static verifyCancellation(session: StM.ISessionStoreState)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.session.verifyCancellation(session, club, pricingTiers);
        };
    }

    static cancel(session: StM.ISessionStoreState, agreeWithCancelationFee: boolean)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.session.cancel(session, club, pricingTiers, agreeWithCancelationFee)
                .then(() => { 
                    return Promise.resolve(dispatch(ActM.UserActions.getCurrent())); 
                });
        };
    }

    static getSessionById(id: number, skipDialogUpdating: boolean = false)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.ISessionStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            let promSession: StM.ISessionStoreState = null;
            return thunkService.api.session.getById(id, club, pricingTiers)
                .then((session: StM.ISessionStoreState) => {
                    if (!skipDialogUpdating) {
                        dispatch({
                            type: 'Dialogs/SessionInfo/Session',
                            payload: session
                        });
                    }
                    promSession = session;
                    return skipDialogUpdating 
                        ? promSession 
                        : dispatch(SessionInfoDialogActions.getSessionInviteToken(session.id));
                }).then(() => {
                    return promSession;
                }).catch(() => {
                    return promSession;
                });
        };
    }

    static getSessionByInviteToken(inviteToken: string)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.ISessionStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            let promSession: StM.ISessionStoreState = null;
            return thunkService.api.session.getByInviteToken(inviteToken, club, pricingTiers)
                .then((session: StM.ISessionStoreState) => {
                    dispatch({
                        type: 'Dialogs/SessionInfo/Session',
                        payload: session
                    });
                    dispatch({
                        type: 'Dialogs/SessionInfo/InviteToken',
                        payload: inviteToken
                    });
                    promSession = session;
                    return promSession;
                }).catch(() => {
                    return promSession;
                });
        };
    }

    static acceptSessionInviteByInviteToken(inviteToken: string)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.ISessionStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            dispatch(ActM.AppActions.showSpinner());
            return thunkService.api.session.acceptInviteByInviteToken(inviteToken, club, pricingTiers)
                .then((session: StM.ISessionStoreState) => {
                    dispatch({
                        type: 'Dialogs/SessionInfo/Session',
                        payload: session
                    });
                    if(getState().app.isAuthorized) dispatch(ActM.UserActions.getCurrent(true));
                    dispatch(ActM.AppActions.hideSpinner());
                    return session;
                }).catch((error) => {
                    dispatch(ActM.AppActions.hideSpinner());
                    throw error;
                });
        };
    }

    static rejectSessionInviteByInviteToken(inviteToken: string)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.ISessionStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            dispatch(ActM.AppActions.showSpinner());
            return thunkService.api.session.rejectInviteByInviteToken(inviteToken, club, pricingTiers)
                .then((session: StM.ISessionStoreState) => {
                    dispatch({
                        type: 'Dialogs/SessionInfo/Session',
                        payload: session
                    });
                    if(getState().app.isAuthorized) dispatch(ActM.NotificationActions.getForCurrentUser());
                    dispatch(ActM.AppActions.hideSpinner());
                    return session;
                }).catch((error) => {
                    dispatch(ActM.AppActions.hideSpinner());
                    throw error;
                });
        };
    }

    static getSessionInviteToken(sessionId: number)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const club = getState().club;
            return thunkService.api.session.getInviteToken(sessionId)
                .then((inviteToken: string) => {
                    dispatch({
                        type: 'Dialogs/SessionInfo/InviteToken',
                        payload: inviteToken
                    });
                }).then(() => {
                    dispatch(ActM.AppActions.hideSpinner());
                });
        };
    }

    static getSessionInviteLink(inviteToken: string, session: StM.ISessionStoreState): string {
        const routeService = new SrvM.RouteService();
        return routeService.getInviteLink(inviteToken, session);
    }

    static getFBSessionInviteLink(inviteToken: string): string {
        const routeService = new SrvM.RouteService();
        return routeService.getFBInviteLink(inviteToken);
    }

    static getSessionVideoLink(token: string): string {
        const routeService = new SrvM.RouteService();
        return routeService.getSessionVideoLink(token);
    }

    static rejectInvitation(sessionId: number, params: any): (d: any, gs: () => StM.IGlobalStoreState, ts: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers, notificationBoard } =  getState();
            return thunkService.api.session.rejectInvitation(sessionId, club, pricingTiers)
                .then((session: StM.ISessionStoreState) => {
                    const noty = notificationBoard.notifications.find((i) => i.targetId === sessionId);
                    if (noty) {
                        dispatch(ActM.NotificationActions.dismiss(noty));
                    }
                }).then(() => {
                    dispatch(ActM.SessionActions.updateForCurrentPage(params));
                });
        };
    }

    static getNotification(sessionId: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.INotificationStoreState> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            const { club, pricingTiers } =  getState();
            return thunkService.api.notification.getForCurrentUserBySession(sessionId, club, pricingTiers)
                .then((notification: StM.INotificationStoreState) => {
                    dispatch({
                        type: 'Dialogs/SessionInfo/Notification',
                        payload: notification
                    });
                    return notification;
                })
        };
    }
}
