import moment from 'moment';
import Constants from '../../../constants';
import { IClubStoreState } from '../clubs';
import { IPackageSessionTypeCreditsDefinitionStoreState } from './packageSessionTypeCreditsDefinitionStoreState';
import { ICoachFeeTierStoreState } from '../coachFeeTierStoreState';
import { IPublicUserStoreState } from '../users';

export interface IPackageDefinitionStoreState {
    id: number;
    title: string;
    description: string;
    creditsDefinitions: Array<IPackageSessionTypeCreditsDefinitionStoreState>;
    club: IClubStoreState;
    isActive: boolean;
    isArchived: boolean;
    createDate: moment.Moment;
    expirationTimeSpan: moment.Duration;
    excludedCoaches: Array<IPublicUserStoreState>;
    credits: number;
    coachFeeTier: ICoachFeeTierStoreState;
    createdBy: IPublicUserStoreState;
    changeDate: moment.Moment;
    changedBy: IPublicUserStoreState;
    price: number;
    sortOrder: number;
    validationResult: IValidationResultDto;
}

export class PackageDefinitionStoreState implements IPackageDefinitionStoreState {
    id: number;
    title: string;
    description: string;
    creditsDefinitions: Array<IPackageSessionTypeCreditsDefinitionStoreState>;
    club: IClubStoreState;
    isActive: boolean;
    isArchived: boolean;
    createDate: moment.Moment;
    expirationTimeSpan: moment.Duration;
    excludedCoaches: Array<IPublicUserStoreState>;
    credits: number;
    coachFeeTier: ICoachFeeTierStoreState;
    createdBy: IPublicUserStoreState;
    changeDate: moment.Moment;
    changedBy: IPublicUserStoreState;
    price: number;
    sortOrder: number;
    validationResult: IValidationResultDto;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.title = dto && dto.title ? dto.title : '';
        this.description = dto && dto.description ? dto.description : '';
        this.creditsDefinitions = dto && dto.creditsDefinitions ? dto.creditsDefinitions : [];
        this.isActive = dto && dto.isActive ? dto.isActive : false;
        this.isArchived = dto && dto.isArchived ? dto.isArchived : false;
        this.createDate = dto && dto.createDate ? moment(dto.createDate, Constants.DateTime.API_FORMAT) : moment.min();
        this.expirationTimeSpan = dto && dto.expirationTimeSpan ? moment.duration(dto.expirationTimeSpan, 'days') : moment.duration();
        this.createdBy = dto && dto.createdBy ? dto.createdBy : null;
        this.changeDate = dto && dto.createDate ? moment(dto.changeDate, Constants.DateTime.API_FORMAT) : moment.min();
        this.changedBy = dto && dto.changedBy ? dto.changedBy : '';
        this.coachFeeTier = dto && dto.coachFeeTier ? dto.coachFeeTier : null;
        this.price = dto && dto.price ? dto.price : 0;
        this.sortOrder = dto && dto.sortOrder ? dto.sortOrder : 0;
        this.validationResult = dto && dto.validationResult ? dto.validationResult : null;
    }
}