export interface IPlayerQualificationStoreState {
    id: number;
    sex: string;
    skill: string;
    ageLower: number;
    ageUpper: number;
    membershipLevels: number[];
}

export class PlayerQualificationStoreState implements IPlayerQualificationStoreState {
    id: number;
    sex: string;
    skill: string;
    ageLower: number;
    ageUpper: number;
    membershipLevels: number[];

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.sex = dto && dto.sex ? dto.sex : '';
        this.skill = dto && dto.skill ? dto.skill : '';
        this.ageLower = dto && dto.ageLower ? dto.ageLower : 0;
        this.ageUpper = dto && dto.ageUpper ? dto.ageUpper : 0;
        this.membershipLevels = dto && dto.membershipLevels ? dto.membershipLevels : [];
    }
}