import BaseReducer from './baseReducer';
import * as StM from '../models/store';

export default class PricingTiersReducer extends BaseReducer {
    static handle(state: Array<StM.IPricingTierStoreState> = new Array<StM.IPricingTierStoreState>(), action: any): Array<StM.IPricingTierStoreState> {
        switch (action.type) {
            case 'PricingTiers/Init': {
                var pricingTiers = <Array<StM.IPricingTierStoreState>>action.payload;
                return [...pricingTiers];
            }
            default: {
                return state;
            }
        }
    }
}
