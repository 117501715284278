import './inputSearch.mobile.scss';

import * as React from 'react';
import ClassNames from 'classnames';
import * as _ from 'lodash';

import { Utils } from '../services';

const utils = new Utils();

interface IInputSearchItem {
    key: any;
    value: any;
    title?: string;
}

interface IInputSearchProps {
    array: Array<any>;
    selected?: any;
    max?: number;
    placeholder?: string;
    classes?: string;
    multiple?: boolean;
    notFoundText?: string;
    addBtnText?: string;
    readOnly?: boolean;
    disabled?: boolean;
    isNeedSort?: boolean;
    autoClose?: boolean;
    alwaysUpdate?: boolean;
    selectedLabelText?: string;
    
    onInputBlur?: (value?: any, filter?: string) => void;
    onSelectChange?: (items: any) => void;
}

interface IInputSearchState {
    filter?: string;
    selected?: any;
    isShowList: boolean;
}

export class InputSearchMobile extends React.Component<IInputSearchProps, IInputSearchState> {
    filterString = '';
    selectedMap: any = {};
    selectedItem: any;
    multiple: boolean = false;
    __DOM: any = null;

    constructor(props: IInputSearchProps) {
        super(props);

        let selected: any;
        if (props.selected) {
            selected = props.selected;
        }

        if (selected) {
            selected = this.setSelected(selected);
        }

        this.multiple = props.multiple || false;
        this.state = {
            filter: '',
            selected: selected,
            isShowList: false
        }
    }

    componentDidUpdate(prevProps: IInputSearchProps) {
        const havePropsChanged = this.havePropsChanged(prevProps);

        if (havePropsChanged) {
            let selected = this.props.selected;
            if (this.props.multiple && this.props.max < this.state.selected.length) {
                selected = selected.slice(0, this.props.max);
            }
            selected = this.setSelected(selected, this.props);
            this.setState({ selected: selected, isShowList: false });
        }
    }

    private havePropsChanged(prevProps: IInputSearchProps) {
        return (
            !_.isEqual(prevProps.array, this.props.array)
            || !_.isEqual(prevProps.selected, this.props.selected)
            || !_.isEqual(prevProps.max, this.props.max)
            || !_.isEqual(prevProps.placeholder, this.props.placeholder)
            || !_.isEqual(prevProps.classes, this.props.classes)
            || !_.isEqual(prevProps.multiple, this.props.multiple)
            || !_.isEqual(prevProps.notFoundText, this.props.notFoundText)
            || !_.isEqual(prevProps.addBtnText, this.props.addBtnText)
            || !_.isEqual(prevProps.readOnly, this.props.readOnly)
            || !_.isEqual(prevProps.disabled, this.props.disabled)
            || !_.isEqual(prevProps.isNeedSort, this.props.isNeedSort)
            || this.props.alwaysUpdate
        );
    }

    render() {
        let filteredArray = this.filterArray(this.state.filter);
        if (this.props.isNeedSort) {
            filteredArray = _.sortBy(filteredArray, (filteredItem: IInputSearchItem) => { return filteredItem.title ? filteredItem.title.toUpperCase() : filteredItem.value.toUpperCase(); });
        }
        if (this.state.filter.length > 0) {
            let arrayStartFromFilter = filteredArray.filter(item => {
                if (item.value.toUpperCase().indexOf(this.state.filter.toUpperCase()) == 0) return true;
                return false;
            });

            let arrayNoStartFromFilter = filteredArray.filter(item => {
                if (item.value.toUpperCase().indexOf(this.state.filter.toUpperCase()) !== 0) return true;
                return false;
            });
            filteredArray = arrayStartFromFilter.concat(arrayNoStartFromFilter)
        }
        for (var i = 0; i < filteredArray.length; i++) {
            if (filteredArray[i].value == "United States") {
                filteredArray.unshift(filteredArray.splice(i, 1)[0]);
            }
        }
        let text = this.props.placeholder || '';
        if (!this.props.multiple) {
            text = this.state.selected ? (this.state.selected.title || this.state.selected.value) : text;
        }
        const classes = ClassNames(this.props.classes, 'custom-select-mobile', {
            'disabled': this.props.disabled,
            'read-only-input': !!this.props.readOnly,
            'not-default-value': this.state.selected
        });
        var zIndexStyle = this.state.isShowList ? { zIndex: 100 } : { zIndex: 5 };
        return (
            <div className={classes} style={zIndexStyle} ref={(element) => { this.__DOM = element; }} onClick={(e) => this.onInputClick(e)}>
                <input type="text" placeholder={text} className="custom-input-mobile" autoComplete="off"
                    value={this.state.filter}
                    readOnly={this.props.readOnly}
                    onFocus={() => this.onFocusHandler()}
                    onClick={(e) => this.onInputClick(e)}
                    onBlur={() => this.onBlurHandler()}
                    onChange={(e) => this.changeFilter(e)} onKeyPress={(e) => this.onInputKeyDown(e)} />
                {this.state.isShowList && !!filteredArray.length && !this.props.disabled &&
                    <ul className="search-list-mobile" ref={this.setListPosition}>
                        {filteredArray.map((item) => {
                            return this.multiple ? this.renderMultipleItem(item) : this.renderItem(item);
                        })}
                        {!filteredArray.length && this.props.notFoundText && this.renderNotFound()}
                    </ul>
                }
                {this.props.children}
                <span className="caret-mobile" onClick={(e) => this.onInputClick(e)}></span>
            </div>
        );
    }

    renderItem(item: any) {
        return (
            <li className="search-list-item-mobile" key={item.key} onClick={(e) => this.selectItem(e, item)}>
                <div className="mobile-row">
                    <div className="mobile-col-9">
                        <div className="name-mobile">{item.title || item.value}</div>
                    </div>
                </div>
            </li>
        )
    }

    renderMultipleItem(item: any) {
        let button: any;
        let invited = false;
        if (this.selectedMap[item.key]) {
            button = null;
            invited = true;
        } else if (this.props.max != undefined && (this.props.max == 0 || this.state.selected.length >= this.props.max)) {
            button = null;
        } else {
            button = this.renderAddButton(item);
        }
        return (
            <li className={"search-list-item-mobile" + (invited ? ' invited' : '')} key={item.key} onClick={(e) => this.selectItem(e, item)}>
                <div className="mobile-row">
                    <div className="mobile-col-9">
                        <div className="name-mobile">{item.title || item.value}</div>
                        <div className="invite-mobile">{this.props.selectedLabelText || "Invited"}</div>
                    </div>
                    <div className="mobile-col-3 btn-wrapper">
                        {button}
                    </div>
                </div>
            </li>
        );
    }

    renderAddButton(item: any) {
        const text = this.props.addBtnText ? this.props.addBtnText : 'Add';
        return (
            <div className="btn-container-mobile">{text}</div>
        )
    }

    renderRemoveButton(item: any) {

        return (
            <div className="btn-container" onClick={(e) => this.unselectItem(e, item)}>Remove</div>
        )
    }

    renderNotFound() {
        return (

            <li className="search-list-item-mobile" key={0}>
                <div className="mobile-row">
                    <div className="mobile-col-9">
                        <div className="name-mobile">{this.props.notFoundText}</div>
                    </div>
                </div>
            </li>
        );
    }

    setSelected(value: Array<any>, props?: IInputSearchProps) {
        this.selectedMap = {};
        this.selectedItem = null;
        props = props ? props : this.props;
        if (!props.multiple) {
            for (let i = 0; i < props.array.length; i++) {
                let item = props.array[i];
                if (item.key == value) {
                    this.selectedItem = value
                    return item;
                }
            }
        } else {
            for (let i = 0; i < value.length; i++) {
                let item = value[i];
                this.selectedMap[item.key] = item;
            }
            return value;
        }
    }

    changeFilter(e: any) {
        var searchList: any = document.getElementsByClassName('search-list-mobile')[0];
        searchList && (searchList.scrollTop = 0);
        this.setState({ ...this.state, filter: e.target.value });
    }

    filterArray(string?: string) {
        let filteredArray = this.props.array.filter(item => {
            if (string) {
                let reg = new RegExp(utils.escapeRegExp(string), 'i');
                return reg.test(item.value);
            }
            return true;
        });
        return filteredArray;
    }

    // handlers functions
    onInputKeyDown(e: any) {
        if (e) { e.stopPropagation(); }
    }

    onFocusHandler() {
        // this.setDocumentClickHandler();
    }

    onBlurHandler() {
        if (this.props.onInputBlur) this.props.onInputBlur(this.state.selected, this.state.filter);
    }

    blur() {
        this.removeDocumentClickHandler();
    }

    setDocumentClickHandler() {
        window.addEventListener('click', this.handleDocumentClick);
        window.addEventListener('keydown', this.handleDocumentKeyPress);
    }

    removeDocumentClickHandler() {
        window.removeEventListener('click', this.handleDocumentClick);
        window.removeEventListener('keydown', this.handleDocumentKeyPress);
    }

    selectItem(e: any, item: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        if (this.selectedMap[item.key] ||
            (this.props.max != undefined && (this.props.max == 0 || this.state.selected.length >= this.props.max)) ||
            (this.selectItem == item.key)) {
            return false;
        }

        let selected: any;

        if (this.multiple) {
            selected = this.state.selected.slice();
            selected.push(item);
            this.selectedMap[item.key] = item;
        } else {
            selected = item;
            this.selectedItem = item.key;
            this.blur();
        }

        if (typeof this.props.onSelectChange == 'function') {
            this.props.onSelectChange(selected);
        }
        this.setState({ filter: '', selected: selected, isShowList: !(this.props.autoClose == null || this.props.autoClose) });
    }

    unselectItem(e: any, item: any) {
        if (e) { e.stopPropagation(); } //e.preventDefault();
        delete this.selectedMap[item.key];
        let selected = this.state.selected.slice();
        let index = 0;
        for (let i = 0; i < selected.length; i++) {
            let _item = selected[i];
            if (item.key == _item.key) {
                index = i;
                break;
            }
        }

        if (index + 1) {
            selected.splice(index, 1);
            if (typeof this.props.onSelectChange == 'function') {
                this.props.onSelectChange(selected);
            }
            this.setState({ filter: '', selected: selected, isShowList: this.props.autoClose == null || this.props.autoClose });
        }
    }

    handleDocumentClick = (evt: any) => {
        if (!!this && !!this.__DOM && !!this.__DOM.contains && !this.__DOM.contains(evt.target)) {
            this.blur();
            this.setState({ ...this.state, isShowList: false });
        }
    }

    handleDocumentKeyPress = (evt: any) => {
        if (this.props.multiple) return false;
        if (evt.keyCode != 38 && evt.keyCode != 40) return false;

        let itemIndex = 0;

        if (this.state.selected) {
            for (let i = 0; i < this.props.array.length; i++) {
                let item = this.props.array[i];
                if (item.key == this.state.selected.key) {
                    itemIndex = i;
                }
            }
        }

        let item = evt.keyCode == 38 ? this.props.array[itemIndex - 1] : this.props.array[itemIndex + 1];
        if (!item) return false;

        this.selectedItem = item.key;
        if (typeof this.props.onSelectChange == 'function') {
            this.props.onSelectChange(item);
        }
        this.setState({ ...this.state, filter: '', selected: item });
    }

    // render
    setListPosition(element: any) {
        if (!element) {
            return false;
        }
        const $input = element.parentElement.getElementsByTagName('input')[0];
        let listHeight = element.offsetHeight;
        let inputHeigth = $input.offsetHeight;
        let coords = $input.getBoundingClientRect();

        let bottomOffset = window.innerHeight - (inputHeigth + coords.top);

        if (bottomOffset < listHeight) {
            element.style.top = 'auto';
            element.style.bottom = '100%';
        }
    }

    onInputClick(e: any) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        if (!this.props.disabled) {
            if (!this.state.isShowList) {
                this.setState({ ...this.state, isShowList: true });
                this.setDocumentClickHandler();
            } else if (this.props.readOnly) {
                this.setState({ ...this.state, isShowList: false });
            }
        }
    }
}