import { ICustomSessionTypeStoreState } from '../store/clubs/customSessionTypeStoreState';
import { IRoleDefinitionStoreState, IClubStoreState } from './clubs';
import { ICoachStoreState, IMembershipLevelStoreState } from './users';
import { ICoachFeeTierStoreState } from './coachFeeTierStoreState';
import { IAddonDefinitionStoreState, AddonDefinitionStoreState } from './addons';
import { IPackageDefinitionStoreState, IPackageSessionTypeStoreState, PackageDefinitionStoreState } from './packages';
import { ISubscriptionOffersState, ISubscriptionOfferStateModel, SubscriptionOffersState } from '@models/store/subscriptionOffers';

export interface ICommonDictionariesStoreState {
    club: IClubStoreState;
    coaches: ICoachStoreState[];
    coachFeeTiers: ICoachFeeTierStoreState[];
    membershipLevels: IMembershipLevelStoreState[];
    packageDefinitions: IPackageDefinitionStoreState[];
    subscriptionOfferDefinitions: ISubscriptionOfferStateModel[];
    addonDefinitions: IAddonDefinitionStoreState[];
    packageSessionTypes: IPackageSessionTypeStoreState[];
    customSessions: ICustomSessionTypeStoreState[];
    permissions: IRoleDefinitionStoreState[];
}

export class CommonDictionariesStoreState implements ICommonDictionariesStoreState {
    club: IClubStoreState;
    coaches: Array<ICoachStoreState>;
    coachFeeTiers: Array<ICoachFeeTierStoreState>;
    membershipLevels: Array<IMembershipLevelStoreState>;
    packageDefinitions: Array<PackageDefinitionStoreState>;
    subscriptionOfferDefinitions: ISubscriptionOfferStateModel[];
    addonDefinitions: IAddonDefinitionStoreState[];
    packageSessionTypes: Array<IPackageSessionTypeStoreState>;
    customSessions: ICustomSessionTypeStoreState[];
    permissions: IRoleDefinitionStoreState[]

    constructor(dto?: any) {
        this.club = dto?.club ?? null;
        this.coaches = dto?.coaches ?? new Array<ICoachStoreState>();
        this.coachFeeTiers = dto?.coachFeeTiers ?? new Array<ICoachFeeTierStoreState>();
        this.membershipLevels = dto?.membershipLevels ?? new Array<IMembershipLevelStoreState>();
        this.packageDefinitions = dto?.packageDefinitions ?? new Array<PackageDefinitionStoreState>();
        this.subscriptionOfferDefinitions = dto?.subscriptionOfferDefinitions ?? new Array<ISubscriptionOffersState>();
        this.addonDefinitions = dto?.addonDefinitions ?? new Array<AddonDefinitionStoreState>();
        this.packageSessionTypes = dto?.packageSessionTypes ?? new Array<IPackageSessionTypeStoreState>();
        this.customSessions = dto?.customSessions ?? [];
        this.permissions = dto?.permissions ?? [];
    }
}
