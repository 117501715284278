import BaseReducer from '../baseReducer';
import * as StM from '../../models/store';
import { PackageDefinitionDto } from '../../models/dto';

export default class AdminNotificationsReducer extends BaseReducer {
    static handle(state: Array<StM.INotificationStoreState> = new Array<StM.NotificationStoreState>(), action: any): Array<StM.INotificationStoreState> {
        switch (action.type) {
            case 'Admin/Notifications': {
                var notifications = <Array<StM.INotificationStoreState>>action.payload;
                return [...notifications];
            }
            default: {
                return state;
            }
        }
    }
}
