import BaseReducer from './baseReducer';
import * as StM from '../models/store';

export default class TemporarySessionReducer extends BaseReducer {
    static handle(state: StM.ISessionStoreState = null, action: any): StM.ISessionStoreState {
        switch (action.type) {
            case 'TemporarySession/Save': {
                return {...action.payload as StM.ISessionStoreState}
            }
            case 'TemporarySession/Clear': {
                return null;
            }
            default: {
                return state;
            }
        }
    }
}
