import * as StM from '../models/store';
import { ThunkService } from '../services/thunkService';
import * as Redux from 'redux';

export class ActiveUsersActions {

    static init(activeUsers: Array<StM.IPublicUserStoreState>): (dispatch: any, getState: any, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'ActiveUsers/Init',
                payload: activeUsers
            });
        };
    }

    static clear(activeUsers: Array<StM.IPublicUserStoreState>): (dispatch: any, getState: any, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'ActiveUsers/Clear',
                payload: activeUsers
            });
        };
    }

    static get(skipCache:boolean = false): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkSrv: ThunkService) => {
            return thunkSrv.api.user.getActives(skipCache)
                .then((activeUsers: Array<StM.IPublicUserStoreState>) => {
                    return dispatch(ActiveUsersActions.init(activeUsers));
                })
        };
    }
}
