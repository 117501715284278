import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';
import * as StM from '../../store'; 

export interface ICreditTermsDialogStoreState extends IBaseDialogStoreState {
    isAccepted: boolean;
}

export class CreditTermsDialogStoreState extends BaseDialogStoreState implements ICreditTermsDialogStoreState{
    isAccepted: boolean;
    constructor(){
        super();
        this.isAccepted = false;
    }
}