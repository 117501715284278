import { IBaseDialogStoreState, BaseDialogStoreState } from './baseDialogStoreState';
import * as StM from '../../store';

export interface IEditAddonDialogStoreState extends IBaseDialogStoreState {
    id?: number;
}

export class EditAddonDialogStoreState extends BaseDialogStoreState {
    id?: number;
    constructor() {
        super();
    }
}