import * as StM from '../../../models/store';
import BaseReducer from '../../baseReducer';

export class CreateGroupAccountDialogReducer extends BaseReducer {
    public static handle(state: StM.ICreateGroupAccountDialogStoreState = new StM.CreateGroupAccountDialogStoreState(), action: any): StM.ICreateGroupAccountDialogStoreState {
        switch (action.type) {
            case 'Dialogs/CreateGroupAccount/Open': {
                return {
                    ...state,
                    isOpened: true
                } as StM.ICreateGroupAccountDialogStoreState;
            }
            case 'Dialogs/CreateGroupAccount/Close': {
                return {
                    ...state,
                    isOpened: false
                } as StM.ICreateGroupAccountDialogStoreState
            }
            default: {
                return state;
            }
        }
    }
}
