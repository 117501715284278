import { ThunkService } from '../../services';
import * as StM from '../../models/store';

export class SubscriptionNotificationDialogActions {
    static open(id: number, sendNotificationCallback: () => void): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Admin/SubscriptionNotification/Open',
                id: id,
                sendNotificationCallback: sendNotificationCallback
            });
        };
    }

    static close(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => void {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch({
                type: 'Dialogs/Admin/SubscriptionNotification/Close',
            });
        };
    }
}
