import * as StM from '../../models/store';
import BaseReducer from '../baseReducer';
import * as _ from 'lodash';

export default class SubscribersListDialogReducer extends BaseReducer {
    static handle(state: StM.ISubscribersListDialogStoreState = new StM.SubscribersListDialogStoreState(), action: any): StM.ISubscribersListDialogStoreState {
        switch (action.type) {
            case 'Dialogs/Admin/SubscribersList/Open': {
                return <StM.ISubscribersListDialogStoreState>_.assign({}, state, { isOpened: true, ...action });
            }
            case 'Dialogs/Admin/SubscribersList/Close': {
                return <StM.ISubscribersListDialogStoreState>_.assign({}, state, { isOpened: false });
            }
            default: {
                return state;
            }
        }
    }
}