import { IInputSearchItem } from '../../components';

export class Gender {
    public static Male: string = 'Male';
    public static Female: string = 'Female';
}

export class UserSkill {
    public static Beginner: string = 'Beginner';
    public static Intermediate: string = 'Intermediate';
    public static Advanced: string = 'Advanced';
}

export class DisplayUserSkill {
    public static Beginner: string = 'Beginner (up to 3.0)';
    public static Intermediate: string = 'Intermediate (3.0-4.0)';
    public static Advanced: string = 'Advanced (4.0+)';
}

export class PlayType {
    public static Hit: string = 'Hit';
    public static Drills: string = 'Drills';
    public static Match: string = 'Match';
    public static Other: string = 'Other';
}

export class SessionType {
    public static Play: string = 'Play';
    public static Clinic: string = 'Clinic';

    public static Private: string = 'Private';
    public static League: string = 'League';

    public static Custom: string = 'Custom';
}

export class PricingTierType {
    public static OffPeak: string = 'Regular';
    public static Peak: string = 'Peak';
    public static Additional: string = 'Additional';
}

export const PricingTierTitles = {
    [PricingTierType.Peak]: 'Peak',
    [PricingTierType.OffPeak]: 'Off Peak',
    [PricingTierType.Additional]: 'Super Saver', 
}

export class BookingStatus {
    public static New: string = 'New';
    public static CheckedOut: string = 'CheckedOut';
    public static CheckedIn: string = 'CheckedIn';
    public static Cancelled: string = 'Cancelled';
    public static Paid: string = 'Paid';
    public static PayFail: string = 'PayFail';
    public static LateCancel: string = 'LateCancel';
    public static NoShow: string = 'NoShow';
    public static NoShowPayError: string = 'NoShowPayError';
    public static LateCancelPayFail: string = 'LateCancelPayFail';
}

export class UserState {
    public static Active: string = 'Active';
    public static Archived: string = 'Archived';
    public static Deleted: string = 'Deleted';
}

export class PaymentTypes {
    public static Undefined: string = 'Undefined';
    public static Charge: string = 'Charge';
    public static Cash: string = 'Cash';
    public static Credits: string = 'Credits';
}

export class PaymentSystemType {
    public static AuthorizeNet: string = 'AuthorizeNet';
    public static Stripe: string = 'Stripe';
}

export class RefundMethod {
    public static NotAvailable: string = 'NotAvailable';
    public static Refund: string = 'Refund';
    public static Void: string = 'Void';
}

export class PaymentProfileStatus {
    public static NotInitialized: string = 'NotInitialized';
    public static Active: string = 'Active';
    public static PaymentFailure: string = 'PaymentFailure';
    public static PaymentMethodError: string = 'PaymentMethodError';
    public static ProfileError: string = 'ProfileError';
    public static ApiError: string = 'ApiError';
    public static Suspended: string = 'Suspended';
}

export class SessionStatus {
    public static None: string = 'None';
    public static Cancel: string = 'Cancel';
    public static CheckedOut: string = 'CheckedOut';
    public static CheckedOutNoHold: string = 'CheckedOutNoHold';
    public static Closed: string = 'Closed';
    public static Reopened: string = 'Reopened';
}

export class Pages {
    public static Book: string = '/book';
    public static Coaches: string = '/coaches';
    public static Coach: string = '/coach';
    public static Pricing: string = '/pricing';
    public static Packages: string = '/packages';
    public static MySessions: string = '/my-sessions';
    public static SessionInfo: string = '/sessioninfo';
    public static OpenSessionsBoard: string = '/open-sessions-board';
    public static User: string = '/user';
    public static FBLink: string = '/fb-link';
    public static Landing: string = '/landing';
    public static Auth: string = '/auth';
    public static Contacts: string = '/contacts';
    public static ContactUs: string = '/contactus';
    public static Waiver: string = '/waiver';
    public static Notifications: string = '/notifications';
    public static Basket: string = '/basket';
    public static Checkout: string = '/checkout';
    public static DemographicInfo: string = '/demographic-info';
    public static BillingInformation: string = '/billing-info';
    public static Join: string = "/custom";
    public static ClubDashboard: string = "/club-dashboard";
    public static Scoreboard: string = "/scoreboard";
    public static Video: string = "/video";
    public static Privacy: string = "/privacy";
    public static Terms: string = "/terms";
    public static SwitchClub: string = "/switchclub"
}

export class AdminPages {
    public static Admin: string = 'admin';
    public static Dashboard: string = 'dashboard';
    public static Packages: string = "packages";
    public static Subscriptions: string = "subscriptions";
    public static Services: string = "services";
    public static Transactions: string = "transactions";
    public static Users: string = "users";
    public static Groups: string = "groups";
    public static Settings: string = "settings";
    public static Scheduling: string = "scheduling";
    public static Schedule: string = "schedule";
    public static CoachAvailability: string = "availability";
    public static GeneralSettings: string = "general-settings";
    public static BrandingSettings: string = "branding";
    public static PricingSettings: string = "pricing";
    public static UserGroupSettings: string = "member-tiers";
    public static PeakTimeSettings: string = "peak-times";
    public static Notifications: string = "notifications";
    public static LegalTexts: string = "legal-texts";
}

export class UserPages {
    public static Profile: string = 'profile';
    public static History: string = 'history';
    public static EditProfile: string = 'edit-profile';
    public static ChangePassword: string = "change-password";
    public static MyPackages: string = 'club-credits';
    public static MyGroups: string = 'groups';
    public static GroupMembers: string = 'members';
}

export class CardSystem {
    public static Visa: string = 'Visa';
    public static MasterCard: string = 'MasterCard';
    public static AMEX: string = 'AMEX';
}

export class MessageStatus {
    public static New: string = 'New';
    public static Sent: string = 'Sent';
    public static Received: string = 'Received';
    public static Watched: string = 'Watched';
    public static Accepted: string = 'Accepted';
    public static Rejected: string = 'Rejected';
    public static Closed: string = 'Closed';
    public static Ignored: string = 'Ignored';
}

export class NotificationType {
    public static Mail: string = 'Mail';
    public static InApp: string = 'InApp';
    public static Transient: string = 'Transient';
}

export class NotificationTargetType {
    public static Session: string = 'Session';
    public static Series: string = 'Series';
    public static User: string = 'User';
    public static Broadcast: string = 'Broadcast';
    public static Group: string = 'Group';
}

export class NotificationEventType {
    public static SessionYouInvited: string = 'SessionYouInvited';
    public static GroupYouInvited: string = 'GroupYouInvited';
    public static SessionUserHasJoined: string = 'SessionUserHasJoined';
}

export class Roles {
    public static Member: string = 'Member';
    public static ApplicationAdmin: string = 'ApplicationAdmin';
    public static Coach: string = 'Coach';
    public static ClubAdmin: string = 'ClubAdmin';
    public static ManagerOnDuty: string = 'ManagerOnDuty';
    public static CoachAdmin: string = 'CoachAdmin';
    public static Frontdesk: string = 'Frontdesk';
    public static Worker: string = 'Worker';
    public static GroupAccount: string = 'GroupAccount';
}

export class DisplayRoles {
    public static Member: string = 'Member';
    public static ApplicationAdmin: string = 'Application Admin';
    public static Coach: string = 'Coach';
    public static ClubAdmin: string = 'Club Admin';
    public static ManagerOnDuty: string = 'Manager On Duty';
    public static CoachAdmin: string = 'Coach Admin';
    public static Frontdesk: string = 'Frontdesk';
    public static Worker: string = 'Worker';
    public static GroupAccount: string = 'Group Account';
}

export class HistoryPeriods {
    public static Week: string = 'Last 7 days';
    public static Month: string = 'Last Month';
    public static NextMonth: string = 'Next Month';
    public static Any: string = 'Period';
}

export class RecurrenceType {
    public static No: string = "Does not recur";
    public static Monthly: string = 'Recurs monthly';
    public static Weekly: string = 'Recurs weekly';
}

export class BookingActions {
    public static CheckIn: string = "CheckIn";
    public static CheckOut: string = "CheckOut"
    public static Pay: string = "Pay";
    public static CancelPay: string = "CancelPay";
    public static Join: string = "Join";
    public static Unjoin: string = "Unjoin";
}

export class SessionActions {
    public static Create: string = "Create";
    public static Update: string = "Update"
    public static Cancel: string = "Cancel";
    public static Close: string = "Close";
    public static Reopen: string = "Reopen";
    public static RemoveVideo: string = "RemoveVideo";
}

export class ProfileTypes {
    public static Private: string = "Private";
    public static Public: string = "Public";
}

export class SalesTaxesTypes {
    public static Play: string = 'Play';
    public static Lesson: string = 'Private';
    public static Clinic: string = 'Clinic';
    public static Custom: string = 'Custom';
    public static Package: string = 'Package';
}

export class TransactionStatuses {
    public static NotAvailable: string = 'Not Available';
    public static Pending: string = 'Pending Payment';
    public static Settled: string = 'Paid';
    public static Refunded: string = 'Refunded';
    public static Voided: string = 'Voided'; 
    public static Declined: string = 'Payment Failure';
    public static RefundFailed: string = 'Refund Failure';
}

export class GroupTypes {
    public static Family: string = 'Family';
    public static Team: string = 'Team';
}

// Input search lists

export const GendersInputSearchItems: Array<IInputSearchItem> = [
    { key: 0, value: '', title: 'All Gender' },
    { key: 1, value: Gender.Male },
    { key: 2, value: Gender.Female }
];

export const SkillInputSearchItems: Array<IInputSearchItem> = [
    { key: 0, value: '', title: 'All Skills' },
    { key: 1, value: UserSkill.Beginner },
    { key: 2, value: UserSkill.Intermediate },
    { key: 3, value: UserSkill.Advanced }
];

export const PlayTypesInputSearchItems: Array<IInputSearchItem> = [
    { key: 1, value: PlayType.Hit },
    { key: 2, value: PlayType.Drills },
    { key: 3, value: PlayType.Match },
    { key: 4, value: PlayType.Other }
];

export class LegalTexts {
    public static Privacy: string = 'privacy';
    public static Terms: string = 'terms';
    public static Waiver: string = 'waiver';
}

export class Permissions {
    public static VoidPackagePurchase: string = 'VoidPackagePurchase';
    public static RefundPackagePurchase: string = 'RefundPackagePurchase';
    public static VoidSession: string = 'VoidSession';
    public static RefundSession: string = 'RefundSession';
}

export enum GroupType {
    Team = 'Team',
    Family ='Family'
}

export enum GroupMemberType {
    Head = 'Head',
    Regular = 'Regular'
}

export enum InvitationStatus {
    Pending = 'Pending',
    Accepted = 'Accepted',
    Declined = 'Declined'
}

export enum Icon {
    Remove = 'remove',
    AddPlayer = 'add-player',
}

export enum GroupInfoFromType {
    InviteLink = 'invite-link',
    InviteAcceptLink = 'accept-invite-link',
    InviteRejectLink = 'reject-invite-link',
}

export enum PackageSessionTypeGroup {
    PlayAdditional = 'PlayAdditional',
    PlayNonPeakTime = 'PlayNonPeakTime',
    PlayAllTime = 'PlayAllTime',
    PrivateAdditional = 'PrivateAdditional',
    PrivateNonPeakTime = 'PrivateNonPeakTime',
    PrivateAllTime = 'PrivateAllTime',
    ClinicAdditional = 'ClinicAdditional',
    ClinicNonPeakTime = 'ClinicNonPeakTime',
    ClinicAllTime = 'ClinicAllTime',
    Custom = 'Custom'
}

export enum AccountStatus {
    Current = 'current',
    Archive = 'archive',
}

export enum SubscriptionStatus {
    Current = 'current',
    Archive = 'archive',
}

export enum PaymentSystem {
    AuthorizeNet = 'AuthorizeNet',
    Stripe = 'Stripe',
}

export enum ServiceAlias {
    Video = 'video',
    VideoAnalysis = 'video_analysis',
    Other = 'other'
}

export const AvailablePricingTiers = [PricingTierType.Peak, PricingTierType.OffPeak, PricingTierType.Additional];

export enum CacheKey {
    SessionsByPeriod = "SESSIONS_BY_PERIOD",
}

export enum RecurrentPackageBillingPeriods {
    Monthly = 1,
    Quarterly = 3,
    Annually = 12
}

export enum RecurrentPackageOfferStatuses {
    Draft = 'Draft',
    Published = 'Published',
    UnPublished = 'UnPublished',
    Canceled = 'Canceled',
    Archived = 'Archived'
}