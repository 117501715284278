import moment from 'moment';
import * as _ from 'lodash';

import { IBasePolicy, BasePolicy } from '../basePolicy';
import * as StM from '../../models/store';
import * as SrvM from '../../services';

export class CoachAvailabilityPolicy extends BasePolicy implements IBasePolicy {
    private timeStart: moment.Moment;
    private timeEnd: moment.Moment;
    private cache: SrvM.RequestCacheService;
    
    constructor(
        private sessions: Array<StM.ISessionStoreState> = []
        , private availableTimes: Array<StM.IAvailableTimeStoreState> = []
        , private currentDate: moment.Moment = moment()
        , private time?: StM.ICourtTimeBlockStoreState
        , private filter?: StM.IBookPageRouteParams
        , private isDouble: boolean = false
        , private coachFeeTiers: StM.ICoachFeeTierStoreState[] = []
    ){
        super();
        
        if(!time) {
            this.timeStart = moment();
            this.timeEnd = moment();
        } else {
            this.timeStart = this.currentDate.clone().set({ 'hour': this.time.start.hours() , 'minute': this.time.start.minutes()});
            this.timeEnd = this.currentDate.clone().set({ 'hour': this.time.end.hours() , 'minute': this.time.end.minutes()});
            if(this.isDouble) this.timeEnd = this.timeEnd.add(this.time.duration, 'minutes');
        }
        this.cache = new SrvM.RequestCacheService();
    }

    public handle(): boolean {
        const isCoachAvailable = !this.getIsNeedCoachFilter() || this.getIsCoachAvailable();
        const isCoachFree = !this.getIsNeedCoachFilter() || this.getIsCoachFree();
        return isCoachAvailable && isCoachFree;
    }

    public handleForDouble(): boolean {
        const isCoachAvailable = !this.getIsNeedCoachFilter() || this.getIsCoachAvailableForDouble();
        const isCoachFree = !this.getIsNeedCoachFilter() || this.getIsCoachFreeForDouble();
        return isCoachAvailable && isCoachFree;
    }

    public getIsNeedCoachFilter(): boolean{
        return this.filter.sessionType === StM.BookPageSessionType.Lesson;
    }

    public getIsCoachAvailable(time?: StM.ICourtTimeBlockStoreState): boolean {
        let isCoachAvailable = false;
        const splitTimes = this.getSplitAvailableTimes();
        const that = this;
        
        _.each(splitTimes, (availableTime: StM.IAvailableTimeStoreState) => {
            const end = availableTime.begin.clone().add(availableTime.duration);
            if(availableTime.begin.isSameOrBefore(that.timeStart) && that.timeEnd.isSameOrBefore(end)){
                isCoachAvailable = true;
            }
        });
        return isCoachAvailable;
    }

    public getIsCoachAvailableForDouble(time?: StM.ICourtTimeBlockStoreState): boolean {
        let currentTime = time || this.time;
        currentTime = this.getDoubleTime(currentTime);

        return this.getIsCoachAvailable(currentTime);
    }

    public getIsCoachFree(time?: StM.ICourtTimeBlockStoreState): boolean {
        const currentTime = time || this.time;
        const currentTimeSession = currentTime.session;
        let isCoachFree = true;
        const currentTimeSessions = this.getSessionsInTime();
        if(currentTimeSessions && currentTimeSessions.length > 0){
            _.each(currentTimeSessions, (existSession: StM.ISessionStoreState) => {
                const isCurrentTimeSession = (currentTimeSession && currentTimeSession.id && currentTimeSession.id === existSession.id)
                    || (currentTimeSession && !currentTimeSession.id && currentTimeSession.basketId === existSession.basketId);
                const hasTrainer = existSession.trainerId === this.filter.lessonSubfilterId || existSession.assistants.some(a => a.id === this.filter.lessonSubfilterId);
                if(!isCurrentTimeSession && hasTrainer){
                    isCoachFree = false;
                }
            });
        }
        return isCoachFree;
    }

    public getIsCoachFreeForDouble(time?: StM.ICourtTimeBlockStoreState): boolean {
        let currentTime = time || this.time;
        currentTime = this.getDoubleTime(currentTime);
        return this.getIsCoachFree(currentTime);
    }

    public getIsCoachAssignedToCourts(coach: StM.ICoachStoreState, courts: StM.ICourtStoreState[]): boolean {
        return this.getIsCoachAssignedToCourtIds(coach, courts.map(court => court.id));
    }

    public getIsCoachAssignedToCourtIds(coach: StM.ICoachStoreState, courtIds: number[]): boolean {
        if(!coach || !coach.coachFeeTierId) return false;
        const coachFeeTier = _.find(this.coachFeeTiers, (item) => item.id === coach.coachFeeTierId);
        if(!coachFeeTier) return false;

        return !coachFeeTier.courts.length
            || !courtIds.length
            || _.every(courtIds, (courtId) => _.includes(coachFeeTier.courts.map(x => x.id), courtId));
    }

    private getSessionsInTime(){
        return _.filter(this.sessions, (session: StM.ISessionStoreState) => {
            return (session.startDateTime.isBefore(this.timeEnd) && session.endDateTime.isAfter(this.timeStart))
        });;
    }

    private getSplitAvailableTimes(){
        let cachedData = this.cache.getItem("AVAILABLE_TIMES_SPLIT" + this.currentDate.valueOf());
        const cachedItems = cachedData && cachedData.items && cachedData.items[this.filter.lessonSubfilterId] || [];
        if(cachedItems.length) return cachedItems as Array<StM.IAvailableTimeStoreState>;

        let coachAvailableTimes = _.filter(this.availableTimes, (availableTime: StM.IAvailableTimeStoreState) => {
            return availableTime.ownerId == this.filter.lessonSubfilterId;
        });
        coachAvailableTimes = _.orderBy(coachAvailableTimes, (availableTime: StM.IAvailableTimeStoreState) => {
            return availableTime.begin.valueOf();
        });

        let splitArray = new Array<StM.IAvailableTimeStoreState>();
        let begin: moment.Moment  = null;
        let end: moment.Moment  = null;

        _.each(coachAvailableTimes, (availableTime: StM.IAvailableTimeStoreState, index: any) => {
            if(!begin || !end){
                begin = begin || availableTime.begin.clone();
                end = availableTime.begin.clone().add(availableTime.duration);
            }
            else if(end.valueOf() == availableTime.begin.valueOf() ){
                end.add(availableTime.duration);
            }else{
                const duration = moment.duration(end.diff(begin));
                const splitTime =  <StM.IAvailableTimeStoreState>_.assign({}, availableTime, { begin: begin, duration: duration});
                splitArray.push(splitTime);
                begin = availableTime.begin.clone();
                end = availableTime.begin.clone().add(availableTime.duration);
            }

            if(begin && end && index === (coachAvailableTimes.length - 1)){
                const duration = moment.duration(end.diff(begin));
                const splitTime =  <StM.IAvailableTimeStoreState>_.assign({}, availableTime, { begin: begin, duration: duration, end: begin.clone().add(duration)});
                splitArray.push(splitTime);
            }
        });
        if(!cachedData || !cachedData.items) cachedData = { items: new Array<any>()};
        cachedData.items[this.filter.lessonSubfilterId] = splitArray;

        this.cache.setItem("AVAILABLE_TIMES_SPLIT" + this.currentDate.valueOf(), cachedData);

        return splitArray;
    }

    private getDoubleTime(time?: StM.ICourtTimeBlockStoreState){
        let currentTime = {...(time || this.time)};
        currentTime.end = moment.duration(currentTime.end).add(this.timeEnd.diff(this.timeStart));
        return currentTime;
    }
}