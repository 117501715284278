import * as StM from '../../models/store';
import * as _ from 'lodash';

export default class BookPageReducer {

    static handle(state: StM.IBookPageStoreState = new StM.BookPageStoreState(), action: any): StM.IBookPageStoreState {
        switch (action.type) {
            case 'Pages/Book/Init': {
                return state;
            }
            case 'Pages/Book/Sessions': {
                let sessions = <Array<StM.ISessionStoreState>>action.payload;
                return <StM.IBookPageStoreState>_.assign({}, state, { sessions: sessions, refresh: false  });
            }
            case 'Pages/Book/Clinics': {
                let clinics = <Array<StM.ISessionStoreState>>action.payload;
                return <StM.IBookPageStoreState>_.assign({}, state, { clinics: clinics, refresh: false  });
            }
            case 'Pages/Book/AvailableTimes': {
                let availableTimes = <Array<StM.IAvailableTimeStoreState>>action.payload;
                return <StM.IBookPageStoreState>_.assign({}, state, { availableTimes: availableTimes, refresh: false  });
            }
            case 'Pages/Book/CourtTimeSlots': {
                let timeSlots = <Array<StM.CourtTimeSlotStoreState>>action.payload;
                return <StM.IBookPageStoreState>_.assign({}, state, { timeSlots: timeSlots, refresh: false });
            }
            case 'Pages/Book/CourtTimeSlotsRefresh': {
                let timeSlots = <Array<StM.CourtTimeSlotStoreState>>action.payload;
                return <StM.IBookPageStoreState>_.assign({}, state, { timeSlots: timeSlots, refresh: true });
            }
            case 'Pages/Book/AvailableTimesLookup': {
                let availableTimesLookup = <IDictionary>action.payload;
                return <StM.IBookPageStoreState>_.assign({}, state, { availableTimesLookup: availableTimesLookup, refresh: false });
            }
            default: {
                return <StM.IBookPageStoreState>_.assign({}, state, { refresh: false });
            }
        }
    }
}
