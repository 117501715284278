import * as _ from 'lodash';

import * as StM from '../models/store';

export default class BasketReducer {
    static handle(state: StM.IBasketStoreState = new StM.BasketStoreState(), action: any): StM.IBasketStoreState {
        const isPackage = !!action.item && 'creditsDefinitions' in action.item;
        
        switch (action.type) {
            case 'Basket/Add': {
                if(isPackage) {
                    const packages = [...state.packages];
                    packages.push(action.item);
                    packages.sort((a, b) => {
                        if (a.id < b.id) return -1;
                        if (a.id > b.id) return 1;
                        return 0;
                    });
            
                    return {...state, ...{ packages: packages }};
                } else {
                    const goods = [...state.goods];
                    const item = action.item;

                    let newId = 1;
                    let isEmpty = false;
                    
                    do {
                        let isInArray = false
                        for (let i = 0; i < goods.length; i++) {
                            let good = goods[i];
                            if (good.basketId == newId) {
                                isInArray = true;
                            }
                        }
                        if (isInArray) {
                            newId++
                        } else {
                            isEmpty = true;
                        }
                    } while(!isEmpty);
    
                    item.basketId = newId;
                    goods.push(action.item);
                    return {...state, ...{ goods: goods }};
                }
            }
            case 'Basket/Remove': {
                const goods = state.goods.filter(g => g.basketId !== action.id);
                return { ...state, goods };
            }
            case 'Basket/ReplacePackages': {
                return <StM.IBasketStoreState>_.assign({}, state, { packages: action.items });
            }
            case 'Basket/RemovePackage': {
                const index = state.packages.findIndex(p => p.id === action.id);

                if (index !== -1) {
                    const packages = [
                        ...state.packages.slice(0, index),
                        ...state.packages.slice(index + 1)
                    ];
                    return { ...state, packages };
                }

                return state;
            }
            case 'Basket/RemoveById': {
                const goods = _.filter(state.goods, (basketSession) => {
                    return basketSession.basketId != action.basketId;
                });
                
                return { ...state, ...{ goods: goods }};
            }
            case 'Basket/Clear': {
                return { ...state, ...{ goods: [], packages: [] }};
            }
            case 'Basket/ShowToggle': {
                const isShown = state.isShown;
                return {... state, ...{ isShown: !isShown }};
            }
            case 'Basket/Close': {
                return { ...state, ...{ isShown: false}};
            }
            case 'Basket/Edit': {
                const item = {...action.item};
                for (let i = 0; i < state.goods.length; i++) {
                    if (state.goods[i].basketId == item.basketId) {
                        const goods = state.goods.slice();
                        goods.splice(i, 1, item);
                        return { ...state, ...{goods: [...goods]}};
                    }
                }
            }
            case 'Basket/Update': {
                const goods = [...action.payload];
                return {...state, ...{ goods: goods }};
            }
            default: { 
                return state;
            }
        }
    }
}
