import * as Redux from 'redux';

export class InviteGroupMemberDialogActions {
    public static open(groupId?: string): (dispatch: any) => void {
        return (dispatch: any) => {
            dispatch({ type: 'Dialogs/InviteGroupMember/Open', payload: groupId });
        };
    }

    public static close(): (dispatch: any) => void {
        return (dispatch: any) => {
            dispatch({ type: 'Dialogs/InviteGroupMember/Close' });
        };
    }
}
