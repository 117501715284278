export interface IAliasedTimeZoneStoreState{
    id: number;
    standardName: string;
    dbName: string;
    alias: string;
    timeZoneInfo: any;
}

export class AliasedTimeZoneStoreState implements IAliasedTimeZoneStoreState {
    id: number;
    standardName: string;
    dbName: string;
    alias: string;
    timeZoneInfo: any;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.standardName = dto && dto.standardName ? dto.standardName : '';
        this.dbName = dto && dto.dbName ? dto.dbName : '';
        this.alias = dto && dto.alias ? dto.alias : '';
        this.timeZoneInfo = dto && dto.timeZoneInfo ? dto.timeZoneInfo : null;
    }
}
