import * as _ from 'lodash';
import * as ServiceModule from '../services';
import * as StoreModule from '../models/store';

export default class HydratedStateReducer {
    static handle(state: StoreModule.IGlobalStoreState): StoreModule.IGlobalStoreState {
        let service = new ServiceModule.HydratedStateService();
        state = service.getRestoredState(state);
        return state;
    }
}
