import * as _ from 'lodash';
import * as SrvM from '../services';
import * as AdminSrvM from '../services/repositories/admin';

export class Api {
    base: SrvM.ApiService;
    common: SrvM.CommonApiService;
    banner: SrvM.BannerApiService;
    club: SrvM.ClubApiService;
    user: SrvM.UserApiService;
    account: SrvM.AccountApiService;
    pricing: SrvM.PricingApiService;
    group: SrvM.GroupApiService;
    session: SrvM.SessionApiService;
    package: SrvM.PackageApiService;
    subscription: SrvM.SubscriptionApiService;
    notification: SrvM.NotificationApiService;
    countries: SrvM.CountriesApiService;
    admin: AdminSrvM.AdminApiService;
    
    constructor(config?: any) {
        this.base = new SrvM.ApiService(config);
        this.common = new SrvM.CommonApiService(_.assign({}, config, { baseURL: config.baseURL + '/api/v2/common' }));
        this.banner = new SrvM.BannerApiService(_.assign({}, config, { baseURL: config.baseURL + '/api/v2/banners' }));
        this.club = new SrvM.ClubApiService(_.assign({}, config, { baseURL: config.baseURL + '/api/v2/clubs' }));
        this.user = new SrvM.UserApiService(_.assign({}, config, { baseURL: config.baseURL + '/api/v2/users' }));
        this.group = new SrvM.GroupApiService(_.assign({}, config, { baseURL: config.baseURL + '/api/v2/groups' }));
        this.account = new SrvM.AccountApiService(_.assign({}, config, { baseURL: config.baseURL + '/api/v2/account' }));
        this.pricing = new SrvM.PricingApiService(_.assign({}, config, { baseURL: config.baseURL + '/api/v2/pricing' }));
        this.session = new SrvM.SessionApiService(_.assign({}, config, { baseURL: config.baseURL + '/api/v2/sessions' }));
        this.package = new SrvM.PackageApiService(_.assign({}, config, { baseURL: config.baseURL + '/api/v2/packages' }));
        this.subscription = new SrvM.SubscriptionApiService(_.assign({}, config, { baseURL: config.baseURL + '/api/v2/recurrent-packages' }));
        this.notification = new SrvM.NotificationApiService(_.assign({}, config, { baseURL: config.baseURL + '/api/v2/notifications' }));
        this.countries = new SrvM.CountriesApiService(_.assign({}, config, { baseURL: 'https://restcountries.eu/rest/v1', headers: {'Access-Control-Allow-Origin':'*'}}));
        this.admin = new AdminSrvM.AdminApiService(_.assign({}, config, { baseURL: config.baseURL + '/api/v2/admin' }));
    }
}

export class ThunkService {
    api: Api;
    logger: SrvM.LogService;
    courtTime: SrvM.CourtTimeService;
    routeService: SrvM.RouteService;
    routeDialogService: SrvM.RouteDialogService;
    cache: SrvM.RequestCacheService;
    constructor(apiConfig?: any) {
        this.api = new Api(apiConfig);
        this.logger = new SrvM.LogService();
        this.courtTime = new SrvM.CourtTimeService();
        this.routeService = new SrvM.RouteService();
        this.routeDialogService = new SrvM.RouteDialogService();
        this.cache = new SrvM.RequestCacheService();
    }
}
