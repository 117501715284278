import moment from 'moment';

import Constants from '../../../constants';
import { IPaymentSystemStoreState } from '../paymentSystems/paymentSystemStoreState';
import { IAliasedTimeZoneStoreState } from './aliasedTimeZoneStoreState';
import { IClubTimeStoreState } from './clubTimeStoreState';
import { ICourtStoreState } from './courtStoreState';
import { IRoleDefinitionStoreState } from './roleDefinitionStoreState';
import { ISalesTaxStoreState } from './salesTaxStoreState';

export interface IClubStoreState {
    id: number;
    title: string;
    address: string;
    address1: string;
    address2: string;
    url: string;
    description: string;
    cssFilePath: string;
    logoUrl: string;
    logoLinkUrl: string;
    additionalImageUrl: string;
    additionalSecondImageUrl: string;
    useExcessivePlayFee: boolean;
    allowedNotExcessivePlayADay: number;
    excessivePlayFee: number;
    systemName: string;
    minimalCancellationPeriod: number;
    lateCancellationFee: number;
    isLateCancellationFeeInPercent: boolean;
    noShowFee: number;
    isNoShowFeeInPercent: boolean;
    aliasedTimeZone: IAliasedTimeZoneStoreState;
    courts: Array<ICourtStoreState>;
    doubleSessionsDiscount: number;
    emailSignature: string;
    emailFooterReference: string;
    emailFooterReferenceText: string;
    allowBackToBackPeakTimeBooking: boolean;
    allowBackToBackRegularTimeBooking: boolean;
    backToBackMinutesGap: number;
    maximumPeakTimeBookingsPerDay: number;
    phone: string;
    generalInquiriesEmail: string;
    generalContactEmail: string;
    lastPeakTimeChanging: moment.Moment;
    applicationInsightsInstrumentationKey: string;
    uxV2MainBannerUrl: string;
    uxV2RightBannerUrl: string;
    bannerAlternateText: string;
    clubTimes: IClubTimeStoreState[];
    salesTaxes: ISalesTaxStoreState[];
    salesTaxRate: number;
    salesTaxTitle: string;
    timeZoneOffset: string;
    customSessions: string;
    recurrentAvailabilityWindow: number;
    permissions: IRoleDefinitionStoreState[];
    logoId: number;
    dashboardLogoId: number;
    emailLogoId: number;
    emailBackgroundId: number;
    showPricingPage: boolean;
    paymentSystem: IPaymentSystemStoreState;
    priceList: string;
    offsetInHours: number;
    minimumNotificationPeriodInDays: number;
}

export class ClubStoreState implements IClubStoreState {
    id: number;
    title: string;
    address: string;
    address1: string;
    address2: string;
    url: string;
    description: string;
    cssFilePath: string;
    logoUrl: string;
    logoLinkUrl: string;
    additionalImageUrl: string;
    additionalSecondImageUrl: string;
    useExcessivePlayFee: boolean;
    allowedNotExcessivePlayADay: number;
    excessivePlayFee: number;
    systemName: string;
    minimalCancellationPeriod: number;
    lateCancellationFee: number;
    isLateCancellationFeeInPercent: boolean;
    noShowFee: number;
    isNoShowFeeInPercent: boolean;
    aliasedTimeZone: IAliasedTimeZoneStoreState
    courts: Array<ICourtStoreState>;
    doubleSessionsDiscount: number;
    emailSignature: string;
    emailFooterReference: string;
    emailFooterReferenceText: string;
    allowBackToBackPeakTimeBooking: boolean;
    allowBackToBackRegularTimeBooking: boolean;
    backToBackMinutesGap: number;
    maximumPeakTimeBookingsPerDay: number;
    phone: string;
    generalInquiriesEmail: string;
    generalContactEmail: string;
    lastPeakTimeChanging: moment.Moment;
    applicationInsightsInstrumentationKey: string;
    clubTimes: IClubTimeStoreState[];
    salesTaxes: ISalesTaxStoreState[];
    uxV2MainBannerUrl: string;
    uxV2RightBannerUrl: string;
    bannerAlternateText: string;
    salesTaxRate: number;
    salesTaxTitle: string;
    timeZoneOffset: string;
    customSessions: string;
    recurrentAvailabilityWindow: number;
    permissions: IRoleDefinitionStoreState[];
    logoId: number;
    dashboardLogoId: number;
    emailLogoId: number;
    emailBackgroundId: number;
    showPricingPage: boolean;    
    paymentSystem: IPaymentSystemStoreState;
    priceList: string;
    offsetInHours: number;
    minimumNotificationPeriodInDays: number;

    constructor(dto?: any) {
        
        this.id = dto && dto.id ? dto.id : 0;
        this.title = dto && dto.title ? dto.title : '';
        this.address = dto && dto.address ? dto.address : '';
        this.address1 = dto && dto.address1 ? dto.address1 : '';
        this.address2 = dto && dto.address2 ? dto.address2 : '';
        this.url = dto && dto.url ? dto.url : '';
        this.description = dto && dto.description ? dto.description : '';
        this.cssFilePath = dto && dto.cssFilePath ? dto.cssFilePath : '';
        this.logoUrl = dto && dto.logoUrl ? dto.logoUrl : '';
        this.logoLinkUrl = dto && dto.logoLinkUrl ? dto.logoLinkUrl : '';
        this.additionalImageUrl = dto && dto.additionalImageUrl ? dto.additionalImageUrl : '';
        this.additionalSecondImageUrl = dto && dto.additionalSecondImageUrl ? dto.additionalSecondImageUrl : '';
        this.useExcessivePlayFee = dto && dto.useExcessivePlayFee ? dto.useExcessivePlayFee : false;
        this.allowedNotExcessivePlayADay = dto && dto.allowedNotExcessivePlayADay ? dto.allowedNotExcessivePlayADay : 0;
        this.excessivePlayFee = dto && dto.excessivePlayFee ? dto.excessivePlayFee : 0;
        this.systemName = dto && dto.systemName ? dto.systemName : '';
        this.minimalCancellationPeriod = dto && dto.minimalCancellationPeriod ? dto.minimalCancellationPeriod : 0;
        this.lateCancellationFee = dto && dto.lateCancellationFee ? dto.lateCancellationFee : 0;
        this.isLateCancellationFeeInPercent = dto && dto.isLateCancellationFeeInPercent ? dto.isLateCancellationFeeInPercent : false;
        this.noShowFee = dto && dto.noShowFee ? dto.noShowFee : 0;
        this.isNoShowFeeInPercent = dto && dto.isNoShowFeeInPercent ? dto.isNoShowFeeInPercent : false;
        this.aliasedTimeZone = dto && dto.aliasedTimeZone ? dto.aliasedTimeZone : null;
        this.courts = dto && dto.courts ? dto.courts : [];
        this.doubleSessionsDiscount = dto && dto.doubleSessionsDiscount ? dto.doubleSessionsDiscount : 0;
        this.emailSignature = dto && dto.emailSignature ? dto.emailSignature : '';
        this.allowBackToBackPeakTimeBooking = dto && dto.allowBackToBackPeakTimeBooking ? dto.allowBackToBackPeakTimeBooking : false;
        this.allowBackToBackRegularTimeBooking = dto && dto.allowBackToBackRegularTimeBooking ? dto.allowBackToBackRegularTimeBooking : false;
        this.backToBackMinutesGap = dto && dto.backToBackMinutesGap ? dto.backToBackMinutesGap : 0;
        this.maximumPeakTimeBookingsPerDay = dto && dto.maximumPeakTimeBookingsPerDay ? dto.maximumPeakTimeBookingsPerDay : 0;
        this.phone = dto && dto.phone ? dto.phone : '';
        this.generalInquiriesEmail = dto && dto.generalInquiriesEmail ? dto.generalInquiriesEmail : '';
        this.generalContactEmail = dto && dto.generalContactEmail ? dto.generalContactEmail : '';
        this.lastPeakTimeChanging = dto && dto.lastPeakTimeChanging ? moment(dto.lastPeakTimeChanging, Constants.DateTime.API_FORMAT) : moment.min();
        this.applicationInsightsInstrumentationKey = dto && dto.applicationInsightsInstrumentationKey ? dto.applicationInsightsInstrumentationKey : '';
        this.clubTimes = dto && dto.clubTimes ? dto.clubTimes : [];
        this.uxV2MainBannerUrl = dto && dto.uxV2MainBannerUrl ? dto.uxV2MainBannerUrl : '';
        this.uxV2RightBannerUrl = dto && dto.uxV2RightBannerUrl ? dto.uxV2RightBannerUrl : '';
        this.bannerAlternateText = dto && dto.bannerAlternateText ? dto.bannerAlternateText : '';
        this.salesTaxes = dto && dto.salesTaxes ? dto.salesTaxes : [];
        this.salesTaxRate = dto && dto.salesTaxRate ? dto.salesTaxRate : 0;
        this.salesTaxTitle = dto && dto.salesTaxTitle ? dto.salesTaxTitle : 0;
        this.timeZoneOffset = dto && dto.timeZoneOffset ? dto.timeZoneOffset : 0;
        this.customSessions = dto && dto.customSessions ? dto.customSessions : '';
        this.recurrentAvailabilityWindow = dto && dto.recurrentAvailabilityWindow ? dto.recurrentAvailabilityWindow : 0;
        this.permissions = dto && dto.permissions ? dto.permissions : [];
        this.emailFooterReference = dto && dto.emailFooterReference ? dto.emailFooterReference : '';
        this.emailFooterReferenceText = dto && dto.emailFooterReferenceText ? dto.emailFooterReferenceText : '';
        this.emailSignature = dto && dto.emailSignature ? dto.emailSignature : '';
        this.logoId = dto && dto.logoId ? dto.logoId : 0;
        this.dashboardLogoId = dto && dto.dashboardLogoId ? dto.dashboardLogoId : 0;
        this.emailLogoId = dto && dto.emailLogoId ? dto.emailLogoId : 0;
        this.emailBackgroundId = dto && dto.emailBackgroundId ? dto.emailBackgroundId : 0;
        this.showPricingPage = dto && dto.showPricingPage ? dto.showPricingPage : 0;
        this.paymentSystem = dto && dto.paymentSystem ? dto.paymentSystem : 0;
        this.priceList = dto && dto.priceList ? dto.priceList : '';
        this.offsetInHours = dto && dto.offsetInHours ? dto.offsetInHours : 0;
        this.minimumNotificationPeriodInDays = dto && dto.minimumNotificationPeriodInDays ? dto.minimumNotificationPeriodInDays : 0;
    }
};
