export interface IBaseDialogStoreState{
    isOpened: boolean;
    returnUrl: string;
}

export class BaseDialogStoreState implements IBaseDialogStoreState{
    isOpened: boolean;
    returnUrl: string;

    constructor(){
        this.isOpened = false;
        this.returnUrl = '';
    }
}
