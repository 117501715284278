import { IBaseDialogStoreState, BaseDialogStoreState } from './baseDialogStoreState';
import * as StM from '../../store';

export interface IEditCoachFeeTierDialogStoreState extends IBaseDialogStoreState {
    id?: number;
}

export class EditCoachFeeTierDialogStoreState extends BaseDialogStoreState {
    id?: number;
    constructor() {
        super();
    }
}