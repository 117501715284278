import BaseReducer from './baseReducer';
import * as StM from '../models/store';

export default class SubscriptionOffersReducer extends BaseReducer {
    static handle(state: StM.ISubscriptionOffersState = new StM.SubscriptionOffersState(), action: any): StM.ISubscriptionOffersState {
        switch (action.type) {
            case 'SubscriptionOffers/Init': {
                var payload = <StM.ISubscriptionOffersState>action.payload;
                return { ...state, ...payload };
            }
            case 'SubscriptionOffers/GetCurrentSubscriptionsSuccess': {
                var currentSubscriptions = action.payload.subscriptions;
                return { ...state,  currentSubscriptions }
            }
            case 'SubscriptionOffers/CommitmentCancellation': {
                return { ...state }
            }
            default: {
                return state;
            }
        }
    }
}
