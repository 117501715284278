import * as Redux from 'redux';

import { ThunkService } from '../../services/thunkService';
import { StM } from '../../modules';
import { AppActions } from '../appActions';
import { AdminCoachFeeTierPricesActions } from './coachFeeTierPricesActions';

export class AdminPricingTiersActions {
    static getAll(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.IPricingTierStoreState[]> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(AppActions.showSpinner())
            return thunkService.api.pricing.getPricingTiers()
                .then((tiers) => {
                    dispatch({
                        type: 'PricingTiers/Init',
                        payload: tiers
                    });
                    dispatch(AppActions.hideSpinner());
                    return tiers;
                }).catch((error) => {
                    dispatch(AppActions.hideSpinner());
                    throw error;
                });
        };
    }

    static save(tiers: StM.IPricingTierStoreState[]): (dispatch: any, 
        getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<StM.IPricingTierStoreState[]> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(AppActions.showSpinner())
            return thunkService.api.pricing.savePricingTiers(tiers)
            .then((updatedTiers) => {
                dispatch({
                    type: 'PricingTiers/Init',
                    payload: updatedTiers
                })
                dispatch(AdminCoachFeeTierPricesActions.getAll());
                dispatch(AppActions.hideSpinner());
                return tiers;
            }).catch((error) => {
                dispatch(AppActions.hideSpinner());
                throw error;
            });
        };
    }

    static deletePricingTier(tierId: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            dispatch(AppActions.showSpinner());
            return thunkService.api.pricing.deletePricingTier(tierId)
                .then(() => {
                    dispatch(AppActions.hideSpinner());
                }).catch((error) => {
                    dispatch(AppActions.hideSpinner());
                    throw error;
                });
        };
    }
}