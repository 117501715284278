import BaseReducer from '../baseReducer';
import { PackageDefinitionDto } from '../../models/dto';

export default class AdminPackagesReducer extends BaseReducer {
    static handle(state: IPackageDefinitionDto[] = [], action: any): IPackageDefinitionDto[] {
        switch (action.type) {
            case 'Admin/Packages': {
                return [...action.payload as IPackageDefinitionDto[]];
            }
            case 'Admin/Package/Update': {
                const packageItem = action.payload as IPackageDefinitionDto;
                const packages = state.filter(p => p.id !== packageItem.id);
                packages.push(packageItem);
                return [...packages];
            }
            default: {
                return state;
            }
        }
    }
}
