import moment from 'moment';
import { ThunkService } from '../services/thunkService';
import * as DtoM from '../models/dto';
import * as StM from '../models/store';
import * as ActM from './';
import { AuthenticationService } from '../services/authenticationService';
import { RouteService } from '../services/routeService';
import {IClubStoreState} from "../models/store";

export class AccountActions {
    public static login(userName: string, password: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thSrv: ThunkService) => {

            return Promise.resolve(thSrv.api.base.postForm('/token',
                {
                    grant_type: 'password'
                    , username: userName
                    , password: password
                }
            ))
                .then((response: any) => {
                    var authTicket = <DtoM.IAuthenticationTicketDto>response.data;
                    dispatch({
                        type: 'AuthTicket/Init',
                        payload: authTicket
                    });
                    dispatch({
                        type: 'AuthTicket/LastAction'
                        , payload: moment()
                    });
                });
        };
    }

    public static loginClub(token: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thSrv: ThunkService) => {

            return Promise.resolve(thSrv.api.base.postForm(`/api/v2/clubs/login/${token}`))
                .then((response: any) => {
                    var authTicket = <DtoM.IAuthenticationTicketDto>response.data;
                    dispatch({
                        type: 'AuthTicket/Init',
                        payload: authTicket
                    });
                    dispatch({
                        type: 'AuthTicket/LastAction',
                        payload: moment()
                    });

                    window.location.href = "/";
                });
        };
    }

    public static switchClub(club: IClubStoreState): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thSrv: ThunkService) => {

            return Promise.resolve(thSrv.api.base.post(`/api/v2/clubs/switch/${club.id}`))
                .then((response: any) => {
                    const token = response.data;

                    window.location.href = thSrv.routeService.getSwitchClubRoute(club, token);
                });
        };
    }

    public static loginOnBehalf(userId: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thSrv: ThunkService) => {
            dispatch(ActM.AppActions.showSpinner());
            return Promise.resolve(thSrv.api.account.signInOnBehalf(userId))
                .then((authTicket: StM.IAuthenticationTicketStoreState) => {
                    dispatch({
                        type: 'AuthTicket/Init',
                        payload: authTicket
                    });
                    dispatch({
                        type: 'AuthTicket/LastAction'
                        , payload: moment()
                    });
                    dispatch(ActM.BasketActions.clear());
                    dispatch(ActM.RouteActions.push(thSrv.routeService.getBookTodayRoute()));
                    return Promise.resolve(dispatch(ActM.UserActions.getCurrent()));
                }).then(() => {
                    const user = getState().user;
                    const authSrv = new AuthenticationService();
                    if(authSrv.isInRole(StM.Roles.GroupAccount, user) 
                        && (!user.paymentProfileId || 
                            user.paymentProfileStatus === StM.PaymentProfileStatus.Suspended || 
                            user.paymentProfileStatus === StM.PaymentProfileStatus.NotInitialized)) 
                    {
                            dispatch(ActM.DialogActions.open(StM.DialogNames.BillingInfo));
                    }
                    const params = getState().app.match.params;
                    return Promise.resolve(dispatch(ActM.SessionActions.updateForCurrentPage(params)));
                }).then(() => {
                    dispatch(ActM.AppActions.hideSpinner());
                }).catch(() => {
                    dispatch(ActM.AppActions.hideSpinner());
                });
        };
    }

    public static switchToGroupAccount(userId: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thSrv: ThunkService) => {
            dispatch(ActM.AppActions.showSpinner());
            return Promise.resolve(thSrv.api.account.signInOnBehalf(userId, true))
                .then((authTicket: StM.IAuthenticationTicketStoreState) => {
                    dispatch({
                        type: 'AuthTicket/Init',
                        payload: authTicket
                    });
                    dispatch({
                        type: 'AuthTicket/LastAction',
                        payload: moment()
                    });
                    
                    window.location.href = thSrv.routeService.getBookTodayRoute();

                }).catch(() => {
                    dispatch(ActM.AppActions.hideSpinner());
                });
        };
    }

    public static resetPass(userMail: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thSrv: ThunkService) => {
            return thSrv.api.account.sendPasswordResetEmail(userMail);
        };
    }

    public static logout(isReloadSessions: boolean = true): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thSrv: ThunkService) => {
            if (navigator.userAgent.indexOf('kiosk') >= 0) {
                let wnd: any = window;
                if (wnd.api) wnd.api.logout(true);
            }
            dispatch({
                type: 'Users/Clear',
            });
            dispatch({
                type: 'Notification/Clear'
            });
            dispatch({
                type: 'Notification/Close',
            });
            dispatch({
                type: 'AuthTicket/Clear',
                payload: null
            });
            dispatch({ type: 'Basket/Clear' });
            dispatch({ type: 'UserExtendedData/Clear' });
            dispatch({
                type: 'App/Disauthorize',
                payload: null
            });
            dispatch({
                type: 'Users/ResetProfileCompletionConfirmation'
            });
            dispatch({
                type: 'GroupAccounts/Clear'
            });
           
            const documentWindow: any = window;

            if (documentWindow.ISADMIN) {
                dispatch(ActM.RouteActions.goHome());
            } else if (!IS_MOBILE) {
                let currentFilter = getState() && getState().app && getState().app.match && getState().app.match.params ? getState().app.match.params : null;
                if(currentFilter && currentFilter.sessionType == StM.BookPageSessionType.Group) {
                    currentFilter = {...currentFilter, sessionType: StM.BookPageSessionType.Play, memberId : null }
                }

                thSrv.cache.removeItem(StM.CacheKey.SessionsByPeriod);
                dispatch(ActM.RouteActions.replace(new RouteService().getBookPage(currentFilter).path));
            }

            return Promise.resolve();
        };
    }

    public static getIsUniqueEmail(email: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<boolean> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thSrv: ThunkService) => {

            return thSrv.api.account.getIsUniqueEmail(email)
                .then((isSucceeded: boolean) => {
                    return isSucceeded;
                });
        };
    }

    public static getIsSharedEmail(email: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<boolean> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thSrv: ThunkService) => {

            return thSrv.api.account.getIsSharedEmail(email)
                .then((isSucceeded: boolean) => {
                    return isSucceeded;
                });
        };
    }

    public static signUp(firstName: string, lastName: string, email: string, password: string, confirmPassword: string,
        newsletterSubscribe: boolean, dateOfBirth: moment.Moment, gender: string, phone: string, isAvailableForInvitation: boolean)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<boolean> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thSrv: ThunkService) => {

            return thSrv.api.account.signUp(firstName, lastName, email, password, confirmPassword, newsletterSubscribe, dateOfBirth, gender, phone, isAvailableForInvitation)
                .then((authTicket: StM.IAuthenticationTicketStoreState) => {
                    dispatch({
                        type: 'AuthTicket/Init',
                        payload: authTicket
                    });
                    dispatch({
                        type: 'AuthTicket/LastAction'
                        , payload: moment()
                    });
                    return true;
                });
        };
    }

    public static getSharedUser(email: string, password: string)
        : (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<IUserRegistrationDto> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thSrv: ThunkService) => {

            return thSrv.api.account.getSharedUser(email, password)
                .then((user: IUserRegistrationDto) => {
                    return user;
                });
        };
    }

    public static resendValidationEmail(email: string): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thSrv: ThunkService) => {
            return thSrv.api.account.resendValidationEmail(email);
        };
    }

    public static revertToSelf(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<void> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thSrv: ThunkService) => {
            dispatch(ActM.AppActions.showSpinner());

            const isselfAsAdmin = getState().authenticationTicket.selfAsAdmin;

            if(isselfAsAdmin){
                return thSrv.api.account.revertToSelf()
                .then((authTicket: StM.IAuthenticationTicketStoreState) => {
                    dispatch({ type: 'AuthTicket/Init', payload: authTicket });
                    dispatch({ type: 'AuthTicket/LastAction', payload: moment() });
                })
                .then(() => {
                    window.location.href = "/admin/";
                }).catch(() => {
                    dispatch(ActM.AppActions.hideSpinner());
                });
            }
            else {
                return thSrv.api.account.revertToSelf()
                .then((authTicket: StM.IAuthenticationTicketStoreState) => {
                    dispatch({ type: 'AuthTicket/Init', payload: authTicket });
                    dispatch({ type: 'AuthTicket/LastAction', payload: moment() });
                    dispatch(ActM.BasketActions.clear());
                    return Promise.resolve(dispatch(ActM.UserActions.getCurrent()));
                }).then(() => {
                    const params = getState().app.match.params;
                    return Promise.resolve(dispatch(ActM.SessionActions.updateForCurrentPage(params)));
                }).then(() => {
                    dispatch(ActM.AppActions.hideSpinner());
                }).catch(() => {
                    dispatch(ActM.AppActions.hideSpinner());
                });
            }
           
        };
    }
}
