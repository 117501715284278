import * as Redux from 'redux';
import * as _ from 'lodash';
import { ThunkService } from '../../services/thunkService';
import * as StM from '../../models/store';

export class AddonsActions {
    constructor() {
    }

    static getAll(): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<Array<IAddonDefinitionDto>> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.getAddons()
                .then((Addon) => {
                    dispatch({
                        type: 'Admin/Addons',
                        payload: Addon
                    });
                    return Addon;
                });
        };
    }

    static saveAddon(definition: IAddonDefinitionDto): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<IAddonDefinitionDto> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.saveAddon(definition);
        };
    }

    static updateAddonOrder(addonId: number, order: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.updateAddonOrder(addonId, order);
        };
    }

    static archiveAddon(addonId: number): (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => Promise<any> {
        return (dispatch: any, getState: () => StM.IGlobalStoreState, thunkService: ThunkService) => {
            return thunkService.api.admin.archiveAddon(addonId);
        };
    }
}