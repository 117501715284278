import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';
import * as StM from '../../store'; 

export interface IContactUsDialogStoreState extends IBaseDialogStoreState{
}

export class ContactUsDialogStoreState extends BaseDialogStoreState{
    constructor(){
        super();
    }
}