import * as React from 'react';
import { connect } from 'react-redux';

import * as StM from '../models/store';
import * as ActM from '../actions';
import * as SrvM from '../services';

interface IErrorBoundaryProps {
    user: StM.IUserStoreState;
    children: React.ReactNode;
    openErrorAlert: (message?: string) => void;
}

interface IErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
    private routeDialogSrv = new SrvM.RouteDialogService();
    private authSrv = new SrvM.AuthenticationService();
    
    constructor(props: IErrorBoundaryProps) {
        super(props);
    }

    public static getDerivedStateFromError() {
        return {hasError: true};
    }

    public componentDidCatch(error: Error, info: any) {
        this.routeDialogSrv.closeOpenedDialogs();
        
        const customWindow: any = window;
        const isAdmin = customWindow.ISADMIN && !this.authSrv.isInRole(StM.Roles.Member, this.props.user);
        const message = isAdmin && error ? error.message : null;
        
        this.props.openErrorAlert(message);
    }

    public render() {
        return this.props.children;
    }
}

const mapStateToProps = (state: StM.IGlobalStoreState, ownProps: any) => ({
    user: state.user,
});

const mapDispatchToProps = (dispatch: any) => ({
    openErrorAlert: (message?: string) => dispatch(ActM.DialogActions.open(StM.DialogNames.Alert, {
        msgKey: StM.MessagesKey.RenderingError,
        messageType: StM.MessageTypes.Error,
        message
    }, true))
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);