import moment from 'moment';

import Constants from '../../../constants';
import { IPublicUserStoreState } from '../users';
import { IClubStoreState } from '../clubs';
import { ServiceAlias } from '../enums';

export interface IAddonDefinitionStoreState {
    id: number;
    createdBy: IPublicUserStoreState;
    created: moment.Moment;
    updatedBy: IPublicUserStoreState;
    updated: moment.Moment;
    isActive: boolean;
    isArchived: boolean;
    club: IClubStoreState;
    price: number;
    title: string;
    description: string;
    alias: string;
    order: number;
}

export class AddonDefinitionStoreState implements IAddonDefinitionStoreState {
    id: number;
    createdBy: IPublicUserStoreState;
    created: moment.Moment;
    updatedBy: IPublicUserStoreState;
    updated: moment.Moment;
    isActive: boolean;
    isArchived: boolean;
    club: IClubStoreState;
    price: number;
    title: string;
    description: string;
    alias: string;
    order: number;

    constructor(dto?: any) {
        this.id = dto && dto.id ? dto.id : 0;
        this.title = dto && dto.title ? dto.title : '';
        this.description = dto && dto.description ? dto.description : '';
        this.alias = dto && dto.alias ? dto.alias : ServiceAlias.Other;
        this.isActive = dto && dto.isActive ? dto.isActive : false;
        this.isArchived = dto && dto.isArchived ? dto.isArchived : false;
        this.created = dto && dto.created ? moment(dto.created, Constants.DateTime.API_FORMAT) : moment.min();
        this.updated = dto && dto.updated ? moment(dto.updated, Constants.DateTime.API_FORMAT) : moment.min();
        this.createdBy = dto && dto.createdBy ? dto.createdBy : null;
        this.updatedBy = dto && dto.updatedBy ? dto.updatedBy : null;
        this.price = dto && dto.price ? dto.price : 0;
        this.order = dto && dto.order ? dto.order : 0;
    }
}