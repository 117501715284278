import * as StM from '../../../models/store';
import BaseReducer from '../../baseReducer';

export class InviteGroupMemberDialogReducer extends BaseReducer {
    public static handle(state: StM.IInviteGroupMemberDialogStoreState = new StM.InviteGroupMemberDialogStoreState(), action: any): StM.IInviteGroupMemberDialogStoreState {
        switch (action.type) {
            case 'Dialogs/InviteGroupMember/Open': {
                return {
                    ...state,
                    isOpened: true,
                    groupId: action.payload,
                } as StM.InviteGroupMemberDialogStoreState;
            }
            case 'Dialogs/InviteGroupMember/Close': {
                return {
                    ...state,
                    isOpened: false,
                    groupId: null,
                } as StM.InviteGroupMemberDialogStoreState
            }
            default: {
                return state;
            }
        }
    }
}
