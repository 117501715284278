import * as StM from '../models/store';
import * as SrvM from '../services';
import { IBasePolicy, BasePolicy } from './basePolicy';
import { store } from '../store/store';

export class GroupInfoPolicy extends BasePolicy implements IBasePolicy {
    private authSrv = new SrvM.AuthenticationService();

    constructor(private user?: StM.IUserStoreState) {
        super();
    }

    public handle() {}

    public getIsAuthenticated() {
        return this.authSrv.isInRole(StM.Roles.GroupAccount, this.getCurrentUser());
    }

    public getIsMember(userId: string, session: StM.ISessionStoreState = null) {
        const user = this.getCurrentUser();
        return this.getIsAuthenticated() && user.group.members.some(m => this.checkIsMember(m, userId, session));
    }

    public getIsHead(userId: string) {
        const user = this.getCurrentUser();
        return this.getIsAuthenticated() && user.group.members.some(m => this.checkIsMember(m, userId) && m.type === StM.GroupMemberType.Head);
    }

    public getGroupMember(userId: string) {
        const user = this.getCurrentUser();
        return this.getIsAuthenticated() && user.group.members.find(m => this.checkIsMember(m, userId));
    }

    public getIsLoggedInAsGroup(userId: string) {
        const state: StM.IGlobalStoreState = store.getState();
        return this.getIsAuthenticated() && state.authenticationTicket.selfId === userId;
    }

    public getMemberTitleMark(userId: string, noParenthesis: boolean = false) {
        if(!this.getIsMember(userId)) return '';
        const result = this.getIsLoggedInAsGroup(userId) ? 'Me' : 'Member';
        return noParenthesis ? result : `(${result})`;
    }

    public checkIsMember(member: StM.IGroupMemberStoreState, userId: string, session: StM.ISessionStoreState = null) {
        const user = this.getCurrentUser();
        return member.invitationStatus === StM.InvitationStatus.Accepted && member.user && member.user.id === userId && (!session || 
            !!user.group && (!!session.bookings.filter((b) => b.groupId == user.group.id && b.userId == userId && b.status != StM.BookingStatus.Cancelled).length
            || !!session.addedUsers.filter((b) => b.id == userId).length
            ));
    }
}