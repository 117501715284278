import './spinner.scss';
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {IGlobalStoreState, IAppStoreState} from '../../models/store';

interface ISpinnerPageProps {
    app: IAppStoreState;
}

interface ISpinnerPageState {
}

class Spinner extends React.Component<ISpinnerPageProps, ISpinnerPageState> {
    constructor(props: ISpinnerPageProps) {
        super(props);
    }

    isTouchDetect():boolean{

        return 'ontouchstart' in window ||
        "ontouchstart" in document.documentElement// works on most browsers 
    //   || 'onmsgesturechange' in window; // works on ie10
}



    render() {
        let styleOverlay = {height: document.getElementById("root").clientHeight + "px", position: "initial" as "initial" | "absolute" |  "fixed"};

        if(this.isTouchDetect()){
            styleOverlay = {height: document.getElementById("root").clientHeight + "px", position: "absolute" as "initial" | "absolute" |  "fixed"};               
                        
        }else{
            styleOverlay = {height: "", position: "fixed" as "initial" | "absolute" |  "fixed"};
        }

        return (this.props.app.isLoading ? 
            <div className="overlay light" style={styleOverlay}>
                <div className="loader-wrapper">
                    <div className="loader1">
                        <i className="first-cirle"></i>
                        <i className="second-cirle"></i>
                        <i className="third-cirle"></i>
                        <i className="fourth-circle"></i>
                    </div>
                </div> 
            </div>: null)
    };

    handleLoginClick(e: any) {
        if (e) { e.preventDefault(); }
    }
};

function mapStateToProps(state: IGlobalStoreState) {
    return {
        app: state.app
    }
}

function mapDispatchToProps(dispatch: any) {
    return {
        //login: bindActionCreators(UserActions.login, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Spinner);
