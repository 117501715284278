import * as Redux from 'redux';
import * as _ from 'lodash';
import {ThunkService} from '../services/thunkService';
import * as DtoModule from '../models/dto';
import * as StoreModule from '../models/store';

export class AddonsActions {

    static init(definitions: Array<StoreModule.IAddonDefinitionStoreState>): (dispatch: any, getState: any, thunkService: ThunkService) => void {
        return (dispatch: any, getState: any, thunkService: ThunkService) => {
            dispatch({
                type: 'Addons/Init',
                payload: definitions
            });
        };
    }
}
