import {IBaseDialogStoreState, BaseDialogStoreState} from './baseDialogStoreState';
import * as StM from '../../store'; 

export interface ITermsAndConditionsDialogStoreState extends IBaseDialogStoreState {
    isAccepted: boolean;
    hideConfimation: boolean;
}

export class TermsAndConditionsDialogStoreState extends BaseDialogStoreState implements ITermsAndConditionsDialogStoreState{
    isAccepted: boolean;
    hideConfimation: boolean;
    constructor(){
        super();
        this.isAccepted = false;
        this.hideConfimation = false;
    }
}